import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import classnames from "classnames";
import { Tooltip } from "@shopify/polaris";
import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import TableData from "../../../common/components/table/TableData";
import TableRow from "../../../common/components/table/TableRow";
import Toggle from "../../../common/components/sixads/toggle/toggle";
import BadgeCell from "../../dynamic-ads/BadgeCell";
import { getFormattedPrice, getNumberWithThousandSeparator, roundNumber, stripLen } from "../../../utils";
import { retryDynamicAdCampaign, changeDynamicAdsCampaignStatus } from "../../../api/api-facebook-ads";
import {
    changeReachInternalStatus,
    changeRemarketingInternalStatus,
    changeUpsellInternalStatus,
    toggleReachCampaignExpansion,
    toggleRemarketingCampaignExpansion,
    toggleUpsellCampaignExpansion,
} from "../../../actions";
import { setBannerMessage, setErrorBanner, setSuccessBanner } from "../../../actions/actions-dynamic-ads";
import EditModal from "./EditModal";
import RetryResourceLaunch from "./RetryResourceLaunch";
import InactiveRow from "../../dynamic-ads/InactiveRow";

const DynamicAdsCampaignToggle = (props) => {
    const { internalStatus, primaryKey, campaignId, campaignType } = props;

    const dispatch = useDispatch();
    const [toggleLoading, setToggleLoading] = useState(false);
    const toggleActiveStatuses = ["active", "paused"];

    const disabled = toggleLoading || !toggleActiveStatuses.includes(internalStatus);
    const value = internalStatus === "active";

    const handleStatusChange = async (value) => {
        setToggleLoading(true);
        const status = value ? "active" : "paused";
        const response = await changeDynamicAdsCampaignStatus(primaryKey, status);
        if (response.error) {
            dispatch(setErrorBanner(true));
            dispatch(setBannerMessage(response.response[0]));
        }
        switch (campaignType) {
            case "reach":
                dispatch(changeReachInternalStatus(primaryKey, campaignId, response.internal_status));
                break;
            case "remarketing":
                dispatch(changeRemarketingInternalStatus(primaryKey, campaignId, response.internal_status));
                break;
            case "upsell":
                dispatch(changeUpsellInternalStatus(primaryKey, campaignId, response.internal_status));
                break;
        }
        setToggleLoading(false);
    };

    return <Toggle value={value} onChange={handleStatusChange} disabled={disabled} />;
};

const DynamicAdCampaignRow = (props) => {
    const dispatch = useDispatch();

    const {
        primaryKey,
        id,
        name,
        namePrefix,
        channelName,
        dailyBudget,
        startDate,
        endDate,
        effectiveStatus,
        internalStatus,
        statusFeedback,
        insights,
        currency,
        resource = "campaign",
        backgroundClass,
        campaign_type,
        inactive,
        isExpanded = true,
    } = props;

    const [retryLoading, setRetryLoading] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);

    const editButtonColor =
        campaign_type === "reach"
            ? "#006fbb"
            : campaign_type === "remarketing"
            ? "#ffe46d"
            : campaign_type === "upsell"
            ? "#de3618"
            : "";

    const handleRetryCampaignLaunch = async () => {
        setRetryLoading(true);
        const apiResponse = await retryDynamicAdCampaign(primaryKey);
        setRetryLoading(false);
        if (apiResponse.error) {
            dispatch(setErrorBanner(true));
            dispatch(setBannerMessage(apiResponse.response[0]));
        } else {
            dispatch(
                campaign_type === "reach"
                    ? changeReachInternalStatus(primaryKey, apiResponse.data.campaign_id, "active")
                    : campaign_type === "remarketing"
                    ? changeRemarketingInternalStatus(primaryKey, apiResponse.data.campaign_id, "active")
                    : campaign_type === "upsell"
                    ? changeUpsellInternalStatus(primaryKey, apiResponse.data.campaign_id, "active")
                    : ""
            );
            dispatch(setSuccessBanner(true));
            dispatch(setBannerMessage("Campaign relaunch successful"));
        }
    };

    const handleExpansionToggle = () => {
        const { primaryKey } = props;

        switch (campaign_type) {
            case "reach":
                dispatch(toggleReachCampaignExpansion(primaryKey));
                break;
            case "remarketing":
                dispatch(toggleRemarketingCampaignExpansion(primaryKey));
                break;
            case "upsell":
                dispatch(toggleUpsellCampaignExpansion(primaryKey));
                break;
            default:
                break;
        }
    };

    const borderColor = inactive
        ? "border-gray-200"
        : campaign_type === "reach"
        ? "border-blue-300"
        : campaign_type === "remarketing"
        ? "border-yellow-200"
        : campaign_type === "upsell"
        ? "border-red-200"
        : "";

    const backgroundClasses = classnames(`border-t border-b ${borderColor}`, {
        [backgroundClass]: backgroundClass.length > 0,
        "bg-transparent ": !backgroundClass.includes("bg-"),
        "opacity-60": retryLoading,
    });

    const dateFormat = (date) => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const d = new Date(date);
        let name = `${month[d.getMonth()]} ${d.getDate()}`;
        return name;
    };

    return (
        <Fragment>
            <EditModal
                name={name}
                campaignType={campaign_type}
                primaryKey={primaryKey}
                editModalShow={editModalShow}
                dailyBudget={dailyBudget}
                startDate={startDate}
                endDate={endDate}
                setEditModalShow={setEditModalShow}
            />
            {!inactive && (
                <TableRow classes={backgroundClasses} key={`${name}-${id}`}>
                    <TableData>
                        {!isExpanded && (
                            <Tooltip active={false} content="Expand row">
                                <IconButton size="small" onClick={() => handleExpansionToggle()}>
                                    <AddCircleIcon style={{ fontSize: 15, color: editButtonColor }} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isExpanded && (
                            <Tooltip active={false} content="Collapse row">
                                <IconButton size="small" onClick={() => handleExpansionToggle()}>
                                    <RemoveCircleIcon style={{ fontSize: 15, color: editButtonColor }} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <DynamicAdsCampaignToggle
                            effectiveStatus={effectiveStatus}
                            internalStatus={internalStatus}
                            primaryKey={primaryKey}
                            campaignId={id}
                            campaignType={campaign_type}
                        />
                    </TableData>

                    <TableData>
                        <Fragment>
                            <BadgeCell
                                effectiveStatus={effectiveStatus}
                                internalStatus={internalStatus}
                                statusFeedback={statusFeedback}
                                resource={resource}
                                resourceRef={`${resource}-${id}`}
                            />
                            {internalStatus === "error" && (
                                <RetryResourceLaunch
                                    resource={resource}
                                    isRetryLoading={retryLoading}
                                    onRetryLaunch={handleRetryCampaignLaunch}
                                />
                            )}
                        </Fragment>
                    </TableData>

                    <TableData>
                        {namePrefix}
                        <div className="flex flex-col">
                            <Tooltip active={false} content={name}>
                                <span className="underline">{stripLen(name, 25)}</span>
                            </Tooltip>
                        </div>
                    </TableData>

                    <TableData>
                        <div className="flex flex-row">
                            <span className="text-gray-700 font-medium text-14px inline-block" tabIndex={-1}>
                                {dateFormat(startDate)} - {dateFormat(endDate)}
                            </span>
                        </div>
                    </TableData>

                    <TableData classes="pl-5 pr-5">{dailyBudget}</TableData>

                    <TableData>
                        {getNumberWithThousandSeparator(insights ? insights.impressions || 0 : 0, " ")}
                    </TableData>

                    <TableData>{getNumberWithThousandSeparator(insights ? insights.clicks || 0 : 0, " ")}</TableData>

                    <TableData>{roundNumber(insights ? insights.ctr || 0 : 0)}%</TableData>

                    <TableData>
                        {currency && currency !== "" && getFormattedPrice(insights ? insights.cpc || 0 : 0, currency)}
                    </TableData>

                    <TableData>
                        {currency && currency !== "" && getFormattedPrice(insights ? insights.cost || 0 : 0, currency)}
                    </TableData>

                    <TableData>{insights ? insights.add_to_cart : 0}</TableData>

                    <TableData>{insights ? insights.sales : 0}</TableData>

                    <TableData>
                        <IconButton size="medium" onClick={() => setEditModalShow(true)}>
                            <EditIcon style={{ fontSize: 24, color: editButtonColor }} />
                        </IconButton>
                    </TableData>
                </TableRow>
            )}
            {inactive && (
                <Fragment>
                    <InactiveRow noOfColumn={13} backgroundClasses={backgroundClasses} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default DynamicAdCampaignRow;
