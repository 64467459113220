import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, Stack, RadioButton, Button } from "@shopify/polaris";

import { setUninstallOption } from "../../actions";

class Options extends Component {
    state = {
        option: "",
    };

    setOption = () => {
        const options = {
            first: "1",
            second: "2",
            third: "3",
            fourth: "4",
            fifth: "5",
            sixth: "6",
        };
        this.props.dispatch(setUninstallOption(options[this.state.option]));
    };

    handleChange = (checked, option) => {
        this.setState({ option: option });
    };

    render() {
        const { option } = this.state;

        return (
            <Card title="Uninstall Sixads App" sectioned={true}>
                <Stack vertical={true}>
                    <RadioButton
                        name="option"
                        checked={option === "first"}
                        id="first"
                        onChange={this.handleChange}
                        label="I don’t use it enough."
                    />
                    <RadioButton
                        name="option"
                        checked={option === "second"}
                        id="second"
                        onChange={this.handleChange}
                        label="I don’t want to show ads on my website."
                    />
                    <RadioButton
                        name="option"
                        checked={option === "third"}
                        id="third"
                        onChange={this.handleChange}
                        label="I don’t like subscription-based charge."
                    />
                    <RadioButton
                        name="option"
                        checked={option === "fourth"}
                        id="fourth"
                        onChange={this.handleChange}
                        label="I am having technical problems."
                    />
                    <RadioButton
                        name="option"
                        checked={option === "fifth"}
                        id="fifth"
                        onChange={this.handleChange}
                        label="It’s too expensive."
                    />
                    <RadioButton
                        name="option"
                        checked={option === "sixth"}
                        id="sixth"
                        onChange={this.handleChange}
                        label="Other reason."
                    />
                </Stack>
                <p className="u-align--right">
                    <Button disabled={!option} onClick={this.setOption}>
                        Continue
                    </Button>
                </p>
            </Card>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(Options);
