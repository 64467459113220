import { INITIATE_STORE_FAILURE, initiate } from "../actions";

const storeMiddleware = (store) => (next) => (action) => {
    const res = next(action);

    if (action.type === INITIATE_STORE_FAILURE) {
        const state = store.getState();

        if (state.store.counter < 5) {
            setTimeout(() => {
                store.dispatch(initiate());
            }, 500);
        }
    }

    return res;
};

export default storeMiddleware;
