import { TOGGLE_TOAST_POPUP } from "../actions";

const initial = {
    active: false,
    type: null,
    messages: [],
};

export const toast = (state = initial, action) => {
    switch (action.type) {
        case TOGGLE_TOAST_POPUP:
            return {
                ...state,
                active: !state.active,
                type: action.payload.type,
                messages: action.payload.messages,
            };

        default:
            return state;
    }
};
