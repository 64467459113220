import PropTypes from "prop-types";
import React from "react";

const Description = (props) => {
    const { description, businessName } = props;
    return (
        <div className="googleAdView-description-wrapper">
            <div className="googleAdView-description">
                <div className="googleAdView-text">{description}</div>
                <div className="googleAdView-business-name">{businessName}</div>
            </div>
            <div className="googleAdView-description-button" />
        </div>
    );
};
Description.propTypes = {
    description: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired,
};

export default Description;
