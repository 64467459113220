import { NAVIGATE_ONBOARDING, SET_ONBOARDING_OPTION } from "../actions";

import { isLocalStorageAvailable } from "../utils";

const onboardingMiddleware = (store) => (next) => (action) => {
    const res = next(action);

    if (action.type === NAVIGATE_ONBOARDING || action.type === SET_ONBOARDING_OPTION) {
        const state = store.getState();

        setTimeout(() => {
            if (isLocalStorageAvailable()) {
                localStorage.setItem(`onboarding-${state.store.id}`, state.onboarding.step);
            }
        }, 10);
    }

    return res;
};

export default onboardingMiddleware;
