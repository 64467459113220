import { ACTIONS } from "../actions/actions-dynamic-ads";

const initialState = {
    create: {
        creative: {
            primaryText: "",
            countries: [],
            audiences: [],
            age: {
                min: 18,
                max: 65,
            },
            gender: "",
        },
        budgetAndSchedule: {
            startDate: "",
            endDate: "",
            dailyBudget: 10,
        },
    },
    type: "",
    showErrorBanner: false,
    showSuccessBanner: false,
    bannerMessage: "",
};

const dynamicAds = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_TYPE:
            const type = action.payload;
            return {
                ...state,
                type,
            };

        case ACTIONS.SET_CREATIVE:
            const creative = action.payload;
            return {
                ...state,
                create: { ...state.create, creative },
            };

        case ACTIONS.SET_BUDGET_AND_SCHEDULE:
            const budgetAndSchedule = action.payload;
            return {
                ...state,
                create: { ...state.create, budgetAndSchedule },
            };

        case ACTIONS.SET_ERROR_BANNER:
            return {
                ...state,
                showErrorBanner: action.payload,
                showSuccessBanner: !action.payload,
            };

        case ACTIONS.SET_SUCCESS_BANNER:
            return {
                ...state,
                showSuccessBanner: action.payload,
                showErrorBanner: !action.payload,
            };

        case ACTIONS.CLOSE_ALL_BANNER:
            return {
                ...state,
                showSuccessBanner: false,
                showErrorBanner: false,
            };

        case ACTIONS.SET_BANNER_MESSAGE:
            const bannerMessage = action.payload;
            return {
                ...state,
                bannerMessage,
            };

        case ACTIONS.RESET_CAMPAIGN_CREATE_STATE:
            return {
                ...state,
                create: {
                    creative: initialState.create.creative,
                    budgetAndSchedule: initialState.create.budgetAndSchedule,
                },
            };

        case ACTIONS.RESET_TYPE:
            return {
                ...state,
                type: "",
            };

        case ACTIONS.RESET_BANNER_MESSAGE:
            return {
                ...state,
                bannerMessage: "",
            };

        default:
            return state;
    }
};

export default dynamicAds;
