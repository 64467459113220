import React from "react";
import PropTypes from "prop-types";
import LayoutOne from "../layouts/LayoutOne";
import LayoutTwo from "../layouts/LayoutTwo";
import LayoutThree from "../layouts/LayoutThree";
import LayoutFour from "../layouts/LayoutFour";

const ImageView = (props) => {
    const {
        template,
        top,
        right,
        bottom,
        left,
        textMargin,
        tagText,
        tagSize,
        tagColor,
        resizable,
        setResizeCoefficient,
    } = props;
    return (
        <React.Fragment>
            {template === 1 && (
                <LayoutOne
                    top={top}
                    right={right}
                    bottom={bottom}
                    left={left}
                    tagColor={tagColor}
                    tagText={tagText}
                    tagSize={tagSize}
                    textMargin={textMargin}
                    resizable={resizable}
                    setResizeCoefficient={setResizeCoefficient}
                />
            )}
            {template === 2 && (
                <LayoutTwo
                    top={top}
                    right={right}
                    bottom={bottom}
                    left={left}
                    tagColor={tagColor}
                    tagText={tagText}
                    tagSize={tagSize}
                    resizable={resizable}
                    setResizeCoefficient={setResizeCoefficient}
                />
            )}
            {template === 3 && (
                <LayoutThree
                    top={top}
                    right={right}
                    bottom={bottom}
                    left={left}
                    tagColor={tagColor}
                    tagText={tagText}
                    tagSize={tagSize}
                    resizable={resizable}
                    setResizeCoefficient={setResizeCoefficient}
                />
            )}
            {template === 4 && (
                <LayoutFour
                    tagColor={tagColor}
                    tagSize={tagSize}
                    resizable={resizable}
                    setResizeCoefficient={setResizeCoefficient}
                />
            )}
        </React.Fragment>
    );
};

ImageView.propTypes = {
    template: PropTypes.number.isRequired,
    tagSize: PropTypes.number,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    tagColor: PropTypes.object.isRequired,
    tagText: PropTypes.string,
    textMargin: PropTypes.number,
    resizable: PropTypes.bool.isRequired,
    setResizeCoefficient: PropTypes.func,
};

ImageView.defaultProps = {
    tagSize: false,
    top: false,
    bottom: false,
    left: false,
    right: false,
    tagText: "",
    textMargin: false,
    setResizeCoefficient: () => {},
};

export default ImageView;
