import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import {
    Welcome,
    AccountConnection,
    CreativeAndPreview,
    BudgetAndSchedule,
    DynamicAdsPlans,
    ThankYou,
} from "../../components/facebook-dynamic-ads/create-campaign";

const CreateCampaign = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Create Dynamic Ad | SIXADS";
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />

            <Switch>
                <Route path="/dynamic-ads/create-campaign/welcome/" component={Welcome} exact={true} />
                <Route
                    path="/dynamic-ads/create-campaign/account-connection/"
                    component={AccountConnection}
                    exact={true}
                />
                <Route
                    path="/dynamic-ads/create-campaign/creative-and-preview/"
                    component={CreativeAndPreview}
                    exact={true}
                />
                <Route path="/dynamic-ads/create-campaign/plans/" component={DynamicAdsPlans} exact={true} />
                <Route
                    path="/dynamic-ads/create-campaign/budget-and-schedule/"
                    component={BudgetAndSchedule}
                    exact={true}
                />
                <Route path="/dynamic-ads/create-campaign/thank-you/" component={ThankYou} exact={true} />
            </Switch>
        </Fragment>
    );
};

export default CreateCampaign;
