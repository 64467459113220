import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoogleAccount from "../settingSections/GoogleAccount";
import { loadGoogleCoreAccount } from "../../../actions";
import StoreRequirements from "../settingSections/StoreRequirements";
import GoogleMerchantAccountConnection from "../settingSections/GoogleMerchantAccountConnection";
import GoogleMerchantAccountShipping from "../settingSections/GoogleMerchantAccountShipping";
import GoogleMerchantAccountTaxSettings from "../settingSections/GoogleMerchantAccountTaxSettings";
import GoogleAdsAccountConnection from "../settingSections/GoogleAdsAccountConnection";
import ClaimWebsiteSettings from "../settingSections/ClaimWebsiteSettings";
import Button from "../../../common/components/button";
import CampaignBudget from "../settingSections/CampaignBudget";
import { CreateGoogleShoppingCampaign } from "../../../api/api-google-shopping";
import Banner from "../../../common/components/sixads/banner";
import ProductFeed from "../settingSections/ProductFeed";
import GoogleAdsAccountBillingSettings from "../settingSections/GoogleAdsAccountBillingSettings";

const ConnectAccounts = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const coreAccount = useSelector((state) => state.googleShopping.coreAccount);
    const isCoreAccountLoading = useSelector((state) => state.googleShopping.isCoreAccountLoading);
    const googleMerchantAccountId = useSelector((state) => state.googleShopping.coreAccount.google_merchant_account_id);
    const storeRequirements = useSelector((state) => state.googleShopping.storeRequirements);
    const shippingSettings = useSelector((state) => state.googleShopping.shippingSettings.services);
    const taxSettings = useSelector((state) => state.googleShopping.taxSettings.rules);
    const adAccount = useSelector((state) => state.googleShopping.coreAccount.google_ads_account_id);
    const campaignBudget = useSelector((state) => state.googleShopping.campaignBudget);
    const coreAccountCountryCode = useSelector((state) => state.googleShopping.coreAccount.targeted_country_code);
    const coreAccountLanguageCode = useSelector((state) => state.googleShopping.coreAccount.targeted_language_code);
    const campaigns = useSelector((state) => state.googleShopping.list.campaigns);
    const adsAccountBilling = useSelector((state) => state.googleShopping.adsAccountBilling);
    const subscription = useSelector((state) => state.store.subscription);

    const [campaignCreationLoading, setCampaignCreationLoading] = useState(false);
    const [bannerState, setBannerState] = useState({
        type: "success",
        text: "",
        showBanner: false,
    });
    const bannerRef = useRef(null);

    const isShippingSettingsAvailable = shippingSettings ? !!shippingSettings.length : false;
    const isTaxSettingsAvailable = (taxSettings ? !!taxSettings.length : false) || (coreAccountCountryCode !== "US");
    const storeRequirementsRemaining = storeRequirements.filter((item) => !item.accepted);
    const isAllStoreRequirementsAccepted = !storeRequirementsRemaining.length && !!storeRequirements.length;
    const isCountryAndLanguageSelected = coreAccountCountryCode && coreAccountLanguageCode;
    const isGoogleMerchantAccountConnected = !!googleMerchantAccountId;
    const isGoogleAdsAccountConnectionAvailable =
        isGoogleMerchantAccountConnected && isShippingSettingsAvailable && isTaxSettingsAvailable;
    const isGoogleAdsAccountConnected = !!adAccount;
    const isBillingSetUp = adsAccountBilling && adsAccountBilling.status === "APPROVED";
    const isUserSubscribedToSixadsPlan = subscription ? !!subscription.id : false;
    const isUnitedStatesSelected = coreAccountCountryCode === "US";

    useEffect(() => {
        if (!coreAccount.email) {
            dispatch(loadGoogleCoreAccount());
        }
    }, []);

    useEffect(() => {
        if (bannerRef.current) {
            bannerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        }
    }, [bannerState]);

    const handleCreateCampaign = () => {
        setCampaignCreationLoading(true);
        CreateGoogleShoppingCampaign(campaignBudget)
            .then((response) => {
                setCampaignCreationLoading(false);
                if (!response.error) {
                    history.push("/google-shopping/create-campaign/thank-you/");
                } else {
                    setBannerState({
                        type: "error",
                        text: "Sorry, google shopping campaign creation failed with an error",
                        showBanner: true,
                    });
                }
            })
            .catch((e) => {
                setCampaignCreationLoading(false);
                setBannerState({
                    type: "error",
                    text: e.response.details,
                    showBanner: true,
                });
            });
    };

    const CampaignBanner = () => (
        <div ref={bannerRef} className="py-4">
            <Banner
                type={bannerState.type}
                onClose={() => {
                    setBannerState((prev) => ({ ...prev, showBanner: false }));
                }}
            >
                {bannerState.text}
            </Banner>
        </div>
    );

    return (
        <div className="py-28">
            <div className="px-10 md:px-28 mx-2">
                {bannerState.showBanner && <CampaignBanner />}
                {!isCoreAccountLoading && <GoogleAccount />}
                {!isCoreAccountLoading && coreAccount.google_credentials_id && <StoreRequirements />}
                {!isCoreAccountLoading && isAllStoreRequirementsAccepted && <ProductFeed />}
                {!isCoreAccountLoading && isAllStoreRequirementsAccepted && isCountryAndLanguageSelected && (
                    <GoogleMerchantAccountConnection />
                )}
                {isGoogleMerchantAccountConnected && coreAccount.claim_website_warning && <ClaimWebsiteSettings />}
                {isGoogleMerchantAccountConnected && <GoogleMerchantAccountShipping />}
                {isGoogleMerchantAccountConnected && isUnitedStatesSelected && <GoogleMerchantAccountTaxSettings />}
                {isGoogleAdsAccountConnectionAvailable && <GoogleAdsAccountConnection />}
                {isGoogleAdsAccountConnected && <GoogleAdsAccountBillingSettings />}
                {isBillingSetUp && isUserSubscribedToSixadsPlan && <CampaignBudget />}
                <br />
                <br />
            </div>

            {isUserSubscribedToSixadsPlan && (
                <div className="bg-white fixed bottom-0 w-full py-6">
                    <div className="px-10 md:px-28 flex justify-end">
                        <Button
                            primary={true}
                            onClick={handleCreateCampaign}
                            loading={campaignCreationLoading}
                            disabled={!adAccount || campaigns.length}
                        >
                            Create Campaign
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConnectAccounts;
