/* eslint-disable no-unused-expressions, prefer-rest-params, no-sequences, prefer-destructuring */
import {
    LOCATION_CHANGE,
    LOG_ANALYTICS,
    SET_ONBOARDING_OPTION,
    SKIP_ONBOARDING,
    SET_UNINSTALL_ACTION,
    SET_UNINSTALL_OPTION,
    COMPLETE_UNINSTALL,
    POSTPONE_UNINSTALL,
} from "../../actions";

import eventTracking from "./events";

import { pushToDataLayer } from "../../utils";

const analyticsId = document.getElementById("analytics").value;

if (typeof window.ga === "undefined") {
    (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r;
        (i[r] =
            i[r] ||
            function () {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

    window.ga("create", analyticsId, "auto");
}

const trackEvent = (fields) => {
    /* eslint-disable no-undef */
    if (!ga) {
        return;
    }
    // on duplicated create this request is ignored
    ga("create", analyticsId, "auto");
    ga("send", fields);
    /* eslint-disable no-undef */
};

const trackPage = (path) => {
    pushToDataLayer({
        event: "SPApageview",
        url: path,
    });
};

const analyticsMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            trackPage(action.path, store.getState());
            break;

        case SET_ONBOARDING_OPTION:
            trackEvent({
                hitType: "event",
                eventCategory: "Onboarding Next Button",
                eventAction: "Click",
                eventLabel: action.option === "machines" ? "Algorithm" : "Manual",
            });

            trackEvent({
                hitType: "event",
                eventCategory: "Onboarding Next Button",
                eventAction: "Click",
                eventLabel: `Step ${action.step}`,
            });
            break;

        case SKIP_ONBOARDING: {
            const { payload } = action;

            trackEvent({
                hitType: "event",
                eventCategory: "Onboarding Skip Setup Button",
                eventAction: "Click",
                eventLabel: `Step ${payload.step}`,
            });
            break;
        }

        case LOG_ANALYTICS:
            trackEvent(action.payload);
            break;

        case SET_UNINSTALL_ACTION:
            trackEvent({
                hitType: "event",
                eventCategory: action.action === "earn" ? "Button" : "Uninstall Button",
                eventAction: "Click",
                eventLabel: action.action === "earn" ? "1st: Earn by Showing Ads" : "Main Page",
            });
            break;

        case SET_UNINSTALL_OPTION:
            trackEvent({
                hitType: "event",
                eventCategory: "Continue Button",
                eventAction: "Click",
                eventLabel: "Uninstall Sequence",
                eventValue: parseInt(action.option, 10),
            });
            break;

        case COMPLETE_UNINSTALL:
            trackEvent({
                hitType: "event",
                eventCategory: "Uninstall Button",
                eventAction: "Click",
                eventLabel: action.reason,
            });
            break;

        case POSTPONE_UNINSTALL:
            trackEvent({
                hitType: "event",
                eventCategory: action.reason,
                eventAction: "Click",
                eventLabel: "Uninstall Sequence",
            });
            break;
        default:
            break;
    }

    if (eventTracking[action.type]) {
        trackEvent(eventTracking[action.type]);
    }

    return next(action);
};

export default analyticsMiddleware;
