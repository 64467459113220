import React, {useEffect, useState} from "react";
import { PropTypes } from "prop-types";
import Grid from "../../grid/grid";
import Select from "../../inputs/Select";
import TextField from "../../inputs/TextField";

export default function Filter(props) {
    const {
        categories = [],
        query = "",
        setQuery = () => {},
        filteredItems = [],
        selectedItems = [],
        itemsCount = null,
        onCategoryChange,
        selectedCategory = ""
    } = props;

    const [gridWidth, setGridWidth] = useState(4);
    const [noColumns, setNoColumns] = useState(1);
    const [items, setItems] = useState(filteredItems);

    const filterOutHiddenItems = (items) => {
        return items.filter((item) => item.hidden !== true)
    }

    const handleGridWidth = () => {
        if (window.innerWidth <= 700) {
            setGridWidth(1);
            setNoColumns(1)
        } else {
            setGridWidth(4);
            setNoColumns(3);
        }
    }

    useEffect(() => {
        handleGridWidth();
        window.addEventListener("resize", handleGridWidth);
    }, []);

    useEffect(() => {
        setItems(filteredItems);
    }, [filteredItems])

    return (
        <Grid>
            <Grid.Row columns={gridWidth}>
                <Grid.Column width={2}>
                    <Grid>
                        <Grid.Row columns={noColumns}>
                            <Grid.Column width={1}>
                                <Select
                                    label=""
                                    options={categories}
                                    onChange={(category) => onCategoryChange(category)}
                                    selected={String(selectedCategory)}
                                    otherClasses="filter-select"
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <TextField
                                    id="search-product"
                                    label=""
                                    placeholder="Search for products"
                                    onChange={(value) => setQuery(value)}
                                    value={query}
                                    otherClasses="filter-select"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={1} otherClasses="flex justify-center items-center">
                    {filterOutHiddenItems(selectedItems).length}/{itemsCount || filterOutHiddenItems(items).length} items selected
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

Filter.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        filter: PropTypes.func,
    })),
    query: PropTypes.string,
    setQuery: PropTypes.func,
    filteredItems: PropTypes.arrayOf(PropTypes.any),
    selectedItems: PropTypes.arrayOf(PropTypes.any),
    onCategoryChange: PropTypes.func,
    selectedCategory: PropTypes.string,
    loading: PropTypes.bool,
};

Filter.defaultProps = {
    categories: [],
    query: "",
    setQuery: () => {},
    filteredItems: [],
    selectedItems: [],
    onCategoryChange: () => {},
    selectedCategory: "",
    loading: false
};
