import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import classnames from "classnames";
import { Collapsible, Tooltip } from "@shopify/polaris";
import { IconButton, Modal } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import {
    logAnalytics,
    toggleTableExpansion,
    loadGoogleShoppingCampaignList,
    googleShoppingUpdateSucceeded,
} from "../../../actions";

import { Table, TableBody, TableData, TableHead, TableHeader, TableRow } from "../../../common/components/table";
import TooltipWithIcon from "../../../common/components/TooltipWithIcon";
import Loader from "../../../common/components/sixads/loader/loader";
import InactiveRow from "../../dynamic-ads/InactiveRow";
import { getFormattedPrice, getNumberWithThousandSeparator, stripLen } from "../../../utils";
import Toggle from "../../../common/components/sixads/toggle/toggle";
import ShowIcon from "../../facebook-dynamic-ads/campaign-list/ShowIcon";
import Banner from "../../../common/components/sixads/banner";
import Button from "../../../common/components/button";
import TotalBudget from "../../../common/components/total-budget/TotalBudget";
import { UpdateGoogleShoppingCampaign } from "../../../api/api-google-shopping";
import { setBannerMessage, setErrorBanner } from "../../../actions/actions-dynamic-ads";
import { Badge } from "../../../common/components/sixads/badge/badge";

const CampaignToggle = (props) => {
    const { campaignId, status } = props;

    const dispatch = useDispatch();
    const [toggleLoading, setToggleLoading] = useState(false);

    const disabled = toggleLoading || !["PAUSED", "SERVING", "PENDING"].includes(status.toUpperCase());
    const value = status.toUpperCase() !== "PAUSED";

    const handleStatusChange = async () => {
        setToggleLoading(true);
        const newStatus = value ? "PAUSED" : "ENABLED";
        const payload = {
            status: newStatus,
        };

        UpdateGoogleShoppingCampaign(campaignId, payload)
            .then((response) => {
                dispatch(googleShoppingUpdateSucceeded({ status: response.status }));
            })
            .catch(() => {
                dispatch(setErrorBanner(true));
                dispatch(setBannerMessage("Status change failed with an error"));
                window.scrollTo({ top: 0, behavior: "smooth" });
            })
            .finally(() => {
                setToggleLoading(false);
            });
    };

    return <Toggle value={value} onChange={handleStatusChange} disabled={disabled} />;
};

const GoogleShoppingCampaigns = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [editModalShow, setEditModalShow] = useState(false);
    const [budget, setBudget] = useState();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [badgeStatus, setBadgeStatus] = useState("DEFAULT");
    const [bannerState, setBannerState] = useState({
        type: "success",
        text: "",
        showBanner: false,
    });
    const [budgetRange, setBudgetRange] = useState({
        min: 2,
        max: 100,
    });
    const campaignType = "google_shopping";

    const { isCampaignsLoading, isTableExpanded, campaigns, googleAdsAccountCurrency, campaignStatus } = useSelector(
        (state) => {
            return {
                isCampaignsLoading: state.googleShopping.list.isLoading,
                isTableExpanded: state.googleShopping.list.isExpanded,
                campaigns: state.googleShopping.list.campaigns,
                googleAdsAccountCurrency: state.googleShopping.coreAccount.google_ads_account_currency,
                campaignStatus: state.googleShopping.list.campaigns.length
                    ? state.googleShopping.list.campaigns[0].status
                    : "",
            };
        }
    );

    useEffect(() => {
        if (campaigns.length) {
            setBudget(campaigns[0].daily_budget.value);
        }
    }, [campaigns]);

    useEffect(() => {
        if (campaigns.length) {
            const status = campaignStatus.toLowerCase() !== "paused" ? "SUCCESS" : "DEFAULT";
            setBadgeStatus(status);
        }
    }, [campaignStatus]);

    useEffect(() => {
        if (googleAdsAccountCurrency) {
            setBudgetRange({
                min: 2 * googleAdsAccountCurrency.usd_equivalent,
                max: 100 * googleAdsAccountCurrency.usd_equivalent,
            });
        }
    }, [googleAdsAccountCurrency]);

    useEffect(() => {
        dispatch(loadGoogleShoppingCampaignList());
    }, []);

    const handleBudgetChange = (ev) => {
        let value = parseFloat(ev.target.value);
        if (Number.isNaN(value)) {
            value = 0;
        }
        setBudget(value);
    };

    const handleRouteToCampaignCreation = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Google Shopping Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Google Shopping Campaign",
            })
        );

        history.push("/google-shopping/create-campaign/welcome/");
    };

    const handleUpdate = () => {
        setBannerState((prev) => ({ ...prev, showBanner: false }));
        setUpdateLoading(true);
        const payload = {
            daily_budget: budget,
        };

        let type;
        let text;
        UpdateGoogleShoppingCampaign(campaigns[0].id, payload)
            .then(() => {
                dispatch(googleShoppingUpdateSucceeded(payload));
                type = "success";
                text = "Campaign update successful";
            })
            .catch(() => {
                type = "error";
                text = "Sorry, system can not update this campaign";
            })
            .finally(() => {
                setUpdateLoading(false);
                setBannerState({
                    type,
                    text,
                    showBanner: true,
                });
            });
    };

    const ifBudgetUpdated = () => {
        return campaigns.length ? campaigns[0].daily_budget.value === parseFloat(budget) : false;
    };

    const titleClasses = classnames("font-sf-display font-semibold text-3xl tracking-wide flex flex-row items-center", {
        "text-gray-275": campaigns.length === 0,
        "text-green-700": campaigns.length > 0,
    });
    const titleTextColor = campaigns.length === 0 ? "#cccccc" : "#1ba583";

    const tableHeadClasses = classnames({
        "bg-gray-200": campaigns.length === 0,
        "bg-green-100": campaigns.length > 0,
    });
    const createCampaignButtonTextClasses = classnames(
        "border rounded-3xl px-6 py-2 flex justify-center items-center text-xl font-medium",
        {
            "text-white bg-green-700 hover:bg-green-500 cursor-pointer": campaigns.length === 0,
            "text-white bg-gray-400 cursor-not-allowed": campaigns.length > 0,
        }
    );

    const ModalBanner = () => (
        <div className="px-6 py-2">
            <Banner
                type={bannerState.type}
                onClose={() => {
                    setBannerState((prev) => ({ ...prev, showBanner: false }));
                }}
            >
                {bannerState.text}
            </Banner>
        </div>
    );

    return (
        <Fragment>
            <div
                id="google-shopping"
                className="border rounded-2xl"
                style={{ boxShadow: "0px 3px 6px #00000029", borderColor: "#CCCCCC" }}
            >
                <div className="px-8 pt-10 pb-5">
                    <div className={titleClasses}>
                        {isTableExpanded && (
                            <Tooltip active={false} content="Collapse section">
                                <IconButton size="small" onClick={() => dispatch(toggleTableExpansion())}>
                                    <RemoveCircleIcon style={{ fontSize: 20, color: titleTextColor }} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!isTableExpanded && (
                            <Tooltip active={false} content="Collapse section">
                                <IconButton size="small" onClick={() => dispatch(toggleTableExpansion())}>
                                    <AddCircleIcon style={{ fontSize: 20, color: titleTextColor }} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <span>Google Smart Shopping</span>
                        <span className="ml-5 flex justify-center items-center">
                            <ShowIcon campaign_type={campaignType} />
                        </span>
                    </div>
                    <Collapsible
                        open={isTableExpanded}
                        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                    >
                        <div
                            className="bg-transparent mt-8 flex flex-col overflow-auto"
                            style={{ maxHeight: "50vh", minHeight: "70px" }}
                        >
                            <div className="flex-grow overflow-auto">
                                {isCampaignsLoading ? (
                                    <Loader />
                                ) : (
                                    <Table>
                                        <TableHead>
                                            <TableRow classes={tableHeadClasses}>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    OFF/ON
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="Delivery"
                                                        text="Current status of your campaigns."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    Campaign name
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="Budget"
                                                        text="The max. amount of money you're willing to spend on your ads."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="Impressions"
                                                        text="The number of times your ads were shown on screen."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="Clicks"
                                                        text="The number of clicks on an ad, based on campaign performance & daily reach. The actual number may differ from estimated."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="CTR"
                                                        text="Click-through-rate. The percentage of people who clicked on your ad."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="CPC"
                                                        text="Cost-per-click. An average amount of money you pay for one ad click."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon label="Conversions" text="" icon="info" />
                                                </TableHeader>
                                                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px">
                                                    <TooltipWithIcon
                                                        label="Edit"
                                                        text="Edit Budget of your Campaign."
                                                        icon="info"
                                                    />
                                                </TableHeader>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {campaigns.map((campaign) => (
                                                <TableRow
                                                    key={campaign.id}
                                                    classes="border-t border-b border-green-300"
                                                >
                                                    <TableData>
                                                        <CampaignToggle
                                                            campaignId={campaign.id}
                                                            status={campaign.status}
                                                        />
                                                    </TableData>
                                                    <TableData>
                                                        <Badge
                                                            status={badgeStatus}
                                                            text={campaign.status.toUpperCase()}
                                                            id={`campaign-${campaign.id}`}
                                                        />
                                                    </TableData>
                                                    <TableData>
                                                        <div className="flex flex-col">
                                                            <Tooltip active={false} content={campaign.name}>
                                                                <span className="underline">
                                                                    {stripLen(campaign.name, 25)}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </TableData>
                                                    <TableData>
                                                        {getFormattedPrice(
                                                            campaign.daily_budget.value,
                                                            campaign.daily_budget.currency
                                                        )}
                                                    </TableData>
                                                    <TableData>
                                                        {getNumberWithThousandSeparator(campaign.impressions, " ")}
                                                    </TableData>
                                                    <TableData>
                                                        {getNumberWithThousandSeparator(campaign.clicks, " ")}
                                                    </TableData>
                                                    <TableData>{campaign.ctr}%</TableData>
                                                    <TableData>
                                                        {getFormattedPrice(campaign.cpc.value, campaign.cpc.currency)}
                                                    </TableData>
                                                    <TableData>{campaign.conversions}</TableData>
                                                    <TableData>
                                                        <IconButton
                                                            size="medium"
                                                            onClick={() => setEditModalShow(true)}
                                                        >
                                                            <EditIcon style={{ fontSize: 24, color: titleTextColor }} />
                                                        </IconButton>
                                                    </TableData>
                                                </TableRow>
                                            ))}

                                            {campaigns.length === 0 && <InactiveRow noOfColumn={10} />}
                                        </TableBody>
                                    </Table>
                                )}
                            </div>
                        </div>
                    </Collapsible>
                    <div className="w-full flex content-center justify-center m-6 mb-2">
                        <button
                            type="button"
                            disabled={campaigns.length}
                            className={createCampaignButtonTextClasses}
                            onClick={handleRouteToCampaignCreation}
                        >
                            <span>+</span>
                            <span className="px-3">Create Smart Shopping campaign</span>
                        </button>
                    </div>
                    <Modal
                        open={editModalShow}
                        onClose={() => setEditModalShow(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div
                            className="absolute top-1/2 left-1/2 w-4/5 md:w-1/2 xl:w-1/3 shadow-2xl bg-white"
                            style={{ transform: "translate(-50%, -50%)" }}
                        >
                            <div>
                                <div className="p-8 w-full text-center text-3xl">
                                    {campaigns.length ? campaigns[0].name : ""}
                                </div>
                                <hr />
                                {bannerState.showBanner && <ModalBanner />}
                                <div className="p-10">
                                    <div className="welcome-description">
                                        Update budget of your Google Shopping Campaign from here.
                                    </div>
                                    <div className="my-10">
                                        <TotalBudget
                                            min={Math.ceil(budgetRange.min)}
                                            max={Math.ceil(budgetRange.max)}
                                            dailyBudget={parseFloat(budget)}
                                            onChange={handleBudgetChange}
                                            perDay={false}
                                            currency={googleAdsAccountCurrency ? googleAdsAccountCurrency.code : ""}
                                        />
                                    </div>
                                </div>
                                <div className="my-8 px-6 w-full flex justify-end">
                                    <Button
                                        disabled={ifBudgetUpdated()}
                                        loading={updateLoading}
                                        primary={true}
                                        onClick={handleUpdate}
                                    >
                                        Edit Campaign
                                    </Button>
                                    <Button basic={true} onClick={() => setEditModalShow(false)}>
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </Fragment>
    );
};

export default GoogleShoppingCampaigns;
