import React, {useState} from "react";
import PropTypes from "prop-types";

import { Star } from "./default-components";

export const Rating = (props) => {
    const { value, numItems, size, onChange } = props;
    const [preview, setPreview] = useState(undefined);

    const selectRating = (val) => {
        onChange({ value: val });
        setPreview(undefined)
    }

    const ratings = Array.from({length: numItems}, (_, i) => i + 1).map(item => {
        return (
            <Star
                key={item}
                isActive={preview !== undefined ? item <= preview : item <= value}
                size={size}
                onMouseOver={() => setPreview(item)}
                onClick={() => selectRating(item)}
            />
        )
    });

    return (
        <div
            onBlur={() => setPreview(undefined)}
            onMouseLeave={() => setPreview(undefined)}
        >
            {ratings}
        </div>
    );
}


Rating.propTypes = {
    value: PropTypes.number,
    numItems: PropTypes.number,
    size: PropTypes.number,
    onChange: PropTypes.func,
};

Rating.defaultProps = {
    value: 1,
    numItems: 5,
    size: 22,
    onChange: () => {},
};

export default Rating;
