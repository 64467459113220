/**
 * Intercom object Initialization script.
 * Taken form intercom documentation:
 * https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 */
const initIntercom = () => {
    /* eslint-disable */
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
    } else {
        var d = document;
        var i = function () {
            i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;
        function l() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/eogapm37";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
        }
        if (w.attachEvent) {
            w.attachEvent("onload", l);
        } else {
            w.addEventListener("load", l, false);
        }
    }
    /* eslint-enable */
};

export const hideIntercomLauncher = (hide) => {
    if (!window.Intercom) {
        initIntercom();
    }

    window.Intercom("update", {
        hide_default_launcher: hide,
    });
};

export default null;
