import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadReachList, loadRemarketingList, loadUpsellList } from "../../../actions";

const useListHook = (listType) => {
    const api = `dynamic-ads-campaigns/?campaign_type=${listType}&`;
    const { results = [], isLoading } = useSelector((state) => ({
        results:
            listType === "reach"
                ? state.reachList.results
                : listType === "remarketing"
                ? state.remarketingList.results
                : listType === "upsell"
                ? state.upsellList.results
                : "",
        isLoading:
            listType === "reach"
                ? state.reachList.isLoading
                : listType === "remarketing"
                ? state.remarketingList.isLoading
                : listType === "upsell"
                ? state.upsellList.isLoading
                : "",
    }));

    const dispatch = useDispatch();

    const loadListItems = useCallback(
        () =>
            dispatch(
                listType === "reach"
                    ? loadReachList(api, listType)
                    : listType === "remarketing"
                    ? loadRemarketingList(api, listType)
                    : listType === "upsell"
                    ? loadUpsellList(api, listType)
                    : ""
            ),
        [dispatch]
    );

    return {
        result: results,
        isLoading: isLoading,
        loadList: loadListItems,
    };
};

export default useListHook;
