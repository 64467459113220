/* eslint-disable react/self-closing-comp */
import React, { Component, Fragment } from "react";

class TableBody extends Component {
    render() {
        const { children } = this.props;

        return (
            <Fragment>
                <tbody>{children}</tbody>
            </Fragment>
        );
    }
}

export default TableBody;
