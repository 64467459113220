/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../common/components/button";
import FacebookSignIn from "../../Facebook/FacebookSignIn";
import { logAnalytics } from "../../../actions";

const AccountConnection = ({ hasAccount, adType }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [nextDisabled, setNextScreenDisabled] = useState(true);

    const nextRoute = "/dynamic-ads/create-campaign/creative-and-preview/";

    useEffect(() => {
        if (hasAccount === true) {
            setNextScreenDisabled(false);
        }
    }, [hasAccount]);

    const handleNextClick = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Facebook Account Connection",
                eventAction: "Button click",
                eventLabel: "Next",
            })
        );

        history.push(nextRoute);
    };

    return (
        <Fragment>
            <div className="pt-24">
                <div className="w-11/12 md:w-2/4 mx-auto my-0 mt-12 font-sf-text pb-40">
                    <div>
                        <h1 className="page-title">
                            Connect your Facebook account{" "}
                            <span role="img" aria-label="Raising Hands">
                                &#128588;
                            </span>
                        </h1>
                        <p className="page-subtitle">
                            You're almost there. Shortly, you'll be running your ads & boosting your sales!
                        </p>
                    </div>
                    <div className="bg-white mt-5 rounded-md shadow">
                        <FacebookSignIn
                            showHowItWorks={false}
                            platformName="Facebook"
                            isProductCatalogRequired={true}
                        />
                    </div>
                </div>
                <div className="bg-white fixed bottom-0 w-full py-6 z-20">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 flex justify-end">
                        <Button primary={true} onClick={handleNextClick} disabled={nextDisabled}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const { settings, dynamicAds } = state;

    const hasAccount = !!(
        settings.facebook.business_manager_id &&
        settings.facebook.page_id &&
        settings.facebook.ad_account_id &&
        settings.facebook.system_user_id
    );
    return {
        hasAccount: hasAccount,
        adType: dynamicAds.type,
    };
}

export default connect(mapStateToProps)(AccountConnection);
