/* eslint-disable camelcase */
const STORE_ID_KEY = "store_id";

export const isLocalStorageAvailable = () => {
    const test = "test";
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
};

export const getItemByStoreId = (key) => {
    const store_id = localStorage.getItem(STORE_ID_KEY);

    let data = "[]";

    if (store_id) {
        data = localStorage.getItem(key + store_id) || "[]";
    }

    return JSON.parse(data);
};

// TODO use this to sest campaign id
export const setItemByStoreId = (key, data) => {
    const store_id = localStorage.getItem(STORE_ID_KEY);

    if (store_id) {
        localStorage.setItem(key + store_id, JSON.stringify(data));
    }
};

// TODO use this to remove campaign id
export const removeItemByStoreId = (key) => {
    const store_id = localStorage.getItem(STORE_ID_KEY);

    if (store_id) {
        localStorage.removeItem(key + store_id);
    }
};

export const saveStateToStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("sixads_state", serializedState);
    } catch {
        //
    }
};
