/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";

const Welcome = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleGetStarted = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Image ad creator flow",
                eventAction: "Button click",
                eventLabel: "Get started",
            })
        );

        history.push("/ad-creative/image-ad-creator/select-product/");
    };

    return (
        <Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                <div className="py-36 md:py-14 lg:pt-0 px-10 md:px-0 flex bg-white justify-center items-center">
                    <div className="h-full md:h-3/5 flex flex-col justify-center items-start w-full md:w-4/5">
                        <h1 className="welcome-title md:pt-10  pl-1">
                            Attention-grabbing Facebook ad images in 3 clicks.{" "}
                            <span role="img" aria-label="Smiling Face with Heart-Eyes">
                                😍
                            </span>{" "}
                            No designer needed.
                        </h1>
                        <p className="welcome-description mt-16 pl-1">
                            Use Facebook ad image creator & make your products stand out. Choose from stunning overlays,
                            backgrounds, frames, & graphic elements. Add texts & offer discounts. No experience needed.
                        </p>
                        <div className="mt-16">
                            <Button class="text-16px" primary={true} onClick={handleGetStarted}>
                                Get started
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="pt-0 md:pt-24 bg-teal-500 flex justify-center items-center">
                    <img src="/static/img/image-overlay-ads.png" alt="Image overlay ads" className="w-full md:w-3/4" />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(Welcome);
