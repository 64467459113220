import classnames from "classnames";
import { createWithClassesOrStyles } from "../../../../../../utils/override";
import { smallScreen } from "../../../../../../utils/window-size";

export const Root = (props) =>
    createWithClassesOrStyles({
        type: "span",
        props: props,
    });

export const IconContainer = (props) => {
    const { isCompleted = false, isActive = false, ...restProps } = props;

    const classes = classnames("center ", {
        [["circle--regular"]]: !isCompleted && !isActive,
        [["circle--active"]]: isActive,
        [["circle--completed"]]: isCompleted && !isActive,
        [["vertical--center"]]: true,
    });

    return createWithClassesOrStyles({
        type: "span",
        classes: classes,
        props: restProps,
    });
};

export const ContentContainer = (props) => {
    const classes = classnames("vertical--center text-body-regular", {
        [["spacing--left"]]: !smallScreen(),
    });

    return createWithClassesOrStyles({
        type: "span",
        classes: classes,
        props: props,
    });
};
