import { PUT_ONBOARDING_ERROR, PUT_ONBOARDING_VALUE } from "../actions";

const initial = {
    isActive: false,
    isSkipped: false,
    step: 0,
    option: "machines",
    pages: {
        aboutBusiness: {
            values: {
                businessName: "",
                businessWebsite: "",
                businessCountry: "",
                businessCurrency: "",
                businessCategory: "",
            },
            errors: [],
        },
        importProducts: {
            values: {
                shopifyProducts: [],
                selectedProducts: [],
            },
            errors: [],
        },
        CampaignGeoTargeting: {
            values: {
                selectedCountriesIds: [],
            },
            errors: [],
        },
        productAds: {
            values: {
                items: [],
            },
            errors: [],
        },
        adEdit: {
            values: {
                primaryText: "",
                headline: "",
                description: "",
            },
            errors: [],
        },
        adTargeting: {
            values: {
                country: "",
                targetDevice: "",
                gender: "",
                minAge: "",
                maxAge: "",
            },
        },
        plans: {
            values: {
                subscription: {
                    facebookEnabled: false,
                    instagramEnabled: false,
                    googleEnabled: false,
                },
            },
            errors: [],
        },
        budgetAndSchedule: {
            values: {
                productAds: [],
                dates: {
                    start: null,
                    end: null,
                },
                dailyBudget: {
                    facebook: null,
                    instagram: null,
                    google: null,
                },
            },
            errors: [],
        },
        accountConnection: {
            values: {
                facebook: {
                    required: false,
                    connected: false,
                },
                google: {
                    required: false,
                    connected: false,
                },
            },
            errors: [],
        },
    },
    current: {
        hasErrors: false,
    },
};

// eslint-disable-next-line import/prefer-default-export
export const createCampaign = (state = initial, action) => {
    switch (action.type) {
        case PUT_ONBOARDING_VALUE:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page]: {
                        values: {
                            ...action.values,
                        },
                        errors: [...state.pages[action.page].errors],
                    },
                },
            };
        case PUT_ONBOARDING_ERROR:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page]: {
                        values: {
                            ...state.pages[action.page].values,
                        },
                        errors: [...action.errors],
                    },
                },
            };
        default:
            return state;
    }
};
