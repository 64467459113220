import { FETCH_COUNTRIES_FAILURE, loadCountries } from "../actions";

const countriesMiddleware = (store) => (next) => (action) => {
    const res = next(action);

    if (action.type === FETCH_COUNTRIES_FAILURE) {
        const { countries } = store.getState();

        if (countries.counter < 5) {
            setTimeout(() => {
                store.dispatch(loadCountries());
            }, 500);
        }
    }

    return res;
};

export default countriesMiddleware;
