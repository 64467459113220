/* eslint-disable eqeqeq */
import {
    LOCATION_CHANGE,
    TOGGLE_NAVIGATION,
    INIT_ONBOARDING,
    EXIT_ONBOARDING,
    INITIATE_STORE_SUCCESS,
    TOGGLE_SECTION,
    NAVIGATION_INITIATED,
} from "../actions";

const initial = {
    path: window.location.pathname + window.location.search,
    isOpen: !(document.documentElement.clientWidth < 769),
    initialSate: true,
    sections: [],
};

export const navigation = (state = initial, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                path: action.path,
            };
        case TOGGLE_NAVIGATION:
            return {
                ...state,
                // eslint-disable-next-line no-nested-ternary
                isOpen: action.status === "open" ? true : action.status === "close" ? false : !state.isOpen,
            };
        case INIT_ONBOARDING:
            return {
                ...state,
                isOpen: false,
            };
        // case SKIP_ONBOARDING: // ToDo: return when /analytics/ is react page
        case EXIT_ONBOARDING:
            return {
                ...state,
                isOpen: true,
            };
        case INITIATE_STORE_SUCCESS: {
            return { ...state, initialSate: true };
        }
        case NAVIGATION_INITIATED:
            state.sections.map((section) => {
                section.pages.map((page) => {
                    if (action.path === page.path) {
                        section.showPages = true;
                    }
                });
            });
            return {
                ...state,
                initialSate: false,
            };
        case TOGGLE_SECTION:
            state.sections.map((section, index) => {
                if (index === action.index) {
                    section.showPages = !section.showPages;
                }
            });
            return {
                ...state,
            };
        default:
            return state;
    }
};
