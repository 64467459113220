/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template13 = ({ name, image, price, adMessage, paused = false, discount }) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["text"]["text"] = adMessage || "SPECIAL DISCOUNT";

        animationObject["ProductNameObj"]["ProductName"]["text"]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName2"]["text"]["text"] = name;
        animationObject["ProductNameObj3"]["ProductName3"]["text"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow2"]["text"]["text"] = price;
        animationObject["PriceTagObj3"]["PriceNow3"]["text"]["text"] = price;

        animationObject["DiscountTagObj"]["DiscountTag"]["text"]["text"] = discount;
        animationObject["DiscountTagObj2"]["DiscountTag2"]["text"]["text"] = discount;
        animationObject["DiscountTagObj3"]["DiscountTag3"]["text"]["text"] = discount;

        const images = AdobeAn.getComposition("CCB70635D19A4CE536ZEA617094ZFB50").getImages();
        images["Bitmap6111"]["src"] = image;
        images["Screenshot20210729at2246371"]["src"] = image;
        images["Screenshot20210729at2247091"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_13"
                composition="CCB70635D19A4CE536ZEA617094ZFB50"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template13.design_name = "green_grey_bubble_bottom";
Template13.image_width = 1000;
Template13.image_height = 1000;
Template13.extra_fields = ["discount"];

export default Template13;
