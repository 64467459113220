export const INIT_UNINSTALL = "INIT_UNINSTALL";
export const EXIT_UNINSTALL = "EXIT_UNINSTALL";
export const COMPLETE_UNINSTALL = "COMPLETE_UNINSTALL";
export const POSTPONE_UNINSTALL = "POSTPONE_UNINSTALL";
// export const REDIRECT_UNINSTALL = 'REDIRECT_UNINSTALL';
export const SET_UNINSTALL_MONEY = "SET_UNINSTALL_MONEY";
export const SET_UNINSTALL_ACTION = "SET_UNINSTALL_ACTION";
export const SET_UNINSTALL_OPTION = "SET_UNINSTALL_OPTION";

export const initUninstall = () => ({
    type: INIT_UNINSTALL,
});

export const exitUninstall = () => ({
    type: EXIT_UNINSTALL,
});

export const completeUninstall = (reason) => ({
    type: COMPLETE_UNINSTALL,
    reason: reason,
});

export const postponeUninstall = (reason) => ({
    type: POSTPONE_UNINSTALL,
    reason: reason,
});

// export const redirectUninstall = () => ({
//     type: REDIRECT_UNINSTALL
// })

export const setUninstallMoney = () => ({
    type: SET_UNINSTALL_MONEY,
});

export const setUninstallOption = (option) => ({
    type: SET_UNINSTALL_OPTION,
    option: option,
});

export const setUninstallAction = (action) => ({
    type: SET_UNINSTALL_ACTION,
    action: action,
});
