import {
    CREATE_ADS_SUCCESS,
    REMOVE_ADS_SUCCESS,
    UPDATE_ADS_REQUEST,
    UPDATE_ADS_SUCCESS,
    CREATE_TAGS_SUCCESS,
    REMOVE_TAGS_REQUEST,
    REMOVE_TAGS_SUCCESS,
} from "../actions";

const initial = {
    isLoading: true,
    isError: false,
    counter: 0,
    items: [],
    pages: 1,
    page: 1,
    isExtending: false,
};

export const ads = (state = initial, action) => {
    switch (action.type) {
        case REMOVE_ADS_SUCCESS:
            return {
                ...state,
                items: state.items.filter((item) => action.payload.items.some((o) => o.id !== item.id)),
            };

        case CREATE_ADS_SUCCESS:
            return {
                ...state,
                items: state.items.concat(action.payload.items),
            };

        case UPDATE_ADS_REQUEST:
            return {
                ...state,
                items: state.items.map((item) => {
                    const props = action.payload.filter((p) => p.id === item.id);

                    const prop = props.length ? props[0] : null;

                    return prop ? { ...item, isEnabling: prop.isEnabling, isLoading: prop.isLoading } : item;
                }),
            };

        case UPDATE_ADS_SUCCESS:
            return {
                ...state,
                items: state.items.map((item) => {
                    const props = action.data.filter((p) => p.id === item.id);

                    const prop = props.length ? props[0] : null;

                    return prop ? { ...item, ...prop, isEnabling: false, isLoading: false } : item;
                }),
            };

        case CREATE_TAGS_SUCCESS:
            if (!action.payload.items || !Array.isArray(action.payload.items)) {
                return state;
            }

            return {
                ...state,
                items: state.items.map((item) => {
                    const tags = action.payload.items.filter((tag) => tag.product_id === item.id);
                    return tags.length ? { ...item, tags: item.tags.concat(tags) } : item;
                }),
            };

        case REMOVE_TAGS_REQUEST:
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.tags.some((tag) => action.payload.indexOf(tag.id) !== -1)) {
                        const tags = item.tags.map((tag) => ({
                            ...tag,
                            isRemoving: action.payload.indexOf(tag.id) !== -1,
                        }));
                        return { ...item, tags: tags };
                    }
                    return item;
                }),
            };

        case REMOVE_TAGS_SUCCESS:
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.tags.some((tag) => action.payload.indexOf(tag.id) !== -1)) {
                        const tags = item.tags.filter((tag) => action.payload.indexOf(tag.id) === -1);
                        return { ...item, tags: tags };
                    }

                    return item;
                }),
            };

        default:
            return state;
    }
};
