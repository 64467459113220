import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import Button from "../../common/components/button";
import { logAnalytics, createFeatureRequest } from "../../actions";

const Upgrade = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleReguestUpdate = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Video flow upgrade",
                eventAction: "Button click",
                eventLabel: "Confirm upgrade",
            })
        );

        dispatch(
            createFeatureRequest({
                type: 12,
            })
        );

        history.push("/video-ads/thanks/");
    };

    const handleCancelUpdate = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Video flow upgrade",
                eventAction: "Button click",
                eventLabel: "Reject upgrade",
            })
        );

        history.push("/video-ads/select-template/");
    };

    return (
        <Fragment>
            <div className="flex flex-col md:flex-row h-screen">
                <div className="flex-1 bg-white flex items-center justify-center">
                    <div className="w-2/4 md:w-2/4 md:h-1/3 flex flex-col justify-center items-center">
                        <div className="pb-6 md:pt-0 pt-24">
                            <h1 className="page-title pb-20 text-center title">
                                Great! You’re on your way to running unique & personalized video ads{" "}
                                <span role="img" aria-label="Star Struck">
                                    🤩
                                </span>
                            </h1>
                            <h2 className="leading-120p font-bold text-gray-900 pb-6 subtitle-text">
                                To use our designer services, please upgrade. <br /> <br />
                                The upgrade includes:
                            </h2>
                            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                            <p className="leading-140p text-18px pb-6 upgrade-list">
                                ✔️ Designer services <br />
                                ✔️ 5 personalized, branded templates <br />
                                ✔️ Option to customize with your brand's fonts, colors, & logos <br />
                                ✔️ Templates for seasons, holidays, & marketing goals <br />
                            </p>
                        </div>
                        <div className="flex buttons">
                            <Button onClick={handleCancelUpdate}>NO THANKS</Button>
                            <Button primary={true} onClick={handleReguestUpdate}>
                                UPGRADE
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx={true}>{`
                .subtitle-text {
                    color: #2c615f;
                    font-size: 24px;
                }
                .title {
                    font-size: 32px;
                }
                .upgrade-list {
                    line-height: 28px;
                    color: 6d6d6d;
                }
                @media screen and (max-width: 768px) {
                    .title {
                        font-size: 22px;
                    }
                    .upgrade-list {
                        font-size: 14px;
                    }
                    .subtitle-text {
                        font-size: 18px;
                    }
                    .buttons {
                        flex-direction: column-reverse;
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default withRouter(Upgrade);
