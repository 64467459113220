import React from "react";
import { Card, Layout, Stack, Image, TextContainer } from "@shopify/polaris";

const ShowHowItWorks = () => (
    <Card.Section title="How It Works">
        <div className="mobileStack">
            <Stack distribution="fillEvenly" style={{ alignItems: "center" }}>
                <Layout.Section oneFourth={true}>
                    <div style={{ textAlign: "center" }}>
                        <Image
                            source="/static/img/how-it-works-1.svg"
                            alt="Connect Facebook ads manager to sixads"
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                            }}
                        />
                        <TextContainer>
                            Connect Facebook Ads <br /> Manager to sixads
                        </TextContainer>
                    </div>
                </Layout.Section>
                <Layout.Section oneFourth={true}>
                    <div style={{ textAlign: "center" }}>
                        <Image
                            source="/static/img/how-it-works-2.svg"
                            alt="Select products to advertise"
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                            }}
                        />
                        <p>
                            Select products <br />
                            to advertise
                        </p>
                    </div>
                </Layout.Section>
                <Layout.Section oneFourth={true}>
                    <div style={{ textAlign: "center" }}>
                        <Image
                            source="/static/img/how-it-works-3.svg"
                            alt="Approve automatically created visuals, target audiences, and texts"
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                            }}
                        />
                        <TextContainer>
                            Approve automatically created <br />
                            visuals, target audiences, and texts
                        </TextContainer>
                    </div>
                </Layout.Section>
                <Layout.Section oneFourth={true}>
                    <div style={{ textAlign: "center" }}>
                        <Image
                            source="/static/img/how-it-works-4.svg"
                            alt="Start selling straight away!"
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                            }}
                        />
                        <p>
                            Start selling
                            <br /> straight away!
                        </p>
                    </div>
                </Layout.Section>
            </Stack>
        </div>
    </Card.Section>
);

export default ShowHowItWorks;
