import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import { Welcome, AboutBusiness, FacebookHeadlines } from "../../components/ad-text-creator";

const AdTextCreator = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        window.document.title = "Ad Text Creator | SIXADS";
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />

            <div>
                <Switch>
                    <Route path="/ad-text-creator/">
                        <Redirect to={`/ad-creative${location.pathname}`} />
                    </Route>
                    <Route path="/ad-creative/ad-text-creator/welcome/" component={Welcome} exact={true} />
                    <Route path="/ad-creative/ad-text-creator/about-business/" component={AboutBusiness} exact={true} />
                    <Route
                        path="/ad-creative/ad-text-creator/fb-headlines/"
                        component={FacebookHeadlines}
                        exact={true}
                    />
                </Switch>
            </div>
        </Fragment>
    );
};

export default AdTextCreator;
