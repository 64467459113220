import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { Toast } from "@shopify/polaris";

import Welcome from "../pages/onboarding-v2/Welcome";

import VideoAdsRouter from "../pages/video-ads/Router";
import VideoAdsV2Router from "../pages/video-ads-v2/Router";

import HowToGuides from "../pages/how-to/HowToGuides";
import PaymentSuccessful from "../pages/PaymentSuccessful";
// import FacebookAudit from "../pages/facebook-audit/FacebookAudit";
import AdTextCreator from "../pages/ad-text-creator/AdTextCreator";
import GoogleShopping from "../pages/google-shopping/GoogleShopping";
import ProductList from "../pages/google-shopping/ProductList";
import DynamicAdsCreateCampaign from "../pages/facebook-dynamic-ads/CreateCampaign";
import ImageOverlayAds from "../pages/image-overlay-ads/ImageOverlayAds";
import DropshippingCourse from "../pages/dropshipping-course/DropshippingCourse";
import CustomVideoAds from "../pages/custom-video-ads/CustomVideoAds";

import Uninstall from "../pages/Uninstall";
import CancelBoostedPlanSuccessful from "../pages/CancelBoostedPlanSuccessful";
// import HowToSellOnShopify from "../pages/HowToSellOnShopify";
import BoostedAds from "../pages/BoostedAds";
import FourZeroFour from "../pages/FourZeroFour";
import { ConnectPlus } from "../components/ConnectPlus";

import { toggleToastPopup } from "../actions";
import Header from "../components/Header";
import { Campaigns } from "../pages/dynamic-ads";
import AdCreative from "../pages/ad-creatives/AdCreative";
import AdsExchange from "../pages/ads-exchange/AdsExchange";

const RedirectForLegacyRoute = () => {
    return <Redirect to="/dynamic-ads/" />;
};

class Routes extends Component {
    render() {
        const { dispatch, toastIsActive, toastMessages, toastIsError } = this.props;

        const toastMarkup = toastIsActive ? (
            <Toast
                content={
                    <ul>
                        {toastMessages.map((message) => (
                            <li key={message}>{message}</li>
                        ))}
                    </ul>
                }
                error={toastIsError}
                onDismiss={() => dispatch(toggleToastPopup())}
            />
        ) : null;

        return (
            <Fragment>
                <Switch>
                    {/*
                        Legacy routes for redirecting to main page
                        Routes have to be before the other routes
                        Otherwise it will cause redirecting loop
                    */}
                    <Route path="/manage-campaigns/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/products-list/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/my-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/settings/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/facebook-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/import-facebook-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/my-facebook-ads/archived/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/my-facebook-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/instagram-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/my-instagram-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/import-instagram-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/google-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/import-google-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/my-google-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    <Route path="/partner-ads/">
                        <RedirectForLegacyRoute />
                    </Route>
                    {/* End of legacy routes */}

                    <Route exact={true} path="/welcome/">
                        <Welcome />
                    </Route>
                    <Route path="\/ads-exchange\/([a-zA-Z0-9-_/]+)"
                        exact={true}
                        component={AdsExchange}
                    />
                    <Route
                        path="\/dynamic-ads/create-campaign\/([a-zA-Z0-9-_/]+)"
                        exact={true}
                        component={DynamicAdsCreateCampaign}
                    />
                    <Route path="/dynamic-ads/">
                        <div className="l-main l-main--cpio">
                            <Header />
                            <Campaigns />
                        </div>
                    </Route>
                    <Route
                        path="\/google-shopping/create-campaign\/([a-zA-Z0-9-_/]+)"
                        exact={true}
                        component={GoogleShopping}
                    />
                    <Route path="/google-shopping/">
                        <div className="l-main l-main--cpio">
                            <Header />
                            <ProductList />
                        </div>
                    </Route>
                    <Route path="/video-ads/">
                        <div className="l-main">
                            <VideoAdsRouter />
                        </div>
                    </Route>
                    <Route path="/dropshipping-course/">
                        <DropshippingCourse />
                    </Route>
                    <Route path="/how-to-guides/">
                        <div className="l-main">
                            <Header />
                            <HowToGuides />
                        </div>
                    </Route>
                    <Route path="/video-ads-v2/">
                        <VideoAdsV2Router />
                    </Route>
                    {/* <Route path="/account-audits/">
                        <div>
                            <Header />
                            <FacebookAudit />
                        </div>
                    </Route> */}
                    <Route path="/image-ad-creator/">
                        <div>
                            <Header />
                            <ImageOverlayAds />
                        </div>
                    </Route>
                    <Route path="/ad-text-creator/">
                        <div>
                            <Header />
                            <AdTextCreator />
                        </div>
                    </Route>
                    <Route path="/ad-creative/">
                        <div>
                            <Header />
                            <AdCreative />
                        </div>
                    </Route>
                    <Route path="/custom-video-ads/">
                        <div>
                            <Header />
                            <CustomVideoAds />
                        </div>
                    </Route>
                    <Route path="/boosted-ads/">
                        <div className="l-main">
                            <Header />
                            <BoostedAds />
                        </div>
                    </Route>
                    <Route path="/app/uninstall/">
                        <div className="l-main l-main--cpio">
                            <Header />
                            <Uninstall />
                            <ConnectPlus />
                        </div>
                    </Route>
                    <Route path="/payment/received/">
                        <div className="l-main l-main--cpio">
                            <PaymentSuccessful />
                        </div>
                    </Route>
                    <Route path="/boosted/cancel/">
                        <div className="l-main l-main--cpio">
                            <CancelBoostedPlanSuccessful />
                        </div>
                    </Route>
                    {/* <Route path="/how-to-sell-on-shopify/">
                        <div className="l-main">
                            <Header />
                            <HowToSellOnShopify />
                        </div>
                    </Route> */}
                    <Route>
                        <div className="l-main">
                            <FourZeroFour />
                            <ConnectPlus />
                        </div>
                    </Route>
                </Switch>

                {toastMarkup}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { toast } = state;

    return {
        toastIsActive: toast.active,
        toastMessages: toast.messages,
        toastIsError: toast.type === "error",
    };
};

export default withRouter(connect(mapStateToProps)(Routes));
