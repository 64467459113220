/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template5 = ({
    name,
    image = `${window.location.origin}/static/img/video_promotions/template_5/index_atlas_1.png?1624295446159`,
    price,
    adMessage,
    paused = false,
}) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"] = adMessage || "Special Discount";

        animationObject["ProductNameObj_1"]["children"][0]["text"] = name;

        animationObject["PriceTagObj"]["text"]["text"] = price;

        const images = AdobeAn.getComposition("148E8DF03C7F9A40A01D95D24D23057D").getImages();
        images["index_atlas_1"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_5"
                composition="148E8DF03C7F9A40A01D95D24D23057D"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template5.design_name = "Pets";

/* Get the width and height from template file
(lib.HeroImgObj = function (mode, startPosition, loop, reversed) {
    ....
}).prototype = getMCSymbolPrototype(lib.HeroImgObj, new cjs.Rectangle(-1, 0, 1761, 1174), null);
*/
// 800*800 is not in the template, but if we use template's size here it make the animation worse.
Template5.image_width = 800;
Template5.image_height = 800;

export default Template5;
