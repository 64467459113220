/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetDynamicAdReducer, setType } from "../../../actions/actions-dynamic-ads";
import Button from "../../../common/components/button";
import { getAllDynamicAds } from "../../../api/api-facebook-ads";
import { logAnalytics } from "../../../actions";

const ThankYou = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const adType = useSelector((state) => state.dynamicAds.type);

    const [descriptionOne, setDescriptionOne] = useState("");
    const [descriptionTwo, setDescriptionTwo] = useState("");
    const [descriptionThree, setDescriptionThree] = useState("");

    const [redirectButtonText, setRedirectButtonText] = useState("");
    const [showReachCampaignButton, setShowReachCampaignButton] = useState(false);
    const [showRemarketingCampaignButton, setShowRemarketingCampaignButton] = useState(false);
    const [showUpsellCampaignButton, setShowUpsellCampaignButton] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await getAllDynamicAds();
            const reachCount = result.filter(
                (item) => item.internal_type === "reach" && item.internal_status === "active"
            ).length;

            const hasNoReach = reachCount === 0;
            const hasNoRemarketing = reachCount === 0;
            const hasNoUpsell = reachCount === 0;

            // TODO: The switch statement looks very ugly with all these if/else. Need to come up with a more elegant solution.
            const createReachCampaignText =
                "We also encourage you to create a Dynamic Reach campaign, to attract new visitors to your store.";
            const createRemarketingCampaignText =
                "We also encourage you to create a Dynamic Retargeting campaign, to invite your past visitors back to your store.";
            const createUpsellCampaignText =
                "We also encourage you to create a Dynamic Upsell campaign, [PLACEHOLDER].";

            setRedirectButtonText("Explore your campaign");
            switch (adType.toLowerCase()) {
                case "reach":
                    setDescriptionOne(
                        "Congrats! You have just created a Dynamic Reach campaign which will help you attract new shoppers to your store! Your ads are in review now, which might take up to 48 hours."
                    );

                    if (hasNoRemarketing) {
                        setDescriptionTwo(createRemarketingCampaignText);
                        setShowRemarketingCampaignButton(true);
                    }
                    if (hasNoUpsell) {
                        setDescriptionThree(createUpsellCampaignText);
                        setShowUpsellCampaignButton(true);
                    }
                    if (hasNoRemarketing || hasNoUpsell) {
                        setRedirectButtonText("No, thanks");
                    }

                    break;
                case "remarketing":
                    setDescriptionOne(
                        "Congrats! You have just created a Dynamic Retargeting campaign which will help you invite your past visitors back to your store! Your ads are in review now, which might take up to 48 hours."
                    );

                    if (hasNoReach) {
                        setDescriptionTwo(createReachCampaignText);
                        setShowReachCampaignButton(true);
                    }
                    if (hasNoUpsell) {
                        setDescriptionThree(createUpsellCampaignText);
                        setShowUpsellCampaignButton(true);
                    }
                    if (hasNoReach || hasNoUpsell) {
                        setRedirectButtonText("No, thanks");
                    }

                    break;
                case "upsell":
                    setDescriptionOne(
                        "Congrats! You have just created a Dynamic Upsell campaign [PLACEHOLDER]! Your ads are in review now, which might take up to 48 hours."
                    );

                    if (hasNoReach) {
                        setDescriptionTwo(createReachCampaignText);
                        setShowReachCampaignButton(true);
                    }
                    if (hasNoRemarketing) {
                        setDescriptionThree(createRemarketingCampaignText);
                        setShowRemarketingCampaignButton(true);
                    }
                    if (hasNoReach || hasNoRemarketing) {
                        setRedirectButtonText("No, thanks");
                    }

                    break;
                default:
                    break;
            }
        })();
    }, []);

    const handleCreateCampaignButtonClick = (campaignType) => {
        dispatch(resetDynamicAdReducer());
        dispatch(setType(campaignType));
        history.push("/dynamic-ads/create-campaign/account-connection/");
    };

    return (
        <Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                <div className="pt-0 md:pt-24 bg-teal-500 flex justify-center items-center">
                    <img src="/static/img/dynamic_ads_thank_you.png" alt="Ad text" className="w-full md:w-3/5" />
                </div>
                <div className="py-36 md:py-14 lg:pt-0 px-10 md:px-0 flex bg-white justify-center items-center">
                    <div className="h-full md:h-3/5 flex flex-col justify-center items-start w-full md:w-4/5">
                        <h1 className="welcome-title md:pt-10  pl-1">
                            Congrats!
                            <span role="img" aria-label="party_popper" className="pl-5">
                                🎉
                            </span>
                            <br />
                            <div className="pt-4">You're all done.</div>
                        </h1>
                        <p className="welcome-description mt-16 pl-1">{descriptionOne}</p>
                        <p className="welcome-description mt-8 pl-1">{descriptionTwo}</p>
                        <p className="welcome-description mt-8 pl-1">{descriptionThree}</p>
                        <div className="flex justify-between mt-36">
                            {showReachCampaignButton && (
                                <Button
                                    class="text-16px"
                                    primary={true}
                                    onClick={() => {
                                        dispatch(
                                            logAnalytics({
                                                hitType: "event",
                                                eventCategory: "Dynamic Campaign Creation Flow",
                                                eventAction: "Button click",
                                                eventLabel: "Create Reach Campaign(Thankyou)",
                                            })
                                        );
                                        handleCreateCampaignButtonClick("REACH");
                                    }}
                                >
                                    Create Reach Campaign
                                </Button>
                            )}
                            {showRemarketingCampaignButton && (
                                <Button
                                    class="text-16px"
                                    primary={true}
                                    onClick={() => {
                                        dispatch(
                                            logAnalytics({
                                                hitType: "event",
                                                eventCategory: "Dynamic Campaign Creation Flow",
                                                eventAction: "Button click",
                                                eventLabel: "Create Retargeting Campaign(Thankyou)",
                                            })
                                        );
                                        handleCreateCampaignButtonClick("REMARKETING");
                                    }}
                                >
                                    Create Retargeting Campaign
                                </Button>
                            )}
                            {showUpsellCampaignButton && (
                                <Button
                                    class="text-16px"
                                    primary={true}
                                    onClick={() => {
                                        dispatch(
                                            logAnalytics({
                                                hitType: "event",
                                                eventCategory: "Dynamic Campaign Creation Flow",
                                                eventAction: "Button click",
                                                eventLabel: "Create Upsell Campaign(Thankyou)",
                                            })
                                        );
                                        handleCreateCampaignButtonClick("UPSELL");
                                    }}
                                >
                                    Create Upsell Campaign
                                </Button>
                            )}
                            {redirectButtonText && (
                                <Button
                                    class="text-16px"
                                    onClick={() => {
                                        dispatch(
                                            logAnalytics({
                                                hitType: "event",
                                                eventCategory: "Dynamic Campaign Creation Flow",
                                                eventAction: "Button click",
                                                eventLabel: "Redirect to dashboard",
                                            })
                                        );
                                        history.push("/dynamic-ads/campaigns/");
                                    }}
                                >
                                    {redirectButtonText}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ThankYou;
