import React from "react";
import { Link } from "react-router-dom";

export default function (props) {
    const { sections } = props;
    const page = sections[0] && sections[0].pages && sections[0].pages[0] ? sections[0].pages[0] : {};

    return page.isSinglePage ? (
        <Link to={page.path} className="c-logo">
            <svg width="100px" height="100px" className="c-logo--svg">
                <use xlinkHref="#svg-logo" />
            </svg>
        </Link>
    ) : (
        <a href={page.path} className="c-logo">
            <svg width="100px" height="100px" className="c-logo--svg">
                <use xlinkHref="#svg-logo" />
            </svg>
        </a>
    );
}
