import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import AdTextCreator from "../ad-text-creator/AdTextCreator";
import ImageOverlayAds from "../image-overlay-ads/ImageOverlayAds";

const AdCreative = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />

            <div>
                <Switch>
                    <Route path="/ad-creative/ad-text-creator/" component={AdTextCreator} />
                    <Route path="/ad-creative/image-ad-creator/" component={ImageOverlayAds} />
                </Switch>
            </div>
        </Fragment>
    );
};

export default AdCreative;
