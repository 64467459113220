/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { Fragment } from "react";
import { Button, DatePicker, FormLayout, Modal, Card, Stack, TextStyle } from "@shopify/polaris";
import { CalendarMajorMonotone } from "@shopify/polaris-icons";
import moment from "moment";
import PropTypes from "prop-types";
import dateUtils from "../../../../utils/date";
import DateRangeSelector from "./date-range-selector";

class DateRangePicker extends React.Component {
    today = new Date();

    inSevenDays = dateUtils.nDaysLater(7);

    yesterday = dateUtils.nDaysAgo(1);

    aLongTimesBefore = dateUtils.nDaysAgo(365000);

    aLongTimesLater = dateUtils.nDaysLater(365000);

    static propTypes = {
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        disabled: PropTypes.bool,
        isPastDatesDisabled: PropTypes.bool,
        showRangeSelector: PropTypes.bool,
        updateDates: PropTypes.func.isRequired,
        fullWidthButton: PropTypes.bool,
    };

    static defaultProps = {
        startDate: {},
        endDate: {},
        disabled: false,
        showRangeSelector: false,
        fullWidthButton: false,
        isPastDatesDisabled: true,
    };

    constructor(props) {
        super(props);

        const initialDates = this.getInitialDates();

        this.state = {
            month: initialDates.startDate.getMonth(),
            year: initialDates.startDate.getFullYear(),
            selected: {
                start: initialDates.startDate,
                end: initialDates.endDate,
            },
            dayDifference: "0",
            dayDifferenceLabel: "Today",
            active: false,
        };
    }

    isDateObject = (date) => {
        if (date) {
            return typeof date.getMonth === "function";
        }
        return false;
    };

    getInitialDates = () => {
        const { startDate, endDate, showRangeSelector = false } = this.props;

        if (this.isDateObject(startDate) && this.isDateObject(endDate)) {
            return {
                startDate: startDate,
                endDate: endDate,
            };
        }

        return {
            startDate: this.today,
            endDate: showRangeSelector ? this.today : this.inSevenDays,
        };
    };

    toggleModal = () => {
        /* eslint-disable no-unused-vars, react/no-access-state-in-setstate */
        this.setState(({ active }) => {
            return { active: !this.state.active };
        });
        /* eslint-disable no-unused-vars, react/no-access-state-in-setstate */
    };

    handleUpdate = () => {
        this.setState({ active: false });
        this.props.updateDates(this.state.selected);
    };

    handleChange = (value) => {
        const { showRangeSelector = false } = this.props;

        this.setState({ selected: value });
        if (showRangeSelector) {
            this.setState({
                dayDifference: "1",
                dayDifferenceLabel: "Custom",
            });
        }
    };

    handleMonthChange = (month, year) => {
        this.setState({
            month: month,
            year: year,
        });
    };

    getRangeViewer = () => {
        const {
            selected: { start = this.today, end = this.today },
            dayDifferenceLabel = "Today",
        } = this.state;

        return (
            <Card sectioned={true}>
                <Stack alignment="center">
                    <Button onClick={this.toggleModal} disabled={this.props.disabled} icon={CalendarMajorMonotone}>
                        {dayDifferenceLabel}
                    </Button>
                    <TextStyle variation="subdued">
                        Comparing from {moment(start).format("MMMM D, Y")} to {moment(end).format("MMMM D, Y")}
                    </TextStyle>
                </Stack>
            </Card>
        );
    };

    render() {
        const { month, year, selected = {}, dayDifference = "0", dayDifferenceLabel = "Today", active } = this.state;

        const { showRangeSelector = false, isPastDatesDisabled, children, render } = this.props;

        const disabledDates = showRangeSelector ? this.aLongTimesBefore : this.yesterday;
        const disableDatesBefore = isPastDatesDisabled ? disabledDates : false

        const childrenItems = children
        ? (<div onClick={this.toggleModal}>{children}</div>)
        : (
            <Button
                onClick={this.toggleModal}
                disabled={this.props.disabled}
                fullWidth={this.props.fullWidthButton}
            >
                Change dates
            </Button>
        );

        const activator = (
            render ?
            (render(this.toggleModal))
            :
            childrenItems  
        );

        return (
            <Fragment>
                {showRangeSelector ? (
                    this.getRangeViewer()
                ) :
                    activator
                }
                <Modal
                    open={active}
                    title="Choose dates"
                    onClose={this.toggleModal}
                    primaryAction={{
                        content: "Update date selection",
                        onAction: this.handleUpdate,
                    }}
                >
                    <Modal.Section>
                        {showRangeSelector && (
                            <DateRangeSelector
                                selected={selected}
                                dayDifference={dayDifference}
                                dayDifferenceLabel={dayDifferenceLabel}
                                onDateRangeSelect={({ selected, dayDifference, dayDifferenceLabel }) => {
                                    this.setState({
                                        selected: selected,
                                        dayDifference: dayDifference,
                                        dayDifferenceLabel: dayDifferenceLabel,
                                    });
                                }}
                            />
                        )}
                        <FormLayout>
                            <DatePicker
                                month={month}
                                year={year}
                                onChange={this.handleChange}
                                onMonthChange={this.handleMonthChange}
                                selected={selected}
                                allowRange={true}
                                multiMonth={true}
                                disableDatesBefore={disableDatesBefore}
                            />
                        </FormLayout>
                    </Modal.Section>
                </Modal>
            </Fragment>
        );
    }
}

export default DateRangePicker;
