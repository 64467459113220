import React from "react";

import PropTypes from "prop-types";

export default function DateField({
    fieldLabel = "",
    fieldValue = "",
    onClick = () => {},
    onChange = () => {},
    disabled = false,
}) {
    return (
        <div className="">
            <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                    <label
                        id="
                    PolarisTextField7Label"
                        htmlFor="PolarisTextField7"
                        className="Polaris-Label__Text"
                    >
                        {fieldLabel}
                    </label>
                </div>
            </div>
            <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    <div className="Polaris-TextField">
                        <input
                            id="PolarisTextField7"
                            className="Polaris-TextField__Input"
                            type="text"
                            aria-labelledby="PolarisTextField7Label"
                            aria-invalid="false"
                            aria-multiline="false"
                            value={fieldValue}
                            onClick={onClick}
                            onChange={onChange}
                            disabled={disabled}
                        />
                        <div className="Polaris-TextField__Backdrop" />
                    </div>
                </div>
            </div>
        </div>
    );
}

DateField.prototypes = {
    fieldLabel: PropTypes.string,
    fieldValue: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

DateField.defaultProps = {
    fieldLabel: "",
    fieldValue: "",
    onClick: () => {},
    onChange: () => {},
    disabled: false,
};
