import styled from "styled-components";

const CornerTagImageContainer = styled.div`
    position: relative;
    padding: 0px;
    margin: 0px;
    max-width: ${(props) => props.maxWidth}px;
    min-width: ${(props) => (props.resizable ? "" : props.maxWidth)}px;
    display: block;
    border: ${(props) => (props.template === 4 ? "" : "1px solid")};
`;

export default CornerTagImageContainer;
