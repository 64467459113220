import React from "react";

export const ALIGNMENT = "items-center";
export const POSITION = "absolute";
export const DIRECTION = "right";

export const Tooltip = ({
    children,
    tooltipText,
    alignment = ALIGNMENT,
    positon: position = POSITION,
    direction = DIRECTION,
    singleLine = false,
}) => {
    const tipRef = React.createRef(null);

    function handleMouseEnter() {
        tipRef.current.style.opacity = 1;
    }
    function handleMouseLeave() {
        tipRef.current.style.opacity = 0;
    }

    return (
        <div className={`relative flex ${alignment}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div
                className={`${position} whitespace-no-wrap bg-black text-white px-4 py-2 rounded flex items-center transition-all duration-150 ${
                    alignment === "items-end" ? "mb-1" : ""
                } tooltipholder--${direction}`}
                style={{ opacity: 0 }}
                ref={tipRef}
            >
                <div className={`bg-black h-3 w-3 absolute tooltip--${direction}`} />
                <pre className={`text-center tooltip-pre ${singleLine ? "tooltip-pre--singleline" : ""}`}>
                    {tooltipText.split(" ").join("\n")}
                </pre>
            </div>
            {children}
        </div>
    );
};
