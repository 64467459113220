import { useDispatch, useSelector } from "react-redux";
import { useCallback, useRef } from "react";
import { loadList, loadMoreItems } from "../../../../actions";

const getScrollingElement = () => {
    if (document.scrollingElement) {
        return document.scrollingElement;
    }

    const docElement = document.documentElement;
    const docElementRect = docElement.getBoundingClientRect();

    return {
        scrollHeight: Math.ceil(docElementRect.height),
        scrollTop: Math.abs(docElementRect.top),
    };
};

/**
 * Use List Hook is a common hook to load list and paginate them (with infinite scroll!)
 *
 * @param listType
 * @param api
 * @returns {{
 *     results: *,
 *     isLoading: *,
 *     handleScroll: handleScroll,
 *     loadList: (function(): *),
 *     isScrolling: *
 * }}
 */

const useListHook = ({ listType = null, api = listType }) => {
    const { results = [], isLoading, next, isScrolling, currentListType, resultsCount } = useSelector((state) => ({
        results: state.list.results,
        resultsCount: state.list.count,
        isLoading: state.list.isLoading,
        next: state.list.next,
        isScrolling: state.list.isScrolling,
        currentListType: state.list.type,
    }));
    const isLoadingNextPageRef = useRef(isScrolling);
    const nextRef = useRef(next);
    isLoadingNextPageRef.current = isScrolling;
    nextRef.current = next;

    const dispatch = useDispatch();
    const handleScroll = (event) => {
        const element = event.target;
        if (
            Math.trunc(getScrollingElement().scrollHeight - getScrollingElement().scrollTop) ===
            element.documentElement.clientHeight
        ) {
            if (nextRef.current && !isLoadingNextPageRef.current) {
                const nextUrl = new URL(nextRef.current);
                const loadMoreUrl = `${nextUrl.pathname}${nextUrl.search}`;
                dispatch(loadMoreItems(loadMoreUrl));
            }
        }
    };

    const loadListItems = useCallback((filters = "") => dispatch(loadList(api, filters, listType, 36)), [dispatch]);

    return {
        results: results,
        resultsCount: resultsCount,
        isLoading: isLoading,
        loadList: loadListItems,
        dispatch: dispatch,
        handleScroll: handleScroll,
        isScrolling: isScrolling,
        listType: currentListType,
    };
};

export default useListHook;
