/* eslint-disable */
import React, { Fragment } from "react";
import { Button as PolarisButton } from "@shopify/polaris";
import PropTypes from "prop-types";

export default function Button(props) {
    const { children = "", style = {}, className = "", id = "" } = props;

    return (
        <div style={style} className={className}>
            <PolarisButton {...props} id={id}>{children}</PolarisButton>
        </div>
    );
}

Button.propTypes = {
    children: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
    className: PropTypes.string,
    id: PropTypes.string,
};

Button.defaultProps = {
    style: {},
    className: "",
    id: "",
};
