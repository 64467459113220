import React from "react";
import { useSelector } from "react-redux";

import { funnelWithDottedLine, tickIcon, errorIcon } from "../../../../utils/icons-utils";
import DashboardIndicator from "../DashboardIndicator";

const FunnelWidget = () => {
    const reachList = useSelector((state) => state.reachList.results);

    const remarketingList = useSelector((state) => state.remarketingList.results);

    const upsellList = useSelector((state) => state.upsellList.results);

    const FunnelIcon = funnelWithDottedLine;

    return (
        <div className="border border-gray-275 rounded-2xl shadow-md">
            <div className="p-5">
                <div className="flex justify-center w-full font-sf-display font-semibold text-3xl tracking-wide py-4 mb-4">
                    Funnel dashboard
                </div>
                <div className="w-full flex justify-center">
                    <div className="flex flex-col p-6 pl-0">
                        <div className="mt-4">{reachList.length ? tickIcon() : errorIcon()}</div>
                        <div className="mt-11">{remarketingList.length ? tickIcon() : errorIcon()}</div>
                        <div className="mt-4">{upsellList.length ? tickIcon() : errorIcon()}</div>
                    </div>
                    <FunnelIcon />
                </div>

                <div className="mt-6 flex flex-col justify-center items-center">
                    <DashboardIndicator campaign_type="Reach" active={reachList.length} />
                    <DashboardIndicator campaign_type="Remarket" active={remarketingList.length} />
                    <DashboardIndicator campaign_type="Upsell" active={upsellList.length} />
                </div>
            </div>
        </div>
    );
};

export default FunnelWidget;
