/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getStoreInformation } from "../../../api/api-store";
import Button from "../../../common/components/sixads/button/button";
import CurrentAdView from "./CurrentAdView";

const Welcome = () => {
    const [products, setProducts] = useState([]);
    const history = useHistory();
    const nextRoute = "/google-shopping/create-campaign/plans/";

    useEffect(() => {
        (async () => {
            const result = await getStoreInformation();
            setProducts(result.results.filter((item) => item.images.length !== 0 && item.inventory_quantity > 0));
        })();
    }, []);

    const DescriptionPara = ({ title, underline = true, children }) => (
        <div className="flex flex-col w-full justify-center items-center md:items-start pt-10">
            <div>
                <span className="text-gray-700 font-bold text-3xl">{title.toUpperCase()}</span>
                <p className="welcome-description pt-5">{children}</p>
            </div>
            {underline && <hr className="mt-10 w-full" />}
        </div>
    );

    const handleNext = () => {
        history.push(nextRoute);
    };

    return (
        <Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                <div className="py-36 px-10 md:px-0 flex bg-white justify-center items-center">
                    <div className="h-full md:h-3/5 flex flex-col justify-center items-start w-full md:w-4/5">
                        <h1 className="welcome-title md:pt-10">How a Smart Shopping campaign works</h1>
                        <DescriptionPara title="CAMPAIGN BUDGET AND BILLING">
                            You only pay if someone clicks on your shopping ad. Google Ads uses an average daily budget.
                            If your daily budget is $15, Google might spend $10 on one day and use the extra money to
                            spend $20 the next day. You will be billed once a month. Learn more about managing your ad
                            budget.
                        </DescriptionPara>

                        <DescriptionPara title="CAMPAIGN DURATION">
                            Your campaign will run until you pause it. Once a campaign begins, It takes Google about 14
                            days to learn how to best promote your products online. During this learning period, we
                            recommend not making changes to your campaign.
                        </DescriptionPara>

                        <DescriptionPara title="PRODUCTS IN YOUR CAMPAIGN">
                            Google creates a unique Smart Shopping ad for every product in your feed that has been
                            approved and has been synced to your Google Merchant Center.
                        </DescriptionPara>

                        <DescriptionPara title="SHOPPING AD PLACEMENT" underline={false}>
                            Smart Shopping campaigns use Google's smart technology to optimize your ads and
                            automatically show your products to shoppers who are searching for products like yours.
                            Smart Shopping campaigns also use remarketing lists from previous website traffic. Your
                            products might appear on Search, YouTube, Gmail or Google's Display network
                        </DescriptionPara>

                        <div className="mt-14 md:mt-18 lg:mt-24">
                            <Button class="text-2xl" primary={true} onClick={handleNext}>
                                Create Goggle Smart Shopping Campaign
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="md:pt-24 px-10 xl:px-20 py-10 md:py-0 bg-teal-500 flex justify-center items-center">
                    <div className="bg-gray-200 mx-24 pb-24 flex flex-col justify-center items-center">
                        <CurrentAdView
                            primaryText="primaryText"
                            headline={products.length ? products[0].title : ""}
                            description={products.length ? `${products[0].price} ${products[0].currency}` : ""}
                            image={products.length ? products[0].images[0].src : ""}
                        />
                        <div className="w-3/4 md:w-1/2 pt-10">
                            Each of your product variants will appear as a separate individual ad. Ads may appear
                            differently depending on the platform and device. A typical ad might look like this
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Welcome;
