import React from "react";
import { createWithClassesOrStyles, getComponents } from "../../../../../utils/override";

const StyledProgressBarContainer = (props) =>
    createWithClassesOrStyles({
        type: "div",
        props: props,
    });

const StyledBar = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: 'bar',
        props: props,
    });

const StyledProgress = (props) => (
    <div className="progress-bar" {...props}>
        {props.value}%
    </div>
)

const StyledContent = (props) =>
    createWithClassesOrStyles({
        type: "span",
        props: props,
    });

const defaultComponents = {
    ProgressBarContainer: StyledProgressBarContainer,
    Bar: StyledBar,
    Progress: StyledProgress,
    Content: StyledContent,
};

export default function ProgressBar(props) {
    const { content = null, value = 10, overrides = {} } = props;
    const {
        ProgressBarContainer: { component: ProgressBarContainer, props: containerProps },
        Bar: { component: Bar, props: barProps },
        Progress: { component: Progress, props: progressProps },
        Content: { component: Content, props: contentProps },
    } = getComponents(defaultComponents, overrides);

    return (
        <ProgressBarContainer {...props} {...containerProps}>
            <Bar {...props} {...barProps}>
                <Progress {...props} {...progressProps} style={{ width: `${value}%` }}>
                    {value || <span>&nbsp;</span>}
                </Progress>
            </Bar>
            <Content {...props} {...contentProps}>
                {content}
            </Content>
        </ProgressBarContainer>
    );
}
