import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import GoogleSettingPanelButton from "../../../common/components/GoogleSettingPanelButton";
import SettingsLayout from "./SettingsLayout";
import { GoogleCredentialsDisconnectRequest } from "../../../api/api-google-shopping";
import GoogleLogin from "../GoogleLogin";

const ConnectButton = ({ clientId }) => {
    return (
        <GoogleLogin
            renderElement={<GoogleSettingPanelButton buttonType="CONNECT">Connect</GoogleSettingPanelButton>}
            clientId={clientId}
        />
    );
};

const DisconnectButton = ({ googleCredentialId }) => {
    const onGoogleCredentialsDisconnectClick = () => {
        GoogleCredentialsDisconnectRequest();
    };

    return (
        <GoogleSettingPanelButton
            buttonType="DISCONNECT"
            disabled={!googleCredentialId}
            onClick={onGoogleCredentialsDisconnectClick}
        >
            Disconnect
        </GoogleSettingPanelButton>
    );
};

const GoogleAccount = () => {
    const coreAccount = useSelector((state) => state.googleShopping.coreAccount);
    const loading = useSelector((state) => state.googleShopping.isCoreAccountLoading);

    const isGoogleCredentialsConnected = !!coreAccount.google_credentials_id;

    let button = <ConnectButton clientId={coreAccount.google_client_id} />;
    if (isGoogleCredentialsConnected) {
        button = <DisconnectButton googleCredentialId={coreAccount.google_credentials_id} />;
    }

    return (
        <SettingsLayout
            header="Google Account"
            subtitle="Shopify uses this account to manage and sync with Google Merchant Center and Google Ads."
            loading={loading}
        >
            <Grid container={true} spacing={3} className="flex flex-row items-center justify-between">
                <Grid item={true} xs={6} className="float-left">
                    <div className="flex flex-row items-center welcome-description">
                        <img
                            className="p-1 m-2"
                            src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/539a975b7a4165d6faf641ce1c682ff0.svg"
                            height={40}
                            width={40}
                            alt="Google Account"
                        />
                        {coreAccount.email || "Please connect Sixads app with your Google Account"}
                    </div>
                </Grid>
                <Grid item={true} xs={6}>
                    <div className="w-full h-full flex justify-end items-center">{button}</div>
                </Grid>
            </Grid>
        </SettingsLayout>
    );
};

export default GoogleAccount;
