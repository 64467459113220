import React from "react";
import { PropTypes } from "prop-types";
import Column from "./column";
import Row from "./row";

const Grid = ({ children, style, otherClasses }) => (
    <div className={`grid ${otherClasses}`} style={style}>
        {children}
    </div>
);

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    otherClasses: PropTypes.string,
};

Grid.defaultProps = {
    style: {},
    otherClasses: '',
};

Grid.Row = Row;
Grid.Column = Column;

export default Grid;
