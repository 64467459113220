/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// TODO: Merge this tooptip component with the existing front-end/js/common/components/tooltip/tooltip.js
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

class Tooltip extends Component {
    static propTypes = {
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        position: PropTypes.oneOf(["top", "bottom"]),
        type: PropTypes.oneOf(["default", "success"]),
    };

    static defaultProps = {
        position: "bottom",
        type: "default",
    };

    state = {
        active: false,
    };

    setTooltipStatus = (status) => {
        this.setState({
            active: status,
        });
    };

    render() {
        const { text, position, type, children } = this.props;
        const { active } = this.state;

        const tooltipTextContainerClasses = classnames(
            "absolute text-white p-5 rounded-lg top-1/2 left-1/2 transform -translate-x-1/2",
            {
                [["-translate-y-7/4"]]: position === "top",
                [["translate-y-3/4"]]: position === "bottom",
                [["bg-black"]]: type === "default",
                [["bg-green-500"]]: type === "success",
            }
        );

        return (
            <Fragment>
                <div className="relative">
                    {active && <div className={tooltipTextContainerClasses}>{text}</div>}

                    <div
                        onMouseOver={() => this.setTooltipStatus(true)}
                        onMouseOut={() => this.setTooltipStatus(false)}
                    >
                        {children}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Tooltip;
