import { get } from "../api/api-list";
import { toggleToastPopup } from "./action-toast";
import { api } from "../utils";

export const FETCH_REMARKETING_LIST_REQUEST = "FETCH_REMARKETING_LIST_REQUEST";
export const FETCH_REMARKETING_LIST_SUCCESS = "FETCH_REMARKETING_LIST_SUCCESS";
export const FETCH_REMARKETING_LIST_ERROR = "FETCH_REMARKETING_LIST_ERROR";
export const UPDATE_REMARKETING_CAMPAIGN = "UPDATE_REMARKETING_CAMPAIGN";
export const TOGGLE_REMARKETING_CAMPAIGN_EXPANSION = "TOGGLE_REMARKETING_CAMPAIGN_EXPANSION";
export const TOGGLE_REMARKETING_TABLE_EXPANSION = "TOGGLE_REMARKETING_TABLE_EXPANSION";

export const ACTIONS = {
    RETRY_LAUNCHING_AD_SET_REQUESTED: "dynamicRemarketingCampaign/RETRY_LAUNCHING_AD_SET_REQUESTED",
    RETRY_LAUNCHING_AD_SET_SUCCEED: "dynamicRemarketingCampaign/RETRY_LAUNCHING_AD_SET_SUCCEED",
    RETRY_LAUNCHING_AD_SET_FAILED: "dynamicRemarketingCampaign/RETRY_LAUNCHING_AD_SET_FAILED",

    FETCH_AD_SET_REQUESTED: "dynamicRemarketingCampaign/FETCH_AD_SET_REQUESTED",
    FETCH_AD_SET_SUCCEED: "dynamicRemarketingCampaign/FETCH_AD_SET_SUCCEED",
    FETCH_AD_SET_FAILED: "dynamicRemarketingCampaign/FETCH_AD_SET_FAILED",

    RETRY_LAUNCHING_AD_REQUESTED: "dynamicRemarketingCampaign/RETRY_LAUNCHING_AD_REQUESTED",
    RETRY_LAUNCHING_AD_SUCCEED: "dynamicRemarketingCampaign/RETRY_LAUNCHING_AD_SUCCEED",
    RETRY_LAUNCHING_AD_FAILED: "dynamicRemarketingCampaign/RETRY_LAUNCHING_AD_FAILED",

    FETCH_AD_REQUESTED: "dynamicRemarketingCampaign/FETCH_AD_REQUESTED",
    FETCH_AD_SUCCEED: "dynamicRemarketingCampaign/FETCH_AD_SUCCEED",
    FETCH_AD_FAILED: "dynamicRemarketingCampaign/FETCH_AD_FAILED",
};

export const loadRemarketingListRequest = (listType) => {
    return {
        type: FETCH_REMARKETING_LIST_REQUEST,
        listType: listType,
    };
};

export const loadRemarketingListSucceeded = (payload) => {
    return {
        type: FETCH_REMARKETING_LIST_SUCCESS,
        payload: payload,
    };
};

export const loadRemarketingListFailed = (payload) => {
    return {
        type: FETCH_REMARKETING_LIST_ERROR,
        payload: payload,
    };
};

export const changeRemarketingInternalStatus = (primary_key, id, internal_status) => {
    return {
        type: UPDATE_REMARKETING_CAMPAIGN,
        payload: { primary_key, id, internal_status },
    };
};

export const loadRemarketingList = (apiUrl, type = null) => (dispatch) => {
    if (!type) {
        return;
    }
    dispatch(loadRemarketingListRequest(type));
    get(apiUrl, type)
        .then((res) => {
            // TODO: Ideally the error should be handled in `catch` block. Reject promise from
            //  front-end/js/api/api-list.js file's `get` method's `catch` block.
            if (res.error) {
                dispatch(toggleToastPopup([res.response.detail], "error"));
                dispatch(loadRemarketingListFailed(res.response.detail));
            } else {
                dispatch(loadRemarketingListSucceeded(res));
            }
        })
        .catch((err) => {
            dispatch(loadRemarketingListFailed(err));
        });
};

export const updateRemarketingCampaign = (primary_key, stop_time, daily_budget) => {
    return {
        type: UPDATE_REMARKETING_CAMPAIGN,
        payload: { primary_key, stop_time, daily_budget },
    };
};

export const toggleRemarketingCampaignExpansion = (primaryKey) => {
    return {
        type: TOGGLE_REMARKETING_CAMPAIGN_EXPANSION,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const toggleRemarketingTableExpansion = () => {
    return {
        type: TOGGLE_REMARKETING_TABLE_EXPANSION,
        payload: {
            //
        },
    };
};

export const retryLaunchingRemarketingAdRequested = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingRemarketingAdSucceed = (ad) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SUCCEED,
        payload: {
            ad: ad,
        },
    };
};

export const retryLaunchingRemarketingAdSucceedFailed = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingRemarketingAd = (primaryKey) => (dispatch) => {
    dispatch(retryLaunchingRemarketingAdRequested(primaryKey));

    api(`/api/v1/dynamic-ads-campaigns/${primaryKey}/retry-ad/`)
        .then((response) => {
            dispatch(retryLaunchingRemarketingAdSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(retryLaunchingRemarketingAdSucceedFailed(primaryKey));
            dispatch(fetchRemarketingAd(primaryKey));
        });
};

export const fetchRemarketingAdRequested = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchRemarketingAdSucceed = (ad) => {
    return {
        type: ACTIONS.FETCH_AD_SUCCEED,
        payload: {
            ad: ad,
        },
    };
};

export const fetchRemarketingAdFailed = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchRemarketingAd = (primaryKey) => (dispatch) => {
    dispatch(fetchRemarketingAdRequested(primaryKey));

    api(`/api/v1/dynamic-ads-ads/${primaryKey}/`)
        .then((response) => {
            dispatch(fetchRemarketingAdSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(fetchRemarketingAdFailed(primaryKey));
        });
};

export const retryLaunchingRemarketingAdSetRequested = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingRemarketingAdSetSucceed = (adSet) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_SUCCEED,
        payload: {
            adSet: adSet,
        },
    };
};

export const retryLaunchingRemarketingAdSetSucceedFailed = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingRemarketingAdSet = (primaryKey) => (dispatch) => {
    dispatch(retryLaunchingRemarketingAdSetRequested(primaryKey));

    api(`/api/v1/dynamic-ads-campaigns/${primaryKey}/retry-adset/`)
        .then((response) => {
            dispatch(retryLaunchingRemarketingAdSetSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(retryLaunchingRemarketingAdSetSucceedFailed(primaryKey));
            dispatch(fetchRemarketingAdSet(primaryKey));
        });
};

export const fetchRemarketingAdSetRequested = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_SET_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchRemarketingAdSetSucceed = (adSet) => {
    return {
        type: ACTIONS.FETCH_AD_SET_SUCCEED,
        payload: {
            adSet: adSet,
        },
    };
};

export const fetchRemarketingAdSetFailed = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_SET_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchRemarketingAdSet = (primaryKey) => (dispatch) => {
    dispatch(fetchRemarketingAdSetRequested(primaryKey));

    api(`/api/v1/dynamic-ads-adsets/${primaryKey}/`)
        .then((response) => {
            dispatch(fetchRemarketingAdSetSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(fetchRemarketingAdSetFailed(primaryKey));
        });
};
