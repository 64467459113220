import {api} from '../utils';

export const postBusinessEvent = (event, data) => {
    const url = "/api/v1/business-events/";
    return api(url, {"method": "POST", data: {event: event, data: data, page_url: window.location.href}})
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};