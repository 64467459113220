/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { hideIntercomLauncher } from "../../utils";
import withVideoAdsData from "../video-ads/with-video-ads";

import TemplatesList from "../../components/video-ads/TemplatesList";

const Product = (image, name, price, currency) => {
    return {
        image: image,
        name: name,
        price: price,
        currency: currency,
    };
};

class SelectTemplate extends Component {
    state = {
        selectedTemplate: "",
        pauseAnimations: false,
    };

    static toggleMenu(status) {
        const menuElements = [window.document.getElementsByClassName("app-navigation")[0]];

        menuElements.forEach((element) => {
            element.style.display = status === "hide" ? "none" : "block";
        });
    }

    componentDidMount() {
        window.document.title = "Select Template | SIXADS";
        SelectTemplate.toggleMenu("hide");
        hideIntercomLauncher(true);
    }

    debouncer() {
        const { onChange } = this.props;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.timeout = null;
            onChange(this.state);
        }, 250);
    }

    onTemplateSelect = async (templateName, designName) => {
        const { history, setLoading, dispatch } = this.props;
        const imageUrl = new URLSearchParams(this.props.location.search).get("image");
        const productPrice = "9.99";
        const productCurrency = "$";

        this.setState(
            {
                selectedTemplate: templateName,
                selectedDefaultProduct: Product(imageUrl, "Product Name", productPrice, productCurrency),
            },
            this.debouncer
        );

        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            history.push("/video-ads-v2/customize/");
        }, 500);
    };

    onListChange = (selectedItems) => {
        this.setState(
            {
                selectedProducts: selectedItems,
            },
            this.debouncer
        );
    };

    render() {
        return (
            <Fragment>
                <TemplatesList onChangeList={this.onListChange} onTemplateSelect={this.onTemplateSelect} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { videoAds } = state;

    const { pages: { product: { values = {}, errors = [] } } = {} } = videoAds;

    return {
        values: values,
        errors: errors,
    };
};

export default withRouter(
    withVideoAdsData({
        prefix: "template",
        // preload: preload,
        validate: () => {},
        WrappedComponent: connect(mapStateToProps)(SelectTemplate),
    })
);
