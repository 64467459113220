import React, { useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";

import { toggleNavigation } from "../../actions";

import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import { Welcome, GetCourse } from "../../components/dropshipping-course";

const DropshippingCourse = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Dropshipping Course | SIXADS";
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />

            <div>
                <Switch>
                    <Route path="/dropshipping-course/welcome/" component={Welcome} exact={true} />
                    <Route path="/dropshipping-course/get-course/" component={GetCourse} exact={true} />
                </Switch>
            </div>
        </Fragment>
    );
};

const mapStateToProps = () => {
    return {
        //
    };
};

export default withRouter(connect(mapStateToProps)(DropshippingCourse));
