import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import CampaignTableContainer from "./CampaignTableContainer";
import {
    toggleReachTableExpansion,
    toggleRemarketingTableExpansion,
    toggleUpsellTableExpansion,
} from "../../../actions";

const FacebookDynamicCampaigns = () => {
    const dispatch = useDispatch();

    const isReachCampaignsExpanded = useSelector((state) => state.reachList.isExpanded);
    const isRemarketingCampaignsExpanded = useSelector((state) => state.remarketingList.isExpanded);
    const isUpsellCampaignsExpanded = useSelector((state) => state.upsellList.isExpanded);

    const handleTableExpansionToggle = (campaignType) => {
        switch (campaignType) {
            case "reach":
                dispatch(toggleReachTableExpansion());
                break;
            case "remarketing":
                dispatch(toggleRemarketingTableExpansion());
                break;
            case "upsell":
                dispatch(toggleUpsellTableExpansion());
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <div
                className="bg-background-funnel-reach bg-center bg-no-repeat pb-8"
                style={{ backgroundSize: "85% 100%" }}
            >
                <CampaignTableContainer
                    campaign_type="reach"
                    header_text="Reach Prospective Customers (Cold)"
                    isTableExpanded={isReachCampaignsExpanded}
                    onTableExpansionToggle={() => handleTableExpansionToggle("reach")}
                />
            </div>

            <div
                className="bg-background-funnel-remarket bg-center bg-no-repeat pb-8"
                style={{ backgroundSize: "85% 100%" }}
            >
                <CampaignTableContainer
                    campaign_type="remarketing"
                    header_text="Remarket existing visitors (Hot)"
                    isTableExpanded={isRemarketingCampaignsExpanded}
                    onTableExpansionToggle={() => handleTableExpansionToggle("remarketing")}
                />
            </div>

            <div
                className="bg-background-funnel-upsell bg-center bg-no-repeat pb-8"
                style={{ backgroundSize: "85% 100%" }}
            >
                <CampaignTableContainer
                    campaign_type="upsell"
                    header_text="Upsell existing customers (Hot)"
                    isTableExpanded={isUpsellCampaignsExpanded}
                    onTableExpansionToggle={() => handleTableExpansionToggle("upsell")}
                />
            </div>
        </Fragment>
    );
};

export default FacebookDynamicCampaigns;
