/* eslint-disable react/no-array-index-key, jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Collapsible } from "@shopify/polaris";

import { initNavigation, locationChange, toggleNavigation } from "../actions";

import Link from "./Link";
import Logo from "./Logo";

class Navigation extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired,
        navigation: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
        this.currentPageSection();

        const { isOpen, location } = this.props;

        const path = `${location.pathname}${location.search}`;

        if (prevProps.isOpen && isOpen && document.documentElement.clientWidth < 769) {
            this.props.dispatch(toggleNavigation("close"));
        }

        if (path !== `${prevProps.location.pathname}${prevProps.location.search}`) {
            this.props.dispatch(locationChange(path));
        }

        return true;
    }

    onToggle = () => {
        this.props.dispatch(toggleNavigation());
    };

    currentPageSection = () => {
        const { location, dispatch, navigation } = this.props;
        const path = `${location.pathname}${location.search}`;

        if (navigation.initialSate) {
            dispatch(initNavigation(path));
        }
    };

    render() {
        const { isSinglePage, location, isOpen, navigation, dispatch } = this.props;

        const { sections } = navigation || { sections: [] };

        const bodyCSS = document.body.classList;

        if (document.documentElement.clientWidth < 769) {
            bodyCSS.toggle("is-navigation--open", isOpen);
        } else {
            bodyCSS.toggle("is-navigation--hidden", !isOpen);
        }

        return (
            <nav className="l-navigation" style={{ zIndex: 544 }}>
                <div className="Polaris-Navigation">
                    <button
                        type="button"
                        className="Polaris-Frame__NavigationDismiss"
                        tabIndex="0"
                        onClick={this.onToggle}
                    >
                        <span className="Polaris-Icon Polaris-Icon--colorWhite">
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                <path
                                    d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                                    fillRule="evenodd"
                                />
                            </svg>
                        </span>
                    </button>

                    <Logo sections={sections} />

                    <div className="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical">
                        {sections.map(function (section, i) {
                            return (
                                <Fragment key={i}>
                                    {!section.hide && (
                                        <ul
                                            key={`section-${i}`}
                                            className={`Polaris-Navigation__Section ${
                                                section.class ? section.class : ""
                                            }`}
                                        >
                                            {section.name && (
                                                <li className="Polaris-Navigation__ListItem">
                                                    <Link
                                                        className={`Polaris-Navigation__Item ${
                                                            location.pathname === section.path &&
                                                            section.pages.length === 0
                                                                ? "Polaris-Navigation__Item--selected"
                                                                : ""
                                                        } ${
                                                            section.outline ? "Polaris-Navigation__Item--outline" : ""
                                                        }`}
                                                        isSinglePage={section.isSinglePage ? isSinglePage : false}
                                                        path={section.path}
                                                        label={section.name}
                                                        ico={section.ico}
                                                        newPage={section.isNew ? section.isNew : false}
                                                        index={i}
                                                        dispatch={dispatch}
                                                        showPages={section.showPages}
                                                        section={section.pagesEnabled && section.pages.length > 0}
                                                    />
                                                </li>
                                            )}
                                            <Collapsible id={i} open={section.showPages}>
                                                {section.pages.map(function (page, j) {
                                                    return (
                                                        <Fragment key={j}>
                                                            {!page.hide && (
                                                                <li key={j} className="Polaris-Navigation__ListItem">
                                                                    <Link
                                                                        className={`Polaris-Navigation__Item ${
                                                                            location.pathname === page.path
                                                                                ? "Polaris-Navigation__Item--selected"
                                                                                : ""
                                                                        } ${
                                                                            section.name
                                                                                ? "Polaris-Navigation__Item--inner"
                                                                                : ""
                                                                        } ${
                                                                            page.outline
                                                                                ? "Polaris-Navigation__Item--outline"
                                                                                : ""
                                                                        }`}
                                                                        isSinglePage={
                                                                            page.isSinglePage ? isSinglePage : false
                                                                        }
                                                                        path={page.path}
                                                                        label={page.label}
                                                                        ico={page.ico}
                                                                        badge={page.badge ? page.badge : ""}
                                                                        newPage={page.isNew ? page.isNew : false}
                                                                        section={false}
                                                                    />
                                                                </li>
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                            </Collapsible>
                                        </ul>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    const { navigation, store } = state;

    return {
        store: store,
        navigation: navigation,
        isOpen: navigation ? navigation.isOpen : true,
    };
};

export default withRouter(connect(mapStateToProps)(Navigation));
