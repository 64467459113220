import React from "react";
import Button from "@mui/material/Button";

const LinkButton = ({ children, onClick, disabled }) => (
    <Button disabled={disabled} variant="outlined" color="primary" onClick={onClick}>
        {children}
    </Button>
);

export default LinkButton;
