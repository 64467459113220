import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Button from "../../common/components/button";

const GuideItem = (props) => {
    const { item, onSelect } = props;

    const { selected = false } = item;

    useEffect(() => {
        if (selected) {
            item.selected = true;
            onSelect();
        }
    }, []);

    return (
        <div>
            <div className="list-item-grid--container m-10">
                <div className="container">
                    <>
                        <div className="canvas">{item.RenderedItem}</div>
                        <div className="button">
                            <Button class="px-10" primary={true} onClick={() => item.onSelect(item.name)}>
                                Download
                            </Button>
                        </div>
                    </>
                </div>
            </div>

            <style jsx={true}>{`
                .container {
                    position: relative;
                }

                .canvas {
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: 0.5s ease;
                    backface-visibility: hidden;
                }

                .button {
                    transition: 0.5s ease;
                    opacity: 0;
                    position: absolute;
                    top: 90%;
                    left: 48%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }

                .container:hover .canvas {
                    opacity: 0.3;
                }

                .container:hover .button {
                    opacity: 1;
                }
            `}</style>
        </div>
    );
};

GuideItem.propTypes = {
    item: PropTypes.shape({
        selected: PropTypes.bool,
        name: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
    onSelect: PropTypes.func,
};

GuideItem.defaultProps = {
    onSelect: () => {},
};

export default GuideItem;
