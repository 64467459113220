import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import GuideItem from "./GuideItem";
import { Card, Page } from "@shopify/polaris";
import List from "../../common/components/sixads/list/list";
import Button from "../../common/components/button";
import { logAnalytics } from "../../actions";

const SelectGuide = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onGuideSelect = (guideName) => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "How to guide flow",
                eventAction: `Button click  ${guideName}`,
                eventLabel: "Download",
            })
        );

        history.push("/how-to-guides/form-submit/");
    };

    const items = [
        {
            RenderedItem: (
                <img
                    className="desktop"
                    src="/static/img/guides/online-business.jpg"
                    alt="How to start online business"
                />
            ),
            name: "how_to_start_online_business",
            onSelect: onGuideSelect,
        },
        {
            RenderedItem: <img className="desktop" src="/static/img/guides/instagram.jpg" alt="How to use instagram" />,
            name: "how_to_use_instagram",
            onSelect: onGuideSelect,
        },
        {
            RenderedItem: (
                <img className="desktop" src="/static/img/guides/dropshipping.jpg" alt="Shopify dropshipping 101" />
            ),
            name: "shopify_dropshipping_101",
            onSelect: onGuideSelect,
        },
        {
            RenderedItem: (
                <img className="desktop" src="/static/img/guides/facebook.jpg" alt="How to advertise on facebook" />
            ),
            name: "how_to_advertise_on_facebook",
            onSelect: onGuideSelect,
        },
    ];

    return (
        <Fragment>
            <div className="guide-select">
                <Page
                    title={<span>Extensive, verified & easy-to-understand How-to Guides 📋 FOR FREE</span>}
                    subtitle="Find help you need & deserve to take your business to the next level now."
                >
                    <Card>
                        <List
                            view="grid"
                            items={items || []}
                            overrides={{
                                ListItem: {
                                    component: GuideItem,
                                },
                            }}
                            single={true}
                        />
                    </Card>
                </Page>
            </div>
        </Fragment>
    );
};

export default withRouter(SelectGuide);
