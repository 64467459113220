/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../common/components/sixads/button/button";

const ThankYou = () => {
    const history = useHistory();

    const handleExploreCampaign = () => {
        history.push("/google-shopping/all-products/");
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
            <div className="pt-0 md:pt-24 bg-teal-500 flex justify-center items-center">
                <img src="/static/img/dynamic_ads_thank_you.png" alt="Ad text" className="w-full md:w-3/5" />
            </div>
            <div className="py-36 md:py-14 lg:pt-0 px-10 md:px-0 flex bg-white justify-center items-center">
                <div className="h-full md:h-3/5 flex flex-col justify-center items-start w-full md:w-4/5">
                    <h1 className="welcome-title md:pt-10  pl-1">
                        Congrats!
                        <span role="img" aria-label="party_popper" className="pl-5">
                            🎉
                        </span>
                        <br />
                        <div className="pt-4">You're all done.</div>
                    </h1>
                    <p className="welcome-description mt-10 pl-1">
                        Congratulations, you have made a first step of creating a Google Shopping Campaign.
                    </p>
                    <p className="welcome-description mt-5 pl-1">
                        IMPORTANT! Now you must provide extra product attributes in order your products would be
                        approved by Google.
                    </p>
                    <div className="flex justify-between mt-24">
                        <Button
                            class="text-16px"
                            primary={true}
                            onClick={() => {
                                handleExploreCampaign();
                            }}
                        >
                            Edit Product Attributes
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
