/* eslint-disable import/prefer-default-export */
export const roundNumber = (number) => {
    if (typeof number === "string") {
        number = parseFloat(number);
    }
    number = Math.round((number + Number.EPSILON) * 100) / 100;

    return number.toFixed(2);
};

export const getFormattedPrice = (price, currency = "USD") => {
    const formatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
    });

    const response = formatter.format(price);
    if (response) {
        // Remove currency symbol from beginning of the string and append at the end.
        return response.substring(1).concat(` ${response.charAt(0)}`);
    }

    return "-";
};

export const getNumberWithThousandSeparator = (number, separator = ",") => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const nFormatter = (num, digits = 1) => {
    const lookup = [
        { value: 0, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    if (item.value === 0) {
        return `${num}${item.symbol}`;
    }
    return (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol;
};
