/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import { toggleNavigation } from "../actions";
import { Icon } from "../utils/Icon";

class HeaderWithoutUser extends Component {
    state = {
        popoverActive: false,
        isMobile: false,
    };

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    componentDidMount() {
        document.addEventListener("click", this.toggleProfileMenu);
        this.handleDisplay();
    }

    handleDisplay = () => {
        const isMobile = window.screen.width <= 769;
        this.setState({
            isMobile: isMobile,
        });
    };

    onToggle = () => {
        const { dispatch } = this.props;

        dispatch(toggleNavigation());
    };

    toggleProfileMenu = (e) => {
        if (!["profile-menu", "profile-menu-expander"].includes(e.target.id)) {
            this.setState({ popoverActive: false });
        }
    };

    render() {
        const { teal = false, isOpen } = this.props;
        const { isMobile = false } = this.state;

        const rootClasses = classnames("fixed w-full right-0 flex justify-between items-center p-5 z-1000", {
            [["bg-teal-700 text-white"]]: teal,
            [["bg-gray-90"]]: !teal && isMobile,
        });

        return (
            <div className={rootClasses}>
                {!isMobile && teal && (
                    <div
                        onClick={this.onToggle}
                        className="flex flex-row items-center w-max justify-space-around cursor-pointer space-x-4"
                    >
                        <Icon name={isOpen ? "navigationToggleCollapse" : "navigationToggleExpand"} />
                        {!isOpen && <Icon name="sixadsLogoWithBrand" />}
                    </div>
                )}
                {isMobile && (
                    <div className="text-black" onClick={this.onToggle}>
                        <Icon
                            color={teal ? "white" : "black"}
                            name={isOpen ? "navigationToggleCollapse" : "navigationToggleExpand"}
                        />
                    </div>
                )}
                {!teal && <div>&nbsp;</div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { email } = state.store;
    const { navigation } = state;
    const { isOpen = false } = navigation;

    return {
        email: email,
        initials: email ? email.charAt(0).toUpperCase() : "",
        isOpen: isOpen,
    };
};

export default connect(mapStateToProps)(HeaderWithoutUser);
