import React, {useEffect, useState} from "react";
import {Card} from "@shopify/polaris";
import PropTypes from "prop-types";
import {getComponents} from "../../../../../utils/override";
import * as defaultComponents from "./default-components";

export default function FilterableList(props) {
    const { items = [], categories, overrides = {}, onChange, onSearchChange = () => {}, loading = false, itemsCount = null, single = false } = props;
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selected, setSelected] = useState([]);
    const [query, setQuery] = useState("");

    let filteredItems = items;

    useEffect(() => {
          filteredItems = onSearchChange(query, items);
    }, [query]);

    useEffect(() => {
          setSelected(items.filter((item) => item.selected));
    });

    const currentCategory = categories[Number(selectedCategory) - 1];
    const { filter = null } = currentCategory || {};

    if (currentCategory && filter) {
        filteredItems = filter(filteredItems);
    }

    const {
        Root: { component: Root, props: rootProps },
        Filter: { component: Filter, props: filterProps },
        List: { component: List, props: listProps },
        ListItem: { component: ListItem },
        EmptyList: { component: EmptyList, props: emptyListProps },
    } = getComponents(defaultComponents, overrides);

    const getList = () => {
        if ((!filteredItems || !filteredItems.filter((item) => item.hidden !== true).length) && !loading) {
            return <EmptyList {...emptyListProps}>No items found</EmptyList>;
        }

        return (
            <List
                view="grid"
                items={filteredItems}
                single={single}
                overrides={{
                    ListItem: {
                        component: ListItem,
                    },
                    Root: {
                        props: {
                            className: "filterable-list--root",
                        },
                    },
                }}
                onChange={({ selectedItems }) => {
                    setSelected(selectedItems);
                    onChange(selectedItems);
                }}
                {...listProps}
            />
        );
    };

    return (
        <Root {...rootProps}>
            <Card>
                <Card.Section>
                    <Filter
                        categories={categories}
                        query={query}
                        setQuery={setQuery}
                        filteredItems={filteredItems}
                        selectedItems={selected}
                        onCategoryChange={(category) => setSelectedCategory(category)}
                        selectedCategory={selectedCategory}
                        loading={loading}
                        itemsCount={itemsCount}
                        {...filterProps}
                    />
                </Card.Section>
                <Card.Section>{getList()}</Card.Section>
            </Card>
        </Root>
    );
}

FilterableList.propTypes = {
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number,
    categories: PropTypes.array,
    overrides: PropTypes.shape({}),
    onChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    loading: PropTypes.bool,
    single: PropTypes.bool
};

FilterableList.defaultProps = {
    itemsCount: null,
    categories: [],
    overrides: {},
    onChange: () => {},
    onSearchChange: () => {},
    loading: false,
    single: false,
};
