import PropTypes from "prop-types";
import React from "react";

const AdViewImage = ({ imageUrl }) => {
    return (
        <div className="image-wrapper">
            <img src={imageUrl} alt="googleAdView" />
        </div>
    );
};

AdViewImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
};

export default AdViewImage;
