import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Banner from "../../common/components/sixads/banner";

const SignInFailedBanner = ({
    sixadsAppId = null,
    scope,
    responseFacebook,
    handleFacebookError,
    API_VERSION,
    onClickFacebookLogin,
    onClose,
}) => (
    <Banner type="info" onClose={onClose}>
        <p>
            To boost your sales with ads, please{" "}
            {sixadsAppId != null && (
                <FacebookLogin
                    appId={sixadsAppId}
                    autoLoad={false}
                    scope={scope}
                    returnScopes={true}
                    callback={responseFacebook}
                    onFailure={handleFacebookError}
                    version={API_VERSION}
                    onClick={onClickFacebookLogin}
                    disableMobileRedirect={true}
                    render={(renderProps) => (
                        <a onClick={renderProps.onClick} onKeyDown={renderProps.onClick} role="link" tabIndex={0}>
                            sign in with Facebook
                        </a>
                    )}
                />
            )}
            . It’s safe to connect & easy to disconnect later. We won’t run ads or post on your Facebook without
            permission.
        </p>
    </Banner>
);

export default SignInFailedBanner;
