/* eslint-disable no-unused-vars */
import {
    getCoreAccountRequest,
    GetCountryListRequest,
    GetGoogleMerchantAccountShippingSettings,
    GetGoogleMerchantAccountTaxSettings,
    GetStoreRequirementRequest,
    GoogleAdsAccountListRequest,
    googleMerchantAccountListRequest,
    UpdateStoreRequirementRequest,
    ValidateGoogleCredentials,
    GetGoogleShoppingCampaignRequest,
    GetGoogleAdsAccountBillingSettings,
} from "../api/api-google-shopping";

export const ACTIONS = {
    TOGGLE_TABLE_EXPANSION: "TOGGLE_TABLE_EXPANSION",
};

export const FETCH_GOOGLE_CORE_ACCOUNT_REQUEST = "FETCH_GOOGLE_CORE_ACCOUNT_REQUEST";
export const FETCH_GOOGLE_CORE_ACCOUNT_SUCCESS = "FETCH_GOOGLE_CORE_ACCOUNT_SUCCESS";
export const FETCH_GOOGLE_CORE_ACCOUNT_ERROR = "FETCH_GOOGLE_CORE_ACCOUNT_ERROR";
export const REMOVE_GOOGLE_CORE_ACCOUNT = "REMOVE_GOOGLE_CORE_ACCOUNT";

export const VALIDATE_GOOGLE_CREDENTIALS_REQUEST = "VALIDATE_GOOGLE_CREDENTIALS_REQUEST";
export const VALIDATE_GOOGLE_CREDENTIALS_SUCCESS = "VALIDATE_GOOGLE_CREDENTIALS_SUCCESS";
export const VALIDATE_GOOGLE_CREDENTIALS_ERROR = "VALIDATE_GOOGLE_CREDENTIALS_ERROR";

export const FETCH_STORE_REQUIREMENTS_REQUEST = "FETCH_STORE_REQUIREMENTS_REQUEST";
export const FETCH_STORE_REQUIREMENTS_SUCCESS = "FETCH_STORE_REQUIREMENTS_SUCCESS";
export const FETCH_STORE_REQUIREMENTS_ERROR = "FETCH_STORE_REQUIREMENTS_ERROR";

export const UPDATE_STORE_REQUIREMENTS_REQUEST = "UPDATE_STORE_REQUIREMENTS_REQUEST";
export const UPDATE_STORE_REQUIREMENTS_SUCCESS = "UPDATE_STORE_REQUIREMENTS_SUCCESS";
export const UPDATE_STORE_REQUIREMENTS_ERROR = "UPDATE_STORE_REQUIREMENTS_ERROR";

export const MERCHANT_ACCOUNT_LIST_REQUEST = "MERCHANT_ACCOUNT_LIST_REQUEST";
export const MERCHANT_ACCOUNT_LIST_SUCCESS = "MERCHANT_ACCOUNT_LIST_SUCCESS";
export const MERCHANT_ACCOUNT_LIST_ERROR = "MERCHANT_ACCOUNT_LIST_ERROR";

export const MERCHANT_ACCOUNT_CONNECTED = "MERCHANT_ACCOUNT_CONNECTED";
export const MERCHANT_ACCOUNT_DISCONNECTED = "MERCHANT_ACCOUNT_DISCONNECTED";

export const MERCHANT_SHIPPING_SETTINGS_REQUEST = "MERCHANT_SHIPPING_SETTINGS_REQUEST";
export const MERCHANT_SHIPPING_SETTINGS_SUCCESS = "MERCHANT_SHIPPING_SETTINGS_SUCCESS";
export const MERCHANT_SHIPPING_SETTINGS_ERROR = "MERCHANT_SHIPPING_SETTINGS_ERROR";

export const MERCHANT_TAX_SETTINGS_REQUEST = "MERCHANT_TAX_SETTINGS_REQUEST";
export const MERCHANT_TAX_SETTINGS_SUCCESS = "MERCHANT_TAX_SETTINGS_SUCCESS";
export const MERCHANT_TAX_SETTINGS_ERROR = "MERCHANT_TAX_SETTINGS_ERROR";

export const ADS_ACCOUNT_LIST_REQUEST = "ADS_ACCOUNT_LIST_REQUEST";
export const ADS_ACCOUNT_LIST_SUCCESS = "ADS_ACCOUNT_LIST_SUCCESS";
export const ADS_ACCOUNT_LIST_ERROR = "ADS_ACCOUNT_LIST_ERROR";

export const ADS_ACCOUNT_CONNECTED = "ADS_ACCOUNT_CONNECTED";
export const ADS_ACCOUNT_DISCONNECTED = "ADS_ACCOUNT_DISCONNECTED";

export const SET_CAMPAIGN_BUDGET = "SET_CAMPAIGN_BUDGET";

export const SET_GOOGLE_AUTH_CODE = "SET_GOOGLE_AUTH_CODE";

export const COUNTRY_LIST_REQUEST = "COUNTRY_LIST_REQUEST";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_ERROR = "COUNTRY_LIST_ERROR";

export const TARGETING_REQUEST_SUCCESS = "TARGETING_REQUEST_SUCCESS";

export const FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_REQUEST = "FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_REQUEST";
export const FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_SUCCESS = "FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_SUCCESS";
export const FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_ERROR = "FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_ERROR";

export const FETCH_GOOGLE_ADS_ACCOUNT_BILLING_REQUEST = "FETCH_GOOGLE_ADS_ACCOUNT_BILLING_REQUEST";
export const FETCH_GOOGLE_ADS_ACCOUNT_BILLING_SUCCESS = "FETCH_GOOGLE_ADS_ACCOUNT_BILLING_SUCCESS";
export const FETCH_GOOGLE_ADS_ACCOUNT_BILLING_ERROR = "FETCH_GOOGLE_ADS_ACCOUNT_BILLING_ERROR";

export const GOOGLE_SHOPPING_CAMPAIGN_UPDATED = "GOOGLE_SHOPPING_CAMPAIGN_UPDATED";

export const CONFIRM_WEBSITE_CLAIMED_BY_USER_SUCCESS = "CONFIRM_WEBSITE_CLAIMED_BY_USER_SUCCESS";

export const confirmClaimWebsiteByUserSuccess = () => {
    return {
        type: CONFIRM_WEBSITE_CLAIMED_BY_USER_SUCCESS,
    };
};

export const loadGoogleCoreAccountRequest = () => {
    return {
        type: FETCH_GOOGLE_CORE_ACCOUNT_REQUEST,
    };
};

export const loadGoogleCoreAccountSucceeded = (payload) => {
    return {
        type: FETCH_GOOGLE_CORE_ACCOUNT_SUCCESS,
        payload,
    };
};

export const loaGoogleCoreAccountFailed = () => {
    return {
        type: FETCH_GOOGLE_CORE_ACCOUNT_ERROR,
    };
};

export const loadGoogleCoreAccount = () => (dispatch) => {
    dispatch(loadGoogleCoreAccountRequest());
    getCoreAccountRequest()
        .then((res) => {
            dispatch(loadGoogleCoreAccountSucceeded(res));
        })
        .catch((err) => {
            dispatch(loaGoogleCoreAccountFailed());
        });
};

export const deleteGoogleCoreAccount = () => {
    return {
        type: REMOVE_GOOGLE_CORE_ACCOUNT,
    };
};

export const validateGoogleCredentialsRequest = () => {
    return {
        type: VALIDATE_GOOGLE_CREDENTIALS_REQUEST,
    };
};

export const validateGoogleCredentialsSucceeded = (payload) => {
    return {
        type: VALIDATE_GOOGLE_CREDENTIALS_SUCCESS,
        payload,
    };
};

export const validateGoogleCredentialsFailed = () => {
    return {
        type: VALIDATE_GOOGLE_CREDENTIALS_ERROR,
    };
};

export const validateGoogleCredentials = () => (dispatch) => {
    dispatch(validateGoogleCredentialsRequest());
    ValidateGoogleCredentials()
        .then((res) => {
            dispatch(validateGoogleCredentialsSucceeded(res));
        })
        .catch((err) => {
            dispatch(validateGoogleCredentialsFailed());
        });
};

export const setGoogleAuthCode = (payload) => {
    return {
        type: SET_GOOGLE_AUTH_CODE,
        payload,
    };
};

export const fetchStoreRequirementsRequest = () => {
    return {
        type: FETCH_STORE_REQUIREMENTS_REQUEST,
    };
};

export const fetchStoreRequirementsSucceeded = (payload) => {
    return {
        type: FETCH_STORE_REQUIREMENTS_SUCCESS,
        payload,
    };
};

export const fetchStoreRequirementsFailed = () => {
    return {
        type: FETCH_STORE_REQUIREMENTS_ERROR,
    };
};

export const fetchStoreRequirements = () => (dispatch) => {
    dispatch(fetchStoreRequirementsRequest());
    GetStoreRequirementRequest()
        .then((res) => {
            dispatch(fetchStoreRequirementsSucceeded(res));
        })
        .catch((err) => {
            dispatch(fetchStoreRequirementsFailed());
        });
};

export const updateStoreRequirementsRequest = () => {
    return {
        type: UPDATE_STORE_REQUIREMENTS_REQUEST,
    };
};

export const updateStoreRequirementsSucceeded = (payload) => {
    return {
        type: UPDATE_STORE_REQUIREMENTS_SUCCESS,
        payload,
    };
};

export const updateStoreRequirementsFailed = () => {
    return {
        type: UPDATE_STORE_REQUIREMENTS_ERROR,
    };
};

export const updateStoreRequirements = (storeRequirementItem) => (dispatch) => {
    dispatch(updateStoreRequirementsRequest());
    UpdateStoreRequirementRequest(storeRequirementItem)
        .then((res) => {
            dispatch(updateStoreRequirementsSucceeded(storeRequirementItem));
        })
        .catch((err) => {
            if (err.status === 200) {
                dispatch(updateStoreRequirementsSucceeded(storeRequirementItem));
            }
            dispatch(updateStoreRequirementsFailed());
        });
};

export const loadMerchantAccountListRequest = () => {
    return {
        type: MERCHANT_ACCOUNT_LIST_REQUEST,
    };
};

export const loadMerchantAccountListSucceeded = (payload) => {
    return {
        type: MERCHANT_ACCOUNT_LIST_SUCCESS,
        payload,
    };
};

export const loadMerchantAccountListFailed = () => {
    return {
        type: MERCHANT_ACCOUNT_LIST_ERROR,
    };
};

export const loadMerchantAccountList = () => (dispatch) => {
    dispatch(loadMerchantAccountListRequest());
    googleMerchantAccountListRequest()
        .then((res) => {
            dispatch(loadMerchantAccountListSucceeded(res));
        })
        .catch((err) => {
            dispatch(loadMerchantAccountListFailed());
        });
};

export const googleMerchantAccountConnected = (merchantAccount) => {
    return {
        type: MERCHANT_ACCOUNT_CONNECTED,
        payload: merchantAccount,
    };
};

export const googleMerchantAccountDisconnected = () => {
    return {
        type: MERCHANT_ACCOUNT_DISCONNECTED,
    };
};

export const loadShippingSettingsRequest = () => {
    return {
        type: MERCHANT_SHIPPING_SETTINGS_REQUEST,
    };
};

export const loadShippingSettingsSucceeded = (payload) => {
    return {
        type: MERCHANT_SHIPPING_SETTINGS_SUCCESS,
        payload,
    };
};

export const loadShippingSettingsFailed = () => {
    return {
        type: MERCHANT_SHIPPING_SETTINGS_ERROR,
    };
};

export const loadShippingSettings = () => (dispatch) => {
    dispatch(loadShippingSettingsRequest());
    GetGoogleMerchantAccountShippingSettings()
        .then((res) => {
            dispatch(loadShippingSettingsSucceeded(res));
        })
        .catch((err) => {
            dispatch(loadShippingSettingsFailed());
        });
};

export const loadTaxSettingsRequest = () => {
    return {
        type: MERCHANT_TAX_SETTINGS_REQUEST,
    };
};

export const loadTaxSettingsSucceeded = (payload) => {
    return {
        type: MERCHANT_TAX_SETTINGS_SUCCESS,
        payload,
    };
};

export const loadTaxSettingsFailed = () => {
    return {
        type: MERCHANT_TAX_SETTINGS_ERROR,
    };
};

export const loadTaxSettings = () => (dispatch) => {
    dispatch(loadTaxSettingsRequest());
    GetGoogleMerchantAccountTaxSettings()
        .then((res) => {
            dispatch(loadTaxSettingsSucceeded(res));
        })
        .catch((err) => {
            dispatch(loadTaxSettingsFailed());
        });
};

export const loadAdsAccountListRequest = () => {
    return {
        type: ADS_ACCOUNT_LIST_REQUEST,
    };
};

export const loadAdsAccountListSucceeded = (payload) => {
    return {
        type: ADS_ACCOUNT_LIST_SUCCESS,
        payload,
    };
};

export const loadAdsAccountListFailed = () => {
    return {
        type: ADS_ACCOUNT_LIST_ERROR,
    };
};

export const loadAdsAccountList = () => (dispatch) => {
    dispatch(loadAdsAccountListRequest());
    GoogleAdsAccountListRequest()
        .then((res) => {
            dispatch(loadAdsAccountListSucceeded(res));
        })
        .catch((err) => {
            dispatch(loadAdsAccountListFailed());
        });
};

export const googleAdsAccountConnected = (payload) => {
    return {
        type: ADS_ACCOUNT_CONNECTED,
        payload,
    };
};

export const googleAdsAccountDisconnected = () => {
    return {
        type: ADS_ACCOUNT_DISCONNECTED,
    };
};

export const toggleTableExpansion = () => {
    return {
        type: ACTIONS.TOGGLE_TABLE_EXPANSION,
        payload: {
            //
        },
    };
};

export const setCampaignBudget = (payload) => {
    return {
        type: SET_CAMPAIGN_BUDGET,
        payload,
    };
};

export const fetchCountryListRequest = () => {
    return {
        type: COUNTRY_LIST_REQUEST,
    };
};

export const fetchCountryListSucceeded = (payload) => {
    return {
        type: COUNTRY_LIST_SUCCESS,
        payload,
    };
};

export const fetchCountryListFailed = () => {
    return {
        type: COUNTRY_LIST_ERROR,
    };
};

export const fetchCountryList = () => (dispatch) => {
    dispatch(fetchCountryListRequest());
    GetCountryListRequest()
        .then((res) => {
            dispatch(fetchCountryListSucceeded(res));
        })
        .catch((err) => {
            dispatch(fetchCountryListFailed());
        });
};

export const targetingSaved = (payload) => {
    return {
        type: TARGETING_REQUEST_SUCCESS,
        payload,
    };
};

export const loadGoogleShoppingCampaignRequest = () => {
    return {
        type: FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_REQUEST,
    };
};

export const loadGoogleShoppingCampaignSucceeded = (payload) => {
    return {
        type: FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_SUCCESS,
        payload,
    };
};

export const loadGoogleShoppingCampaignFailed = () => {
    return {
        type: FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_ERROR,
    };
};

export const loadGoogleShoppingCampaignList = () => (dispatch) => {
    dispatch(loadGoogleShoppingCampaignRequest());
    GetGoogleShoppingCampaignRequest()
        .then((res) => {
            dispatch(loadGoogleShoppingCampaignSucceeded(res));
        })
        .catch((err) => {
            dispatch(loadGoogleShoppingCampaignFailed());
        });
};

export const googleShoppingUpdateSucceeded = (payload) => {
    return {
        type: GOOGLE_SHOPPING_CAMPAIGN_UPDATED,
        payload,
    };
};

export const loadGoogleAdsAccountBillingRequest = () => {
    return {
        type: FETCH_GOOGLE_ADS_ACCOUNT_BILLING_REQUEST,
    };
};

export const loadGoogleAdsAccountBillingSucceeded = (payload) => {
    return {
        type: FETCH_GOOGLE_ADS_ACCOUNT_BILLING_SUCCESS,
        payload,
    };
};

export const loadGoogleAdsAccountBillingFailed = () => {
    return {
        type: FETCH_GOOGLE_ADS_ACCOUNT_BILLING_ERROR,
    };
};

export const loadGoogleAdsAccountBilling = () => (dispatch) => {
    dispatch(loadGoogleAdsAccountBillingRequest());
    GetGoogleAdsAccountBillingSettings()
        .then((res) => {
            dispatch(loadGoogleAdsAccountBillingSucceeded(res));
        })
        .catch((err) => {
            dispatch(loadGoogleAdsAccountBillingFailed());
        });
};
