import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "../../common/components/button";
import TooltipWithIcon from "../../common/components/TooltipWithIcon";
import TextField from "../../common/components/textfield";
import { logAnalytics, toggleToastPopup } from "../../actions";
import { setAboutBusinessData } from "../../actions/actions-ad-text-creator";

class AboutBusiness extends Component {
    state = {
        companyName: this.props.companyName,
        companyKeywords: this.props.companyKeywords,
        companyAudience: this.props.companyAudience,
        specialOffer: this.props.specialOffer,
    };

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleGenerateAdTextClick = () => {
        const { dispatch, history } = this.props;
        const { companyName, companyKeywords, companyAudience, specialOffer } = this.state;

        const hasValidationError = this.validateInputs();

        if (hasValidationError) {
            return null;
        }

        dispatch(
            setAboutBusinessData({
                companyName: companyName,
                companyKeywords: companyKeywords,
                companyAudience: companyAudience,
                specialOffer: specialOffer,
            })
        );

        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Ad text creation flow",
                eventAction: "Button click",
                eventLabel: "Generate ad texts",
            })
        );

        history.push("/ad-creative/ad-text-creator/fb-headlines/");
    };

    validateInputs = () => {
        const { dispatch } = this.props;

        let hasValidationError = false;
        Object.keys(this.state).map((key) => {
            if (!this.state[key]) {
                hasValidationError = true;
            }
        });

        if (hasValidationError) {
            dispatch(toggleToastPopup(["Please fill out all fields."], "error"));
        }

        return hasValidationError;
    };

    render() {
        const { companyName, companyKeywords, companyAudience, specialOffer } = this.state;

        return (
            <Fragment>
                <div className="pt-24">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 mt-12 font-sf-text pb-40">
                        <div>
                            <h1 className="page-title">
                                Tell us about your business{" "}
                                <span role="img" aria-label="Megaphone">
                                    📣
                                </span>
                            </h1>
                            <p className="page-subtitle">
                                Answers about your business will help our AI-powered ad text creator generate
                                personalised ad texts that fit your business perfectly.
                            </p>
                        </div>
                        <div className="bg-white mt-5 rounded-md shadow p-6">
                            <div className="grid grid-cols-1 gap-6">
                                <TextField
                                    label="Company's name"
                                    name="companyName"
                                    value={companyName}
                                    onChange={this.handleInputChange}
                                />
                                <TextField
                                    label={
                                        <TooltipWithIcon
                                            icon="question"
                                            label="Company's keywords"
                                            text="List products or services that you sell (climbing t-shirts, handmade jewelry, toys, watches, etc.)"
                                        />
                                    }
                                    name="companyKeywords"
                                    placeholder="t-shirts, bracelets, denim jeans, underwear"
                                    value={companyKeywords}
                                    onChange={this.handleInputChange}
                                />
                                <TextField
                                    label={
                                        <TooltipWithIcon
                                            icon="question"
                                            label="Company's audience"
                                            text="Who are your buyers (women, men, hikers, dog owners)?"
                                        />
                                    }
                                    name="companyAudience"
                                    placeholder="millenials, business owners, women, dog lovers"
                                    value={companyAudience}
                                    onChange={this.handleInputChange}
                                />
                                <TextField
                                    label={
                                        <TooltipWithIcon
                                            icon="question"
                                            label="Add a special offer"
                                            text="It can be a discount, free shipping, promotion for Valentine's day, etc."
                                        />
                                    }
                                    name="specialOffer"
                                    placeholder="Black Friday Sale"
                                    value={specialOffer}
                                    onChange={this.handleInputChange}
                                />

                                <div>
                                    <Button primary={true} onClick={this.handleGenerateAdTextClick}>
                                        Generate ad texts
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        adTextCreator: { aboutBusiness },
    } = state;

    return {
        companyName: aboutBusiness.companyName,
        companyKeywords: aboutBusiness.companyKeywords,
        companyAudience: aboutBusiness.companyAudience,
        specialOffer: aboutBusiness.specialOffer,
    };
};

export default withRouter(connect(mapStateToProps)(AboutBusiness));
