import { get } from "../api/api-list";
import { toggleToastPopup } from "./action-toast";
import { api } from "../utils";

export const FETCH_REACH_LIST_REQUEST = "FETCH_REACH_LIST_REQUEST";
export const FETCH_REACH_LIST_SUCCESS = "FETCH_REACH_LIST_SUCCESS";
export const FETCH_REACH_LIST_ERROR = "FETCH_REACH_LIST_ERROR";
export const UPDATE_REACH_CAMPAIGN = "UPDATE_REACH_CAMPAIGN";
export const TOGGLE_REACH_CAMPAIGN_EXPANSION = "TOGGLE_REACH_CAMPAIGN_EXPANSION";
export const TOGGLE_REACH_TABLE_EXPANSION = "TOGGLE_REACH_TABLE_EXPANSION";

export const ACTIONS = {
    RETRY_LAUNCHING_AD_SET_REQUESTED: "dynamicReachCampaign/RETRY_LAUNCHING_AD_SET_REQUESTED",
    RETRY_LAUNCHING_AD_SET_SUCCEED: "dynamicReachCampaign/RETRY_LAUNCHING_AD_SET_SUCCEED",
    RETRY_LAUNCHING_AD_SET_FAILED: "dynamicReachCampaign/RETRY_LAUNCHING_AD_SET_FAILED",

    FETCH_AD_SET_REQUESTED: "dynamicReachCampaign/FETCH_AD_SET_REQUESTED",
    FETCH_AD_SET_SUCCEED: "dynamicReachCampaign/FETCH_AD_SET_SUCCEED",
    FETCH_AD_SET_FAILED: "dynamicReachCampaign/FETCH_AD_SET_FAILED",

    RETRY_LAUNCHING_AD_REQUESTED: "dynamicReachCampaign/RETRY_LAUNCHING_AD_REQUESTED",
    RETRY_LAUNCHING_AD_SUCCEED: "dynamicReachCampaign/RETRY_LAUNCHING_AD_SUCCEED",
    RETRY_LAUNCHING_AD_FAILED: "dynamicReachCampaign/RETRY_LAUNCHING_AD_FAILED",

    FETCH_AD_REQUESTED: "dynamicReachCampaign/FETCH_AD_REQUESTED",
    FETCH_AD_SUCCEED: "dynamicReachCampaign/FETCH_AD_SUCCEED",
    FETCH_AD_FAILED: "dynamicReachCampaign/FETCH_AD_FAILED",
};

export const loadReachListRequest = (listType) => {
    return {
        type: FETCH_REACH_LIST_REQUEST,
        listType: listType,
    };
};

export const loadReachListSucceeded = (payload) => {
    return {
        type: FETCH_REACH_LIST_SUCCESS,
        payload: payload,
    };
};

export const loadReachListFailed = (payload) => {
    return {
        type: FETCH_REACH_LIST_ERROR,
        payload: payload,
    };
};

export const changeReachInternalStatus = (primary_key, id, internal_status) => {
    return {
        type: UPDATE_REACH_CAMPAIGN,
        payload: { primary_key, id, internal_status },
    };
};

export const loadReachList = (apiUrl, type = null) => (dispatch) => {
    if (!type) {
        return;
    }
    dispatch(loadReachListRequest(type));
    get(apiUrl, type)
        .then((res) => {
            if (res.error) {
                dispatch(toggleToastPopup([res.response.detail], "error"));
                dispatch(loadReachListFailed(res.response.detail));
            } else {
                dispatch(loadReachListSucceeded(res));
            }
        })
        .catch((err) => {
            dispatch(loadReachListFailed(err));
        });
};

export const updateReachCampaign = (primary_key, stop_time, daily_budget) => {
    return {
        type: UPDATE_REACH_CAMPAIGN,
        payload: { primary_key, stop_time, daily_budget },
    };
};

export const toggleReachCampaignExpansion = (primaryKey) => {
    return {
        type: TOGGLE_REACH_CAMPAIGN_EXPANSION,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const toggleReachTableExpansion = () => {
    return {
        type: TOGGLE_REACH_TABLE_EXPANSION,
        payload: {
            //
        },
    };
};

export const retryLaunchingReachAdRequested = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingReachAdSucceed = (ad) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SUCCEED,
        payload: {
            ad: ad,
        },
    };
};

export const retryLaunchingReachAdSucceedFailed = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingReachAd = (primaryKey) => (dispatch) => {
    dispatch(retryLaunchingReachAdRequested(primaryKey));

    api(`/api/v1/dynamic-ads-campaigns/${primaryKey}/retry-ad/`)
        .then((response) => {
            dispatch(retryLaunchingReachAdSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(retryLaunchingReachAdSucceedFailed(primaryKey));
            dispatch(fetchReachAd(primaryKey));
        });
};

export const fetchReachAdRequested = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchReachAdSucceed = (ad) => {
    return {
        type: ACTIONS.FETCH_AD_SUCCEED,
        payload: {
            ad: ad,
        },
    };
};

export const fetchReachAdFailed = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchReachAd = (primaryKey) => (dispatch) => {
    dispatch(fetchReachAdRequested(primaryKey));

    api(`/api/v1/dynamic-ads-ads/${primaryKey}/`)
        .then((response) => {
            dispatch(fetchReachAdSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(fetchReachAdFailed(primaryKey));
        });
};

export const retryLaunchingReachAdSetRequested = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingReachAdSetSucceed = (adSet) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_SUCCEED,
        payload: {
            adSet: adSet,
        },
    };
};

export const retryLaunchingReachAdSetSucceedFailed = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingReachAdSet = (primaryKey) => (dispatch) => {
    dispatch(retryLaunchingReachAdSetRequested(primaryKey));

    api(`/api/v1/dynamic-ads-campaigns/${primaryKey}/retry-adset/`)
        .then((response) => {
            dispatch(retryLaunchingReachAdSetSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(retryLaunchingReachAdSetSucceedFailed(primaryKey));
            dispatch(fetchReachAdSet(primaryKey));
        });
};

export const fetchReachAdSetRequested = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_SET_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchReachAdSetSucceed = (adSet) => {
    return {
        type: ACTIONS.FETCH_AD_SET_SUCCEED,
        payload: {
            adSet: adSet,
        },
    };
};

export const fetchReachAdSetFailed = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_SET_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchReachAdSet = (primaryKey) => (dispatch) => {
    dispatch(fetchReachAdSetRequested(primaryKey));

    api(`/api/v1/dynamic-ads-adsets/${primaryKey}/`)
        .then((response) => {
            dispatch(fetchReachAdSetSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(fetchReachAdSetFailed(primaryKey));
        });
};
