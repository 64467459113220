import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "../common/components/Icon";
import NewLinkBadge from "./NewLinkBadge";
import { toggleSection } from "../actions";

class NavigationLink extends Component {
    static propTypes = {
        index: PropTypes.number,
        showPages: PropTypes.bool,
    };

    static defaultProps = {
        index: 0,
        showPages: true,
    };

    handleClick = (event) => {
        const { index, showPages } = this.props;
        this.props.dispatch(toggleSection(index));

        if (showPages) {
            event.preventDefault();
        }
    };

    render() {
        const { isSinglePage, path, label, className, ico, badge, newPage, section } = this.props;

        return isSinglePage ? (
            <Link
                to={path}
                className={className}
                onClick={section ? this.handleClick : () => {}}
                // style={{textTransform: 'capitalize'}}
            >
                <Icon ico={ico} />
                <span className="Polaris-Navigation__Text">{label}</span>
                {typeof badge === "number" && (
                    <div className="Polaris-Navigation__Badge">
                        <span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">{badge}</span>
                    </div>
                )}
                {newPage && <NewLinkBadge />}
            </Link>
        ) : (
            <a
                href={path}
                className={className}
                onClick={section ? this.handleClick : () => {}}
                // style={{textTransform: 'capitalize'}}
            >
                <Icon ico={ico} />
                <span className="Polaris-Navigation__Text">{label}</span>
                {typeof badge === "number" && (
                    <div className="Polaris-Navigation__Badge">
                        <span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">{badge}</span>
                    </div>
                )}
                {newPage && <NewLinkBadge />}
            </a>
        );
    }
}

export default NavigationLink;
