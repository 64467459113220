import React, { Fragment, useState } from "react";
import GoogleLoginButton from "react-google-login";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import { OAUTH_SCOPES } from "./constants/google";
import { ConnectGoogleCredentials } from "../../api/api-google-shopping";
import { setGoogleAuthCode } from "../../actions";

const PermissionsErrorAlert = ({ onClose }) => (
    <Fragment>
        <Alert severity="error" onClose={onClose}>
            <div className="text-2xl">
                <strong>Manage your AdWords campaigns</strong> and{" "}
                <strong>Manage your product listings and accounts for Google Shopping</strong> permissions are required.
            </div>
        </Alert>
        <br />
    </Fragment>
);

const GoogleLogin = ({ renderElement, clientId }) => {
    const dispatch = useDispatch();

    const [permissionsError, setPermissionsError] = useState(false);

    const onLoginPermissionsFailure = (error) => {
        if (error.status === 403) {
            setPermissionsError(true);
        } else {
            console.log(error);
        }
    };

    const onLoginSuccess = async (response) => {
        dispatch(setGoogleAuthCode(response.code));
        const credentialsResponse = await ConnectGoogleCredentials(onLoginPermissionsFailure, response.code);
        if (credentialsResponse) {
            window.location.reload();
        }
    };

    return (
        <Fragment>
            {permissionsError && (
                <PermissionsErrorAlert
                    onClose={() => {
                        setPermissionsError(false);
                    }}
                />
            )}
            {clientId && (
                <GoogleLoginButton
                    clientId={clientId}
                    onSuccess={onLoginSuccess}
                    onFailure={(e) => console.log(e)}
                    accessType="offline"
                    responseType="code"
                    prompt="consent"
                    scope={OAUTH_SCOPES.join(" ")}
                    render={
                        renderElement
                            ? (renderProps) => (
                                  <span onClick={renderProps.onClick} aria-hidden="true">
                                      {renderElement}
                                  </span>
                              )
                            : undefined
                    }
                />
            )}
        </Fragment>
    );
};

export default GoogleLogin;
