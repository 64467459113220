import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "../../../styles/app.css";
import "./welcome.css";

import { persistor } from "../../store/create";
import { hideIntercomLauncher } from "../../utils";
import Button from "../../common/components/button";
import { logAnalytics } from "../../actions";
import { setType } from "../../actions/actions-dynamic-ads";

class Welcome extends Component {
    static toggleMenu(status) {
        const menuElements = [
            // window.document.getElementsByClassName("l-header")[0],
            window.document.getElementsByClassName("app-navigation")[0],
        ];

        menuElements.forEach((element) => {
            element.style.display = status === "hide" ? "none" : "block";
        });
    }

    async componentDidMount() {
        window.document.title = "Welcome | SIXADS";
        hideIntercomLauncher(true);
        Welcome.toggleMenu("hide");

        setTimeout(() => {
            persistor.purge();
        }, 500);
    }

    componentWillUnmount() {
        Welcome.toggleMenu("show");
    }

    /**
     * Converts shop url to Shop Name.
     * parde4.myshopify.com => Parde4
     * lorem-development-store.myshopify.com => Lorem Development Store
     * @param url
     */
    shopNameFromUrl = (url) => {
        return url
            .slice(0, -14) // Remove .myshopify.com
            .split("-")
            .map((text) => {
                return text.charAt(0).toUpperCase() + text.slice(1); // Uppercase first character
            })
            .join(" ");
    };

    isLargeStoreFromUrl = () => {
        const audit = new URLSearchParams(this.props.location.search).get("audit");
        if (audit === "true") {
            return true;
        }
        return false;
    };

    handleStartAdCampaign = () => {
        const { dispatch, history } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Start Ad Campaign Button",
                eventAction: "Click",
                eventLabel: "Welcome Page",
            })
        );

        if (this.isLargeStoreFromUrl()) {
            history.push("/account-audits/welcome/");
        }
    };

    handleReachStarted = () => {
        const { dispatch, history } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Reach Campaign(Onboarding)",
            })
        );
        dispatch(setType("REACH"));
        history.push("/dynamic-ads/create-campaign/account-connection/");
    };

    handleRemarketingStarted = () => {
        const { dispatch, history } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Retargeting Campaign(Onboarding)",
            })
        );
        dispatch(setType("REMARKETING"));
        history.push("/dynamic-ads/create-campaign/account-connection/");
    };

    handleUpsellStarted = () => {
        const { dispatch, history } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Upsell Campaign(Onboarding)",
            })
        );
        dispatch(setType("UPSELL"));
        history.push("/dynamic-ads/create-campaign/account-connection/");
    };

    handleGoogleShoppingStarted = () => {
        const { dispatch, history } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Google Shopping Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Google Shopping Campaign(Onboarding)",
            })
        );
        dispatch(setType("GOOGLESHOPPING"));
        history.push("/google-shopping/create-campaign/welcome/");
    };

    render() {
        const { shopUrl } = this.props;

        return (
            <Fragment>
                <div className="flex flex-col-reverse md:flex-row h-screen">
                    <div className="bg-teal-500 flex-1 flex flex-col justify-center items-center pt-10 md:pt-0 px-6">
                        <div className="font-sf-display text-display md:text-display-xl text-white text-center">
                            {this.shopNameFromUrl(shopUrl)}&nbsp;
                            <span role="img" aria-label="Waving Hand">
                                &#128075;
                            </span>
                            ,<br />
                            welcome to sixads
                        </div>
                        <div className="mt-14 w-full flex justify-center items-center">
                            <img
                                src="/static/img/dynamic-ads-dashboard.jpg"
                                alt="Dynamic ads dashboard"
                                className="w-full md:w-3/4"
                            />
                        </div>
                    </div>
                    <div className="bg-white flex-1 flex items-center justify-center pt-10 md:pt-0 relative">
                        <div className="w-full h-full md:h-1/3 flex flex-col justify-center items-center mx-10">
                            <div className="mt-28 md:mt- mx-2">
                                <div className="font-sf-display text-display md:text-display-lg text-gray-900">
                                    Accelerate your sales
                                    <span role="img" aria-label="Rocket">
                                        &#128640;
                                    </span>
                                </div>
                                <div className="font-sf-text text-card-subheading text-gray-700 mb-4 :mb-0 mt-12">
                                    Put your products in front of shoppers who want to buy what you sell with
                                    high-performing ads on Facebook, Google & Instagram. No need to be proficient.
                                </div>
                            </div>
                            <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-32 mb-14 w-full">
                                <div className="mt-4">
                                    <Button
                                        class="text-16px h-full"
                                        primary={true}
                                        onClick={() => this.handleReachStarted()}
                                    >
                                        Create Facebook Reach Campaign
                                    </Button>
                                </div>
                                <div className="mt-4">
                                    <Button
                                        class="text-16px h-full"
                                        primary={true}
                                        onClick={() => this.handleRemarketingStarted()}
                                    >
                                        Create Facebook Retargeting Campaign
                                    </Button>
                                </div>
                                <div className="mt-4">
                                    <Button
                                        class="text-16px h-full"
                                        primary={true}
                                        onClick={() => this.handleUpsellStarted()}
                                    >
                                        Create Facebook Upsell Campaign
                                    </Button>
                                </div>
                                <div className="mt-4">
                                    <Button
                                        class="text-16px h-full"
                                        primary={true}
                                        onClick={() => this.handleGoogleShoppingStarted()}
                                    >
                                        Create Google Shopping Campaign
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Welcome.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    shopUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { store } = state;

    return {
        isLoading: store.isLoading,
        shopUrl: store.shop_url,
    };
};

export default withRouter(connect(mapStateToProps)(Welcome));
