import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { Image } from "@shopify/polaris";
import { subscriptionPlans } from "../../../common/constants";
import { createSubscription, logAnalytics } from "../../../actions";

import styles from "./styles";
import Plan from "../../../common/components/sixads/plan";
import Switch from "../../../common/components/sixads/switch";
import Grid from "../../../common/components/sixads/grid/grid";
import PlanPurchaseSuccessBanner from "../../PlanPurchaseSuccessBanner";

const SwitchView = ({ switchOptions, selectedSwitchOption, handleSwtichChange }) => (
    <Fragment>
        <Image source="/static/img/get-three-months-free.svg" alt="Get three months free" className="plan-banner" />
        <div className="plan-switch">
            {selectedSwitchOption && (
                <Switch options={switchOptions} selected={selectedSwitchOption} onChange={handleSwtichChange} />
            )}
        </div>
    </Fragment>
);

const SwitchViewMobile = ({ switchOptions, selectedSwitchOption, handleSwtichChange }) => (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column width={1}>
                <Image
                    source="/static/img/get-three-months-free.svg"
                    alt="Get three months free"
                    className="plan-banner"
                />
            </Grid.Column>
            <Grid.Column width={1}>
                <div>
                    {selectedSwitchOption && (
                        <Switch options={switchOptions} selected={selectedSwitchOption} onChange={handleSwtichChange} />
                    )}
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

class Plans extends Component {
    static propTypes = {
        onRef: PropTypes.func,
        setStep: PropTypes.func,
        setProgress: PropTypes.func,
        dispatch: PropTypes.func,
        history: PropTypes.object,
        plans: PropTypes.object,
        subscription: PropTypes.shape({
            exists: PropTypes.bool,
            isLoading: PropTypes.bool,
            url: PropTypes.string,
            baseUrl: PropTypes.string,
            urlParams: PropTypes.array,
        }),
        onBackUrl: PropTypes.string,
        onNextUrl: PropTypes.string,
        returnUrl: PropTypes.string,
        freeUrl: PropTypes.string,
        Wrapper: PropTypes.node,
    };

    static defaultProps = {
        dispatch: () => {},
        onRef: () => {},
        setStep: () => {},
        setProgress: () => {},
        history: {},
        plans: {},
        subscription: {
            exists: false,
            url: "",
            baseUrl: "",
            urlPrams: [],
            isLoading: false,
        },
        onBackUrl: "/welcome/step4/",
        onNextUrl: "/welcome/step7/",
        returnUrl: "/welcome/step7/",
        freeUrl: "/welcome/step9/",
    };

    state = {
        switchOptions: [],
        selectedSwitchOption: "",
        selectedPlanId: null,
        isMobile: false,
        globalDiscountRate: 1.0,
        discountCode: "",
    };

    componentDidMount() {
        window.document.title = "Plans | SIXADS";

        const { onRef, setStep, setProgress, plans } = this.props;

        onRef(this);
        setStep(3);
        setProgress(83);

        const switchOptions = Object.keys(plans);
        this.setState({
            switchOptions: switchOptions,
            selectedSwitchOption: switchOptions[1],
        });

        window.addEventListener("resize", this.handleDisplay);
        this.handleDisplay();
    }

    disableNext = () => {
        const {
            subscription: { exists = false },
        } = this.props;
        return !exists;
    };

    handleSwitchChange = (option) => {
        this.setState({
            selectedSwitchOption: option,
        });
    };

    handleDisplay = () => {
        const isMobile = window.screen.width <= 700;
        this.setState({
            isMobile: isMobile,
        });
    };

    setGlobalDiscountRate = (globalDiscountRate) => {
        this.setState({
            globalDiscountRate: globalDiscountRate,
        });
    };

    setDiscountCode = (discountCode) => {
        this.setState({
            discountCode: discountCode,
        });
    };

    handleCreateSubscription = (planId, planName) => {
        const { dispatch, history, returnUrl, freeUrl } = this.props;
        const { discountCode } = this.state;

        this.setState({
            selectedPlanId: planId,
        });

        this.logAnalytics(planName);

        if (planName.toLowerCase() === "free") {
            history.push(freeUrl);
        } else {
            dispatch(createSubscription(planId, returnUrl, discountCode));
        }
    };

    logAnalytics = (planName) => {
        const { dispatch } = this.props;
        const { selectedSwitchOption } = this.state;

        planName = planName.toUpperCase();
        if (selectedSwitchOption === "yearly") {
            planName += " YEARLY";
        }
        const data = {
            hitType: "event",
            eventCategory: "Buy button",
            eventAction: "Click",
            eventLabel: planName,
        };

        dispatch(logAnalytics(data));
    };

    back() {
        const { history, onBackUrl } = this.props;
        history.push(onBackUrl);
    }

    next() {
        const { history, setStep, onNextUrl } = this.props;
        setStep(3);
        history.push(onNextUrl);
    }

    render() {
        const {
            plans: allPlans,
            subscription: { isLoading },
        } = this.props;
        const {
            switchOptions,
            selectedSwitchOption,
            selectedPlanId,
            isMobile,
            globalDiscountRate,
            discountCode,
        } = this.state;

        const plans = allPlans[selectedSwitchOption];

        return (
            <Fragment>
                <div className="wrapper">
                    <div className="Polaris-Page-Header">
                        <div className="Polaris-Page-Header__TitleActionMenuWrapper">
                            <div>
                                <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
                                    <PlanPurchaseSuccessBanner />
                                    <div className="Polaris-Header-Title title">
                                        <div className="header-with-subtitle">
                                            <h1 className="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                                                Accelerate your sales with ads{" "}
                                                <span role="img" aria-label="Money with Wings">
                                                    &#128184;
                                                </span>
                                            </h1>
                                            <p className="subtitle">Choose a plan that&apos;s right for you</p>
                                        </div>
                                        {!isMobile && (
                                            <SwitchView
                                                switchOptions={switchOptions}
                                                selectedSwitchOption={selectedSwitchOption}
                                                handleSwtichChange={this.handleSwitchChange}
                                            />
                                        )}

                                        {isMobile && (
                                            <SwitchViewMobile
                                                switchOptions={switchOptions}
                                                selectedSwitchOption={selectedSwitchOption}
                                                handleSwtichChange={this.handleSwitchChange}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Polaris-Page__Content">
                        <div className="row">
                            {plans &&
                                plans.map((plan) => {
                                    return (
                                        <div className="col" key={plan.name}>
                                            <Plan
                                                id={plan.id}
                                                name={plan.name}
                                                description={plan.description}
                                                badgeTitle={plan.badgeTitle}
                                                unitPrice={plan.monthlyPrice.toFixed(2)}
                                                unitPriceBeforeDiscount={
                                                    typeof plan.monthlyPriceBeforeDiscount === "number"
                                                        ? plan.monthlyPriceBeforeDiscount.toFixed(2)
                                                        : null
                                                }
                                                totalPrice={plan.totalPrice.toFixed(2)}
                                                featuresTitle={plan.featuresTitle}
                                                features={plan.features}
                                                onCreateSubscription={this.handleCreateSubscription}
                                                highlight={plan.highlight}
                                                subscribeButtonText={plan.subscribeButtonText}
                                                isLoading={isLoading && plan.id === selectedPlanId}
                                                globalDiscountRate={globalDiscountRate}
                                                setGlobalDiscountRate={this.setGlobalDiscountRate}
                                                discountCode={discountCode}
                                                setDiscountCode={this.setDiscountCode}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>

                <style jsx={true}>{styles}</style>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        store: {
            isCreateSubscriptionLoading,
            subscription: { id = null },
        },
    } = state;

    return {
        plans: subscriptionPlans,
        subscription: {
            exists: !!id,
            isLoading: isCreateSubscriptionLoading,
        },
    };
};

export default withRouter(connect(mapStateToProps)(Plans));
