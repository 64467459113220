import React from "react";
import { tickIcon, errorIcon } from "../../../utils/icons-utils";

const DashboardIndicator = (props) => {
    const { campaign_type, active } = props;

    const Icon = active ? tickIcon : errorIcon;
    const indicatorText = `${campaign_type} campaign ${active ? "created" : "inactive"}`;
    const backgroundColor = active ? "#1BA5831A" : "#FFDFBF80";
    const color = active ? "#1BA583" : "#AF5800";

    return (
        <div
            className="w-full flex flex-row items-center m-2 p-4 border rounded-2xl"
            style={{ backgroundColor: backgroundColor, color: color, borderColor: color }}
        >
            <div className="w-10 ml-2">
                <Icon />
            </div>
            <div className="ml-4 mt-1">{indicatorText}</div>
        </div>
    );
};

export default DashboardIndicator;
