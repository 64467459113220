import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class TableRow extends Component {
    static propTypes = {
        classes: PropTypes.string,
        isExpandable: PropTypes.bool,
        collapsedContent: PropTypes.element,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        children: PropTypes.node,
    };

    static defaultProps = {
        classes: "",
        isExpandable: false,
        collapsedContent: null,
        onMouseEnter: () => {},
        onMouseLeave: () => {},
        children: <span />,
    };

    render() {
        const { classes, isExpandable, collapsedContent, children, onMouseEnter, onMouseLeave } = this.props;
        return (
            <Fragment>
                <tr
                    onMouseEnter={() => {
                        onMouseEnter();
                    }}
                    onMouseLeave={() => {
                        onMouseLeave();
                    }}
                    className={classes}
                >
                    {children}
                </tr>
                {isExpandable && collapsedContent}
            </Fragment>
        );
    }
}

export default TableRow;
