import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Plans from "../../../pages/onboarding-v2/plans/Plans";

const GoogleShoppingPlans = () => {
    const history = useHistory();
    const subscription = useSelector((state) => state.store.subscription);
    const settingPageUrl = "/google-shopping/create-campaign/connect-accounts/";
    const freeUrl = "/dynamic-ads/";

    useEffect(() => {
        if (subscription.id) {
            history.push(settingPageUrl);
        }
    }, [subscription]);

    return (
        <Fragment>
            <Plans returnUrl={settingPageUrl} freeUrl={freeUrl} />
        </Fragment>
    );
};

export default GoogleShoppingPlans;
