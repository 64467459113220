/* eslint-disable */
import { Card } from "@shopify/polaris";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { logAnalytics, saveEditedProductInfo } from "../../actions";
import Button from "../../common/components/button";
import InputNumber from "../../common/components/inputNumber";
import Banner from "../../common/components/sixads/banner";
import TextArea from "../../common/components/textArea/TextArea";
import TextField from "../../common/components/textfield";

const EditProductDetails = () => {
    let { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(1);
    const [currentProduct, setCurrentProduct] = useState({});

    const { selectProducts } = useSelector((state) => state.imageOverlayAds);
    const { products: allProducts } = selectProducts;

    const getProduct = (_id) => {
        return allProducts.find((product) => product.api_id == _id);
    };

    useEffect(() => {
        const item = getProduct(id);
        setCurrentProduct(item);
        setDescription(item.description);
        setTitle(item.title);
        setPrice(item.price);
    }, [id, selectProducts]);

    const handleSave = () => {
        dispatch(saveEditedProductInfo({ id, title, description, price }));
        history.goBack();
    };

    const handleNavigateNext = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Ads exchange flow",
                eventAction: "Button click",
                eventLabel: "Go to selected product list",
            })
        );

        history.push("/ads-exchange/selected-product-list");
    };

    const ErrorBanner = () => {
        return (
            <Banner ref={errorRef} type="error" onClose={() => setShowErrorBanner(false)}>
                {errorText}
            </Banner>
        );
    };

    return (
        <div className="pt-24 md:pt-0 flex h-screen items-start md:items-center justify-start md:justify-center">
            <div className="w-full lg:w-3/4 xl:w-2/3 edit-card-view px-6 md:px-0">
                <Card>
                    <div className="grid md:grid-cols-2">
                        <div className="w-full items-start py-10 px-4">
                            <div className="py-3">
                                <TextField
                                    label="Title *"
                                    name="title"
                                    value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                />
                            </div>

                            <div className="py-3">
                                <TextArea
                                    label="Description"
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                    ariaExpanded={true}
                                    rows={5}
                                    placeholder="Description about the product"
                                />
                            </div>

                            <div className="py-3 flex flex-row">
                                <div className="w-40">
                                    <InputNumber
                                        label="Price *"
                                        name="price"
                                        value={price}
                                        onChange={(event) => setPrice(event.target.value)}
                                        placeholder="Price"
                                        minValue="1"
                                    />
                                </div>
                                <div className="pt-14 pl-2 text-3xl">{currentProduct.currency}</div>
                            </div>

                            <div className="w-full my-10">
                                <Button onClick={() => {}}>Select Photo</Button>
                                <Button primary={true} onClick={handleSave}>
                                    Save
                                </Button>
                            </div>
                        </div>
                        <div
                            className="p-10 flex justify-center items-center bg-white w-screen md:w-full overflow-auto"
                            style={{ backgroundColor: "#E0EBF6" }}
                        >
                            <div className="w-full px-8 md:w-4/5 xl: 2/3">
                                {currentProduct.images ? (
                                    currentProduct.images.length ? (
                                        <img src={currentProduct.images[0].src} />
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default EditProductDetails;
