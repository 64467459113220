import React, { useEffect } from "react";
import PropTypes from "prop-types";

const VideoEditorPriceV2 = (props) => {
    const { currency, price, setPrice, setCurrency } = props;

    return (
        <div>
            <label className="block text-3xl">
                <span className="text-gray-900 leading-10 text-2xl font-bold">Product price</span>
                <input
                    type="text"
                    name="productPrice"
                    className="mt-7 block w-10/12 rounded-md  border-0 border-b-3 border-gray-200 focus:ring-0 focus:border-gray-900 text-gray-900"
                    onChange={(ev) => setPrice(ev.target.value)}
                    value={price}
                />
            </label>
            <label className="block text-3xl">
                <span className="text-gray-900 leading-10 text-2xl font-bold">Currency</span>
                <input
                    type="text"
                    name="productCurrency"
                    className="mt-7 block w-10/12 rounded-md  border-0 border-b-3 border-gray-200 focus:ring-0 focus:border-gray-900 text-gray-900"
                    onChange={(ev) => setCurrency(ev.target.value)}
                    value={currency}
                />
            </label>
        </div>
    );
};

VideoEditorPriceV2.propTypes = {
    currency: PropTypes.string,
    price: PropTypes.string,
    setPrice: PropTypes.func,
    setCurrency: PropTypes.func,
};

export default VideoEditorPriceV2;
