import React, { useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import { SelectGuide, Request, Thanks } from "../../components/how-to";

const HowToGuides = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "How to guides | SIXADS";
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>

            <div>
                <Switch>
                    <Route path="/how-to-guides/welcome/" component={SelectGuide} exact={true} />
                    <Route path="/how-to-guides/form-submit/" component={Request} exact={true} />
                    <Route path="/how-to-guides/thank-you/" component={Thanks} exact={true} />
                </Switch>
            </div>
        </Fragment>
    );
};

const mapStateToProps = () => {
    return {
        //
    };
};

export default withRouter(connect(mapStateToProps)(HowToGuides));
