import React, { Fragment } from "react";
import Proptypes from "prop-types";

import styles from "./styles";
import InputLabelWithTooltip from "../../../../common/components/InputLabelWithTooltip";
import { nFormatter } from "../../../../utils";

const Estimations = (props) => {
    const { estimations } = props;
    return (
        <Fragment>
            <label>Estimated campaign results</label>
            <div className="estimations">
                <div>
                    <div className="estimations--icon">
                        <img src="/static/i/budget/reach.png" alt="reach" />
                    </div>
                    <div className="estimations--estimate">
                        <InputLabelWithTooltip
                            label="Reach"
                            tooltipIcon="info"
                            tooltipText="The number of people who see your ad, based on your daily budget & targeting. The actual number may differ from estimated."
                        />
                        <p className="responsive-estimations">{`${nFormatter(estimations.reach.min)} - ${nFormatter(
                            estimations.reach.max
                        )}`}</p>
                    </div>
                </div>

                <div>
                    <div className="estimations--icon">
                        <img src="/static/i/budget/click.png" alt="click" />
                    </div>
                    <div className="estimations--estimate">
                        <InputLabelWithTooltip
                            label="Clicks"
                            tooltipIcon="info"
                            tooltipText="The number of clicks on an ad, based on campaign performance & daily reach. The actual number may differ from estimated."
                        />
                        <p className="responsive-estimations">{`${nFormatter(estimations.click.min)} - ${nFormatter(
                            estimations.click.max
                        )}`}</p>
                    </div>
                </div>
            </div>

            <style jsx={true}>{styles}</style>
        </Fragment>
    );
};

Estimations.propTypes = {
    estimations: Proptypes.shape({
        reach: Proptypes.shape({
            min: Proptypes.number,
            max: Proptypes.number,
        }),
        click: Proptypes.shape({
            min: Proptypes.number,
            max: Proptypes.number,
        }),
    }).isRequired,
};

export default Estimations;
