import React from "react";
import { GoogleAdView } from "../../GoogleAdView";

const CurrentAdView = ({ primaryText = "", headline = " ", description = " ", image = "" }) => {
    const GenericView = ({ View }) => (
        <View primaryText={primaryText} imageUrl={image} headline={headline} description={description} />
    );

    return (
        <div className="ad-preview-wrapper">
            <div
                className="ad-preview"
                style={{
                    paddingTop: "2rem",
                }}
            >
                <GenericView View={GoogleAdView} />
            </div>
        </div>
    );
};

export default CurrentAdView;
