import { countries, exclude, get, subscribe, unsubscribe, update, updateSubscription } from "../api/api-store";
import { isLocalStorageAvailable, setSentryContextForShop } from "../utils";

export const INITIATE_STORE_REQUEST = "INITIATE_STORE_REQUEST";
export const INITIATE_STORE_FAILURE = "INITIATE_STORE_FAILURE";
export const INITIATE_STORE_SUCCESS = "INITIATE_STORE_SUCCESS";

export const initiateStoreRequest = (payload) => ({
    type: INITIATE_STORE_REQUEST,
    payload: payload,
});

export const initiateStoreFailure = (payload) => ({
    type: INITIATE_STORE_FAILURE,
    payload: payload,
});

export const initiateStoreSuccess = (payload) => ({
    type: INITIATE_STORE_SUCCESS,
    payload: payload,
});

export const initiate = (URL) => (dispatch) => {
    initiateStoreRequest();

    return get(URL)
        .then((payload) => {
            if (payload.status === "error") {
                dispatch(initiateStoreFailure(payload));
            } else {
                if (isLocalStorageAvailable()) localStorage.setItem("store_id", payload.id);
                dispatch(initiateStoreSuccess(payload));
                setSentryContextForShop(payload);
            }
        })
        .catch((error) => {
            throw error;
        });
};

export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const UPDATE_STORE_FAILURE = "UPDATE_STORE_FAILURE";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";

export const updateStoreRequest = (payload) => ({
    type: UPDATE_STORE_REQUEST,
    payload: payload,
});

export const updateStoreFailure = (payload) => ({
    type: UPDATE_STORE_FAILURE,
    payload: payload,
});

export const updateStoreSuccess = (payload) => ({
    type: UPDATE_STORE_SUCCESS,
    payload: payload,
});

export const updateStore = (data) => (dispatch) => {
    dispatch(updateStoreRequest(data));

    return update(data)
        .then((payload) => {
            dispatch(updateStoreSuccess(payload));
        })
        .catch((error) => {
            throw error;
        });
};

export const EXCLUDE_CATEGORIES_REQUEST = "EXCLUDE_CATEGORIES_REQUEST";
export const EXCLUDE_CATEGORIES_FAILURE = "EXCLUDE_CATEGORIES_FAILURE";
export const EXCLUDE_CATEGORIES_SUCCESS = "EXCLUDE_CATEGORIES_SUCCESS";

export const updateCategoriesRequest = (payload) => ({
    type: EXCLUDE_CATEGORIES_REQUEST,
    payload: payload,
});

export const updateCategoriesFailure = (payload) => ({
    type: EXCLUDE_CATEGORIES_FAILURE,
    payload: payload,
});

export const updateCategoriesSuccess = (payload) => ({
    type: EXCLUDE_CATEGORIES_SUCCESS,
    payload: payload,
});

export const updateCategories = (data, action) => (dispatch) => {
    dispatch(updateCategoriesRequest());

    return exclude({ ids: data }, action)
        .then((payload) => {
            dispatch(updateCategoriesSuccess(payload));
        })
        .catch((error) => {
            throw error;
        });
};

export const cancelSubscription = () => {
    return unsubscribe("Uninstall page");
};

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";

export const cancelSubscriptionRequest = (payload) => ({
    type: CANCEL_SUBSCRIPTION_REQUEST,
    payload: payload,
});

export const cancelSubscriptionSuccess = (payload) => ({
    type: CANCEL_SUBSCRIPTION_SUCCESS,
    payload: payload,
});

export const cancelSubscriptionError = (payload) => ({
    type: CANCEL_SUBSCRIPTION_ERROR,
    payload: payload,
});

export const cancelSubscriptionBoostedPage = (reason) => (dispatch) => {
    dispatch(cancelSubscriptionRequest());
    return unsubscribe(reason).then((payload) => {
        if (payload.status === "error") {
            dispatch(cancelSubscriptionError());
        } else {
            dispatch(cancelSubscriptionSuccess(payload));
        }
    });
};

export const UPDATE_STORE_COUNTRIES = "UPDATE_STORE_COUNTRIES";

export const updateStoreCountries = (payload) => ({
    type: UPDATE_STORE_COUNTRIES,
    payload: payload,
});

export const updateCountries = (data, action) => (dispatch) => {
    return countries({ ids: data }, action)
        .then((payload) => {
            if (payload.status !== "error") {
                dispatch(updateStoreCountries(payload.items));
            }
        })
        .catch((error) => {
            throw error;
        });
};

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_ERROR = "CREATE_SUBSCRIPTION_ERROR";

export const createSubscriptionRequest = (payload) => ({
    type: CREATE_SUBSCRIPTION_REQUEST,
    payload: payload,
});

export const createSubscriptionError = (payload) => ({
    type: CREATE_SUBSCRIPTION_ERROR,
    payload: payload,
});

export const createSubscription = (subscriptionId, returnUrl = null, discountCode = "") => (dispatch) => {
    dispatch(createSubscriptionRequest());
    return subscribe(subscriptionId, returnUrl, discountCode).then((response) => {
        if (response.error) {
            dispatch(createSubscriptionError());
        } else {
            window.location.href = response.url;
        }
    });
};

export const UPDATE_SUBSCRIPTION_CHANNELS_REQUEST = "UPDATE_SUBSCRIPTION_CHANNELS_REQUEST";
export const UPDATE_SUBSCRIPTION_CHANNELS_SUCCESS = "UPDATE_SUBSCRIPTION_CHANNELS_SUCCESS";
export const UPDATE_SUBSCRIPTION_CHANNELS_ERROR = "UPDATE_SUBSCRIPTION_CHANNELS_ERROR";

export const updateSubscriptionChannelsRequest = (payload) => ({
    type: UPDATE_SUBSCRIPTION_CHANNELS_REQUEST,
    payload: payload,
});

export const updateSubscriptionChannelsSuccess = (payload) => ({
    type: UPDATE_SUBSCRIPTION_CHANNELS_SUCCESS,
    payload: payload,
});

export const updateSubscriptionChannelsError = (payload) => ({
    type: UPDATE_SUBSCRIPTION_CHANNELS_ERROR,
    payload: payload,
});

export const updateSubscriptionChannels = (data) => (dispatch) => {
    dispatch(updateSubscriptionChannelsRequest());
    return updateSubscription(data).then((response) => {
        if (response.error) {
            dispatch(updateSubscriptionChannelsError());
        } else {
            dispatch(updateSubscriptionChannelsSuccess(response));
        }
    });
};
