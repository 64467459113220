const withRetryLaunching = (reducer, actions) => (state, action) => {
    switch (action.type) {
        case actions.RETRY_LAUNCHING_AD_REQUESTED:
        case actions.FETCH_AD_REQUESTED:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    return {
                        ...campaign,
                        ad_sets: campaign.ad_sets.map((adSet) => {
                            return {
                                ...adSet,
                                ads: adSet.ads.map((ad) => {
                                    if (ad.primary_key === action.payload.primaryKey) {
                                        return {
                                            ...ad,
                                            isLoading: true,
                                        };
                                    }
                                    return { ...ad };
                                }),
                            };
                        }),
                    };
                }),
            };
        case actions.RETRY_LAUNCHING_AD_SUCCEED:
        case actions.FETCH_AD_SUCCEED:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    return {
                        ...campaign,
                        ad_sets: campaign.ad_sets.map((adSet) => {
                            return {
                                ...adSet,
                                ads: adSet.ads.map((ad) => {
                                    if (ad.primary_key === action.payload.ad.primary_key) {
                                        return {
                                            ...action.payload.ad,
                                            isLoading: false,
                                        };
                                    }
                                    return { ...ad };
                                }),
                            };
                        }),
                    };
                }),
            };
        case actions.RETRY_LAUNCHING_AD_FAILED:
        case actions.FETCH_AD_FAILED:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    return {
                        ...campaign,
                        ad_sets: campaign.ad_sets.map((adSet) => {
                            return {
                                ...adSet,
                                ads: adSet.ads.map((ad) => {
                                    if (ad.primary_key === action.payload.primaryKey) {
                                        return {
                                            ...ad,
                                            isLoading: false,
                                        };
                                    }
                                    return { ...ad };
                                }),
                            };
                        }),
                    };
                }),
            };

        case actions.RETRY_LAUNCHING_AD_SET_REQUESTED:
        case actions.FETCH_AD_SET_REQUESTED:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    return {
                        ...campaign,
                        ad_sets: campaign.ad_sets.map((adSet) => {
                            if (adSet.primary_key === action.payload.primaryKey) {
                                return {
                                    ...adSet,
                                    isLoading: true,
                                };
                            }
                            return { ...adSet };
                        }),
                    };
                }),
            };
        case actions.RETRY_LAUNCHING_AD_SET_SUCCEED:
        case actions.FETCH_AD_SET_SUCCEED:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    return {
                        ...campaign,
                        ad_sets: campaign.ad_sets.map((adSet) => {
                            if (adSet.primary_key === action.payload.adSet.primary_key) {
                                return {
                                    ...action.payload.adSet,
                                    isLoading: false,
                                    ads: action.payload.adSet.ads.map((ad) => {
                                        return {
                                            ...ad,
                                            isLoading: false,
                                        };
                                    }),
                                };
                            }
                            return { ...adSet };
                        }),
                    };
                }),
            };
        case actions.RETRY_LAUNCHING_AD_SET_FAILED:
        case actions.FETCH_AD_SET_FAILED:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    return {
                        ...campaign,
                        ad_sets: campaign.ad_sets.map((adSet) => {
                            if (adSet.primary_key === action.payload.primaryKey) {
                                return {
                                    ...adSet,
                                    isLoading: false,
                                };
                            }
                            return { ...adSet };
                        }),
                    };
                }),
            };

        default:
            return reducer(state, action);
    }
};

export default withRetryLaunching;
