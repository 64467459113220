import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, Image, Layout, Page, Stack, Spinner } from "@shopify/polaris";

import { logAnalytics } from "../actions";

import Heading from "../components/Heading";

class PaymentSuccessful extends Component {
    state = {
        status: "success",
    };

    componentDidMount() {
        document.title = "Payment Successful | SIXADS";

        const node = document.querySelector("#payment-status");

        if (node && node.value) {
            this.setState({ status: node.value });
        }
    }

    handlePromoBannerClick = (redirectTo, eventAction) => {
        const { dispatch, history } = this.props;

        const data = {
            hitType: "event",
            eventCategory: "Cross-sell Banner",
            eventAction: eventAction,
            eventLabel: "Boosted Thank You Page",
        };
        dispatch(logAnalytics(data));
        history.push(redirectTo);
    };

    render() {
        const { status } = this.state;
        const { planType, planName } = this.props;

        const Success = () => {
            return (
                <Card sectioned={true}>
                    <Heading content={`Congrats! Your ${planType} ${planName} plan is now active`} />
                </Card>
            );
        };

        const Fail = () => {
            return (
                <Card sectioned={true}>
                    <Heading content="Transaction aborted." />
                    <Fragment>
                        Your account was not charged. Try again or contact our support via support@sixads.net
                    </Fragment>
                </Card>
            );
        };

        const ToStartSelling = () => {
            return (
                <Card title="To start selling">
                    <div className="mobileStack">
                        <Stack distribution="fillEvenly" style={{ alignItems: "center" }}>
                            <Layout.Section oneFourth={true}>
                                <div style={{ textAlign: "center" }}>
                                    <Image source="/static/img/how-it-works-1.svg" />
                                    <p>
                                        Choose ad platforms you <br /> want to advertise on.
                                    </p>
                                </div>
                            </Layout.Section>
                            <Layout.Section oneFourth={true}>
                                <div style={{ textAlign: "center" }}>
                                    <Image source="/static/img/how-it-works-2.svg" />
                                    <p>Choose products to advertise.</p>
                                </div>
                            </Layout.Section>
                            <Layout.Section oneFourth={true}>
                                <div style={{ textAlign: "center" }}>
                                    <Image source="/static/img/how-it-works-3.svg" />
                                    <p>
                                        Approve personalized product <br /> ads we create for you.
                                    </p>
                                </div>
                            </Layout.Section>
                            <Layout.Section oneFourth={true}>
                                <div style={{ textAlign: "center" }}>
                                    <Image source="/static/img/how-it-works-4.svg" />
                                    <p>
                                        Start selling
                                        <br />
                                    </p>
                                </div>
                            </Layout.Section>
                        </Stack>
                    </div>
                </Card>
            );
        };

        return (
            <Page>
                {planType === undefined && (
                    <div className="c-spinner">
                        <Spinner />
                    </div>
                )}
                {planType !== undefined && status === "success" && <Success />}
                {planType !== undefined && status === "failed" && <Fail />}
                <ToStartSelling />
            </Page>
        );
    }
}

const mapStateToProps = (state) => {
    const { store } = state;
    const { subscription } = store;

    const toLowerCase = (text) => {
        return text != null ? text.toLowerCase() : text;
    };

    return {
        channels: subscription.traffic_channels_to_enable || 0,
        planName: subscription.name,
        // Need to lowercase plan name. But sometimes its null.
        planType: toLowerCase(subscription.type),
    };
};

export default withRouter(connect(mapStateToProps)(PaymentSuccessful));
