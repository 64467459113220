/**
 * Fetches specific error message from the global errors array and return thata message.
 * Should use it to display error messages in components like text fields, select fields etc.
 *
 * @param errors
 * @param key
 * @returns {null|string}
 */
const renderError = (errors = [], key, customMessage = null) => {
    const [error] = errors.filter(({ requiredFor = "" }) => requiredFor === key);
    if (error) {
        return customMessage || error.message;
    }
    return null;
};

const toTitleCase = (text) => text.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());

export { renderError, toTitleCase };
