/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "../../common/components/button";
import Tooltip from "../../common/components/tooltip2";
import { getFacebookHeadlinesTexts } from "../../actions/actions-ad-text-creator";
import Loader from "../../common/components/loader";

class FacebookHeadlines extends Component {
    state = {
        tooltipText: "Copy this text",
        tooltipType: "default",
    };

    componentDidMount() {
        const { dispatch, companyName, companyKeywords, companyAudience, specialOffer } = this.props;

        dispatch(getFacebookHeadlinesTexts(companyName, companyKeywords, companyAudience, specialOffer));
    }

    copyText = (textContainer) => {
        const textArea = document.createElement("textarea");
        textArea.value = textContainer.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
    };

    showSuccessMessage = () => {
        this.setState({
            tooltipText: "Text copied",
            tooltipType: "success",
        });

        window.setTimeout(() => {
            this.setState({
                tooltipText: "Copy this text",
                tooltipType: "default",
            });
        }, 500);
    };

    handleCopyText = (event) => {
        const textContainer = event.target;

        this.copyText(textContainer);

        this.showSuccessMessage(textContainer);
    };

    handleBack = () => {
        const { history } = this.props;

        history.push("/ad-creative/ad-text-creator/about-business/");
    };

    render() {
        const { isLoading, headlines } = this.props;
        const { tooltipText, tooltipType } = this.state;

        return (
            <Fragment>
                <div className="pt-24">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 mt-12 font-sf-text pb-52">
                        <div>
                            <h1 className="page-title">
                                Your powerful Facebook Ad headlines are ready!{" "}
                                <span role="img" aria-label="Heavy Check Mark">
                                    ✔
                                </span>
                            </h1>
                            <p className="page-subtitle">
                                Pick the one you love the most, copy it, and paste it on your Facebook Ad.
                            </p>
                        </div>
                        <div className="mt-5">
                            {isLoading && (
                                <div className="flex justify-center">
                                    <Loader />
                                </div>
                            )}

                            <div className="grid grid-cols-1 gap-10">
                                {headlines.map((headline) => {
                                    return (
                                        <div
                                            className="border-solid bg-white hover:bg-green-100 shadow rounded-lg cursor-pointer"
                                            key={headline.id}
                                        >
                                            <Tooltip text={tooltipText} type={tooltipType}>
                                                <div className="p-10 text-16px" onClick={this.handleCopyText}>
                                                    {headline.text}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white fixed bottom-0 w-full py-6">
                        <div className="w-11/12 md:w-2/4 mx-auto my-0 flex justify-end">
                            <Button onClick={this.handleBack}>Back</Button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        adTextCreator: { aboutBusiness, facebookHeadlines },
    } = state;

    return {
        isLoading: facebookHeadlines.isLoading,
        companyName: aboutBusiness.companyName,
        companyKeywords: aboutBusiness.companyKeywords,
        companyAudience: aboutBusiness.companyAudience,
        specialOffer: aboutBusiness.specialOffer,
        headlines: facebookHeadlines.headlines,
    };
};

export default withRouter(connect(mapStateToProps)(FacebookHeadlines));
