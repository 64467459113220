/* eslint-disable dot-notation */
import { combineReducers } from "redux";

import { settings } from "./reducer-settings";
import { ads } from "./reducer-ads";
import { categories } from "./reducer-categories";
import { countries } from "./reducer-countries";
import { features } from "./reducer-features";
import { navigation } from "./reducer-navigation";
import { onboarding } from "./reducer-onboarding";
import { products } from "./reducer-products";
import { store } from "./reducer-store";
import { uninstall } from "./reducer-uninstall";
import { createCampaign } from "./reducer-create-campaign";
import { toast } from "./reducer-toast";
import { popUp } from "./reducer-popup";
import list from "./reducer-list";
import reachList from "./reducer-dynamic-reach-list";
import remarketingList from "./reducer-dynamic-remarketing-list";
import upsellList from "./reducer-dynamic-upsell-list";
import { videoAds } from "./reducer-video-ads";
import adTextCreator from "./reducer-ad-text-creator";
import imageOverlayAds from "./reducer-image-overlay-ads";
import dynamicAds from "./reducer-dynamic-ads";
import googleShopping from "./reducer-google-shopping";
import adsExchange from "./reducer-ads-exchange";

const rootReducer = combineReducers({
    settings: settings,
    ads: ads,
    categories: categories,
    countries: countries,
    features: features,
    navigation: navigation,
    onboarding: onboarding,
    products: products,
    store: store,
    uninstall: uninstall,
    toast: toast,
    popUp: popUp,
    createCampaign: createCampaign,
    list: list,
    reachList: reachList,
    remarketingList: remarketingList,
    upsellList: upsellList,
    videoAds: videoAds,
    adTextCreator: adTextCreator,
    imageOverlayAds: imageOverlayAds,
    dynamicAds: dynamicAds,
    googleShopping: googleShopping,
    adsExchange: adsExchange,
});

export default rootReducer;
