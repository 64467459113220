import React from "react";

export const subscriptionPlans = {
    yearly: [
        {
            id: 7,
            name: "Essential",
            description: "Facebook remarketing",
            badgeTitle: "Just starting",
            monthlyPrice: 14.99,
            monthlyPriceBeforeDiscount: 29.99,
            totalPrice: 179.88,
            features: [
                "Automated campaign creation",
                "Facebook remarketing campaigns",
                "Personalized ad visuals & texts",
            ],
        },
        {
            id: 8,
            name: "Pro",
            description: "Facebook reach + remarketing",
            badgeTitle: "Improve ROAS",
            monthlyPrice: 25.99,
            monthlyPriceBeforeDiscount: 44.99,
            totalPrice: 311.88,
            featuresTitle: (
                <p>
                    Everything in <b>ESSENTIAL</b>, plus
                </p>
            ),
            features: ["Facebook reach + remarketing campaigns", "Powerful video ads", "Support via email"],
            highlight: true,
        },
        {
            id: 9,
            name: "Premium",
            description: "Facebook & Google reach + remarketing",
            badgeTitle: "Scale profitability & fast",
            monthlyPrice: 67.99,
            monthlyPriceBeforeDiscount: 89.99,
            totalPrice: 815.88,
            featuresTitle: (
                <p>
                    Everything in <b>PRO</b>, plus
                </p>
            ),
            features: [
                "Facebook reach + remarketing",
                "Google reach + remarketing",
                "Channel performance comparison",
                "Live chat support",
            ],
        },
        // {
        //     id: 11,
        //     name: "Managed Service",
        //     description: (
        //         <span>
        //             For stores that generate <b>at least 20 sales per week</b>
        //         </span>
        //     ),
        //     badgeTitle: "Scale profitability & fast",
        //     monthlyPrice: 299.25,
        //     monthlyPriceBeforeDiscount: 399.0,
        //     totalPrice: 3591.0,
        //     subscribeButtonText: "Start 7-day trial",
        //     featuresTitle: <p>Account management by our Paid Marketing Expert</p>,
        //     features: [
        //         "Ad account audit",
        //         "Creation of full marketing funnel on Facebook",
        //         "Constant campaign monitoring and optimisation",
        //         "Campaign scaling",
        //         "Reporting and improvement suggestions",
        //         "7 days free trial",
        //     ],
        // },
    ],
    monthly: [
        {
            id: 4,
            name: "Essential",
            description: "Facebook remarketing",
            badgeTitle: "Just starting",
            monthlyPrice: 29.99,
            totalPrice: 29.99,
            features: [
                "Automated campaign creation",
                "Facebook remarketing campaigns",
                "Personalized ad visuals & texts",
            ],
        },
        {
            id: 5,
            name: "Pro",
            description: "Facebook reach + remarketing",
            badgeTitle: "Improve ROAS",
            monthlyPrice: 44.99,
            totalPrice: 44.99,
            featuresTitle: (
                <p>
                    Everything in <b>ESSENTIAL</b>, plus
                </p>
            ),
            features: ["Facebook reach + remarketing campaigns", "Powerful video ads", "Support via email"],
            highlight: true,
        },
        {
            id: 6,
            name: "Premium",
            description: "Facebook & Google reach + remarketing",
            badgeTitle: "Scale profitability & fast",
            monthlyPrice: 89.99,
            totalPrice: 89.99,
            featuresTitle: (
                <p>
                    Everything in <b>PRO</b>, plus
                </p>
            ),
            features: [
                "Facebook reach + remarketing",
                "Google reach + remarketing",
                "Channel performance comparison",
                "Live chat support",
            ],
        },
        // {
        //     id: 10,
        //     name: "Managed Service",
        //     description: (
        //         <span>
        //             For stores that generate <b>at least 20 sales per week</b>
        //         </span>
        //     ),
        //     badgeTitle: "Scale profitability & fast",
        //     monthlyPrice: 399.0,
        //     totalPrice: 399.0,
        //     subscribeButtonText: "Start 7-day trial",
        //     featuresTitle: <p>Account management by our Paid Marketing Expert</p>,
        //     features: [
        //         "Ad account audit",
        //         "Creation of full marketing funnel on Facebook",
        //         "Constant campaign monitoring and optimisation",
        //         "Campaign scaling",
        //         "Reporting and improvement suggestions",
        //         "7 days free trial",
        //     ],
        // },
    ],
};

export default null;
