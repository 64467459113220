import React from "react";
import { Checkbox } from "@mui/material";
import { withStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} color="default" {...props} />);

export default GreenCheckbox;
