/* eslint-disable */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "../../../common/components/button";
import { logAnalytics, navigateVideoAds } from "../../../actions";
import { Template0 } from "../../../components/video-ads/templates";
import BrowserNotSupportedBanner from "../BrowserNotSupportedBanner";

const Welcome = ({ history, dispatch }) => {
    useEffect(() => {
        window.document.title = "Welcome to Video Ads | SIXADS";
    }, []);

    const handleButtonClick = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Choose product for your video",
                eventAction: "Button click",
                eventLabel: "Video flow",
            })
        );

        dispatch(navigateVideoAds(2));
        history.push("/video-ads/select-product/");
    };

    return (
        <div className="pt-0 md:mt-0 -mt-40 md:pt-8 md:max-w-1/2 mx-auto  my-0">
            <BrowserNotSupportedBanner wrapperClasses="mb-10" />

            <div className="flex md:flex-row flex-col md:space-x-7 flex-col-reverse p-16">
                <div className="flex-1 flex flex-col justify-center">
                    <div className="flex flex-col justify-around space-y-13 pt-16">
                        <p className="font-sf-display text-display md:text-display-lg text-gray-900">
                            Show-stopping & effective video ads. For FREE. 🎥
                        </p>
                        <p className="text-gray-700 md:text-3xl text-lg">
                            Transform your Shopify products to powerful video ads that sell. Explore stunning templates
                            & create custom product videos in just a few clicks.
                        </p>
                    </div>
                    <div className="mt-16 md:mt-48">
                        <Button primary={true} onClick={handleButtonClick}>
                            <b>
                                <span className="md:text-3xl text-xl">Choose product for your video</span>
                            </b>
                        </Button>
                    </div>
                </div>
                <div className="flex-1 template-holder" style={{ width: "40%" }}>
                    <Template0
                        name="BESTHER WIDE WAISTBAND RIB-KNIT CAPRI LEGGINGS"
                        price="$ 56.90"
                        adMessage="FREE SHIPPING"
                    />
                </div>
            </div>
        </div>
    );
};

export default withRouter(connect()(Welcome));
