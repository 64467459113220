import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import { Welcome, SelectProduct, ChooseTemplate, ThankYou, CreateAd } from "../../components/image-overlay-ads";

const ImageOverlayAds = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        window.document.title = "Image overlay ads | SIXADS";
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />

            <div>
                <Switch>
                    <Route path="/image-ad-creator/">
                        <Redirect to={`/ad-creative${location.pathname}`} />
                    </Route>
                    <Route path="/ad-creative/image-ad-creator/welcome/" component={Welcome} exact={true} />
                    <Route
                        path="/ad-creative/image-ad-creator/select-product/"
                        component={SelectProduct}
                        exact={true}
                    />
                    <Route
                        path="/ad-creative/image-ad-creator/choose-template/"
                        component={ChooseTemplate}
                        exact={true}
                    />
                    <Route path="/ad-creative/image-ad-creator/create-ad/" component={CreateAd} exact={true} />
                    <Route path="/ad-creative/image-ad-creator/thank-you/" component={ThankYou} exact={true} />
                </Switch>
            </div>
        </Fragment>
    );
};

export default ImageOverlayAds;
