import { LOCATION_CHANGE, TOGGLE_NAVIGATION } from "../actions";
import { hideIntercomLauncher } from "../utils";

const animateNavigation = () => {
    const bodyCSS = document.body.classList;
    const node = document.querySelector(".l-navigation");

    bodyCSS.add("is-navigation--animating");

    if (!node) {
        return;
    }

    node.addEventListener("transitionend", function handler(e) {
        if (e.propertyName !== "transform") {
            return;
        }
        bodyCSS.remove("is-navigation--animating");
        node.removeEventListener(e.type, handler);
    });
};

const navigationMiddleware = () => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        if (window.location.href.indexOf("/welcome") === -1 && window.location.href.indexOf("/video-ads-v2/") === -1) {
            hideIntercomLauncher(false);
        }

        window.scrollTo(0, 0);
        // this function is defined in sixads/tempaltes/base.html
        if (typeof window.triggerHotJarNPS !== "undefined") {
            window.triggerHotJarNPS(action.path);
        }

        // setTimeout(triggerConnectPlus, 50);
    }

    if (action.type === TOGGLE_NAVIGATION) {
        animateNavigation();
    }

    return next(action);
};

export default navigationMiddleware;
