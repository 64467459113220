import React from "react";

export const getComponents = (defaultComps, overrides = {}) => {
    return Object.keys(defaultComps).reduce((acc, name) => {
        const override = overrides[name] ? overrides[name] : {};
        const { props = {}, component = defaultComps[name], children = <span /> } = override;
        acc[name] = {
            component: component,
            props: { style: override ? override.style : {}, ...props },
            children: children,
        };
        return acc;
    }, {});
};

export const createDefault = (type = null, props = {}) => {
    return React.createElement(type, props, props.children || null);
};

export const createWithClassesOrStyles = ({ type = null, props = {}, classes = "", styles = {} }) =>
    createDefault(type, {
        className: classes,
        style: styles,
        ...props,
    });
