import {
    EXIT_ONBOARDING,
    INIT_ONBOARDING,
    NAVIGATE_ONBOARDING,
    PUT_ONBOARDING_ERROR,
    PUT_ONBOARDING_VALUE,
    SET_ONBOARDING_OPTION,
    SKIP_ONBOARDING,
} from "../actions";

const initial = {
    isActive: false,
    isSkipped: false,
    step: 0,
    option: "machines",
    pages: {
        importProducts: {
            values: {
                shopifyProducts: [],
                selectedProducts: [],
            },
            errors: [],
        },
        CampaignGeoTargeting: {
            values: {
                selectedCountriesIds: [],
            },
            errors: [],
        },
        productAds: {
            values: {
                items: [],
            },
            errors: [],
        },
        adEdit: {
            values: {
                primaryText: "",
                headline: "",
                description: "",
            },
            errors: [],
        },
        adTargeting: {
            values: {
                country: "",
                targetDevice: "",
                gender: "",
                minAge: "",
                maxAge: "",
                interestCategoryId: "",
            },
        },
        plans: {
            values: {
                subscription: {
                    facebookEnabled: false,
                    instagramEnabled: false,
                    googleEnabled: false,
                },
            },
            errors: [],
        },
        budgetAndSchedule: {
            values: {
                productAds: [],
                dates: {
                    start: null,
                    end: null,
                },
                dailyBudget: 2,
                hasRemarketing: true,
            },
            errors: [],
        },
        accountConnection: {
            values: {
                google: {
                    required: false,
                    connected: false,
                },
            },
            errors: [],
        },
    },
    current: {
        hasErrors: false,
    },
};

// eslint-disable-next-line import/prefer-default-export
export const onboarding = (state = initial, action) => {
    switch (action.type) {
        case INIT_ONBOARDING:
            return {
                ...state,
                isActive: true,
                step: action.payload.step || 0,
                option: action.payload.step ? "manually" : "",
            };
        case EXIT_ONBOARDING:
            return {
                ...state,
                isActive: false,
            };
        case SKIP_ONBOARDING:
            return {
                ...state,
                isActive: false,
                isSkipped: true,
            };
        case NAVIGATE_ONBOARDING:
            return {
                ...state,
                step: action.step,
            };
        case SET_ONBOARDING_OPTION:
            return {
                ...state,
                step: action.step,
                option: action.option,
            };
        case PUT_ONBOARDING_VALUE:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page]: {
                        values: {
                            ...action.values,
                        },
                        errors: [...state.pages[action.page].errors],
                    },
                },
            };
        case PUT_ONBOARDING_ERROR:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page]: {
                        values: {
                            ...state.pages[action.page].values,
                        },
                        errors: [...action.errors],
                    },
                },
            };
        default:
            return state;
    }
};
