import React, { useEffect, useState, useCallback } from "react";
// import { Modal } from "@shopify/polaris";
import { Modal } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "../common/components/button";
import { Rating } from "../common/components/sixads/rating/rating";
import TextField from "../common/components/sixads/inputs/TextField";
import { api, getItemByStoreId, setItemByStoreId } from "../utils";
import Loader from "../common/components/sixads/loader/loader";
import { logAnalytics } from "../actions";

const shopifyReviveLink = "https://apps.shopify.com/sixads-net?#modal-show=ReviewListingModal";

const ReviewPopup = ({ dispatch = () => {}, popUpDelay = 2000 }) => {
    const [value, setValue] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const reviewPopUpShowed = getItemByStoreId("wasReviewPopUpShowed");
        const isReviewPopUpShowedEmptyArray = Array.isArray(reviewPopUpShowed) && reviewPopUpShowed.length === 0;
        if (!reviewPopUpShowed || isReviewPopUpShowedEmptyArray) {
            setTimeout(() => {
                setOpen(true);
                setItemByStoreId("wasReviewPopUpShowed", "true");
            }, popUpDelay);
        }
    }, []);

    const saveFeedback = async () => {
        const url = "/api/v1/features/";
        setLoading(true);
        await api(url, {
            method: "POST",
            data: {
                type: 10,
                message: `${value}: ${feedback}`,
            },
        })
            .then((response) => {
                dispatch(
                    logAnalytics({
                        hitType: "event",
                        eventCategory: "Leave Review Button",
                        eventAction: "Click",
                        eventLabel: "Internal review",
                    })
                );
                setLoading(false);
                setOpen(false);
                return response;
            })
            .catch((error) => {
                setLoading(false);
                return error;
            });
    };

    const handleChange = () => {
        setOpen((prev) => !prev);
    };

    const redirectToShopify = async () => {
        window.open(shopifyReviveLink, "_blank");
        await saveFeedback();
    };

    const noThanks = async () => {
        setOpen(false);
        await saveFeedback();
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleChange}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                className="absolute top-1/2 left-1/2 w-4/5 md:w-1/2 xl:w-1/3 shadow-2xl bg-white"
                style={{ transform: "translate(-50%, -50%)" }}
            >
                {loading && <Loader />}
                {!loading && (
                    <div>
                        <div className="text-center">
                            <h1 className="text-6xl p-10">How would you rate our app?</h1>
                            <div className="p-10">
                                <Rating size={44} onChange={(data) => setValue(data.value)} value={value} />
                            </div>
                        </div>
                        {value >= 1 && value <= 3 && (
                            <div className="text-center px-14 py-10">
                                <p>What could we improve?</p> <br />
                                <TextField
                                    id="suggestions"
                                    label=""
                                    type="text"
                                    name="suggestions"
                                    multiline={5}
                                    placeholder="What could we improve?"
                                    value={feedback}
                                    onChange={(v) => setFeedback(v)}
                                />{" "}
                                <br />
                                <Button primary={true} onClick={() => saveFeedback()}>
                                    Submit
                                </Button>
                            </div>
                        )}
                        {value >= 4 && value <= 5 && (
                            <div className="text-center px-14 py-10">
                                <p className="leading-120p text-gray-700 py-6 text-26px">
                                    Thanks a lot for a great review!
                                </p>
                                <p className="welcome-description">
                                    Would you be so kind and leave us a kind review in Shopify App Store? This helps us
                                    grow and build more useful features
                                </p>
                                <br />
                                <br />
                                <Button primary={true} onClick={() => redirectToShopify()}>
                                    Leave a review on Shopify
                                </Button>
                                <Button basic={true} onClick={() => noThanks()}>
                                    No thanks
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

ReviewPopup.propTypes = {
    dispatch: PropTypes.func,
    popUpDelay: PropTypes.number,
};

ReviewPopup.defaultProps = {
    dispatch: () => {},
    popUpDelay: 2000,
};

export default connect()(ReviewPopup);
