import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetDynamicAdReducer, setType, resetType } from "../../../actions/actions-dynamic-ads";
import ProductsCarousel from "../../ProductsCarousel";
import Button from "../../../common/components/button";
import { logAnalytics } from "../../../actions";

const Welcome = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isFacebookConnected = useSelector((state) => state.settings.facebook.is_connected);
    const nextRoute = isFacebookConnected
        ? "/dynamic-ads/create-campaign/creative-and-preview/"
        : "/dynamic-ads/create-campaign/account-connection/";

    useEffect(() => {
        dispatch(resetType());
        dispatch(resetDynamicAdReducer());
    }, []);

    const handleReachStarted = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Reach Campaign",
            })
        );

        dispatch(setType("REACH"));
        history.push(nextRoute);
    };

    const BackhandIndexPointingRight = () => (
        <span role="img" aria-label="Backhand Index Pointing Right">
            👉
        </span>
    );

    return (
        <Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                <div className="py-36 md:py-14 lg:pt-0 px-10 md:px-0 flex bg-white justify-center items-center">
                    <div className="h-full md:h-3/5 flex flex-col justify-center items-start w-full md:w-4/5">
                        <h1 className="welcome-title md:pt-10  pl-1">
                            Accelerate your sales
                            <span role="img" aria-label="Rocket">
                                &#128640;
                            </span>
                        </h1>
                        <p className="welcome-description mt-10 pl-1">
                            Create Dynamic Ads on Facebook and display your products to potential customers. Let
                            Facebook's AI find your best converting products.
                        </p>

                        <div className="flex flex-col w-full justify-center items-center md:items-start pt-10 mb-14 md:mb-0 ">
                            <div>
                                <div className="flex flex-row mt-5 mb-5">
                                    <BackhandIndexPointingRight />
                                    <p className="welcome-description ml-3">
                                        We will connect your facebook ad account to our application.
                                    </p>
                                </div>
                                <div className="mb-5">
                                    <div className="flex flex-row">
                                        <BackhandIndexPointingRight />
                                        <p className="welcome-description ml-3">
                                            We will automatically create your product catalog on facebook and take care
                                            of :
                                        </p>
                                    </div>
                                    <p className="welcome-description ml-10 my-2">i) product updates</p>
                                    <p className="welcome-description ml-10  my-2">ii) price changes</p>
                                    <p className="welcome-description ml-10  my-2">iii) availability</p>
                                </div>
                                <div className="flex flex-row mb-5">
                                    <BackhandIndexPointingRight />
                                    <p className="welcome-description ml-3">
                                        we will create smart product sets on facebook catalogue for reach, retargeting
                                        and upsell campaigns.
                                    </p>
                                </div>
                                <div className="mb-5">
                                    <div className="flex flex-row">
                                        <BackhandIndexPointingRight />
                                        <p className="welcome-description ml-3">
                                            we will create a campaign with multiple adsets within it :
                                        </p>
                                    </div>
                                    <p className="welcome-description ml-10 my-2">
                                        i) targeting different product sets
                                    </p>
                                    <p className="welcome-description ml-10  my-2">
                                        ii) targeting different interest with multiple product sets.
                                    </p>
                                    <p className="welcome-description ml-10  my-2">
                                        iii) targeting different lookalikes with multiple product sets.
                                    </p>
                                </div>
                                <div className="flex flex-row mb-5">
                                    <BackhandIndexPointingRight />
                                    <p className="welcome-description ml-3">
                                        your campaign will be optimised for conversion
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-14 md:mt-18 lg:mt-24">
                            <Button class="text-2xl" primary={true} onClick={handleReachStarted}>
                                Create Facebook Reach Campaign
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="md:pt-24 px-10 xl:px-20 py-10 md:py-0 bg-teal-500 flex justify-center items-center">
                    <div className="w-full">
                        <ProductsCarousel />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Welcome;
