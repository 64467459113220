import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, Modal, RadioButton, Spinner, Stack, TextField } from "@shopify/polaris";

import { pushToDataLayer } from "../utils";
import { cancelSubscriptionBoostedPage, logAnalytics } from "../actions";

class CancelBoostedPlanModal extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    state = {
        option: "",
        other: "",
        redirect: false,
    };

    getCancelReason = () => {
        const { option, other } = this.state;

        let data = option;
        if (option === "other") {
            data = other;
        }

        return data;
    };

    componentDidUpdate = () => {
        if (this.state.redirect && this.props.isCancelSuccess) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ redirect: false });
            const reason = this.getCancelReason();
            pushToDataLayer({
                event: "cancelPaidPlan",
                cancelationReason: reason,
            });
            this.props.history.push("/boosted/cancel/");
        }
    };

    handleCancelPlan = () => {
        const data = this.getCancelReason();

        this.props.dispatch(cancelSubscriptionBoostedPage(data, this.props.history));
        this.setState({ redirect: true });
        this.props.dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Confirm cancelling boosts plan button",
                eventAction: `Click ${data}`,
                eventLabel: "Boost my ads page",
            })
        );
    };

    handleInputChange = (value) => {
        this.setState({ other: value });
    };

    handleOptionChange = (checked, option) => {
        this.setState({ option: option });
    };

    render() {
        const { open, onClose, isRequestLoading } = this.props;
        const { option, other } = this.state;

        return (
            <Modal
                open={open}
                onClose={onClose}
                title="Do you really want to stop your subscription?"
                primaryAction={{
                    content: "Cancel plan",
                    onAction: this.handleCancelPlan,
                    disabled: isRequestLoading || !option,
                }}
                secondaryActions={[
                    {
                        content: "Go back",
                        onAction: onClose,
                        disabled: isRequestLoading,
                    },
                ]}
            >
                {isRequestLoading && (
                    <Fragment>
                        <div className="c-spinner">
                            <Spinner />
                        </div>
                    </Fragment>
                )}
                {!isRequestLoading && (
                    <Card title="Please, tell us the reason for canceling the subscription:" sectioned={true}>
                        <span className="PlanCancelOptions">
                            <Stack vertical={true}>
                                <RadioButton
                                    name="option"
                                    id="No support"
                                    onChange={this.handleOptionChange}
                                    label="No support, can’t get the questions answered"
                                />
                                <RadioButton
                                    name="option"
                                    id="No impressions"
                                    onChange={this.handleOptionChange}
                                    label="Not enough impressions"
                                />
                                <RadioButton
                                    name="option"
                                    id="Too expensive"
                                    onChange={this.handleOptionChange}
                                    label="Too expensive compared to other options."
                                />
                                <RadioButton
                                    name="option"
                                    id="Not the right time"
                                    onChange={this.handleOptionChange}
                                    label="I only run my ads during certain times of the year"
                                />
                                <RadioButton
                                    name="option"
                                    id="Too complicated"
                                    onChange={this.handleOptionChange}
                                    label="Understanding the results of the plan is too complicated"
                                />
                                <RadioButton
                                    name="option"
                                    id="Poor ROI"
                                    onChange={this.handleOptionChange}
                                    label="Poor return on investment"
                                />
                                <RadioButton
                                    name="option"
                                    id="other"
                                    onChange={this.handleOptionChange}
                                    label={
                                        <span>
                                            Other:{" "}
                                            <span style={{ display: "inline-block" }}>
                                                <TextField
                                                    label="Other"
                                                    onChange={this.handleInputChange}
                                                    value={other}
                                                    labelHidden={true}
                                                />
                                            </span>
                                        </span>
                                    }
                                />
                            </Stack>
                        </span>
                    </Card>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const { store } = state;

    return {
        isRequestLoading: store.isCancelSubscriptionLoading,
        isCancelSuccess: store.isCancelSubscriptionSuccess,
    };
};

export default withRouter(connect(mapStateToProps)(CancelBoostedPlanModal));
