import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import { SelectedProductList, SelectProducts, EditProductDetails } from "../../components/ads-exchange";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";

const AdsExchange = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Ads Exchange | SIXADS";
        dispatch(toggleNavigation("close"));
    }, []);

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />
                <Switch>
                    <Route
                        path="/ads-exchange/select-product"
                        component={SelectProducts}
                        exact={true}
                    />
                    <Route
                        path="/ads-exchange/selected-product-list"
                        component={SelectedProductList}
                        exact={true}
                    />
                    <Route
                        path="/ads-exchange/edit-product-info/:id"
                        component={EditProductDetails}
                        exact={true}
                    />
                </Switch>
        </Fragment>
    );
};

export default AdsExchange;
