import * as Sentry from "@sentry/browser";
import { postBusinessEvent } from "../../../api/api-business-event";
import FACEBOOK_BUSINESS_EVENTS from "../../../common/business_events_constants";
import { validateEmail } from "../../../utils";

export default function createBusinessManager(
    businessManagerName,
    email,
    setError,
    handleError,
    setSuccess,
    setIsLoading
) {
    if (!email || !validateEmail(email)) {
        handleError("Please provide a valid email.");
    } else {
        setError("");
        setIsLoading(true);
        window.FB.api(
            "me/businesses",
            "POST",
            { name: businessManagerName, email: email, vertical: "OTHER" },
            (response) => {
                if (!response) {
                    handleError("No response");
                } else if (response.error) {
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SUBMIT_FB_BM_FAILURE, { error: response.error });
                    if ("error_user_msg" in response.error && response.error.error_user_msg) {
                        handleError(response.error.error_user_msg);
                    } else if ("message" in response.error && response.error.message) {
                        handleError(response.error.message);
                    } else {
                        handleError("Unknown error");
                    }
                } else {
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SUBMIT_FB_BM_SUCCESS);
                    Sentry.addBreadcrumb({ data: { businessManager: response.data }, level: Sentry.Severity.Info });
                    Sentry.captureEvent({ message: "Business Manager Created", level: Sentry.Severity.Info });
                    setSuccess(true);
                    setIsLoading(false);
                }
            }
        );
    }
}
