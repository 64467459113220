import {
    CREATE_FEATURES_REQUEST,
    FETCH_FEATURES_REQUEST,
    FETCH_FEATURES_FAILURE,
    FETCH_FEATURES_SUCCESS,
} from "../actions";

const initial = {
    isLoading: false,
    isError: false,
    counter: 0,
    requests: [],
    items: [],
};

export const features = (state = initial, action) => {
    switch (action.type) {
        case CREATE_FEATURES_REQUEST:
            return {
                ...state,
                counter: 0,
                requests: state.requests.concat(action.request),
            };

        case FETCH_FEATURES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_FEATURES_FAILURE: {
            const counter = state.counter + 1;

            return {
                ...state,
                isLoading: false,
                isError: true,
                counter: counter,
            };
        }

        case FETCH_FEATURES_SUCCESS: {
            const items = Array.isArray(action.payload.items) ? action.payload.items.filter((item) => !!item) : [];

            return {
                ...state,
                isLoading: false,
                isError: false,
                counter: 0,
                items: state.items.concat(items),
                requests: [],
            };
        }

        default:
            return state;
    }
};
