/* eslint-disable */
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ACTIONS } from "../../actions/actions-image-overlay-ads";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";
import Banner from "../../common/components/sixads/banner";

const SelectedProductList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {selectProducts} = useSelector((state) => state.imageOverlayAds)
    const {products: allProducts} = selectProducts;
    const {selectedProducts} = selectProducts;

    const handleNavigateNext = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Ads exchange flow",
                eventAction: "Button click",
                eventLabel: "Go to selected product edit",
            })
        );

        // history.push("/ads-exchange/image-ad-creator/choose-template/");
    };

    const handlePreviewAndEdit = (id) => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Ads exhchange flow",
                eventAction: "Button click",
                eventLabel: "Go to preview and edit",
            })
        );

        history.push(`/ads-exchange/edit-product-info/${id}`);
    };

    const getProduct = (id) => {
        return allProducts.find((product) => product.api_id === id);
    };

    const getProductName = (fullName) => {
        if (fullName) {
            return fullName.length < 20 ? fullName : `${fullName.substring(0, 20)}...`;
        }
    };

    return (
        <Fragment>
            <div className="pt-24">
                <div className="w-11/12 md:w-4/5 lg:w-1/2 mx-auto my-0 mt-12 font-sf-text pb-40">
                    {selectedProducts.length === 0 && (
                        <div className="mb-10">
                            <Banner
                                type="error"
                                icon={
                                    <span className="text-4xl" role="img" aria-label="pensive">
                                        💔
                                    </span>
                                }
                            >
                                Please choose at least one product to proceed.
                            </Banner>
                        </div>
                    )}

                    <div className="">
                        <h1 className="page-title">
                            Ad creatives we made & optimized for you{" "}
                            <span role="img" aria-label="Megaphone">
                                📣
                            </span>
                        </h1>
                        <p className="page-subtitle">
                            You're all set. Changes can also be made later.
                        </p>
                    </div>
                    {
                        selectedProducts.map((productId) => {
                            const product = getProduct(productId);
                            return (
                                <div className="bg-white mt-5 rounded-md shadow" key={product.title}>
                                    {/* {getProductName(product.title)} */}
                                    <div className="flex flex-row px-4">
                                        <img
                                            src={product.images[0].src}
                                            alt={getProductName(product.title)}
                                            className="w-48"
                                        />
                                        <div className="flex flex-row w-full pl-6 md:pl-12 pr-4 md:pr-10 justify-between items-center">
                                            <div className="flex flex-col h-full justify-center">
                                                <div className="font-light">{getProductName(product.title)}</div>
                                                <div className="font-semibold pt-3">{product.price+" "+product.currency}</div>
                                            </div>
                                            <div>
                                                <Button onClick={()=>handlePreviewAndEdit(productId)}>
                                                    Preview & edit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="bg-white fixed bottom-0 w-full py-6">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 flex justify-end">
                        <Button primary={true} disabled={selectedProducts.length === 0} onClick={handleNavigateNext}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SelectedProductList;