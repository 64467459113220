import css from "styled-jsx/css";

export default css`
    .wrapper {
        max-width: 160rem;
        margin: 0 auto;
        padding-top: 56px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .row > .col {
        flex: 25%;
        padding: 0px 15px;
    }

    .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .title > p {
        flex: 1;
    }

    .title > div {
        flex: 1;
    }

    .subtitle {
        padding-top: 12px;
        color: #637381;
    }

    .header-with-subtitle {
        flex: 1 1 0%;
    }

    @media screen and (max-width: 1200px) {
        .row > .col {
            flex: 50%;
        }
    }

    @media screen and (max-width: 700px) {
        .wrapper {
            padding-top: 2rem;
        }

        .row > .col {
            flex: 100%;
        }

        .title {
            display: block;
        }

        .subtitle {
            padding-bottom: 12px;
        }
    }

    @media screen and (max-width: 330px) {
        .title {
            display: block;
        }

        .subtitle {
            padding-bottom: 12px;
        }

        .col:nth-child(1) :global(div.box) {
            height: 70vh;
        }

        .col:nth-child(2) :global(div.box) {
            height: 85vh;
        }

        .col:nth-child(3) :global(div.box) {
            height: 100vh;
        }
    }
`;
