import React, { Component } from "react";

import { Banner, Page } from "@shopify/polaris";

class FourZeroFour extends Component {
    constructor(props) {
        super(props);

        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }

    componentDidMount() {
        document.title = "Page not found";
    }

    render() {
        return (
            <Page>
                <Banner title="404 Page not found!" action={{ content: "Back to homepage" }} status="warning">
                    <p>Sorry we could not find the page you are looking for.</p>
                </Banner>
            </Page>
        );
    }
}

export default FourZeroFour;
