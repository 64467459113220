import React  from "react";
import PropTypes from "prop-types";

import { TextField as PolarisTextField } from "@shopify/polaris";

const TextField = (props) => {
    const { id, label, value, disabled, onChange, autoFocus, key, otherClasses, maximumChar, handleKeyDown, placeholder, ...restProps } = props;
    return (
        <div
            className={otherClasses}
            onKeyDown={e => handleKeyDown(e)}
            role="button"
            tabIndex={0}
        >
            <PolarisTextField
                id={id}
                label={
                    <label htmlFor={id} className="text-gray-700 font-bold leading-10">{label}</label>
                }
                placeholder={placeholder}
                key={key}
                value={value}
                disabled={disabled}
                onChange={onChange}
                autoFocus={autoFocus}
                maxLength={maximumChar}
                {...restProps}
            />
        </div>
    );
};
TextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    key: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    otherClasses: PropTypes.string,
    maximumChar: PropTypes.number,
    handleKeyDown: PropTypes.func,
};

TextField.defaultProps = {
    value: "",
    disabled: false,
    onChange: () => "",
    autoFocus: false,
    key: undefined,
    otherClasses: '',
    maximumChar: "",
    handleKeyDown: () => {}
};

export default TextField;
