import { api } from "../utils";
import { toggleToastPopup } from "./action-toast";

export const ACTIONS = {
    SET_ABOUT_BUSINESS_DATA: "adTextCreator/SET_ABOUT_BUSINESS_DATA",

    GET_FACEBOOK_HEADLINES_REQUESTED: "adTextCreator/GET_FACEBOOK_HEADLINES_REQUESTED",
    GET_FACEBOOK_HEADLINES_SUCCEED: "adTextCreator/GET_FACEBOOK_HEADLINES_SUCCEED",
    GET_FACEBOOK_HEADLINES_FAILED: "adTextCreator/GET_FACEBOOK_HEADLINES_FAILED",

    RESET_STATE: "adTextCreator/RESET_STATE",
};

export const setAboutBusinessData = ({ companyName, companyKeywords, companyAudience, specialOffer }) => {
    return {
        type: ACTIONS.SET_ABOUT_BUSINESS_DATA,
        payload: {
            companyName: companyName,
            companyKeywords: companyKeywords,
            companyAudience: companyAudience,
            specialOffer: specialOffer,
        },
    };
};

const getFacebookHeadlinesRequested = () => {
    return {
        type: ACTIONS.GET_FACEBOOK_HEADLINES_REQUESTED,
        payload: {},
    };
};

const getFacebookHeadlinesSucceed = (headlines) => {
    return {
        type: ACTIONS.GET_FACEBOOK_HEADLINES_SUCCEED,
        payload: {
            headlines: headlines,
        },
    };
};

const getFacebookHeadlinesFailed = () => {
    return {
        type: ACTIONS.GET_FACEBOOK_HEADLINES_FAILED,
        payload: {},
    };
};

export const getFacebookHeadlinesTexts = (companyName, companyKeywords, companyAudience, specialOffer) => (
    dispatch
) => {
    dispatch(getFacebookHeadlinesRequested());

    api("/api/v1/ad-texts/facebook_headline/", {
        method: "POST",
        data: {
            company_name: companyName,
            company_keywords: companyKeywords,
            company_audience: companyAudience,
            special_offer: specialOffer,
        },
    })
        .then((response) => {
            dispatch(getFacebookHeadlinesSucceed(response));
        })
        .catch(() => {
            dispatch(toggleToastPopup(["Ad text creation failed."], "error"));
            dispatch(getFacebookHeadlinesFailed());
        });
};

export const resetState = () => {
    return {
        type: ACTIONS.RESET_STATE,
        payload: {},
    };
};
