import React, { Component, Fragment } from "react";

import classnames from "classnames";

class TableData extends Component {
    getClasses() {
        const { classes, paddingRequired = true } = this.props;

        return classnames(
            "text-gray-700 font-normal text-14px flex justify-center items-center inline-block whitespace-nowrap",
            classes,
            {
                [["gap-3 py-6"]]: paddingRequired,
            }
        );
    }

    render() {
        const { children, tdClasses = "", rowSpan = 1, colSpan = 1 } = this.props;

        return (
            <Fragment>
                <td className={tdClasses} rowSpan={rowSpan} colSpan={colSpan}>
                    <div className={this.getClasses()}>{children}</div>
                </td>
            </Fragment>
        );
    }
}

export default TableData;
