import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReviewPopup from "../../components/ReviewPopup";
import { FunnelWidget, InsightsOverviewWidget } from "../../components/facebook-dynamic-ads/campaign-list/widgets";
import FacebookDynamicCampaigns from "../../components/facebook-dynamic-ads/campaign-list/FacebookDynamicCampaigns";
import Banner from "../../common/components/sixads/banner";
import { closeAllBanner, resetBannerMessage } from "../../actions/actions-dynamic-ads";
import { GoogleShoppingCampaigns } from "../../components/google-shopping/campaign-list";

const Campaigns = () => {
    const dispatch = useDispatch();

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        window.document.title = "Dynamic Ads | SIXADS";
    }, []);

    useEffect(() => {
        fetch("/api/v1/notification/lookalike-audience/")
            .then((response) => response.json())
            .then((data) => {
                setNotifications(data);
            })
            .catch(() => {
                //
            });
    }, []);

    const handleNotificationBannerClose = () => {
        setNotifications([]);
        fetch("/api/v1/notification/lookalike-audience/", {
            method: "DELETE",
        });
    };

    const allCampaignsCount = useSelector((state) => {
        return state.reachList.count + state.remarketingList.count + state.upsellList.count;
    });
    const isFirstCampaignCreated = allCampaignsCount === 1;

    // TODO: Move the banner showing logic to FacebookDynamicCampaigns and GoogleShoppingCampaigns component.
    const showErrorBanner = useSelector((state) => state.dynamicAds.showErrorBanner);
    const showSuccessBanner = useSelector((state) => state.dynamicAds.showSuccessBanner);
    const bannerMessage = useSelector((state) => state.dynamicAds.bannerMessage);

    const ErrorBanner = () => {
        return (
            <Banner
                type="error"
                onClose={() => {
                    dispatch(closeAllBanner());
                    dispatch(resetBannerMessage());
                }}
            >
                {bannerMessage}
            </Banner>
        );
    };

    const SuccessBanner = () => {
        return (
            <Banner
                type="success"
                onClose={() => {
                    dispatch(closeAllBanner());
                    dispatch(resetBannerMessage());
                }}
            >
                {bannerMessage}
            </Banner>
        );
    };

    return (
        <Fragment>
            {isFirstCampaignCreated && <ReviewPopup />}

            {notifications.length > 0 && (
                <div className="md:mt-20">
                    <Banner type="success" icon={null} onClose={() => handleNotificationBannerClose()}>
                        <ul className="ml-5 list-disc">
                            {notifications.map((notification, index) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                <li key={index}>{notification}</li>
                            ))}
                        </ul>
                    </Banner>
                </div>
            )}

            <div className="pt-32 md:pt-12 ml-8 md:w-4/5 2xl:w-11/12">
                <div className="md:pt-8 mb-6">
                    <div className="font-sf-text text-26px text-gray-900 font-bold leading-10">
                        Dynamic ads - all campaigns
                    </div>
                </div>

                <div className="w-full xl:w-5/6">
                    {showErrorBanner && <ErrorBanner />}
                    {showSuccessBanner && <SuccessBanner />}
                </div>

                <div className="grid xl:grid-cols-6 mt-6">
                    <div className="xl:col-span-5 overflow-auto">
                        <FacebookDynamicCampaigns />
                        <GoogleShoppingCampaigns />
                    </div>
                    <div className="xl:col-span-1">
                        <div className="w-3/5 sm:w-5/6 xl:w-max gap-8 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 ml-0 xl:ml-10 mt-12 xl:mt-0">
                            <FunnelWidget />
                            <InsightsOverviewWidget />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Campaigns;
