import { LOCATION_CHANGE } from "../actions";

const toggleTimer = (path) => {
    const bodyCSS = document.body.classList;
    const areHidden =
        path.includes("/welcome/") ||
        path.includes("/create-campaign/") ||
        path === "/boosted-ads/" ||
        path === "/how-to-sell-on-shopify/";

    bodyCSS.toggle("are-distractions--hidden", areHidden);
};

// eslint-disable-next-line no-unused-vars
const timerNavigation = (store) => (next) => (action) => {
    const res = next(action);

    if (action.type === LOCATION_CHANGE) {
        toggleTimer(res.path);
    }

    return res;
};

export default timerNavigation;
