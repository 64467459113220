import React, { Component, Fragment } from "react";

import {
    Card,
    TextContainer,
    Caption,
    ResourceList,
    TextField,
    TextStyle,
    Form,
    FormLayout,
    Button,
} from "@shopify/polaris";

class Option extends Component {
    state = {
        additional: false,
        issue: "",
        other: "",
    };

    getHeading = (option) => {
        switch (option) {
            case "1":
                return "I don’t use it enough.";
            case "2":
                return "I don’t want to show ads on my website.";
            case "3":
                return "I don’t like subscription-based charge.";
            case "4":
                return "I am having technical problems.";
            case "5":
                return "It’s too expensive.";
            case "6":
                return "Other reason.";
            default:
                return "";
        }
    };

    getItems = (option) => {
        switch (option) {
            case "1":
                return [
                    { option: option, column: 1, content: "Pause my account to save my data." },
                    { option: option, column: 2, content: "Uninstall and lose all my data." },
                ];
            case "2":
                return [
                    {
                        option: option,
                        column: 1,
                        content: "Disable ad showing in your shop and continue generating traffic.",
                    },
                    {
                        option: option,
                        column: 2,
                        content: "Uninstall and stop traffic from coming to my shop.",
                    },
                ];
            case "3":
                return [
                    { option: option, column: 1, content: "Cancel your subscription." },
                    {
                        option: option,
                        column: 2,
                        content: "Uninstall and stop traffic from coming to my shop.",
                    },
                ];
            case "4":
                return [
                    {
                        option: option,
                        column: 1,
                        content: "Tell us more about the issue and get 1000 free Boosted Impressions.",
                    },
                    {
                        option: option,
                        column: 2,
                        content: "Uninstall and don’t get free Boosted Impressions.",
                    },
                ];
            case "5":
                return [
                    {
                        option: option,
                        column: 1,
                        content: `Get 50% off for any of our recurring plans with a discount code`,
                    },
                    {
                        option: option,
                        column: 2,
                        content: "Uninstall and stop generating more traffic.",
                    },
                ];
            default:
                return [];
        }
    };

    handleIssueChange = (issue) => {
        this.setState({ issue: issue });
    };

    handleOtherChange = (other) => {
        this.setState({ other: other });
    };

    reportIssue = () => {
        this.props.logAnalytics({
            hitType: "event",
            eventCategory: "Technical Issue Button",
            eventAction: "Click",
            eventLabel: "Uninstall Sequence",
        });

        this.setState({ additional: true });
    };

    getActions = (column, option) => {
        if (column === 2) {
            return [{ content: "Uninstall", onAction: this.props.handleUninstall }];
        }

        switch (option) {
            case "1":
                return [{ content: "Pause my account", onAction: this.props.handleSolution }];
            case "2":
                return [{ content: "Disable ad showing", onAction: this.props.handleSolution }];
            case "3":
                return [{ content: "Cancel my subscription", onAction: this.props.handleSolution }];
            case "4":
                return [{ content: "Tell us more about the issue", onAction: this.reportIssue }];
            case "5":
                return [{ content: "Get 50% OFF", onAction: this.props.handleSolution }];
            default:
                return "";
        }
    };

    renderItem = (item) => {
        const { column, content, option } = item;

        const actions = this.getActions(column, option);

        return (
            <ResourceList.Item shortcutActions={actions} persistActions={true}>
                <h3>
                    <TextStyle variation="strong">
                        {content}
                        {option === "5" && column === 1 && (
                            <b>
                                <i> halfprice</i>
                            </b>
                        )}
                    </TextStyle>
                </h3>
                {option === "4" && column === 1 && (
                    <div>Free Boosted Impressions are credited after your issue is verified and fixed. </div>
                )}
                {option === "5" && column === 1 && (
                    <div>You can use the discount once for recurring plans only, it will expire in 24 hrs.</div>
                )}
            </ResourceList.Item>
        );
    };

    handleIssueSubmit = () => {
        this.props.handleSolution(this.state.issue);
    };

    handleOtherSubmit = () => {
        this.props.handleUninstall(this.state.other);
    };

    render() {
        const { option } = this.props;

        const items = this.getItems(option);

        return (
            <Card>
                <Card.Header title={this.getHeading(option)} />
                <Card.Section>
                    {option === "1" && (
                        <Fragment>
                            <TextContainer>
                                You can pause your account to save all your ads, settings and data. You can get back to
                                advertising whenever you want.
                            </TextContainer>
                            <Caption>
                                Both your product and partner ads will be disabled until you turn them back on.
                            </Caption>
                        </Fragment>
                    )}
                    {option === "2" && <TextContainer>You can do just that!</TextContainer>}
                    {option === "3" && (
                        <TextContainer>
                            Not an issue! You can stop your subscription charges and switch to one-time payments.
                        </TextContainer>
                    )}
                    {option === "4" && (
                        <Fragment>
                            <TextContainer>
                                We can fix it, just tell us the specifics. We’ll not only solve your problem, we’ll also
                                give you 1000 Boosted Impressions free of charge.{" "}
                            </TextContainer>
                            {this.state.additional && (
                                <Form onSubmit={this.handleIssueSubmit}>
                                    <FormLayout>
                                        <br />
                                        <TextField
                                            label="Tell us more about the issue and get 1000 free Boosted Impressions"
                                            value={this.state.issue}
                                            onChange={this.handleIssueChange}
                                            multiline={4}
                                        />
                                        <p className="u-align--right">
                                            <Button submit={true} disabled={!this.state.issue}>
                                                Submit
                                            </Button>
                                        </p>
                                    </FormLayout>
                                </Form>
                            )}
                        </Fragment>
                    )}
                    {option === "5" && (
                        <TextContainer>Use a discount to continue using premium features.</TextContainer>
                    )}
                    {option === "6" && (
                        <Fragment>
                            <TextContainer>Please let us know why you’re uninstalling Sixads</TextContainer>
                            <Form onSubmit={this.handleOtherSubmit}>
                                <FormLayout>
                                    <TextField
                                        label=""
                                        value={this.state.other}
                                        onChange={this.handleOtherChange}
                                        multiline={4}
                                    />
                                    <p className="u-align--right">
                                        <Button submit={true} disabled={!this.state.other}>
                                            Uninstall
                                        </Button>
                                    </p>
                                </FormLayout>
                            </Form>
                        </Fragment>
                    )}
                </Card.Section>

                {option !== "6" && !this.state.additional && (
                    <ResourceList items={items} renderItem={this.renderItem} />
                )}
            </Card>
        );
    }
}

export default Option;
