/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable no-case-declarations */
import {
    FETCH_GOOGLE_CORE_ACCOUNT_REQUEST,
    FETCH_GOOGLE_CORE_ACCOUNT_SUCCESS,
    FETCH_GOOGLE_CORE_ACCOUNT_ERROR,
    REMOVE_GOOGLE_CORE_ACCOUNT,
    VALIDATE_GOOGLE_CREDENTIALS_REQUEST,
    VALIDATE_GOOGLE_CREDENTIALS_SUCCESS,
    VALIDATE_GOOGLE_CREDENTIALS_ERROR,
    SET_GOOGLE_AUTH_CODE,
    FETCH_STORE_REQUIREMENTS_REQUEST,
    FETCH_STORE_REQUIREMENTS_SUCCESS,
    FETCH_STORE_REQUIREMENTS_ERROR,
    UPDATE_STORE_REQUIREMENTS_REQUEST,
    UPDATE_STORE_REQUIREMENTS_SUCCESS,
    UPDATE_STORE_REQUIREMENTS_ERROR,
    MERCHANT_ACCOUNT_LIST_REQUEST,
    MERCHANT_ACCOUNT_LIST_SUCCESS,
    MERCHANT_ACCOUNT_LIST_ERROR,
    MERCHANT_ACCOUNT_CONNECTED,
    MERCHANT_ACCOUNT_DISCONNECTED,
    MERCHANT_SHIPPING_SETTINGS_REQUEST,
    MERCHANT_SHIPPING_SETTINGS_SUCCESS,
    MERCHANT_SHIPPING_SETTINGS_ERROR,
    MERCHANT_TAX_SETTINGS_REQUEST,
    MERCHANT_TAX_SETTINGS_SUCCESS,
    MERCHANT_TAX_SETTINGS_ERROR,
    ADS_ACCOUNT_LIST_REQUEST,
    ADS_ACCOUNT_LIST_SUCCESS,
    ADS_ACCOUNT_LIST_ERROR,
    ADS_ACCOUNT_CONNECTED,
    ADS_ACCOUNT_DISCONNECTED,
    SET_CAMPAIGN_BUDGET,
    COUNTRY_LIST_REQUEST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_ERROR,
    TARGETING_REQUEST_SUCCESS,
    FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_REQUEST,
    FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_SUCCESS,
    FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_ERROR,
    GOOGLE_SHOPPING_CAMPAIGN_UPDATED,
    FETCH_GOOGLE_ADS_ACCOUNT_BILLING_REQUEST,
    FETCH_GOOGLE_ADS_ACCOUNT_BILLING_SUCCESS,
    FETCH_GOOGLE_ADS_ACCOUNT_BILLING_ERROR,
    CONFIRM_WEBSITE_CLAIMED_BY_USER_SUCCESS,
} from "../actions";
import { ACTIONS } from "../actions/actions-google-shopping";

const initialState = {
    list: {
        isLoading: false,
        isExpanded: true,
        campaigns: [],
    },
    create: {
        welcome: {
            //
        },
        accountConnection: {
            // TODO: Move the account connection related information here.
        },
    },

    isCoreAccountLoading: true,
    isStoreRequirementsLoading: true,
    isStoreRequirementsChangeLoading: false,
    isMerchantAccountLoading: true,
    isShippingSettingsLoading: true,
    isTaxSettingsLoading: true,
    isAdsAccountLoading: true,
    isCountryListLoading: true,
    isAdsAccountBillingLoading: true,
    coreAccount: {
        id: 0,
        sixads_account_id: 0,
        google_credentials_id: 0,
        google_ads_account_id: 0,
        google_merchant_account_id: 0,
        claim_website_warning: null,
        google_ads_account_currency: null,
        store_requirement_met: false,
        email: "",
        targeted_country_code: null,
        targeted_language_code: null,
        google_client_id: null,
    },
    googleValidationCredentials: null,
    googleAuthCode: "",
    storeRequirements: [],
    merchantAccounts: [],
    shippingSettings: "",
    taxSettings: "",
    googleAdsAccount: {},
    adAccounts: [],
    campaignBudget: 0,
    countryList: [],
    adsAccountBilling: null,
};

const googleShopping = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case FETCH_GOOGLE_CORE_ACCOUNT_REQUEST:
            return {
                ...state,
                isCoreAccountLoading: true,
            };

        case FETCH_GOOGLE_CORE_ACCOUNT_SUCCESS:
            return {
                ...state,
                coreAccount: payload,
                isCoreAccountLoading: false,
                isMerchantAccountLoading: false,
                isShippingSettingsLoading: false,
                isAdsAccountLoading: false,
                isAdsAccountBillingLoading: false,
            };

        case FETCH_GOOGLE_CORE_ACCOUNT_ERROR:
            return {
                ...state,
                isCoreAccountLoading: false,
            };

        case REMOVE_GOOGLE_CORE_ACCOUNT:
            return {
                ...state,
                isCoreAccountLoading: false,
                coreAccount: initialState.coreAccount,
            };

        case VALIDATE_GOOGLE_CREDENTIALS_REQUEST:
            return {
                ...state,
                isCoreAccountLoading: true,
            };

        case VALIDATE_GOOGLE_CREDENTIALS_SUCCESS:
            return {
                ...state,
                googleValidationCredentials: payload,
                isCoreAccountLoading: false,
            };

        case VALIDATE_GOOGLE_CREDENTIALS_ERROR:
            return {
                ...state,
                isCoreAccountLoading: false,
            };

        case SET_GOOGLE_AUTH_CODE:
            return {
                ...state,
                isCoreAccountLoading: false,
                googleAuthCode: payload,
            };

        case FETCH_STORE_REQUIREMENTS_REQUEST:
            return {
                ...state,
                isStoreRequirementsLoading: true,
            };

        case FETCH_STORE_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                storeRequirements: payload,
                isStoreRequirementsLoading: false,
            };

        case FETCH_STORE_REQUIREMENTS_ERROR:
            return {
                ...state,
                isStoreRequirementsLoading: false,
            };

        case UPDATE_STORE_REQUIREMENTS_REQUEST:
            return {
                ...state,
                isStoreRequirementsChangeLoading: true,
            };

        case UPDATE_STORE_REQUIREMENTS_SUCCESS:
            const newStoreRequirements = state.storeRequirements.map((item) =>
                payload.guideline_id === item.guideline_id ? payload : item
            );
            return {
                ...state,
                storeRequirements: newStoreRequirements,
                isStoreRequirementsChangeLoading: false,
            };

        case UPDATE_STORE_REQUIREMENTS_ERROR:
            return {
                ...state,
                isStoreRequirementsChangeLoading: false,
            };

        case MERCHANT_ACCOUNT_LIST_REQUEST:
            return {
                ...state,
                isMerchantAccountLoading: true,
            };

        case MERCHANT_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                merchantAccounts: payload,
                isMerchantAccountLoading: false,
            };

        case MERCHANT_ACCOUNT_LIST_ERROR:
            return {
                ...state,
                isMerchantAccountLoading: false,
            };

        case MERCHANT_ACCOUNT_CONNECTED:
            const coreAccountWithGoogleMerchantAccount = state.coreAccount;
            coreAccountWithGoogleMerchantAccount.google_merchant_account_id = parseInt(payload.merchant_id);
            coreAccountWithGoogleMerchantAccount.claim_website_warning = payload.error_message;
            return {
                ...state,
                coreAccount: coreAccountWithGoogleMerchantAccount,
            };

        case MERCHANT_ACCOUNT_DISCONNECTED:
            const newCoreAccount2 = state.coreAccount;
            newCoreAccount2.google_merchant_account_id = 0;
            newCoreAccount2.google_ads_account_id = 0;
            newCoreAccount2.google_ads_account_currency = null;
            return {
                ...state,
                coreAccount: newCoreAccount2,
            };

        case MERCHANT_SHIPPING_SETTINGS_REQUEST:
            return {
                ...state,
                isShippingSettingsLoading: true,
            };

        case MERCHANT_SHIPPING_SETTINGS_SUCCESS:
            return {
                ...state,
                shippingSettings: payload,
                isShippingSettingsLoading: false,
            };

        case MERCHANT_SHIPPING_SETTINGS_ERROR:
            return {
                ...state,
                isShippingSettingsLoading: false,
            };

        case MERCHANT_TAX_SETTINGS_REQUEST:
            return {
                ...state,
                isTaxSettingsLoading: true,
            };

        case MERCHANT_TAX_SETTINGS_SUCCESS:
            return {
                ...state,
                taxSettings: payload,
                isTaxSettingsLoading: false,
            };

        case MERCHANT_TAX_SETTINGS_ERROR:
            return {
                ...state,
                isTaxSettingsLoading: false,
            };

        case ADS_ACCOUNT_LIST_REQUEST:
            return {
                ...state,
                isAdsAccountLoading: true,
            };

        case ADS_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                adAccounts: payload,
                isAdsAccountLoading: false,
            };

        case ADS_ACCOUNT_LIST_ERROR:
            return {
                ...state,
                isAdsAccountLoading: false,
            };

        case ADS_ACCOUNT_CONNECTED:
            const newCoreAccount3 = state.coreAccount;
            newCoreAccount3.google_ads_account_id = parseInt(payload.id);
            newCoreAccount3.google_ads_account_currency = payload.currency;
            return {
                ...state,
                coreAccount: newCoreAccount3,
            };

        case ADS_ACCOUNT_DISCONNECTED:
            const newCoreAccount4 = state.coreAccount;
            newCoreAccount4.google_ads_account_id = 0;
            newCoreAccount4.google_ads_account_currency = null;
            return {
                ...state,
                coreAccount: newCoreAccount4,
                adsAccountBilling: null,
            };

        case ACTIONS.TOGGLE_TABLE_EXPANSION:
            return {
                ...state,
                list: {
                    ...state.list,
                    isExpanded: !state.list.isExpanded,
                },
            };

        case SET_CAMPAIGN_BUDGET:
            return {
                ...state,
                campaignBudget: payload,
            };

        case COUNTRY_LIST_REQUEST:
            return {
                ...state,
                isCountryListLoading: true,
            };

        case COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                countryList: payload,
                isCountryListLoading: false,
            };

        case COUNTRY_LIST_ERROR:
            return {
                ...state,
                isCountryListLoading: false,
            };

        case TARGETING_REQUEST_SUCCESS:
            const newCoreAccount5 = state.coreAccount;
            newCoreAccount5.targeted_country_code = payload.country_code;
            newCoreAccount5.targeted_language_code = payload.language_code;
            return {
                ...state,
                coreAccount: newCoreAccount5,
            };

        case FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_REQUEST:
            const { list } = state;
            list.isLoading = true;
            return {
                ...state,
                list,
            };

        case FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_SUCCESS:
            const list1 = state.list;
            list1.campaigns = [payload];
            list1.isLoading = false;
            return {
                ...state,
                list: list1,
            };

        case FETCH_GOOGLE_SHOPPING_CAMPAIGN_LIST_ERROR:
            const list2 = state.list;
            list2.isLoading = false;
            return {
                ...state,
                list: list2,
            };

        case GOOGLE_SHOPPING_CAMPAIGN_UPDATED:
            const list3 = state.list;
            if (payload.daily_budget) {
                const daily_budget = { ...state.list.campaigns[0].daily_budget, value: payload.daily_budget };
                list3.campaigns[0].daily_budget = daily_budget;
            } else if (payload.status) {
                list3.campaigns[0].status = payload.status;
            }
            return {
                ...state,
                list: list3,
            };

        case FETCH_GOOGLE_ADS_ACCOUNT_BILLING_REQUEST:
            return {
                ...state,
                isAdsAccountBillingLoading: true,
            };

        case FETCH_GOOGLE_ADS_ACCOUNT_BILLING_SUCCESS:
            return {
                ...state,
                isAdsAccountBillingLoading: false,
                adsAccountBilling: payload,
            };

        case FETCH_GOOGLE_ADS_ACCOUNT_BILLING_ERROR:
            return {
                ...state,
                isAdsAccountBillingLoading: false,
            };
        case CONFIRM_WEBSITE_CLAIMED_BY_USER_SUCCESS:
            return {
                ...state,
                coreAccount: {
                    ...state.coreAccount,
                    claim_website_warning: null,
                },
            };
        default:
            return state;
    }
};

export default googleShopping;
