/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template14 = ({ name, image, price, adMessage, paused = false }) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"]["text"] = adMessage || "SPECIAL DISCOUNT";

        animationObject["ProductNameObj"]["ProductName"]["text"]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName2"]["text"]["text"] = name;
        animationObject["ProductNameObj3"]["ProductName3"]["text"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow2"]["text"]["text"] = price;
        animationObject["PriceTagObj3"]["PriceNow3"]["text"]["text"] = price;

        const images = AdobeAn.getComposition("CCB70635D19A4CE536ZEA617094ZLB50").getImages();
        images["Bitmap61111"]["src"] = image;
        images["Screenshot20210729at22463711"]["src"] = image;
        images["Screenshot20210729at22470911"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_14"
                composition="CCB70635D19A4CE536ZEA617094ZLB50"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template14.design_name = "white_beige_wavy";
Template14.image_width = 1000;
Template14.image_height = 1000;

export default Template14;
