import fetch from "cross-fetch";

const listURL = "/api/v1/categories/?limit=250";

export const get = () => {
    return fetch(listURL)
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            }
            return {
                status: "error",
                response: res,
            };
        })
        .catch((error) => {
            return error;
        });
};

export const getSubCategories = (ID, page = 1) => {
    return fetch(`${listURL}&base_parent_id=${ID}&page=${page}`)
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            }
            return {
                status: "error",
                response: res,
            };
        })
        .catch((error) => {
            return error;
        });
};
