import styled from "styled-components";

export const TriangleCornerTag = styled.div`
    position: absolute;
    top: ${(props) => (props.top ? 0 : "")};
    left: ${(props) => (props.left ? 0 : "")};
    bottom: ${(props) => (props.bottom ? 0 : "")};
    right: ${(props) => (props.right ? 0 : "")};
    width: 0;
    border-right: ${(props) => props.borderRight};
    border-top: ${(props) => props.borderTop};
    border-left: ${(props) => props.borderLeft};
    border-bottom: ${(props) => props.borderBottom};
`;

export const CircleCornerTag = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${(props) => (props.top ? props.tagMargin : "")};
    left: ${(props) => (props.left ? props.tagMargin : "")};
    bottom: ${(props) => (props.bottom ? props.tagMargin : "")};
    right: ${(props) => (props.right ? props.tagMargin : "")};
    width: ${(props) => props.diameter}px;
    height: ${(props) => props.diameter}px;
    border-radius: 50%;
    background: ${(props) =>
        `rgba(${props.tagColor.r}, ${props.tagColor.g}, ${props.tagColor.b}, ${props.tagColor.a})`};
`;

export const RectangleCornerTag = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${(props) => (props.top ? props.tagMargin : "")};
    left: ${(props) => (props.left ? props.tagMargin : "")};
    bottom: ${(props) => (props.bottom ? props.tagMargin : "")};
    right: ${(props) => (props.right ? props.tagMargin : "")};
    width: ${(props) => props.width}px;
    height: ${(props) => props.width * 0.6}px;
    border-radius: 10%;
    background: ${(props) =>
        `rgba(${props.tagColor.r}, ${props.tagColor.g}, ${props.tagColor.b}, ${props.tagColor.a})`};
`;

export const SquareBorderTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    padding: 0px;
    margin: 0px;
    border-style: solid;
    border-color: ${(props) =>
        `rgba(${props.tagColor.r}, ${props.tagColor.g}, ${props.tagColor.b}, ${props.tagColor.a})`};
    border-width: ${(props) => (props.borderWidth ? props.borderWidth : "2px")};
`;

export const TriangleCornerText = styled.div`
    position: absolute;
    top: ${(props) => (props.top ? 0 : "")};
    left: ${(props) => (props.left ? 0 : "")};
    bottom: ${(props) => (props.bottom ? 0 : "")};
    right: ${(props) => (props.right ? 0 : "")};
    margin-top: ${(props) => (props.top ? props.margin : "")};
    margin-bottom: ${(props) => (props.bottom ? props.margin : "")};
    text-align: center;

    /* transform: rotate(-45deg); */
    transform: ${(props) =>
        (props.top && props.left) || (props.bottom && props.right) ? "rotate(-45deg)" : "rotate(45deg)"};

    /* Legacy vendor prefixes that you probably don't need... */

    /* Safari */
    -webkit-transform: ${(props) =>
        (props.top && props.left) || (props.bottom && props.right) ? "rotate(-45deg)" : "rotate(45deg)"};

    /* Firefox */
    -moz-transform: ${(props) =>
        (props.top && props.left) || (props.bottom && props.right) ? "rotate(-45deg)" : "rotate(45deg)"};

    /* IE */
    -ms-transform: ${(props) =>
        (props.top && props.left) || (props.bottom && props.right) ? "rotate(-45deg)" : "rotate(45deg)"};

    /* Opera */
    -o-transform: ${(props) =>
        (props.top && props.left) || (props.bottom && props.right) ? "rotate(-45deg)" : "rotate(45deg)"};

    /* Internet Explorer */
    /* filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); */
`;
