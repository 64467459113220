import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import Stepper from "../../common/components/sixads/stepper/stepper";
import { Step } from "../../common/components/sixads/stepper/progress-steps/step/step";
import ProgressBar from "../../common/components/sixads/stepper/progress-bar/progress-bar";
import ProgressSteps from "../../common/components/sixads/stepper/progress-steps/progress-steps";
import Welcome from "./welcome/Welcome";
import { logAnalytics, navigateVideoAds, toggleNavigation } from "../../actions";
import SelectProduct from "./select-product/SelectProduct";
import Button from "../../common/components/button";
import SelectTemplate from "./template/Select-Template";
import VideoEditor from "./Video-Editor";
import Success from "./Success";
import Upgrade from "./Upgrade";
import Thanks from "./Thanks";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";

const WelcomeRoute = "/video-ads/welcome";
const SelectProductRoute = "/video-ads/select-product/";
const SelectTemplateRoute = "/video-ads/select-template/";
const CustomizeRoute = "/video-ads/customize/";
const SuccessRoute = "/video-ads/success/";
const UpgradeRoute = "/video-ads/upgrade/";
const ThanksRoute = "/video-ads/thanks/";

const steps = [
    {
        title: "Welcome",
        step: 1,
        progress: 10,
        route: WelcomeRoute,
    },
    {
        title: "Select product",
        step: 2,
        progress: 40,
        route: SelectProductRoute,
    },
    {
        title: "Select template",
        step: 3,
        progress: 60,
        route: SelectTemplateRoute,
    },
    {
        title: "Start editing",
        step: 4,
        progress: 90,
        route: CustomizeRoute,
    },
    {
        title: "Download video",
        step: 5,
        progress: 100,
        route: SuccessRoute,
    },
];

const VideoAdsRouter = ({ step = 1, errors = [], history }) => {
    const dispatch = useDispatch();
    dispatch(toggleNavigation("close"));

    const { step: currentStep = 1, progress: currentProgress = 10 } = steps[step - 1];

    useEffect(() => {
        const route = window.location.pathname;
        const match = steps.find((s) => s.route === route);

        if (match && match.step) {
            dispatch(navigateVideoAds(match.step));
        }
    }, [step]);

    const navigate = (back = false) => {
        const cstep = back ? currentStep - 1 : currentStep + 1;
        dispatch(navigateVideoAds(cstep));
        history.push(steps[cstep - 1].route);
    };

    const isDisabled = () => currentStep >= steps.length || errors.length > 0;

    return (
        <div>
            <HeaderWithoutUser teal={true} />
            {step > 1 && (
                <Stepper
                    steps={steps}
                    overrides={{
                        Root: {
                            props: {
                                sticky: true,
                            },
                        },
                        Header: {
                            props: {
                                children: (
                                    <div>
                                        <ProgressSteps current={currentStep}>
                                            {steps.map(({ route, title }) => {
                                                return <Step key={route}>{title}</Step>;
                                            })}
                                        </ProgressSteps>
                                        <ProgressBar value={currentProgress} />
                                    </div>
                                ),
                            },
                        },
                    }}
                />
            )}
            <div className="video-ads-main">
                <Switch>
                    <Route path={WelcomeRoute} exact={true}>
                        <Welcome />
                    </Route>
                    <Route path={SelectProductRoute} exact={true}>
                        <SelectProduct />
                    </Route>
                    <Route path={SelectTemplateRoute} exact={true}>
                        <SelectTemplate />
                    </Route>
                    <Route path={CustomizeRoute} exact={true}>
                        <VideoEditor />
                    </Route>
                    <Route path={SuccessRoute} exact={true}>
                        <Success />
                    </Route>
                    <Route path={UpgradeRoute} exact={true}>
                        <Upgrade />
                    </Route>
                    <Route path={ThanksRoute} exact={true}>
                        <Thanks />
                    </Route>
                </Switch>
            </div>

            {[2].includes(step) && (
                <footer className="fixed h-24 bg-white w-full bottom-0 flex flex-row items-center justify-between z-50">
                    <a className="text-body text-gray-600 ml-12">&nbsp;</a>

                    <div className="mr-12 lg:mr-16p">
                        {/* {currentStep > 1 && (
                            <Fragment>
                                <Button onClick={() => navigate(true)}>Back</Button>
                                &nbsp;&nbsp;
                            </Fragment>
                        )} */}
                        {![3, 4, 5].includes(currentStep) && (
                            <Button
                                primary={true}
                                onClick={() => {
                                    if (currentStep === 2) {
                                        dispatch(
                                            logAnalytics({
                                                hitType: "event",
                                                eventCategory: "Choose template",
                                                eventAction: "Button click",
                                                eventLabel: "Video flow",
                                            })
                                        );
                                    }

                                    navigate();
                                }}
                                disabled={isDisabled()}
                            >
                                {currentStep === 2 && "Choose template"}
                                {currentStep !== 2 && "Next"}
                            </Button>
                        )}
                    </div>
                </footer>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const { step, pages } = state.videoAds;
    const keys = Object.keys(pages);
    const key = keys[step - 1];
    const errors = (key && pages[key] && pages[key].errors) || [];
    return {
        step: step,
        errors: errors,
    };
};

export default withRouter(connect(mapStateToProps)(VideoAdsRouter));
