import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setTemplateSelection } from "../../../actions/actions-image-overlay-ads";

const SideSelector = (props) => {
    const { dispatch, selectedTemplate } = props;
    const [shapePosition, setShapePosition] = useState(1);

    const adjustShapePosition = () => {
        const pos =
            selectedTemplate.top && selectedTemplate.left
                ? 1
                : selectedTemplate.top && selectedTemplate.right
                ? 3
                : selectedTemplate.bottom && selectedTemplate.right
                ? 9
                : selectedTemplate.bottom && selectedTemplate.left
                ? 7
                : "";
        setShapePosition(pos);
    };

    useEffect(() => {
        adjustShapePosition();
    }, [selectedTemplate]);

    const handlePositionChange = (position, top, right, bottom, left) => {
        setShapePosition(position);
        dispatch(
            setTemplateSelection(
                selectedTemplate.templateLayout,
                top,
                right,
                bottom,
                left,
                selectedTemplate.originalImageWidth,
                selectedTemplate.currentImageWidth
            )
        );
    };

    const SelectedIcon = () => (
        <div className="h-10 w-10 bg-blue-600 flex items-center justify-center m-0">
            <div className="h-4 w-4 bg-white rounded-full" />
        </div>
    );

    const NonSelectedIcon = () => <div className="h-10 w-10 bg-white flex items-center justify-center m-0" />;

    return (
        <div className="flex-col mt-10 ml-5 mr-5">
            <div className="text-16px font-semibold font-sf-display text-gray-700 leading-140p mb-5">
                Select Position
            </div>
            <div className="flex">
                <div className="grid grid-cols-3">
                    <div
                        className="bg-white border-solid border border-gray-300 m-0"
                        onClick={() => handlePositionChange(1, true, false, false, true)}
                    >
                        {shapePosition === 1 ? <SelectedIcon /> : <NonSelectedIcon />}
                    </div>
                    <div className="h-11 w-11 bg-gray-300 border-solid border border-gray-300 m-0" />
                    <div
                        className="bg-white border-solid border border-gray-300 m-0"
                        onClick={() => handlePositionChange(3, true, true, false, false)}
                    >
                        {shapePosition === 3 ? <SelectedIcon /> : <NonSelectedIcon />}
                    </div>
                    <div className="h-11 w-11 bg-gray-300 border-solid border border-gray-300 m-0" />
                    <div className="h-11 w-11 bg-gray-300 border-solid border border-gray-300 m-0" />
                    <div className="h-11 w-11 bg-gray-300 border-solid border border-gray-300 m-0" />
                    <div
                        className="bg-white border-solid border border-gray-300 m-0"
                        onClick={() => handlePositionChange(7, false, false, true, true)}
                    >
                        {shapePosition === 7 ? <SelectedIcon /> : <NonSelectedIcon />}
                    </div>
                    <div className="h-11 w-11 bg-gray-300 border-solid border border-gray-300 m-0" />
                    <div
                        className="bg-white border-solid border border-gray-300 m-0"
                        onClick={() => handlePositionChange(9, false, true, true, false)}
                    >
                        {shapePosition === 9 ? <SelectedIcon /> : <NonSelectedIcon />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {
        imageOverlayAds: { selectedTemplate },
    } = state;

    return {
        selectedTemplate,
    };
};

SideSelector.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SideSelector);
