// export const INIT_STORE = 'INIT_STORE';

export const TOGGLE_NAVIGATION = "NAVIGATION/TOGGLE";
export const LOCATION_CHANGE = "NAVIGATION/LOCATION_CHANGE";

export const LOG_ANALYTICS = "LOG_ANALYTICS";
export const PUT_ONBOARDING_VALUE = "PUT_ONBOARDING_VALUE";
export const PUT_ONBOARDING_ERROR = "PUT_ONBOARDING_ERROR";
export const PUT_IMPORT_PRODUCTS_SELECTED_ITEMS = "PUT_IMPORT_PRODUCTS_SELECTED_ITEMS";

export * from "./action-settings";

export * from "./action-ads.js";

export * from "./action-categories.js";

export * from "./action-countries.js";

export * from "./action-features.js";

export * from "./action-store.js";

export * from "./action-onboarding.js";

export * from "./action-tags.js";

export * from "./action-uninstall.js";

export * from "./action-facebook-ads.js";

export * from "./action-navigation";

export * from "./action-conversion";

export * from "./action-toast";

export * from "./action-ad_sets";

export * from "./action-lists";

export * from "./action-dynamic-reach-list";

export * from "./action-dynamic-remarketing-list";

export * from "./action-dynamic-upsell-list";

export * from "./action-video-ads";

export * from "./actions-google-shopping";

export * from "./actions-ads-exchange";

export const toggleNavigation = (status) => ({
    type: TOGGLE_NAVIGATION,
    status: status,
});

export const locationChange = (path) => ({
    type: LOCATION_CHANGE,
    path: path,
});

export const logAnalytics = (payload) => ({
    type: LOG_ANALYTICS,
    payload: payload,
});

export const putOnboardingValue = (page, key, value) => ({
    type: PUT_ONBOARDING_VALUE,
    page: page,
    key: key,
    value: value,
});

export const putOnboardingError = (page, key, error) => ({
    type: PUT_ONBOARDING_ERROR,
    page: page,
    key: key,
    error: error,
});

export const putImportProductsSelectedItems = (selectedItems) => ({
    type: PUT_IMPORT_PRODUCTS_SELECTED_ITEMS,
    selectedItems: selectedItems,
});
