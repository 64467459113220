import React, { Fragment } from "react";

import { Tooltip } from "@shopify/polaris";

import { Icon } from "../../../utils/Icon";

const RetryResourceLaunch = ({ resource, isRetryLoading, onRetryLaunch }) => {
    return (
        <Fragment>
            <div style={{ zIndex: 2 }}>
                <Tooltip active={false} content={`Retry launching ${resource}`}>
                    <div
                        className={isRetryLoading ? "cursor-wait animate-spin" : "cursor-pointer"}
                        onClick={() => (isRetryLoading ? "" : onRetryLaunch())}
                    >
                        <Icon name="reloadSVG" />
                    </div>
                </Tooltip>
            </div>
        </Fragment>
    );
};

export default RetryResourceLaunch;
