import { get } from "../api/api-list";
import { toggleToastPopup } from "./action-toast";
import { api } from "../utils";

export const FETCH_UPSELL_LIST_REQUEST = "FETCH_UPSELL_LIST_REQUEST";
export const FETCH_UPSELL_LIST_SUCCESS = "FETCH_UPSELL_LIST_SUCCESS";
export const FETCH_UPSELL_LIST_ERROR = "FETCH_UPSELL_LIST_ERROR";
export const UPDATE_UPSELL_CAMPAIGN = "UPDATE_UPSELL_CAMPAIGN";
export const TOGGLE_UPSELL_CAMPAIGN_EXPANSION = "TOGGLE_UPSELL_CAMPAIGN_EXPANSION";
export const TOGGLE_UPSELL_TABLE_EXPANSION = "TOGGLE_UPSELL_TABLE_EXPANSION";

export const ACTIONS = {
    RETRY_LAUNCHING_AD_SET_REQUESTED: "dynamicUpsellCampaign/RETRY_LAUNCHING_AD_SET_REQUESTED",
    RETRY_LAUNCHING_AD_SET_SUCCEED: "dynamicUpsellCampaign/RETRY_LAUNCHING_AD_SET_SUCCEED",
    RETRY_LAUNCHING_AD_SET_FAILED: "dynamicUpsellCampaign/RETRY_LAUNCHING_AD_SET_FAILED",

    FETCH_AD_SET_REQUESTED: "dynamicUpsellCampaign/FETCH_AD_SET_REQUESTED",
    FETCH_AD_SET_SUCCEED: "dynamicUpsellCampaign/FETCH_AD_SET_SUCCEED",
    FETCH_AD_SET_FAILED: "dynamicUpsellCampaign/FETCH_AD_SET_FAILED",

    RETRY_LAUNCHING_AD_REQUESTED: "dynamicUpsellCampaign/RETRY_LAUNCHING_AD_REQUESTED",
    RETRY_LAUNCHING_AD_SUCCEED: "dynamicUpsellCampaign/RETRY_LAUNCHING_AD_SUCCEED",
    RETRY_LAUNCHING_AD_FAILED: "dynamicUpsellCampaign/RETRY_LAUNCHING_AD_FAILED",

    FETCH_AD_REQUESTED: "dynamicUpsellCampaign/FETCH_AD_REQUESTED",
    FETCH_AD_SUCCEED: "dynamicUpsellCampaign/FETCH_AD_SUCCEED",
    FETCH_AD_FAILED: "dynamicUpsellCampaign/FETCH_AD_FAILED",
};

export const loadUpsellListRequest = (listType) => {
    return {
        type: FETCH_UPSELL_LIST_REQUEST,
        listType: listType,
    };
};

export const loadUpsellListSucceeded = (payload) => {
    return {
        type: FETCH_UPSELL_LIST_SUCCESS,
        payload: payload,
    };
};

export const loadUpsellListFailed = (payload) => {
    return {
        type: FETCH_UPSELL_LIST_ERROR,
        payload: payload,
    };
};

export const changeUpsellInternalStatus = (primary_key, id, internal_status) => {
    return {
        type: UPDATE_UPSELL_CAMPAIGN,
        payload: { primary_key, id, internal_status },
    };
};

export const loadUpsellList = (apiUrl, type = null) => (dispatch) => {
    if (!type) {
        return;
    }
    dispatch(loadUpsellListRequest(type));
    get(apiUrl, type)
        .then((res) => {
            if (res.error) {
                dispatch(toggleToastPopup([res.response.detail], "error"));
                dispatch(loadUpsellListFailed(res.response.detail));
            } else {
                dispatch(loadUpsellListSucceeded(res));
            }
        })
        .catch((err) => {
            dispatch(loadUpsellListFailed(err));
        });
};

export const updateUpsellCampaign = (primary_key, stop_time, daily_budget) => {
    return {
        type: UPDATE_UPSELL_CAMPAIGN,
        payload: { primary_key, stop_time, daily_budget },
    };
};

export const toggleUpsellCampaignExpansion = (primaryKey) => {
    return {
        type: TOGGLE_UPSELL_CAMPAIGN_EXPANSION,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const toggleUpsellTableExpansion = () => {
    return {
        type: TOGGLE_UPSELL_TABLE_EXPANSION,
        payload: {
            //
        },
    };
};

export const retryLaunchingUpsellAdRequested = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingUpsellAdSucceed = (ad) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SUCCEED,
        payload: {
            ad: ad,
        },
    };
};

export const retryLaunchingUpsellAdSucceedFailed = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingUpsellAd = (primaryKey) => (dispatch) => {
    dispatch(retryLaunchingUpsellAdRequested(primaryKey));

    api(`/api/v1/dynamic-ads-campaigns/${primaryKey}/retry-ad/`)
        .then((response) => {
            dispatch(retryLaunchingUpsellAdSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(retryLaunchingUpsellAdSucceedFailed(primaryKey));
            dispatch(fetchUpsellAd(primaryKey));
        });
};

export const fetchUpsellAdRequested = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchUpsellAdSucceed = (ad) => {
    return {
        type: ACTIONS.FETCH_AD_SUCCEED,
        payload: {
            ad: ad,
        },
    };
};

export const fetchUpsellAdFailed = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchUpsellAd = (primaryKey) => (dispatch) => {
    dispatch(fetchUpsellAdRequested(primaryKey));

    api(`/api/v1/dynamic-ads-ads/${primaryKey}/`)
        .then((response) => {
            dispatch(fetchUpsellAdSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(fetchUpsellAdFailed(primaryKey));
        });
};

export const retryLaunchingUpsellAdSetRequested = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingUpsellAdSetSucceed = (adSet) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_SUCCEED,
        payload: {
            adSet: adSet,
        },
    };
};

export const retryLaunchingUpsellAdSetSucceedFailed = (primaryKey) => {
    return {
        type: ACTIONS.RETRY_LAUNCHING_AD_SET_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const retryLaunchingUpsellAdSet = (primaryKey) => (dispatch) => {
    dispatch(retryLaunchingUpsellAdSetRequested(primaryKey));

    api(`/api/v1/dynamic-ads-campaigns/${primaryKey}/retry-adset/`)
        .then((response) => {
            dispatch(retryLaunchingUpsellAdSetSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(retryLaunchingUpsellAdSetSucceedFailed(primaryKey));
            dispatch(fetchUpsellAdSet(primaryKey));
        });
};

export const fetchUpsellAdSetRequested = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_SET_REQUESTED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchUpsellAdSetSucceed = (adSet) => {
    return {
        type: ACTIONS.FETCH_AD_SET_SUCCEED,
        payload: {
            adSet: adSet,
        },
    };
};

export const fetchUpsellAdSetFailed = (primaryKey) => {
    return {
        type: ACTIONS.FETCH_AD_SET_FAILED,
        payload: {
            primaryKey: primaryKey,
        },
    };
};

export const fetchUpsellAdSet = (primaryKey) => (dispatch) => {
    dispatch(fetchUpsellAdSetRequested(primaryKey));

    api(`/api/v1/dynamic-ads-adsets/${primaryKey}/`)
        .then((response) => {
            dispatch(fetchUpsellAdSetSucceed(response));
        })
        .catch((error) => {
            dispatch(toggleToastPopup([error.response.detail], "error"));
            dispatch(fetchUpsellAdSetFailed(primaryKey));
        });
};
