import { create, remove } from "../api/api-tags";

export const CREATE_TAGS_REQUEST = "CREATE_TAGS_REQUEST";
export const CREATE_TAGS_FAILURE = "CREATE_TAGS_FAILURE";
export const CREATE_TAGS_SUCCESS = "CREATE_TAGS_SUCCESS";

export const createTagsRequest = (payload) => ({
    type: CREATE_TAGS_REQUEST,
    payload: payload,
});

export const createTagsFailure = (payload) => ({
    type: CREATE_TAGS_FAILURE,
    payload: payload,
});

export const createTagsSuccess = (payload) => ({
    type: CREATE_TAGS_SUCCESS,
    payload: payload,
});

export const createTags = (data) => (dispatch) => {
    dispatch(createTagsRequest());

    return create({ tags: data })
        .then((payload) => {
            dispatch(createTagsSuccess(payload));
        })
        .catch((error) => {
            throw error;
        });
};

export const REMOVE_TAGS_REQUEST = "REMOVE_TAGS_REQUEST";
export const REMOVE_TAGS_FAILURE = "REMOVE_TAGS_FAILURE";
export const REMOVE_TAGS_SUCCESS = "REMOVE_TAGS_SUCCESS";

export const removeTagsRequest = (payload) => ({
    type: REMOVE_TAGS_REQUEST,
    payload: payload,
});

export const removeTagsFailure = (payload) => ({
    type: REMOVE_TAGS_FAILURE,
    payload: payload,
});

export const removeTagsSuccess = (payload) => ({
    type: REMOVE_TAGS_SUCCESS,
    payload: payload,
});

export const removeTags = (data) => (dispatch) => {
    dispatch(removeTagsRequest(data));

    return remove({ ids: data })
        .then(() => {
            dispatch(removeTagsSuccess(data));
        })
        .catch((error) => {
            throw error;
        });
};
