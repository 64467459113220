/* eslint-disable no-underscore-dangle, prefer-rest-params, no-multi-assign */
import { LOG_CONVERSION } from "../actions";

const getTypeName = (type) => {
    let typeName;
    switch (type) {
        case "facebook":
            typeName = "FB PLAN";
            break;
        case "google":
            typeName = "GOOGLE PLAN";
            break;
        default:
            typeName = type;
    }

    return typeName;
};

const trackTapFiliateConversion = (paymentId, paymentType, price, type = "facebook") => {
    // Create tap object if not created
    if (typeof window.tap === "undefined") {
        (function (t, a) {
            t.TapfiliateObject = a;
            t[a] =
                t[a] ||
                function () {
                    (t[a].q = t[a].q || []).push(arguments);
                };
        })(window, "tap");
        window.tap("create", "12410-18b8f4", { integration: "javascript" });
    }

    window.tap("conversion", `${getTypeName(type) + paymentType}:${paymentId}`, price, {}, type);
};

/* eslint-disable no-unused-expressions */
const trackFacebookPixelConversion = (price, type) => {
    if (typeof window.fbq === "undefined") {
        !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, ...arguments) : n.queue.push(...arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            // eslint-disable-next-line prefer-destructuring
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        window.fbq("init", "319820449217135");
    }

    window.fbq("track", "Purchase", {
        currency: "EUR",
        value: ((price * 0.4) / 1.12).toFixed(2),
        content_name: getTypeName(type),
    });
};
/* eslint-disable no-unused-expressions */

const conversionMiddleware = () => (next) => (action) => {
    if (action.type === LOG_CONVERSION) {
        const { payload } = action;
        trackTapFiliateConversion(payload.id, payload.paymentType, payload.price, payload.type);
        trackFacebookPixelConversion(payload.price, payload.type);
    }

    return next(action);
};

export default conversionMiddleware;
