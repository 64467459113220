import { FETCH_SETTINGS_REQUEST, FETCH_SETTINGS_FAILURE, FETCH_SETTINGS_SUCCESS } from "../actions";

const initial = {
    isLoading: false,
    adStatuses: {},
    google: {
        customer_id: null,
        descriptive_name: null,
        sixads_oauth_client_id: null,
        is_connected: false,
        is_billing_correct: true,
    },
    facebook: {
        ad_account_id: null,
        business_manager_id: null,
        page_id: null,
        ads_pixel_id: null,
        system_user_id: null,
        sixads_business_manager_id: null,
        product_catalog_id: null,
        sixads_app_id: null,
        scope: null,
        is_connected: false,
        lookalike_audiences: [],
    },
    instagram: {
        ad_account_id: null,
        business_manager_id: null,
        page_id: null,
        ads_pixel_id: null,
        system_user_id: null,
        sixads_business_manager_id: null,
        sixads_app_id: null,
        scope: null,
        is_connected: false,
    },
};

export const settings = (state = initial, action) => {
    switch (action.type) {
        case FETCH_SETTINGS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_SETTINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case FETCH_SETTINGS_SUCCESS: {
            // Revert ad status response, by swapping key with value. It will make searching faster(O(1)) later.
            const statuses = Object.assign(
                {},
                ...Object.entries(action.payload.ad_statuses).map(([key, value]) => {
                    return {
                        [value]: parseInt(key, 10),
                    };
                })
            );

            return {
                ...state,
                isLoading: false,
                adStatuses: statuses,
                google: action.payload.google,
                facebook: action.payload.facebook,
                instagram: action.payload.instagram,
            };
        }
        default:
            return state;
    }
};
