import React, { Fragment } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import { pencil } from "../../../utils/icons-utils";

const TableHeader = (props) => {
    const { rootElementClasses, editable, children, onClick, classes, style } = props;

    const getClasses = () => {
        const defaultClasses =
            "text-gray-700 font-medium text-14px flex justify-center items-center py-4 px-2 inline-block whitespace-nowrap";
        return classnames(defaultClasses, classes);
    };

    return (
        <Fragment>
            <th onClick={onClick} className={rootElementClasses} style={style}>
                <div className={getClasses()}>
                    {children}

                    {editable && (
                        <span className="opacity-0 hover:opacity-100 flex flex-row justify-center items-center text-gray-950 ml-4 gap-1 pt-16 -ml-3.5rem">
                            {pencil()} <span className="text-12px font-normal underline">Edit</span>
                        </span>
                    )}
                </div>
            </th>
        </Fragment>
    );
};

TableHeader.propTypes = {
    classes: PropTypes.string,
    rootElementClasses: PropTypes.string,
    editable: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

TableHeader.defaultProps = {
    rootElementClasses: "",
    classes: "",
    editable: false,
    onClick: () => {},
};

export default TableHeader;
