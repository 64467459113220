import React from "react";

export default function (props) {
    const { icon, style, height, width } = props;

    const getWidth = () => {
        const widthFinal = width || 24;
        return `${widthFinal}px`;
    };

    const getHeight = () => {
        const heightFinal = height || 24;
        return `${heightFinal}px`;
    };

    return icon ? (
        <svg width={getWidth()} height={getHeight()} className="c-nav--ico" style={style}>
            <use xlinkHref={`#nav-ico--${icon}`} />
        </svg>
    ) : (
        ""
    );
}
