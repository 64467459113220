import React, { Fragment } from 'react';
import PropTypes from "prop-types";

const StarSvg  = (props) => {
    const { isActive, size, onMouseOver, onClick } = props;
    const starStroke = (isActive) ? "fill-current text-yellow-400" : "fill-current text-gray-500";
    const starFill = (isActive) ? "fill-current text-yellow-300" : "fill-current text-gray-300";

    return (
        <svg
            className={`star ${starStroke} ${starFill} p-0 inline-block`}
            width={size} height={size} viewBox="0 0 22 20"
            fill="none" xmlns="http://www.w3.org/2000/svg"
            onMouseOver={onMouseOver}
            onClick={onClick}
        >
                <path
                    d="M11 1.54816L13.1268 7.78053L13.2423 8.11905H13.6H20.4143L14.9132 11.9714L14.6055 12.1869L14.7268 12.5424L16.8321 18.7118L11.2868 14.8285L11 14.6277L10.7132 14.8285L5.16792 18.7118L7.27321 12.5424L7.39454 12.1869L7.08681 11.9714L1.58566 8.11905H8.4H8.75769L8.87321 7.78053L11 1.54816Z"
                    fill={starFill}
                    stroke={starStroke}
                />
        </svg>
    )

}

StarSvg.propTypes = {
    isActive: PropTypes.bool,
    size: PropTypes.number,
    onMouseOver: PropTypes.func,
    onClick: PropTypes.func,
};

StarSvg.defaultProps = {
    isActive: false,
    size: 22,
    onMouseOver: () => {},
    onClick: () => {},
};

const StarSvgWithLink = (props) => {
    const {link, isActive, size, onClick, onMouseOver} = props;
    return (
        <a href={link} target="_blank" rel="noreferrer">
            <StarSvg isActive={isActive} size={size} onClick={onClick} onMouseOver={onMouseOver} />
        </a>
    )
}

StarSvgWithLink.propTypes = {
    link: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    size: PropTypes.number,
    onMouseOver: PropTypes.func,
    onClick: PropTypes.func,
};

StarSvgWithLink.defaultProps = {
    isActive: false,
    size: 22,
    onMouseOver: () => {},
    onClick: () => {},
};

export const Star = (props) => {
    const {isActive, size, onMouseOver, onClick, openTabTo } = props;

    return (
        <Fragment>
            {openTabTo && (
                <StarSvgWithLink
                    link={openTabTo}
                    isActive={isActive}
                    size={size}
                    onMouseOver={onMouseOver}
                    onClick={onClick}
                />
            )}
            {!openTabTo && <StarSvg isActive={isActive} size={size} onMouseOver={onMouseOver} onClick={onClick} />}
        </Fragment>
    );
}

Star.propTypes = {
    isActive: PropTypes.bool.isRequired,
    size: PropTypes.number,
    onMouseOver: PropTypes.func,
    onClick: PropTypes.func,
    openTabTo: PropTypes.string
};

Star.defaultProps = {
    size: 22,
    onMouseOver: () => {},
    onClick: () => {},
    openTabTo: null
};

export default Star;
