import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Page, Spinner } from "@shopify/polaris";

import Heading from "../components/Heading";
import FirstPage from "./uninstall/FirstPage";
import EarnMoney from "./uninstall/EarnMoney";
import Options from "./uninstall/Options";
import Option from "./uninstall/Option";
import Feedback from "./uninstall/Feedback";

import {
    initUninstall,
    exitUninstall,
    completeUninstall,
    postponeUninstall,
    logAnalytics,
    updateStore,
    cancelSubscription,
    createFeatureRequest,
} from "../actions";

class Uninstall extends Component {
    componentDidMount() {
        document.title = "Uninstall | SIXADS";

        this.props.dispatch(initUninstall());
    }

    componentWillUnmount() {
        this.props.dispatch(exitUninstall());
    }

    logAnalytics = (data) => {
        this.props.dispatch(logAnalytics(data));
    };

    handleSolution = (msg) => {
        let reason = "";

        switch (this.props.option) {
            case "1":
                this.props.dispatch(updateStore({ is_paused: true }));

                reason = "Pause Account Button";
                break;
            case "2":
                this.props.dispatch(updateStore({ ads_disabled: true }));

                reason = "Disable Ad Showing Button";
                break;
            case "3":
                cancelSubscription();

                reason = "Cancel Subscription Button";
                break;
            case "4":
                this.props.dispatch(
                    createFeatureRequest({
                        email: this.props.email,
                        message: msg,
                        type: 2,
                    })
                );

                reason = "Technical Issue Submit Button";
                break;
            case "5":
                reason = "Too Expensive Button";
                break;
            default:
                reason = "";
                break;
        }

        this.props.dispatch(postponeUninstall(reason));
    };

    handleUninstall = (msg) => {
        let reason = "";

        switch (this.props.option) {
            case "1":
                reason = "Don’t use it enough";
                break;
            case "2":
                reason = "Don’t want display ads";
                break;
            case "3":
                reason = "Don’t want subscription";
                break;
            case "4":
                reason = "Technical issues";
                break;
            case "5":
                reason = "Too Expensive";
                break;
            case "6":
                this.props.dispatch(
                    createFeatureRequest({
                        email: this.props.email,
                        message: msg,
                        type: 3,
                    })
                );

                reason = "Other";
                break;
            default:
                reason = "";
                break;
        }

        this.props.dispatch(completeUninstall(reason));

        window.location.href = `https://${this.props.shop_url}/admin/apps`;
    };

    render() {
        const { step, action, option, email, isLoading } = this.props;

        return (
            <Page>
                <Heading content="Uninstall" />
                {isLoading && (
                    <div className="c-spinner">
                        <Spinner />
                    </div>
                )}
                {!isLoading && (
                    <Fragment>
                        {step === 1 && <FirstPage />}
                        {step === 2 && action === "earn" && <EarnMoney email={email} />}
                        {step === 2 && action === "uninstall" && <Options />}
                        {step === 3 && (
                            <Option
                                option={option}
                                logAnalytics={this.logAnalytics}
                                handleUninstall={this.handleUninstall}
                                handleSolution={this.handleSolution}
                            />
                        )}
                        {step === 4 && <Feedback option={option} />}
                    </Fragment>
                )}
            </Page>
        );
    }
}

const mapStateToProps = (state) => {
    const { store, uninstall } = state;

    return {
        action: uninstall.action || "",
        option: uninstall.option || 0,
        step: uninstall.step || 1,
        shop_url: store.shop_url,
        email: store.email,
        isLoading: store.isLoading || uninstall.isRedirecting || false,
    };
};

export default connect(mapStateToProps)(Uninstall);
