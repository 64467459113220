import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { toggleNavigation } from "../../actions";
import { ProductInfo } from "../../components/google-shopping";

const ProductList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Google Products | SIXADS";
        dispatch(toggleNavigation("open"));
    });

    return (
        <Fragment>
            <Switch>
                <Route path="/google-shopping/all-products/" component={ProductInfo} exact={true} />
            </Switch>
        </Fragment>
    );
};

export default ProductList;
