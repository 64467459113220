import css from "styled-jsx/css";

export default css`
    form {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        border-radius: 999999999px;
    }

    form > div {
        color: white;
        padding-top: 24px;
        display: block;
        position: absolute;
        left: -4px;
        background-color: #499995;
        border-radius: 999999999px;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.4);
        z-index: 1;
        pointer-events: none;
        transition: transform 0.3s;
    }

    form::after {
        content: "";
        display: block;
        clear: both;
    }

    form label {
        float: left;
        position: relative;
        color: #499995;
        background: #e2ecf5;
        padding: 20px 0 40px;
        overflow: hidden;
        transition: color 0.3s;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }

    form label:first-of-type {
        border-radius: 999999999px 0 0 999999999px;
    }

    form label:last-of-type {
        border-radius: 0 999999999px 999999999px 0;
    }

    form label input {
        position: absolute;
        top: -200%;
    }

    form label div {
        z-index: 5;
        position: absolute;
        width: 100%;
    }

    form label.selected {
        color: white;
    }
`;
