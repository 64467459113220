import React, { Component } from "react";
import { Card, Page, TextContainer } from "@shopify/polaris";
import Heading from "../components/Heading";

class CancelBoostedPlanSuccessful extends Component {
    componentDidMount() {
        document.title = "Cancel plan| SIXADS";
    }

    render() {
        return (
            <Page>
                <Heading content="Your plan is successfully cancelled." />
                <Card sectioned={true}>
                    <TextContainer spacing="loose">
                        <p>You won’t be charged again.</p>
                        <p>
                            Please take note that invoices of charges usually come later than the actual charges, so you
                            could see an invoice of your past charges only next month, usually together with Shopify
                            billing.
                        </p>
                    </TextContainer>
                </Card>
            </Page>
        );
    }
}

export default CancelBoostedPlanSuccessful;
