/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, FormControlLabel } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import GreenCheckbox from "../../../common/components/GreenCheckBox";
import { fetchStoreRequirements, updateStoreRequirements } from "../../../actions";

const RequirementsCheckboxHeader = ({ isAllChecked }) => (
    <div className="ml-1 mb-2 text-2xl">
        {isAllChecked && <React.Fragment>Your shop meets Google Merchant Center's basic requirements</React.Fragment>}
        {!isAllChecked && (
            <React.Fragment>
                Your shop needs to meet all the Google Merchant Center's basic requirements to move forward
            </React.Fragment>
        )}
    </div>
);

const StoreRequirements = () => {
    const dispatch = useDispatch();
    const coreAccount = useSelector((state) => state.googleShopping.coreAccount);
    const storeRequirements = useSelector((state) => state.googleShopping.storeRequirements);
    const isCoreAccountLoading = useSelector((state) => state.googleShopping.isCoreAccountLoading);
    const isStoreRequirementsLoading = useSelector((state) => state.googleShopping.isStoreRequirementsLoading);
    const isStoreRequirementsChangeLoading = useSelector(
        (state) => state.googleShopping.isStoreRequirementsChangeLoading
    );

    useEffect(() => {
        if (coreAccount.google_credentials_id) {
            dispatch(fetchStoreRequirements());
        }
    }, [coreAccount.google_credentials_id]);

    const handleChange = async (index, checked) => {
        if (checked && !isStoreRequirementsChangeLoading) {
            const prevItem = storeRequirements[index];
            const newItem = {
                ...prevItem,
                accepted: checked,
            };
            dispatch(updateStoreRequirements(newItem));
        }
    };

    function ifAllRequirementsChecked() {
        const acceptedRequirements = storeRequirements.filter((requirement) => requirement.accepted);
        return acceptedRequirements.length === storeRequirements.length;
    }

    return (
        <React.Fragment>
            <SettingsLayout
                header="Store requirements"
                subtitle="This checklist ensures that your store meets. Google Merchant Center's requirements (LINK)"
                disabled={isCoreAccountLoading || isStoreRequirementsLoading || isStoreRequirementsChangeLoading}
                loading={isCoreAccountLoading || isStoreRequirementsLoading}
                active={coreAccount.google_credentials_id && !coreAccount.store_requirements_met}
            >
                {storeRequirements && (
                    <div>
                        <RequirementsCheckboxHeader isAllChecked={ifAllRequirementsChecked()} />
                        {storeRequirements.map(({ accepted, description }, index) => (
                            <div key={index}>
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox
                                            checked={accepted}
                                            name={description}
                                            onChange={(e) => handleChange(index, e.target.checked)}
                                        />
                                    }
                                    label={<div className="welcome-description">{description}</div>}
                                />
                            </div>
                        ))}
                        <Divider />
                        <div className="pt-2 text-xl">
                            This checklist must remain complete as Google is reviewing your Merchant Center account. Any
                            changes could result in an account suspension.
                        </div>
                    </div>
                )}
            </SettingsLayout>
        </React.Fragment>
    );
};

export default StoreRequirements;
