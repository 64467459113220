import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";

const GoogleSettingPanelButton = ({ buttonType, children, onClick, disabled }) => {
    if (buttonType === "REFRESH") {
        return (
            <Button size="large" disabled={disabled} variant="outlined" startIcon={<RefreshIcon />} onClick={onClick}>
                {children}
            </Button>
        );
    }
    if (buttonType === "CONNECT") {
        return (
            <Button size="large" disabled={disabled} variant="contained" color="primary" onClick={onClick}>
                {children}
            </Button>
        );
    }
    if (buttonType === "DISCONNECT") {
        return (
            <Button size="large" disabled={disabled} variant="outlined" color="secondary" onClick={onClick}>
                {children}
            </Button>
        );
    }
    return <Fragment />;
};

export default GoogleSettingPanelButton;
