/* eslint-disable no-case-declarations */
import { SAVE_EDITED_PRODUCT_INFO } from "../actions";

const initialState = {
    savedProducts: [],
};

const adsExchange = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case SAVE_EDITED_PRODUCT_INFO:
            const oldList = state.savedProducts.filter((item) => item.id !== payload.id)
            const newList = [...oldList, payload];
            return {
                ...state,
                savedProducts: newList,
            };

        default:
            return state;
    }
};

export default adsExchange;
