import { api } from "../../../utils";
import { parseShopifyItems } from "./utils/shopify-products-mapping";

const shopifyItemsUrl = "/api/v1/shopify-products/?limit=1";

const loadShopifyItems = async () => {
    return api(shopifyItemsUrl)
        .then((response) => {
            const items = parseShopifyItems(response.results.filter((item) => item.images.length > 0));
            return items.map((item) => ({
                ...item,
                selected: true,
            }));
        })
        .catch(() => {
            return [];
        });
};

/**
 * Preload you data before passing them into component. This will do all the work/api calls
 * that are done in componentDidMount or useHooks. The parameter passed is the redux state and
 * contains all redux data, to use them before passing into components.
 *
 * @param state
 * @returns {Promise<{}>}
 */

const preload = async (state) => {
    const {
        onboarding: {
            pages: {
                importProducts: {
                    values: { selectedProducts = [] },
                },
            },
        },
    } = state;

    const selectedItems = selectedProducts.length ? selectedProducts : await loadShopifyItems();

    return {
        selectedProducts: selectedItems,
    };
};

export default preload;
