import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Banner, Button, Page, Spinner } from "@shopify/polaris";
import { logAnalytics, toggleNavigation } from "../actions";
import CancelBoostedPlanModal from "../components/CancelBoostedPlanModal";
import Plans from "./onboarding-v2/plans/Plans";

class BoostedAds extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    state = {
        error: "",
        showCancelSubscriptionModal: false,
    };

    componentDidMount() {
        document.title = "Boost ad impressions | SIXADS";

        this.props.dispatch(toggleNavigation("close"));

        const node = document.querySelector("#boosted-error");

        if (node && node.value) {
            this.setState({ error: node.value });
        }
    }

    getPlanHeading = (type, name) => {
        let heading = "";
        if (type === "ONE_TIME") {
            heading = `Your ${name} plan is active.`;
        } else {
            heading = `You are subscribed to ${type.toLowerCase()} ${name} plan.`;
        }

        return heading;
    };

    handleSubscriptionModalOpen = (event) => {
        event.preventDefault();
        this.setState({ showCancelSubscriptionModal: true });
        this.props.dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Cancel boosted plan button",
                eventAction: "Click",
                eventLabel: "Boost my ads page",
            })
        );
    };

    handleSubscriptionModalClose = () => {
        this.setState({ showCancelSubscriptionModal: false });
    };

    render() {
        const { isLoading, subscription } = this.props;
        const { showCancelSubscriptionModal } = this.state;

        return (
            <div>
                {/* <Heading content="Choose a paid plan to get EXTRA traffic" /> */}
                {this.state.error && (
                    <Fragment>
                        <Banner title="Error on applying the code" status="critical">
                            <p>{this.state.error}</p>
                        </Banner>
                        <br />
                    </Fragment>
                )}

                {isLoading && (
                    <div className="c-spinner">
                        <Spinner />
                    </div>
                )}

                {!isLoading && subscription.id && (
                    <Fragment>
                        <Banner title={this.getPlanHeading(subscription.type, subscription.name)} status="success">
                            {subscription.type === "YEARLY" && (
                                <p>The best value option with the biggest savings (up to 45% off).</p>
                            )}
                            <p>
                                Want to cancel your plan? Click on the button to proceed &nbsp; &nbsp;
                                <Button outline={true} onClick={this.handleSubscriptionModalOpen}>
                                    <span style={{ textDecoration: "underline", color: "#45848f" }}>Cancel Plan</span>
                                </Button>
                            </p>
                        </Banner>
                        <br />
                    </Fragment>
                )}

                {subscription.id && (
                    <CancelBoostedPlanModal
                        open={showCancelSubscriptionModal}
                        onClose={this.handleSubscriptionModalClose}
                        history={this.props.history}
                    />
                )}

                {!isLoading && (
                    <div className="zoom-90">
                        <Plans returnUrl="/boosted-ads/" />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { store } = state;

    return {
        isLoading: store.isLoading || false,
        subscription: store.subscription,
    };
};

export default withRouter(connect(mapStateToProps)(BoostedAds));
