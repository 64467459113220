import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, Button } from "@shopify/polaris";

import { createFeatureRequest, setUninstallMoney } from "../../actions";

class EarnMoney extends Component {
    componentDidMount() {
        document.title = "Earn money by showing ads | SIXADS";
    }

    handleOnClick = () => {
        const { dispatch, email } = this.props;
        // this.props.dispatch(redirectUninstall())

        // dispatch( logAnalytics({
        //     hitType: 'event',
        //     eventCategory: 'Button',
        //     eventAction: 'Click',
        //     eventLabel: '2st: Earn by Showing Ads'
        // }) );

        dispatch(
            createFeatureRequest({
                email: email,
                message: "I want to earn money by showing ads.",
                type: 4,
            })
        );

        dispatch(setUninstallMoney());
    };

    render() {
        return (
            <Card sectioned={true}>
                <p>
                    You can now earn money for showing ads on your website. Get in touch by clicking the button below to
                    find out more details. Clicking the button doesn’t automatically opt you in. You need to get opt-in
                    via email we send you.
                </p>
                <br />
                <Button primary={true} onClick={this.handleOnClick}>
                    I’m interested in earning money!
                </Button>
            </Card>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(EarnMoney);
