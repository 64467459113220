import React from "react";

import { TableData, TableRow } from "../../common/components/table";

const BlankField = () => <div className="rounded-full py-3 px-6 w-2/3 h-7 bg-gray-200" />;

const InactiveRow = ({ noOfColumn = 0, backgroundClasses = "" }) => (
    <TableRow classes={backgroundClasses}>
        {new Array(noOfColumn).fill("", 0, noOfColumn).map((value, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <TableData key={index}>
                <BlankField />
            </TableData>
        ))}
    </TableRow>
);

export default InactiveRow;
