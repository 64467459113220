import React, { useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import Welcome from "../../components/custom-video-ads";

const ShopifyStoreChecker = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Custom Video Ads | SIXADS";
        dispatch(toggleNavigation("close"));
    }, []);

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />

            <div>
                <Switch>
                    <Route path="/custom-video-ads/" component={Welcome} exact={true} />
                </Switch>
            </div>
        </Fragment>
    );
};

export default ShopifyStoreChecker;
