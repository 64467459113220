import React from "react";
import PropTypes from "prop-types";
import { createWithClassesOrStyles } from "../../../../utils/override";

export const Root = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: `stepper-root--default ${props.sticky ? 'stepper-sticky' : ""}`,
        props: props,
    });

export const Header = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: "center",
        props: props,
    });

export const Body = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: "stepper-body--default",
        props: props,
    });

export const Footer = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: "stepper-footer-default",
        props: props,
    });

const childrenPropTypes = {
    children: PropTypes.node,
    sticky: PropTypes.bool,
};

const childrenDefaultProps = {
    children: <span />,
    sticky: undefined,
};

Root.propTypes = childrenPropTypes;
Root.defaultProps = childrenDefaultProps;
Header.propTypes = childrenPropTypes;
Header.defaultProps = childrenDefaultProps;
Body.propTypes = childrenPropTypes;
Body.defaultProps = childrenDefaultProps;
Footer.propTypes = childrenPropTypes;
Footer.defaultProps = childrenDefaultProps;
