/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { api } from "../../utils";
import BrowserNotSupportedBanner from "./BrowserNotSupportedBanner";
import VideoEditor from "../../components/video-ads/VideoEditor";
import { logAnalytics, navigateVideoAds, toggleToastPopup } from "../../actions";

const VideoEditorPage = ({ selectedTemplate, selectedProduct, history }) => {
    const dispatch = useDispatch();

    if (!selectedProduct || !selectedTemplate) {
        window.location.href = "/video-ads/select-product/";
    }

    useEffect(() => {
        window.document.title = "Video Editor | SIXADS";
    });

    return (
        <div className="md:max-w-1/2 my-0 mx-auto pt-32">
            <BrowserNotSupportedBanner wrapperClasses="mt-24 mx-auto my-0" />

            <VideoEditor selectedProduct={selectedProduct} selectedTemplate={selectedTemplate} videoAdsVersion={"v1"} />
        </div>
    );
};

const mapStateToProps = (state) => {
    const {
        videoAds: {
            pages: {
                product: {
                    values: { selectedProducts },
                },
                template: {
                    values: { selectedTemplate },
                },
            },
        },
    } = state;

    let selectedProduct = selectedProducts && selectedProducts.length ? selectedProducts[0] : null;

    return {
        selectedProduct: selectedProduct,
        selectedTemplate: selectedTemplate,
    };
};

export default withRouter(connect(mapStateToProps)(VideoEditorPage));
