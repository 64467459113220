import moment from "moment";

const today = new Date();

const nDaysAgo = (days) => new Date(today.getTime() - Number(days) * 24 * 60 * 60 * 1000);

const nDaysLater = (days) => new Date(today.getTime() + Number(days) * 24 * 60 * 60 * 1000);

const nMonthsLater = (months, date = null) => {
    date = date == null ? new Date() : new Date(date.getTime());
    date.setMonth(date.getMonth() + months);

    return date;
};

const formatAndPrepareDateRange = (date1, date2) => ({
    start: date1.getTime(),
    end: date2.getTime(),
});

const dateRangeOptions = [
    { label: "Today", value: "0" },
    { label: "Last Week", value: "7" },
    { label: "Last Month", value: "30" },
    { label: "Custom", value: "1" },
];

const formatDate = (date, format = "YYYY-MM-DD") => {
    return moment(date).format(format);
};

export default {
    dateRangeOptions: dateRangeOptions,
    nDaysAgo: nDaysAgo,
    nDaysLater: nDaysLater,
    nMonthsLater: nMonthsLater,
    formatDate: formatDate,
    formatAndPrepareDateRange: formatAndPrepareDateRange,
};
