import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";

const Welcome = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleGetStarted = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Get courses flow",
                eventAction: "Button click",
                eventLabel: "Get started",
            })
        );
        history.push("/dropshipping-course/get-course/");
    };

    return (
        <Fragment>
            <div className="pt-24 md:pt-0 flex flex-col md:flex-row h-screen">
                <div className="flex-1 bg-white flex items-center justify-center">
                    <div className="w-3/4 md:h-2/5 flex flex-col justify-between items-start">
                        <div>
                            <h1 className="welcome-title md:pb-10">
                                Scale your e-commerce business with this A-Z online course{" "}
                                <span role="img" aria-label="Nerd Face">
                                    🤓
                                </span>
                            </h1>
                            <p className="welcome-description">
                                The course includes 7 lessons that will help you build a successful online store. You
                                will learn how to choose products, find suppliers & build your store as well as market
                                it on Facebook & Instagram.
                            </p>
                        </div>
                        <Button primary={true} onClick={handleGetStarted}>
                            <span>Check out the course</span>
                        </Button>
                    </div>
                </div>
                <div className="flex-1 bg-teal-300 flex items-center justify-center">
                    <img src="/static/img/dropshipping-course.jpg" alt="" className="w-9/12 md:w-3/4" />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = () => {
    return {
        //
    };
};

export default withRouter(connect(mapStateToProps)(Welcome));
