/* eslint-disable import/named */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Alert, AlertTitle } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import GoogleSettingPanelButton from "../../../common/components/GoogleSettingPanelButton";
import { GoogleAdsAccountDisconnectRequest } from "../../../api/api-google-shopping";
import { googleAdsAccountDisconnected, loadGoogleShoppingCampaignList } from "../../../actions";

const GoogleAdsAccountDetails = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // const [accountMightBeSuspended, setAccountMightBeSuspended] = useState(false);
    // const [alertShown, setAlertShown] = useState(true);
    const googleAdsAccount = useSelector((state) => state.googleShopping.coreAccount.google_ads_account_id);
    const isAdsAccountLoading = useSelector((state) => state.googleShopping.isAdsAccountLoading);

    useEffect(() => {
        // async function fetchStatus() {
        //     setLoading(true);
        //     const response = await GetGoogleAdsAccountStatus();
        //     setAccountMightBeSuspended(response.google_ads_account_might_be_suspended);
        //     setLoading(false);
        // }

        // fetchStatus();
        dispatch(loadGoogleShoppingCampaignList());
    }, []);

    const onGoogleAdsAccountDisconnectClick = async () => {
        if (googleAdsAccount) {
            setLoading(true);
            await GoogleAdsAccountDisconnectRequest();
            setLoading(false);
            dispatch(googleAdsAccountDisconnected());
        }
    };

    return (
        <SettingsLayout
            header="Google Ads Account"
            subtitle="Product data will be synced to this ads account."
            loading={loading || isAdsAccountLoading}
        >
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                    <img
                        className="p-1 m-2"
                        src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/dd881a477807b6788dc697a5bbc62e40.svg"
                        height={40}
                        width={40}
                        alt="Google Account"
                    />
                    <div className="flex flex-col">
                        <p className="font-bold">GOOGLE ADS ACCOUNT ID</p>
                        <p>{googleAdsAccount}</p>
                    </div>
                </div>
                <div>
                    <GoogleSettingPanelButton buttonType="DISCONNECT" onClick={onGoogleAdsAccountDisconnectClick}>
                        Disconnect
                    </GoogleSettingPanelButton>
                </div>
            </div>
            {/* {alertShown && accountMightBeSuspended && (
                <Alert severity="error" onClose={() => setAlertShown(false)}>
                    <AlertTitle>Make sure your account is not suspended!</AlertTitle>
                    Your google ads account might be suspended. Go to your google ads account dashboard and make sure
                    they are not suspended!
                </Alert>
            )} */}
        </SettingsLayout>
    );
};

export default GoogleAdsAccountDetails;
