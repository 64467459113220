/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template7 = ({
    name,
    image = `${window.location.origin}/static/img/video_promotions/template_7/Screenshot20210725at225027.png`,
    price,
    adMessage,
    paused = false,
}) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["children"][0]["text"] = adMessage || "SPECIAL DISCOUNT";

        animationObject["ProductNameObj"]["ProductName"]["children"][0]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName2"]["children"][0]["text"] = name;
        animationObject["ProductNameObj3"]["ProductName3"]["children"][0]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["children"][0]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow2"]["children"][0]["text"] = price;
        animationObject["PriceTagObj3"]["PriceNow3"]["children"][0]["text"] = price;

        const images = AdobeAn.getComposition("CCB70635D42A4CE596CEAB17094AFB50").getImages();
        images["ProductImage1"]["src"] = image;
        images["ProductImage2"]["src"] = image;
        images["ProductImage3"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_7"
                composition="CCB70635D42A4CE596CEAB17094AFB50"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template7.design_name = "black_sleek";
Template7.image_width = 1000;
Template7.image_height = 1000;

export default Template7;
