import css from "styled-jsx/css";

const styles = css.global`
    label,
    div.dates-column :global(label) {
        color: #637381;
        font-weight: bold;
        line-height: 3rem;
    }

    input {
        border: none;
    }

    input:focus {
        outline: none;
    }

    .budget {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 4px 24px rgba(199, 199, 199, 0.25);
    }

    .dates-column {
        flex: 0.9;
        background: white;
        border-right: 1px solid #dff3e8;
        padding: 4rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .budget-column {
        flex: 1;
        background: linear-gradient(to bottom, rgba(212, 223, 234, 0) 0%, #e0ebf6 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 3rem;
    }

    .budget-column--total-budget {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .budget-column--channels {
        width: 100%;
    }

    .budget-column--slider {
        width: 100%;
        margin-top: 21px;
    }

    .budget-column--slider--range-mark {
        text-align: center;
    }

    .budget-column--slider--range-mark span {
        margin-top: 5px;
        display: block;
        width: 40%;
        height: 8px;
        background-image: url("/static/i/budget/budget-slider.png");
        background-size: 100% 100%;
        margin-left: 17%;
    }

    .budget-column--slider--range-mark p {
        width: 40%;
        font-size: 12px;
        margin-left: 17%;
    }

    .budget-column--estimations {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    @media only screen and (max-width: 700px) {
        .budget {
            flex-direction: column;
        }

        .Polaris-Banner {
            margin-top: 2rem;
        }

        .dates-column--inputs {
            padding-right: 0;
        }
    }

    .Polaris-Banner--statusCritical {
        margin-top: 30px;
        width: 100%;
    }

    .budget .grid,
    .budget .grid__row {
        display: flex;
        justify-content: space-between;
        align-items: normal;
    }

    .budget .grid .list-item-grid--checkbox-not-selected,
    .budget .grid__row .list-item-grid--checkbox-not-selected {
        width: 16px;
        height: 16px;
    }

    .list-item-grid--checkbox-checkmark {
        width: 16px;
        height: 16px;
        border-radius: 3.84375px;
    }

    .list-item-grid--checkbox-container .list-item-grid--checkbox-checkmark:after {
        left: 5px;
        top: 2px;
        width: 6px;
        height: 9px;
    }

    .list-item-grid--selected {
        background: none;
    }

    .Polaris-Page__Content {
        margin: 2rem 0rem;
    }

    @media (max-width: 400px) {
        .Polaris-Page__Content {
            margin: 2rem 2rem;
        }
    }
`;

export default styles;
