import React, { Component, Fragment } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { DisplayText, Banner } from "@shopify/polaris";
import PasswordProtectedBanner from "./PasswordProtectedBanner";

class Heading extends Component {
    static propTypes = {
        warning: Proptypes.bool,
        missing: Proptypes.array,
        isLoading: Proptypes.bool,
        hasSubscription: Proptypes.bool,
        products: Proptypes.array,
        passwordProtected: Proptypes.bool,
        path: Proptypes.string,
        bannersEnabled: Proptypes.bool,
    };

    static defaultProps = {
        warning: false,
        missing: [],
        isLoading: false,
        products: [],
        hasSubscription: false,
        passwordProtected: false,
        path: "",
        bannersEnabled: false,
    };

    render() {
        const {
            content,
            children,
            isLoading,
            hasSubscription,
            warning,
            missing,
            products,
            passwordProtected,
            path,
            bannersEnabled,
        } = this.props;

        return (
            <Fragment>
                <div className="c-page-heading">
                    <DisplayText size="large">{content}</DisplayText>

                    {children}

                    {!children && <br />}
                </div>

                {!isLoading && warning && (
                    <Fragment>
                        {!bannersEnabled && !hasSubscription && (
                            <Fragment>
                                <Banner title="Your ads are not running" status="critical">
                                    <p>
                                        To run your campaign <Link to="/partner-ads/">turn ON partner ads</Link> or{" "}
                                        <Link to="/boosted-ads/">check out our paid plans</Link>.
                                    </p>
                                </Banner>
                                <br />
                            </Fragment>
                        )}
                        {missing.length > 0 && products.length > 0 && (
                            <Fragment>
                                <Banner title="Some of your ads are missing some information" status="warning">
                                    {path === "/my-ads/" && <p>Edit ads information</p>}
                                    {path !== "/my-ads/" && (
                                        <p>
                                            <Link to="/my-ads/">Edit ads information</Link>
                                        </p>
                                    )}
                                </Banner>
                                <br />
                            </Fragment>
                        )}
                        {passwordProtected && (
                            <Fragment>
                                <PasswordProtectedBanner />
                                <br />
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { store, ads, products, navigation } = state;
    const {
        subscription: { id: subscriptionId = null },
    } = store;
    const storeBanners = [
        store.homepage_visible,
        store.collection_visible,
        store.products_page_visible,
        store.thank_you_page_visible,
        store.single_image_ad_visible,
    ];

    return {
        title: props.title,
        children: props.children,
        warning: props.warning || false,
        missing: Array.isArray(ads.items)
            ? ads.items.filter((ad) => ad && ad.enabled && (!ad.title || !ad.category_id || !ad.sub_category_id))
            : [],
        isLoading: store.isLoading || ads.isLoading || products.isLoading || false,
        hasSubscription: !!subscriptionId,
        products: Array.isArray(products.items)
            ? products.items.filter((p) => p && p.images && Array.isArray(p.images) && p.images[0] && p.images[0].src)
            : [],
        published: store.published || false,
        passwordProtected: store.password_protected || false,
        path: navigation.path || "",
        bannersEnabled: storeBanners.some((banner) => banner === true),
    };
};

export default connect(mapStateToProps)(Heading);
