/* eslint-disable react/no-unused-prop-types */

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import "./styles.css";
import { Icon } from "../../../utils/Icon";

class Button extends Component {
    static propTypes = {
        basic: PropTypes.bool,
        primary: PropTypes.bool,
        destructive: PropTypes.bool,

        outline: PropTypes.bool,

        id: PropTypes.string,
        class: PropTypes.string,

        loading: PropTypes.bool,
        disabled: PropTypes.bool,

        onClick: PropTypes.func,
        small: PropTypes.bool,
    };

    static defaultProps = {
        basic: false,
        primary: false,
        destructive: false,

        outline: false,

        id: "",
        class: "",

        loading: false,
        disabled: false,
        small: false,

        onClick: () => {},
    };

    state = {
        basic: this.props.basic,
    };

    componentDidMount() {
        const { basic, primary, destructive } = this.props;

        if (![basic, primary, destructive].includes(true)) {
            this.setState({
                basic: true,
            });
        }
    }

    onClick(ev) {
        const { onClick, loading, disabled } = this.props;

        if (loading || disabled) {
            ev.preventDefault();
            return;
        }

        onClick(ev);
    }

    getButtonClass() {
        const { class: classes, primary, loading: isLoading, disabled: isDisabled, small = false } = this.props;
        const { basic } = this.state;

        const isDisabledOrLoading = isLoading || isDisabled;
        const overrideBorder = classes.match(/border-[a-z]+-[0-9]+/g);
        const overrideBorderHover = classes.match(/hover:border-[a-z]+-[0-9]+/g);
        const overrideText = classes.match(/text-[a-z]+-[0-9]+/g);
        const overrideTextHover = classes.match(/hover:text-[a-z]+-[0-9]+/g);
        const overrideBackground = classes.includes("bg-");
        const overrideBackgroundHover = classes.match(/hover:bg-[a-z]+-[0-9]+/g);

        return classnames(
            "border rounded-md inline-flex items-center m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline text-white",
            {
                "px-12 py-4": !small,
                "px-3 py-3": small,
            },
            {
                "border-gray-400": basic && !overrideBorder,
                "border-teal-600": primary && !isDisabledOrLoading && !overrideBorder,
                "border-teal-500": primary && isDisabledOrLoading && !overrideBorder,
            },
            {
                "hover:border-gray-900": basic && !isDisabledOrLoading && !overrideBorderHover,
                "hover:border-teal-800": primary && !isDisabledOrLoading && !overrideBorderHover,
            },
            {
                "text-gray-900": basic && !isDisabledOrLoading && !overrideText,
                "text-gray-700": basic && isDisabledOrLoading && !overrideText,
                "text-white": !basic && !isDisabledOrLoading && !overrideText,
            },
            {
                "hover:text-gray-900": basic && !isDisabledOrLoading && !overrideTextHover,
                "hover:text-gray-700": basic && isDisabledOrLoading && !overrideTextHover,
            },
            {
                "bg-teal-600": primary && !isDisabledOrLoading && !overrideBackground,
                "bg-teal-500": primary && isDisabledOrLoading && !overrideBackground,
            },
            {
                "hover:bg-teal-800": primary && !isDisabledOrLoading && !overrideBackgroundHover,
            },
            {
                "cursor-not-allowed": isDisabledOrLoading,
            },
            {
                "button-spinner": isLoading,
            },
            {
                "opacity-30": isDisabled,
            },
            { [classes]: true }
        );
    }

    render() {
        const { children, icon = null } = this.props;
        const buttonClass = this.getButtonClass();

        return (
            <Fragment>
                <button type="button" className={`${buttonClass}`} onClick={(ev) => this.onClick(ev)}>
                    <span className="flex items-center gap-x-3">
                        {icon && (
                            <span>
                                <Icon name={icon} />
                            </span>
                        )}
                        {children}
                    </span>
                </button>
            </Fragment>
        );
    }
}

export default Button;
