import React from "react";
import PageHeader from "./PageHeader";
import withTemplateSelectionTile from "./withTemplateSelectionTile";
import LayoutOne from "../layouts/LayoutOne";
import LayoutTwo from "../layouts/LayoutTwo";
import LayoutThree from "../layouts/LayoutThree";
import LayoutFour from "../layouts/LayoutFour";

const ChooseTemplate = () => {
    const Tile1 = withTemplateSelectionTile(LayoutOne, 1, true, false, true, false);
    const Tile2 = withTemplateSelectionTile(LayoutTwo, 2, true, false, true, false);
    const Tile3 = withTemplateSelectionTile(LayoutThree, 3, true, false, true, false);
    const Tile4 = withTemplateSelectionTile(LayoutFour, 4, false, false, false, false);

    return (
        <div className="pt-24 flex flex-col justify-start items-center h-screen">
            <div className="w-11/12 md:w-4/5 lg:w-1/2 mx-auto my-0 mt-12 font-sf-text pb-40">
                <PageHeader />
                <div className="bg-white mt-5 rounded-md shadow">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-10">
                        <Tile1 />
                        <Tile2 />
                        <Tile3 />
                        <Tile4 />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseTemplate;
