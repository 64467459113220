import React, { Component } from "react";

import TemplateItem from "./TemplateItem";
import List from "../../common/components/sixads/list/list";

import {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
    Template7,
    Template8,
    Template9,
    Template10,
    Template11,
    Template12,
    Template13,
    Template14,
    Template15,
} from "./templates";

class TemplatesList extends Component {
    render() {
        const { onChangeList, onTemplateSelect, withUpgrade, onFirstUpgradeSelect, onSecondUpgradeSelect } = this.props;

        const items = [
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_1/StaticPreview.png"
                        alt={Template1.design_name}
                    />
                ),
                name: "template_1",
                design_name: Template1.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_2/StaticPreview.png"
                        alt={Template2.design_name}
                    />
                ),
                name: "template_2",
                design_name: Template2.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_3/StaticPreview.png"
                        alt={Template3.design_name}
                    />
                ),
                name: "template_3",
                design_name: Template3.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_4/StaticPreview.jpg"
                        alt={Template4.design_name}
                    />
                ),
                name: "template_4",
                design_name: Template4.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_5/StaticPreview.png"
                        alt={Template5.design_name}
                    />
                ),
                name: "template_5",
                design_name: Template5.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_6/StaticPreview.png"
                        alt={Template6.design_name}
                    />
                ),
                name: "template_6",
                design_name: Template6.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_7/StaticPreview.png"
                        alt={Template7.design_name}
                    />
                ),
                name: "template_7",
                design_name: Template7.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_8/StaticPreview.png"
                        alt={Template8.design_name}
                    />
                ),
                name: "template_8",
                design_name: Template8.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_9/StaticPreview.png"
                        alt={Template9.design_name}
                    />
                ),
                name: "template_9",
                design_name: Template9.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_10/StaticPreview.png"
                        alt={Template10.design_name}
                    />
                ),
                name: "template_10",
                design_name: Template10.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_11/StaticPreview.png"
                        alt={Template11.design_name}
                    />
                ),
                name: "template_11",
                design_name: Template11.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_12/StaticPreview.png"
                        alt={Template12.design_name}
                    />
                ),
                name: "template_12",
                design_name: Template12.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_13/StaticPreview.png"
                        alt={Template13.design_name}
                    />
                ),
                name: "template_13",
                design_name: Template13.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_14/StaticPreview.png"
                        alt={Template14.design_name}
                    />
                ),
                name: "template_14",
                design_name: Template14.design_name,
                onSelect: onTemplateSelect,
            },
            {
                RenderedItem: (
                    <img
                        className="desktop"
                        src="/static/img/video_promotions/template_15/StaticPreview.png"
                        alt={Template15.design_name}
                    />
                ),
                name: "template_15",
                design_name: Template15.design_name,
                onSelect: onTemplateSelect,
            },
        ];

        if (withUpgrade == true) {
            items.splice(3, 0, {
                RenderedItem: <img className="desktop" src="/static/img/upgrade-banner.svg" alt="Upgrade" />,
                name: "upgrade_banner",
                onSelect: onFirstUpgradeSelect,
                isUpgrade: true,
            });
            items.splice(parseInt(items.length / 4) * 4, 0, {
                RenderedItem: <img className="desktop" src="/static/img/upgrade-banner.svg" alt="Upgrade" />,
                name: "upgrade_banner",
                onSelect: onSecondUpgradeSelect,
                isUpgrade: true,
            });
        }
        return (
            <List
                view="grid"
                items={items || []}
                onChange={onChangeList}
                overrides={{
                    ListItem: {
                        component: TemplateItem,
                    },
                }}
                single={true}
            />
        );
    }
}

export default TemplatesList;
