import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip } from "../tooltip/tooltip";
import { Icon } from "../../../utils/Icon";
import SubMenus from "./SubMenus";

const MenuItem = (props) => {
    const { menu, navStatus, expanded } = props;
    const location = useLocation();
    const firstRoute = `/${location.pathname.split("/")[1]}/`;
    const [menuCollapse, setMenuCollapse] = useState(menu.url.includes(firstRoute) ? false : true);
    const dispatch = useDispatch();

    const menuUrl = menu.url.split("?")[0];
    const url = menuUrl.replace(/(?:\/+(\?))/, "$1").replace(/\/+$/, "");
    const windowUrl = window.location.pathname.replace(/(?:\/+(\?))/, "$1").replace(/\/+$/, "");
    const menuClasses = classNames(
        `transition-width duration-200 ease-in flex flex-row justify-space-between items-center hover:bg-gray-20 p-5 rounded-md cursor-pointer`,
        {
            [["mt-40"]]: menu.gap && !expanded,
            [["mt-20"]]: menu.gap && expanded,
            [["w-218px"]]: expanded,
            [["w-max"]]: !expanded,
            [["bg-gray-20"]]: url === windowUrl,
        }
    );

    return (
        <Fragment key={url}>
            {!menu.children && (
                <a
                    href={menu.url}
                    target={`${menu.newTab ? "_blank" : ""}`}
                    onClick={(ev) => {
                        if (menu.actionBeforeRedirect) {
                            ev.preventDefault();
                            const redirectFunction =
                                window.location.pathname === menu.url
                                    ? () => ""
                                    : () => window.location.replace(menu.url);
                            dispatch(menu.actionBeforeRedirect(redirectFunction));
                        }
                    }}
                >
                    {navStatus === "NAV_DOCKED" && (
                        <Tooltip tooltipText={menu.name} position="fixed" alignment={menu.gap ? "items-end" : ""}>
                            <div className={menuClasses}>
                                <Icon name={menu.icon} />
                                {expanded && (
                                    <span className="text-white font-bold font-sf-text text-16px px-4">
                                        {menu.name}
                                    </span>
                                )}
                            </div>
                        </Tooltip>
                    )}
                    {navStatus !== "NAV_DOCKED" && (
                        <div className={menuClasses}>
                            <Icon name={menu.icon} />
                            {expanded && (
                                <span className="text-white font-bold font-sf-text text-16px px-4">{menu.name}</span>
                            )}
                        </div>
                    )}
                </a>
            )}

            {menu.children && (
                <div
                    onClick={(ev) => {
                        setMenuCollapse((prevState) => !prevState);
                    }}
                >
                    {navStatus === "NAV_DOCKED" && (
                        <a href={menu.url}>
                            <Tooltip
                                href={menu.url}
                                tooltipText={menu.name}
                                position="fixed"
                                alignment={menu.gap ? "items-end" : ""}
                            >
                                <div className={menuClasses}>
                                    <Icon name={menu.icon} />
                                    {expanded && (
                                        <span className="text-white font-bold font-sf-text text-16px px-4">
                                            {menu.name}
                                        </span>
                                    )}
                                </div>
                            </Tooltip>
                        </a>
                    )}
                    {navStatus !== "NAV_DOCKED" && (
                        <div className={menuClasses}>
                            <Icon name={menu.icon} />
                            {expanded && (
                                <span className="text-white font-bold font-sf-text text-16px px-4">{menu.name}</span>
                            )}
                            {!menuCollapse && <Icon name="upArrow" />}
                            {menuCollapse && <Icon name="downArrow" />}
                        </div>
                    )}
                </div>
            )}

            {menu.children && expanded && <SubMenus menu={menu} menuCollapse={menuCollapse} />}
        </Fragment>
    );
};

MenuItem.propTypes = {
    // menu: PropTypes.array.isRequired,  // TODO: Enable proptypes validation. Disabling for now to reduce console errors.
    navStatus: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
};

export default MenuItem;
