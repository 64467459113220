import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

const Toggle = ({ value, disabled, onChange }) => {
    const backGroundClasses = classnames("flex rounded-full py-3 items-center w-max h-10 text-12px " +
        "transition-spacing duration-50  ", {
        [["bg-gray-200 pl-2 pr-10"]]: !value,
        [["bg-green-100 pl-5 pr-2"]]: value,
        [["cursor-pointer"]]: !disabled,
        [["cursor-not-allowed"]]: disabled,
    });

    const iconClasses = classnames("flex rounded-full h-6 w-6", {
        [["bg-gray-700"]]: !value,
        [["bg-green-700 ml-4"]]: value,
    });

    const handleClick = () => {
        if (disabled) {
            return;
        }

        onChange(!value);
    };

    return (
        <div className={backGroundClasses} onClick={handleClick} onKeyUp={handleClick} role="button" tabIndex={0}>
            <div className={iconClasses} />
        </div>
    );
}

Toggle.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

Toggle.defaultProps = {
    onChange: () => {},
    disabled: false,
}

export default Toggle;
