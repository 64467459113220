import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Toast} from "@shopify/polaris";

import styles from "./styles";
import Button from "../../button";
import TextField from "../inputs/TextField";
import {api} from "../../../../utils";

// TODO, this is a feature toggle. will need to switch it when everything is ready.
const DISCOUNT_ENABLED = true

const WithRecommendedHighlight = ({highlight, children}) => (
    <div>
        {highlight && (
            <div
                style={{
                    position: "relative",
                    top: "1.5em",
                    background: "#1ECA77",
                    width: "60%",
                    margin: "0 auto",
                    color: "white",
                    borderRadius: "3px",
                    textAlign: "center",
                    padding: "10px 20px"
                }}
            >
                RECOMMENDED
            </div>
        )}
        {!highlight && <div>&nbsp;<div>&nbsp;</div></div>}
        {children}
    </div>
);

const PlanHeader = ({ name, description, unitPrice, unitPriceBeforeDiscount, totalPrice, isGlobalDiscountApplied }) => (
    <Fragment>
        <div className="box-header--title">{name}</div>

        {description && <div className="box-header--subtitle">{description}</div>}
        {!description && <div className="box-header--subtitle">&nbsp;</div>}

        <div className="box-header--unit-prices">
            {unitPriceBeforeDiscount &&
            <Fragment>
                <div className="unit-price--before-discount">${unitPriceBeforeDiscount}</div>
                &nbsp;
            </Fragment>
            }

            <div>
                <span className="unit-price">${unitPrice}</span>
                <span className="unit-price--suffix"> / month</span>
            </div>
        </div>

        <div className="box-header--total-price">
            {(unitPrice !== totalPrice) && <b>${totalPrice} billed yearly</b>}
            {(unitPrice === totalPrice) && <span>&nbsp;</span>}
        </div>
        {isGlobalDiscountApplied && <div className="box-header--total-price">Discount code applied!</div>}
        <style jsx={true}>{`
            .box-header--title {
                font-weight: bold;
                margin-top: 16px;
            }

            .box-header--subtitle {
                font-weight: regular;
            }

            .box-header--title,
            .box-header--subtitle,
            .notification > div {
                color: #637381;
            }

            .box-header--title {
                font-size: 1.5em;
            }

            .box-header--subtitle {
                font-size: 1em;
            }

            .box-header--unit-prices {
                display: flex;
                flex-direction: row;
                font-size: 1.4em;
                font-weight: bold;
                padding-top: 12px;
            }

            .unit-price--before-discount {
                font-weight: normal;
                color: #eb5757;
                text-decoration: line-through;
            }

            .unit-price--suffix {
                font-size: small;
                color: #919eab;
            }

            .box-header--total-price {
                color: rgba(0, 0, 0, 0.5);
                padding-bottom: 20px;
            }

            @media screen and (max-width: 700px) {
                .box-header--subtitle {
                    text-align: center;
                }
            }
        `}</style>
    </Fragment>
);


const PlanBody = ({featuresTitle, features}) => (
    <Fragment>
        {featuresTitle}

        <ul className="main-list">
            {/* eslint-disable consistent-return */}
            {features.map((feature, index) => {
                if (Array.isArray(feature)) {
                    return;
                }

                const nextIndex = index + 1;

                if (features[nextIndex] && Array.isArray(features[nextIndex])) {
                    return (
                        <li key={feature}>
                            <span dangerouslySetInnerHTML={{__html: feature }} />
                            <ul className="nested-list">
                                {features[nextIndex].map((nestedFeature) => {
                                    return <li key={nestedFeature}>{nestedFeature}</li>;
                                })}
                            </ul>
                        </li>
                    );
                }
                return <li key={feature}>{feature}</li>;
            })}
            {/* eslint-disable consistent-return */}
        </ul>

        <style jsx={true}>{`
            ul {
                list-style: none;
                padding-inline-start: 0;
            }

            ul.main-list {
                line-height: 3rem;
                color:  #454F5B;
            }

            ul.main-list > li:before {
                content: "✓";
                color: #1eca77;
                font-weight: bold;
                margin-right: 0.5rem;
            }

            ul.main-list > li > ul {
                list-style: none;
                padding-left: 20px;
                line-height: 2rem;
            }

            ul.main-list > li > ul > li:before {
                content: "- ";
            }
        `}</style>
    </Fragment>
);

const PlanFooter = (props) => {
    const {
        id,
        name,
        onCreateSubscription,
        isLoading,
        setGlobalDiscountRate,
        discountCode,
        setDiscountCode,
        subscribeButtonText,
    } = props;
    const [showDiscountForm, setShowDiscountForm] = useState(false)

    // Toast
    const [toastActive, setToastActive] = useState(false);
    const [toastContent, setToastContent] = useState('');
    const [toastError, setToastError] = useState(false)
    const toggleActive = useCallback(() => setToastActive((active) => !active), []);

    const toastMarkup = toastActive ? (
        <Toast content={toastContent} onDismiss={toggleActive} error={toastError} />
    ) : null;

    const successMessage = 'Success, the discount has been applied!'
    const invalidCodeMesssage = 'This discount code is invalid'

    function validate() {
        if (discountCode === '') {
            return
        }

        api("/api/v1/coupon/check/", {"data": {"code": discountCode}, method: "POST"}).then(result => {
            setGlobalDiscountRate(result.coefficient)
            setToastContent(successMessage)
            setToastError(false)
            setToastActive(true)
        }).catch(() => {
            setToastContent(invalidCodeMesssage)
            setToastError(true)
            setToastActive(true)
        })
    }

    return <Fragment>
        <Button primary={true} loading={isLoading} onClick={() => onCreateSubscription(id, name)}>
            {subscribeButtonText ? subscribeButtonText : "Subscribe now"}
        </Button>
        <span>No commitments, cancel any time</span>

        {DISCOUNT_ENABLED && !showDiscountForm && <u role="button" tabIndex={-1} onClick={() => setShowDiscountForm(true)} onKeyDown={() => setShowDiscountForm(true)}>Have a discount code?</u>}
        {showDiscountForm && (
            <Fragment>
                <TextField
                    placeholder="Enter discount code here"
                    label=""
                    id="1"
                    value={discountCode}
                    onChange={(values) => setDiscountCode(values)}
                />
                <Button onClick={validate}>Apply discount</Button>
                {toastMarkup}
            </Fragment>
        )
        }
        <style jsx={true}>{`
            span, u {
                padding-top: 12px;
                color: rgba(0, 0, 0, 0.5);
            }
        `}</style>
    </Fragment>
};

const Plan = (props) => {
    const {
        id,
        name,
        description,
        badgeTitle,
        unitPrice,
        unitPriceBeforeDiscount,
        totalPrice,
        featuresTitle,
        features,
        onCreateSubscription,
        highlight,
        highlightedGradientColorFrom,
        highlightedGradientColorTo,
        isLoading,
        globalDiscountRate,
        setGlobalDiscountRate,
        discountCode,
        setDiscountCode,
        subscribeButtonText,
    } = props;


    const highlightedHeaderStyles = highlight
        ? {
            backgroundImage: `linear-gradient(to bottom, ${highlightedGradientColorFrom}, ${highlightedGradientColorTo})`,
        }
        : {};

    return (
        <Fragment>
            <WithRecommendedHighlight highlight={highlight}>
                <div className="box">
                    <div className="box-header" style={highlightedHeaderStyles}>
                        <PlanHeader
                            name={name}
                            description={description}
                            unitPrice={(unitPrice * globalDiscountRate).toFixed(2)}
                            unitPriceBeforeDiscount={unitPriceBeforeDiscount}
                            totalPrice={(totalPrice * globalDiscountRate).toFixed(2)}
                            isGlobalDiscountApplied={globalDiscountRate < 1}
                        />
                    </div>

                    <div className="banner">
                        {badgeTitle}
                    </div>

                    <div className="box-body">
                        <PlanBody featuresTitle={featuresTitle} features={features} />
                    </div>

                    <div className="box-footer">
                        <PlanFooter
                            id={id}
                            name={name}
                            onCreateSubscription={onCreateSubscription}
                            isLoading={isLoading}
                            setGlobalDiscountRate={setGlobalDiscountRate}
                            discountCode={discountCode}
                            setDiscountCode={setDiscountCode}
                            subscribeButtonText={subscribeButtonText}
                        />
                    </div>
                </div>

                <style jsx={true}>{styles}</style>
            </WithRecommendedHighlight>
        </Fragment>
    );
};

Plan.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    badgeTitle: PropTypes.string,
    unitPrice: PropTypes.string.isRequired,
    unitPriceBeforeDiscount: PropTypes.string,
    totalPrice: PropTypes.string.isRequired,
    featuresTitle: PropTypes.string,
    features: PropTypes.array.isRequired,
    onCreateSubscription: PropTypes.func.isRequired,
    highlight: PropTypes.bool,
    highlightedGradientColorFrom: PropTypes.string,
    highlightedGradientColorTo: PropTypes.string,
    isLoading: PropTypes.bool,
    subscribeButtonText: PropTypes.string,
};

Plan.defaultProps = {
    badgeTitle: "",
    description: "",
    unitPriceBeforeDiscount: "0",
    featuresTitle: "",
    highlight: false,
    highlightedGradientColorFrom: "#8be3b8",
    highlightedGradientColorTo: "#e8fff4",
    isLoading: false,
};

export default Plan;
