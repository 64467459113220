import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

class InputNumber extends Component {
    static propTypes = {
        id: PropTypes.string,
        inputClasses: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        name: PropTypes.string,
        labelClasses: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        placeholder: PropTypes.string,
        prefix: PropTypes.node,
        rounded: PropTypes.shape({
            left: PropTypes.bool,
            right: PropTypes.bool,
        }),
        value: PropTypes.number.isRequired,
        minValue: PropTypes.string,
        maxValue: PropTypes.string,
    };

    static defaultProps = {
        id: "",
        inputClasses: "",
        label: "",
        name: "",
        labelClasses: "",
        onBlur: () => {},
        onChange: () => {},
        onFocus: () => {},
        placeholder: "",
        prefix: null,
        rounded: {
            left: true,
            right: true,
        },
        minValue: "0",
        maxValue: "999999999",
    };

    getLabelClasses() {
        const { labelClasses, prefix } = this.props;

        return classnames(
            "block",
            {
                relative: prefix,
            },
            labelClasses
        );
    }

    getInputClasses() {
        const { inputClasses, prefix, rounded } = this.props;

        return classnames(
            "mt-1 py-4 block w-full border-gray-200 text-gray-900 placeholder-gray-700 text-xl font-medium focus:border-blue-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50 placeholder-gray-500 placeholder-opacity-50",
            {
                "pl-4": !prefix,
                "pl-12": prefix,
                "rounded-l-md": rounded.left,
                "rounded-r-md": rounded.right,
            },
            inputClasses
        );
    }

    render() {
        const {
            id,
            label,
            name,
            onBlur,
            onChange,
            onFocus,
            placeholder,
            prefix,
            value,
            minValue,
            maxValue,
        } = this.props;
        const labelClasses = this.getLabelClasses();
        const inputClasses = this.getInputClasses();

        return (
            <Fragment>
                <label className={labelClasses}>
                    {label && <span className="text-gray-700 font-bold text-14px">{label}</span>}
                    {prefix && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                            <button type="button" className="p-1 focus:outline-none focus:shadow-outline">
                                {prefix}
                            </button>
                        </span>
                    )}
                    <input
                        id={id}
                        name={name}
                        type="number"
                        className={inputClasses}
                        onBlur={onBlur}
                        onChange={onChange}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        value={value}
                        min={minValue}
                        max={maxValue}
                    />
                </label>
            </Fragment>
        );
    }
}

export default InputNumber;
