const MENU = [
    {
        name: "Ad account audit",
        icon: "audit",
        url:
            "https://sixads.net/ad-account-audits/?utm_source=app.sixads.net&utm_medium=referral&utm_campaign=meniu-nav",
        newTab: true,
    },
    {
        name: "Dynamic ads",
        icon: "filter",
        url: "/dynamic-ads/",
    },
    {
        name: "Google Shopping",
        icon: "filter",
        url: "/google-shopping/",
        children: [
            {
                name: "Campaigns",
                url: "/dynamic-ads/#google-shopping",
            },
            {
                name: "Products",
                url: "/google-shopping/all-products/",
            },
            {
                name: "Account connection",
                url: "/google-shopping/create-campaign/connect-accounts/",
            },
        ],
    },
    {
        gap: true,
        name: "Video ads",
        icon: "video",
        url: "/video-ads/welcome/",
    },
    {
        name: "Upgrade plan",
        icon: "activity",
        url: "/boosted-ads",
    },
    {
        name: "Creatives for ads",
        icon: "imageWhite",
        url: "/ad-creative/",
        children: [
            {
                name: "Create images",
                url: "/ad-creative/image-ad-creator/welcome/",
            },
            {
                name: "Create ad texts",
                url: "/ad-creative/ad-text-creator/welcome/",
            },
        ],
    },
];

export default MENU;
