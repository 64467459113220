import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Page, SkeletonBodyText, Layout } from "@shopify/polaris";
import { PUT_VIDEO_ADS_ERROR, PUT_VIDEO_ADS_VALUE } from "../../actions";
import { getItemByStoreId } from "../../utils";

export default function withVideoAdsData({ prefix, validate = (data) => data, preload, WrappedComponent }) {
    class Module extends Component {
        static propTypes = {
            dispatch: PropTypes.func,
            values: PropTypes.shape({}),
            errors: PropTypes.arrayOf(PropTypes.shape({})),
        };

        static defaultProps = {
            dispatch: () => {},
            values: {},
            errors: [],
        };

        constructor(props) {
            super(props);
            this.state = {
                loaded: false,
            };

            this.onChange = this.onChange.bind(this);
            this.setLoaded = this.setLoaded.bind(this);
            this.loadData = this.loadData.bind(this);
            this.isComponentMounted = false;
        }

        async componentDidMount() {
            const { values } = this.props;
            this.isComponentMounted = true;
            if (preload) {
                await this.loadData();
            } else {
                this.onChange(values);
            }
        }

        componentWillUnmount() {
            this.isComponentMounted = false;
        }

        setLoaded(loaded = false) {
            this.setState({
                loaded: !loaded,
            });
        }

        onChange = (data) => {
            const { dispatch } = this.props;

            const callback = (validation) => {
                dispatch({
                    type: PUT_VIDEO_ADS_ERROR,
                    page: prefix,
                    errors: validation,
                });
            };

            const validation = validate(data);

            if (validation && Array.isArray(validation)) {
                callback(validation);
            }

            dispatch({
                type: PUT_VIDEO_ADS_VALUE,
                page: prefix,
                values: data,
            });
        };

        // eslint-disable-next-line class-methods-use-this
        getLoading() {
            return (
                <Page>
                    <Layout>
                        <Layout.Section>
                            <SkeletonBodyText lines={2} />
                        </Layout.Section>
                        <Layout.Section>
                            <div className="onboarding-v2-loader-style">
                                <img
                                    src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDQgNDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjU0MiAxLjQ4N0EyMS41MDcgMjEuNTA3IDAgMDAuNSAyMmMwIDExLjg3NCA5LjYyNiAyMS41IDIxLjUgMjEuNSA5Ljg0NyAwIDE4LjM2NC02LjY3NSAyMC44MDktMTYuMDcyYTEuNSAxLjUgMCAwMC0yLjkwNC0uNzU2QzM3LjgwMyAzNC43NTUgMzAuNDczIDQwLjUgMjIgNDAuNSAxMS43ODMgNDAuNSAzLjUgMzIuMjE3IDMuNSAyMmMwLTguMTM3IDUuMy0xNS4yNDcgMTIuOTQyLTE3LjY1YTEuNSAxLjUgMCAxMC0uOS0yLjg2M3oiIGZpbGw9IiM5MTlFQUIiLz48L3N2Zz4K"
                                    alt=""
                                    className="Polaris-Spinner Polaris-Spinner--colorTeal Polaris-Spinner--sizeLarge mx-auto"
                                    draggable="false"
                                    role="status"
                                />
                            </div>
                        </Layout.Section>
                    </Layout>
                </Page>
            );
        }

        async loadData() {
            const { state, dispatch } = this.props;
            const storage = getItemByStoreId(prefix) || null;
            const data = await preload(state, storage);

            dispatch({
                type: PUT_VIDEO_ADS_VALUE,
                page: prefix,
                values: data,
            });

            // if (this.isComponentMounted) {
            this.setState({
                loaded: true,
            });
            // }
            this.onChange(data);
        }

        render() {
            const { loaded } = this.state;
            const { errors = [] } = this.props;

            if (preload && !loaded) {
                return this.getLoading();
            }

            return (
                /* eslint-disable react/jsx-props-no-spreading */
                <WrappedComponent
                    {...this.props}
                    errors={errors}
                    onChange={this.onChange}
                    hasErrors={errors.length > 0}
                    setLoading={this.setLoaded}
                    loadData={this.loadData}
                />
                /* eslint-disable react/jsx-props-no-spreading */
            );
        }
    }
    return connect((state) => {
        return {
            errors: state.videoAds.pages[prefix].errors,
            values: state.videoAds.pages[prefix].values,
            state: state,
        };
    })(Module);
}
