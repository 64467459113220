import { FETCH_FEATURES_FAILURE, sendFeaturesRequest } from "../actions";

const featuresMiddleware = (store) => (next) => (action) => {
    const res = next(action);

    if (action.type === FETCH_FEATURES_FAILURE) {
        const { features } = store.getState();

        if (features.counter < 5) {
            setTimeout(() => {
                store.dispatch(sendFeaturesRequest());
            }, 500);
        }
    }

    return res;
};

export default featuresMiddleware;
