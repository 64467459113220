import * as Sentry from "@sentry/browser";

export const setSentryContextForShop = (shop) => {
    const planName = shop.subscription && shop.subscription.name ? `paid: ${shop.subscription.name}` : "free";
    Sentry.setUser({ id: shop.id, username: shop.shop_url });
    Sentry.setContext("shop", {
        shop_id: shop.id,
        shop_url: shop.shop_url,
        plan: planName,
    });
    Sentry.setTag("plan", planName);
};
