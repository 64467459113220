import fetch from "cross-fetch";
import { isJson, isString } from "../utils";

const listURL = "/api/v1/settings";

export const get = () => {
    return fetch(listURL)
        .then(async (response) => {
            if (!response.ok) {
                let errorResponse = response.statusText;
                await response.text().then((error) => {
                    if (isJson(error)) {
                        errorResponse = error;
                    }
                });
                throw new Error(errorResponse);
            }
            return response.json();
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            let errorMessage = "Something went wrong!";
            if (error.prototyle.hasOwnProperty.call("message")) {
                if (isJson(error.message)) {
                    errorMessage = JSON.parse(error.message);
                } else if (isString(error.message)) {
                    errorMessage = error.message;
                }
            }
            return Promise.reject(errorMessage);
        });
};
