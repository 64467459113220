import React, { useCallback, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { TextField } from "@shopify/polaris";
import Button from "../../common/components/button";
import { logAnalytics, createFeatureRequest } from "../../actions";

const Thanks = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [value, setValue] = useState("");

    const handleChange = useCallback((newValue) => setValue(newValue), []);

    const handleRequestPDF = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "How to guide flow",
                eventAction: "Button click",
                eventLabel: "Submit",
            })
        );

        dispatch(
            createFeatureRequest({
                message: value,
                type: 13,
            })
        );

        history.push("/how-to-guides/thank-you/");
    };

    return (
        <Fragment>
            <div className="flex flex-col md:flex-row h-screen">
                <div className="flex-1 bg-white flex items-center justify-center">
                    <div className="w-3/4 md:w-2/4 md:h-1/3 flex flex-col justify-center items-center">
                        <div className="pb-6">
                            <h1 className="page-title pb-6 md:pb-10 text-left">
                                You’re one step closer to scaling your business!{" "}
                                <span role="img" aria-label="Money bag">
                                    💰
                                </span>
                            </h1>
                            <h2 className="text-16px leading-120p text-gray-900 pb-6">
                                We’ll notify you when the How-to Guide you’ve requested to download is ready in PDF
                                format.
                            </h2>
                            <h3 className="text-14px leading-120p text-gray-900 pb-6">
                                Let us know if there are other topics you’d like us to write about.
                            </h3>
                            <TextField
                                value={value}
                                onChange={handleChange}
                                multiline={4}
                                placeholder="Enter your answer here"
                            />
                        </div>

                        <Button primary={true} onClick={handleRequestPDF}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(Thanks);
