/* eslint-disable dot-notation */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template11 = ({ name, image, price, adMessage, paused = false, discount }) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"]["text"] = adMessage || "SPECIAL DISCOUNT";

        animationObject["ProductNameObj"]["ProductName"]["text"]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName2"]["text"]["text"] = name;
        animationObject["ProductNameObj3"]["ProductName3"]["text"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow2"]["text"]["text"] = price;
        animationObject["PriceTagObj3"]["PriceNow3"]["text"]["text"] = price;

        animationObject["DiscountTagObj"]["DiscountTag"]["text"]["text"] = discount;
        animationObject["DiscountTagObj2"]["DiscountTag2"]["text"]["text"] = discount;
        animationObject["DiscountTagObj3"]["DiscountTag3"]["text"]["text"] = discount;

        const images = window.AdobeAn.getComposition("CCB80635D42A4CE596CEAB17094ZFB50").getImages();
        images["Bitmap61"]["src"] = image;
        images["Screenshot20210729at224637"]["src"] = image;
        images["Screenshot20210729at224709"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_11"
                composition="CCB80635D42A4CE596CEAB17094ZFB50"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template11.design_name = "beige_brown";
Template11.image_width = 590;
Template11.image_height = 590;
Template11.extra_fields = ["discount"];

export default Template11;
