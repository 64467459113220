import React, { useRef, useState, useEffect } from "react";
import Button from "../../common/components/button";
import { Icon } from "../../utils/Icon";
import styles from "./Welcome.module.css";

const Welcome = () => {
    const [showPlayButton, setShowPlayButton] = useState(true);
    const ref = useRef(null);

    const videoInitiate = () => {
        setShowPlayButton(true);
    };

    useEffect(() => {
        ref.current.addEventListener("ended", videoInitiate, false);
    }, []);

    const handleClick = () => {
        const url =
            "https://billo.app/ugc-video-ads-sixads/?ref=sixads&utm_source=app.sixads.net&utm_medium=referral&utm_campaign=landing-page&utm_term=custom-video-ads&utm_content=button";
        window.open(url, "_blank").focus();
    };

    const handlePlayButtonClick = () => {
        if (showPlayButton) {
            ref.current.play();
            setShowPlayButton(false);
        }
    };

    const handleOnVideoClick = () => {
        if (!showPlayButton) {
            ref.current.pause();
            setShowPlayButton(true);
        } else {
            ref.current.play();
            setShowPlayButton(false);
        }
    };

    const PlayButton = () => (
        <div className="absolute text-center p-10 rounded-full bg-pink-300" onClick={() => handlePlayButtonClick()}>
            <div className={styles.buttonPlay} />
        </div>
    );

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
            <div className="pt-36 md:pt-14 lg:pt-0 px-10 md:px-0 flex bg-white justify-center items-center">
                <div className="h-full mb-14 md:h-3/5 flex flex-col justify-center items-center md:items-start w-full md:w-4/5">
                    <h1
                        className="welcome-title mb-5 md:pt-10"
                        style={{ color: "rgba(4, 120, 87, var(--tw-text-opacity))" }}
                    >
                        LIMITED-TIME OFFER - 20%
                    </h1>
                    <h1 className="welcome-title-h2 mt-4 mb-5">User-Generated Product Video Ads That Sell</h1>
                    <p className="welcome-description pt-10">
                        Stand out with high-quality, unique & affordable product video ads. How?
                    </p>
                    <div className="flex flex-col justify-center items-start pt-10 mb-8 md:mb-0 ">
                        <div className="mb-10">
                            <div className="mt-3 mb-3">
                                <p className="welcome-description ml-3">
                                    1. Go to Billo app, use code SIXADSAB & get 20% off.
                                </p>
                            </div>
                            <div className="flex flex-row mb-3">
                                <p className="welcome-description ml-3">2. Describe your video.</p>
                            </div>
                            <div className="flex flex-row mb-3">
                                <p className="welcome-description ml-3">3. Choose from talented video creators.</p>
                            </div>
                            <div className="flex flex-row mb-3">
                                <p className="welcome-description ml-3">
                                    4. Download your videos, create ads & skyrocket your sales.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Button className="text-16px" primary={true} onClick={handleClick}>
                        <b>
                            <span className="md:text-3xl text-xl">Claim 20% Off NOW</span>
                        </b>
                    </Button>
                </div>
            </div>
            <div className="pt-0 md:pt-24 bg-teal-500 flex justify-center items-center">
                <div className="py-20 flex flex-col justify-center items-center">
                    <div className="relative flex justify-center items-center">
                        <video
                            ref={ref}
                            width="45%"
                            controlsList="nofullscreen"
                            poster="https://billo.app/wp-content/uploads/2021/07/Screenshot-2021-07-14-at-13.19.56.png"
                            onClick={() => handleOnVideoClick()}
                        >
                            <source
                                src="https://billo.app/wp-content/uploads/2021/07/1624203023542-9571b2cf93cd51237921-Scooch_Wingback_IG_RI_15s_9x16_04.mp4"
                                type="video/mp4"
                            />
                        </video>
                        {showPlayButton && <PlayButton />}
                    </div>
                    <div className="w-5/12 pt-8 flex justify-center items-center">
                        <div className="pt-5">
                            <Icon name="billoLogo" />
                        </div>
                        <div className="w-40 flex justify-center items-center">
                            <div className=" h-14 w-2 rounded-lg bg-white"></div>
                        </div>
                        <div className="pt-3">
                            <Icon name="sixadsWhiteLogo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
