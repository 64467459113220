/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-return-await */
/* eslint-disable react/no-unused-state */
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Banner, Button, Card, Layout, Link, Select, Stack, TextContainer } from "@shopify/polaris";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import * as Sentry from "@sentry/browser";
import Proptypes from "prop-types";
import TooltipWithIcon from "../../common/components/TooltipWithIcon";
import { fetchSettings, logAnalytics } from "../../actions";
import CreateBusinessManagerModal from "./CreateBusinessManagerModal/CreateBusinessManagerModal";
import CreateAdAccountModal from "./CreateAdAccountModal/CreateAdAccountModal";
import { postBusinessEvent } from "../../api/api-business-event";
import FACEBOOK_BUSINESS_EVENTS from "../../common/business_events_constants";
import TwoFactorAuthenticationWarningBanner from "./TwoFactorAuthenticationWarningBanner";
import SignInFailedBanner from "./SignInFailedBanner";
import ShowHowItWorks from "./ShowHowItWorks";

function FacebookSignInError(message) {
    this.name = "FacebookSignInError";
    this.message = message;
    this.stack = new Error().stack;
}

FacebookSignInError.prototype = new Error();

class FacebookUserSignInComponent extends React.Component {
    static propTypes = {
        linkedBusinessManagerId: Proptypes.string,
        linkedPageId: Proptypes.string,
        linkedAdAccountId: Proptypes.string,
        linkedAdsPixelId: Proptypes.string,
        systemUserId: Proptypes.string,
        sixadsBusinessManagerId: Proptypes.string,
        sixadsAppId: Proptypes.string,
        scope: Proptypes.string,
        // eslint-disable-next-line react/no-unused-prop-types
        updateShowGetStarted: Proptypes.func,
        dispatch: Proptypes.func,
        shopUrl: Proptypes.string,
        store: Proptypes.object,
        showHowItWorks: Proptypes.bool,
        disabled: Proptypes.bool,
    };

    static defaultProps = {
        linkedBusinessManagerId: "",
        linkedPageId: "",
        linkedAdAccountId: "",
        linkedAdsPixelId: "",
        systemUserId: "",
        sixadsBusinessManagerId: "",
        sixadsAppId: "",
        scope: "",
        dispatch: () => {},
        updateShowGetStarted: () => {},
        shopUrl: "",
        store: {},
        showHowItWorks: true,
        disabled: false,
    };

    API_VERSION = "17.0";

    adsPixelDaysSinceLastFireToConsiderInactive = 7;

    signInWithFacebookText = "Sign in with Facebook";

    state = {
        email: "",
        isError: false,
        approveIsLoading: false,
        signInIsLoading: false,
        errorMessage: "",
        successMessage: "",

        showCurrentConfiguration: true,

        // Business users
        businessUsers: [],
        showTwoFacStatusWarningBanner: false,

        // Business manager
        showBusinessManagerSelection: false,
        businessManagerOptions: [],
        selectedBusinessManagerId: "",
        linkedBusinessManagerId: "",
        showBusinessManagerSelectionError: false,
        showCreateBusinessManagerModal: false,

        // Page
        showPageSelection: false,
        pageOptions: [],
        selectedPageId: "",
        linkedPageId: "",

        // Ad account
        showAdAccountSelection: false,
        adAccountOptions: [],
        selectedAdAccountId: "",
        adAccountBlocked: false,
        linkedAdAccountId: "",
        showAdAccountSelectionError: false,
        showCreateAdAccountModal: false,

        showAdAccountPaymentWidget: false,
        paymentMethodAvailable: false,

        showAcceptTos: false,

        // Pixel
        showAdsPixelSelectionSection: false,
        showAdsPixelSelection: false,
        adsPixelOptions: [],
        selectedAdsPixelId: "",
        linkedAdsPixelId: "",
        facebookBannerOpen: false,
        showApprove: true,

        // Catalog
        selectedProductCatalogId: "",
    };

    constructor() {
        super();
        this.onClickApprove = this.onClickApprove.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps.linkedBusinessManagerId === prevState.linkedBusinessManagerId
            ? {}
            : {
                  linkedBusinessManagerId: nextProps.linkedBusinessManagerId,
                  linkedAdsPixelId: nextProps.linkedAdsPixelId,
                  linkedPageId: nextProps.linkedPageId,
                  linkedAdAccountId: nextProps.linkedAdAccountId,
                  systemUserId: nextProps.systemUserId,
              };
    }

    componentDidUpdate(prevProps) {
        const { store, shopUrl } = this.props;
        if (prevProps.shopUrl !== shopUrl) {
            Sentry.setUser({ url: shopUrl });
        }
        if (prevProps.store !== store) {
            Sentry.setContext("store", store);
        }
    }

    onClickFacebookLogin = async () => {
        Sentry.addBreadcrumb({ category: "Click", message: "Click Facebook Login" });
        await postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SIGN_IN_WITH_FB_BUTTON_CLICK).catch((error) =>
            console.log(error)
        );
        this.setState({
            linkedBusinessManagerId: "",
            linkedPageId: "",
            linkedAdAccountId: "",
            linkedAdsPixelId: null,
            signInIsLoading: true,
            approveIsLoading: false,
            selectedBusinessManagerId: "",
        });
        const data = {
            hitType: "event",
            eventCategory: this.signInWithFacebookText,
            eventAction: "Click",
            eventLabel: window.location.href,
        };
        this.props.dispatch(logAnalytics(data));
    };

    onClickCreateBusinessManager = async (eventAction) => {
        await postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CREATE_FB_BM_BUTTON_CLICK).catch((error) =>
            console.log(error)
        );
        const data = {
            hitType: "event",
            eventCategory: "Create Business Manager",
            eventAction: eventAction,
            eventLabel: window.location.href,
        };
        this.props.dispatch(logAnalytics(data));
        this.setState({ showCreateBusinessManagerModal: true });
    };

    onClickCreatePageButton = () => {
        const data = {
            hitType: "event",
            eventCategory: "Create Page",
            eventAction: "Button Click",
            eventLabel: window.location.href,
        };
        this.props.dispatch(logAnalytics(data));
    };

    onClickCreateAdAccount = async (eventAction) => {
        await postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CREATE_FB_AD_ACCOUNT_BUTTON_CLICK).catch((error) =>
            console.log(error)
        );
        const data = {
            hitType: "event",
            eventCategory: "Create Ad Account",
            eventAction: eventAction,
            eventLabel: window.location.href,
        };
        this.props.dispatch(logAnalytics(data));
        this.setState({ showCreateAdAccountModal: true });
    };

    async onClickApprove() {
        const data = {
            hitType: "event",
            eventCategory: "Approve all Fb settings success",
            eventAction: "Button Click",
            eventLabel: window.location.href,
        };
        this.props.dispatch(logAnalytics(data));

        const { selectedBusinessManagerId, selectedPageId, selectedAdAccountId, selectedAdsPixelId } = this.state;
        await postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.APPROVE_ALL_FB_SETTINGS_BUTTON_CLICK, {
            selectedBusinessManagerId: selectedBusinessManagerId,
            selectedPageId: selectedPageId,
            selectedAdAccountId: selectedAdAccountId,
            selectedAdsPixelId: selectedAdsPixelId,
        }).catch((error) => console.log(error));
        Sentry.addBreadcrumb({ category: "Click", message: "Click Approve" });
        this.setState({ approveIsLoading: true });
        await this.connectBusinesses(selectedBusinessManagerId, this.props.sixadsBusinessManagerId);

        const systemUserId = await this.createSystemUser(selectedBusinessManagerId);
        if (!systemUserId) {
            return;
        }
        // eslint-disable-next-line react/no-unused-state
        this.setState({ systemUserId: systemUserId });

        const productCatalogId = await this.createProductCatalog(selectedBusinessManagerId, systemUserId);

        await Promise.all([
            this.assignPage(selectedPageId, systemUserId, selectedBusinessManagerId),
            this.assignAdAccount(selectedAdAccountId, systemUserId, selectedBusinessManagerId),
            this.assignCatalog(productCatalogId, selectedBusinessManagerId, systemUserId),
        ]);

        if (selectedAdsPixelId === "sixads") {
            await this.createAdsPixelAndLinkAdsPixel(selectedAdAccountId);
        }
        await this.linkAdsPixel(this.state.selectedAdsPixelId);

        this.validate(selectedPageId, selectedAdAccountId, productCatalogId);
        this.scrollDown();
    }

    responseFacebook = async (response) => {
        const expectedScopes = this.props.scope.split(",");
        const grantedScopes = response.grantedScopes.split(",");
        // TODO might be broken? Does not work for me.
        // const scopesAreGood = expectedScopes.every((val) => grantedScopes.includes(val));
        const scopesAreGood = true;
        Sentry.addBreadcrumb({
            category: "FB API response",
            data: { response: response, expectedScopes: expectedScopes, grantedScopes: grantedScopes },
        });
        const accessToken = window.FB.getAccessToken();

        if (!scopesAreGood) {
            Sentry.addBreadcrumb({
                level: Sentry.Severity.Warning,
                message: "Scopes do not match",
                data: { response: response, expectedScopes: expectedScopes, grantedScopes: grantedScopes },
            });
            Sentry.captureMessage("Scopes do not match", Sentry.Severity.Warning);
            // Do nothing, hopefully user will try signin up again.
        } else if (!accessToken) {
            Sentry.addBreadcrumb({
                level: Sentry.Severity.Warning,
                message: "Not able to retrieve token",
                data: { response: response },
            });
            Sentry.captureException("Not able to retrieve token");
            // Do nothing, hopefully user will try signin up again.
        } else {
            await postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.FB_SIGN_IN_SUCCESS, {
                response: response,
            }).catch((error) => console.log(error));
            window.FB.api("me", { fields: "email,name,id" }, (response) => this.setState({ email: response.email }));
            this.getBusinessManagers();
            this.getBusinessUsers();
        }
        this.setState({
            signInIsLoading: false,
            showPageSelection: false,
            showAdAccountSelection: false,
            facebookBannerOpen: false,
        });
    };

    handleFacebookError = async (response) => {
        console.warn(response);
        await postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.FB_SIGN_IN_FAILURE, { response: response }).catch((error) =>
            console.log(error)
        );
        if (response.status === "unknown") {
            Sentry.addBreadcrumb({ message: "Response status 'unknown'" });
            // On "sixads will receive: your name and profile picture." Press "Cancel"
        } else if (response.status === "not_authorized") {
            Sentry.addBreadcrumb({ message: "Response status 'not_authorized'" });
            // On "sixads will receive: your name and profile picture." Close popup
        }
        this.setState({ signInIsLoading: false, facebookBannerOpen: true });
    };

    parseResults = (data) => {
        const output = [];
        for (let i = 0; i < data.length; i += 1) {
            const row = data[i];
            output.push({
                label: `${row.name} (${row.id})`,
                value: row.id,
                fundingSource: row.funding_source,
                tosAccepted: row.tos_accepted,
                accountStatus: row.account_status ? row.account_status : undefined,
            });
        }
        return output;
    };

    getBusinessManagers = () => {
        Sentry.addBreadcrumb({ category: "FB API call", message: "getBusinessManagers" });
        const accessToken = window.FB.getAccessToken();
        if (!accessToken) {
            this.updateError("Failed to get business managers: No access tokens");
            return;
        }

        window.FB.api("me/businesses", { fields: "id,created_by,name,verification_status" }, (response) => {
            if (!response) {
                this.updateError("Failed to get business managers: No response from facebook");
            } else if (response.error) {
                this.handleFacebookApiError(response.error);
            } else {
                if (response.data.length === 0) {
                    this.setState({ showBusinessManagerSelectionError: true });
                }
                postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ALL_FB_BMS_RETRIEVED, { response: response.data });

                const output = this.parseResults(response.data);
                this.setState({
                    showBusinessManagerSelection: true,
                    businessManagerOptions: output,

                    showPageSelection: false,
                    showAdAccountSelection: false,
                    showAdsPixelSelection: false,

                    showCurrentConfiguration: false,
                });
            }
        });
    };

    getBusinessUsers = () => {
        Sentry.addBreadcrumb({ category: "FB API call", message: "getBusinessUsers" });
        const fields = [
            "assigned_ad_accounts",
            "assigned_pages",
            "business",
            "ip_permission",
            "role",
            "title",
            "two_fac_status",
        ];

        window.FB.api("me/business_users", { fields: fields.toString(), limit: 100 }, (response) => {
            if (!response) {
                this.updateError("Failed to get business users: No response");
            } else if (response.error) {
                this.handleFacebookApiError(response.error);
            } else {
                postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ALL_BUSINESS_USERS_RETRIEVED, {
                    response: response.data,
                });
                this.setState({
                    businessUsers: response.data,
                });
            }
        });
    };

    getBusinessManagerOwnedPages = (businessManagerId) => {
        Sentry.addBreadcrumb({ category: "FB API call", message: "getBusinessManagerOwnedPages" });
        window.FB.api(
            `${businessManagerId}/owned_pages?fields=global_brand_page_name,id,tasks,business,is_owned,name`,
            (response) => {
                if (!response) {
                    this.updateError("Failed to get business manager owned pages: No response");
                } else if (response.error) {
                    this.handleFacebookApiError(response.error);
                } else {
                    if (response.data.length === 0) {
                        this.setState({ showPageSelectionError: true });
                    }
                    const output = this.parseResults(response.data);
                    Sentry.addBreadcrumb({ category: "Options", message: "Business managers retrieved", data: output });
                    this.setState({ pageOptions: output });
                }
            }
        );
    };

    getBusinessManagerOwnedAdAccounts = (businessManagerId) => {
        Sentry.addBreadcrumb({ category: "FB API call", message: "getBusinessManagerOwnedAdAccounts" });
        window.FB.api(
            `${businessManagerId}/owned_ad_accounts?fields=name,funding_source,tos_accepted,account_status,disable_reason`,
            (response) => {
                if (!response) {
                    this.updateError("Failed to get business managers owned ad accounts: No response");
                } else if (response.error) {
                    this.handleFacebookApiError(response.error);
                } else {
                    if (response.data.length === 0) {
                        this.setState({ showAdAccountSelectionError: true });
                    }
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ALL_FB_AD_ACCOUNTS_RETRIEVED, {
                        response: response.data,
                    });

                    const output = this.parseResults(response.data);
                    this.setState({ adAccountOptions: output });
                }
            }
        );
    };

    createAdsPixelAndLinkAdsPixel = (adAccountId) => {
        Sentry.addBreadcrumb({ category: "FB API call", message: "createAdsPixelAndLinkAdsPixel" });
        const defaultAdsPixelName = "sixads.net ads pixel";
        return new Promise((resolve, reject) => {
            window.FB.api(`${adAccountId}/adspixels`, "POST", { name: defaultAdsPixelName }, (response) => {
                console.log("createAdsPixelAndLinkAdsPixel", response);
                if (!response) {
                    this.updateError("Failed to create ad and link pixel ad: No response");
                    reject(Error("No response"));
                } else if (response.error) {
                    this.handleFacebookApiError(response.error);
                    reject(response.error);
                } else {
                    const adsPixelId = response.id;
                    Sentry.addBreadcrumb({ message: "Ads pixel created and linked", data: { adsPixelId: adsPixelId } });
                    this.setState({ selectedAdsPixelId: adsPixelId }, () => resolve(adsPixelId));
                }
            });
        });
    };

    getAdAccountAdsPixels = (adAccountId) => {
        Sentry.addBreadcrumb({ message: "getAdAccountAdsPixels" });

        window.FB.api(`${adAccountId}/adspixels?fields=name,is_created_by_business,last_fired_time`, (response) => {
            if (!response) {
                this.updateError("Failed to get ad accounts ad pixel: No response");
            } else if (response.error) {
                this.handleFacebookApiError(response.error);
            } else if (response.data.length === 0) {
                this.setState({
                    adsPixelOptions: [{ label: "New pixel will be created", value: "sixads" }],
                    selectedAdsPixelId: "sixads",
                });
                this.scrollDown();
            } else {
                const output = [];
                for (let i = 0; i < response.data.length; i += 1) {
                    const row = response.data[i];

                    // Select Color
                    let color = "🔴";
                    const lastFireTime = new Date(row.last_fired_time);
                    if (lastFireTime) {
                        const today = new Date();
                        const daysSinceLastFire = (today - lastFireTime) / (24 * 60 * 60 * 1000);
                        if (daysSinceLastFire < this.adsPixelDaysSinceLastFireToConsiderInactive) {
                            color = "🟢";
                        }
                    }

                    output.push({ label: `${row.name} (${row.id}) ${color}`, value: row.id });
                }
                Sentry.addBreadcrumb({ message: "Ads pixel options retrieved", data: output });
                this.setState({
                    adsPixelOptions: output,
                    selectedAdsPixelId: output[0].value,
                });
                this.scrollDown();
            }
        });
    };

    connectBusinesses = (selectedBusinessManagerId, sixadsBusinessManagerId) => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_CONNECT_FB_BMS);
        Sentry.addBreadcrumb({
            message: "connectBusinesses",
            data: {
                selectedBusinessManagerId: selectedBusinessManagerId,
                sixadsBusinessManagerId: sixadsBusinessManagerId,
            },
        });
        const that = this;
        if (that.state.isError === true) {
            return undefined;
        }
        return new Promise((resolve, reject) => {
            window.FB.api(
                `/${sixadsBusinessManagerId}/managed_businesses`,
                "POST",
                { existing_client_business_id: selectedBusinessManagerId },
                (response) => {
                    if (!response) {
                        that.setState(() => ({ isError: true }));
                        reject(Error("error"));
                    } else if (response.error) {
                        that.handleFacebookApiError(response.error);
                        reject(response.error);
                    } else {
                        Sentry.addBreadcrumb({
                            message: "Businesses connected",
                            data: { selectedBusinessManagerId: selectedBusinessManagerId },
                        });
                        that.setState({ linkedBusinessManagerId: selectedBusinessManagerId });
                        resolve(response);
                    }
                }
            );
        });
    };

    onChangeBusinessManager = (businessManagerId) => {
        if (!businessManagerId) {
            throw new Error("businessManagerId is required");
        }
        const { businessUsers } = this.state;
        if (!businessUsers) {
            throw new Error("this.state.businessUsers has to be populated");
        }
        const selectedBusinessUser = businessUsers.find(
            (businessUser) => businessUser.business.id === businessManagerId
        );
        const { two_fac_status: twoFacStatus } = selectedBusinessUser;
        if (twoFacStatus !== "enabled") {
            this.setState({ showTwoFacStatusWarningBanner: true });
        }

        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SELECT_FB_BM, { businessManagerId: businessManagerId }).then();

        // window.FB.api(`${businessManagerId}/owned_product_catalogs`, (response) => {
        //     console.log(response);
        //     // this.setState({linkedProductCatalogId: response.data});
        // });

        this.getBusinessManagerOwnedPages(businessManagerId);
        this.getBusinessManagerOwnedAdAccounts(businessManagerId);
        this.setState({
            selectedBusinessManagerId: businessManagerId,

            // Reset forward selections, hide select components and disable errors.
            showPageSelection: true,
            selectedPageId: "",
            showPageSelectionError: false,

            showAdAccountSelection: false,
            selectedAdAccountId: "",
            showAdAccountSelectionError: false,

            showAdsPixelSelection: false,
            selectedAdsPixelId: "",
        });
        this.scrollDown();
    };

    onChangePageId = (pageId) => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SELECT_FB_PAGE, { pageId: pageId });
        Sentry.addBreadcrumb({
            category: "Select",
            message: "Page id",
            data: { pageId: pageId },
        });
        this.setState({
            selectedPageId: pageId,

            showAdAccountSelection: true,
            showAdsPixelSelection: false,
            showAdAccountPaymentWidget: false,
        });
        this.scrollDown();
    };

    onChangeAdAccountId = (adAccountId) => {
        const { adAccountOptions } = this.state;
        const adAccountDetails = adAccountOptions.filter((adAccount) => adAccount.value === adAccountId)[0];
        this.setState({
            adAccountBlocked: adAccountDetails.accountStatus !== 1,
        });
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SELECT_FB_AD_ACCOUNT, { adAccount: adAccountDetails });

        this.setState({ showAdAccountPaymentWidget: false });
        this.getAdAccountAdsPixels(adAccountId);
        const adAccount = this.state.adAccountOptions.find((element) => element.value === adAccountId);
        const { fundingSource } = adAccount;

        let webCustomAudienceTos = 0;
        try {
            // Sometimes toAccepted can be undefined
            // https://developers.facebook.com/docs/marketing-api/audiences-api/pixel/
            // https://developers.facebook.com/docs/marketing-api/audiences/reference/custom-audience-terms-of-service/
            webCustomAudienceTos = adAccount.tosAccepted.web_custom_audience_tos;
        } catch (e) {
            console.error(e);
        }

        if (!fundingSource) {
            postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ADD_PAYMENT_METHOD_WARNING_SHOWN);
            this.setState({
                selectedAdAccountId: adAccountId,
                paymentMethodAvailable: false,
                showAdAccountPaymentWidget: true,
                showAdsPixelSelection: false,
            });
        } else if (webCustomAudienceTos !== 1) {
            postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ACCEPT_TOS_WARNING_SHOWN);
            this.setState({
                selectedAdAccountId: adAccountId,
                showAcceptTos: true,
                showAdsPixelSelection: false,
            });
        } else {
            this.setState({
                selectedAdAccountId: adAccountId,
                paymentMethodAvailable: true,
                showAdsPixelSelection: true,
            });
        }
        this.scrollDown();
    };

    handleAddPayment = () => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ADD_PAYMENT_METHOD_BUTTON_CLICK);
        Sentry.addBreadcrumb({
            category: "Click",
            message: "User clicked Add Payment",
            data: { selectedAdAccountId: this.state.selectedAdAccountId },
        });
        window.FB.ui({
            account_id: this.state.selectedAdAccountId.split("_")[1],
            display: "popup",
            method: "ads_payment",
        });
    };

    refreshBusinessUsers = () => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ENABLE_TWO_FACTOR_AUTH_REFRESH_LINK_CLICK);
        this.getBusinessUsers();
        this.setState({
            showAdAccountSelection: false,
            selectedBusinessManagerId: "",
            showTwoFacStatusWarningBanner: false,
        });
    };

    refreshAdAccounts = () => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ADD_PAYMENT_OR_TOS_METHOD_REFRESH_LINK_CLICK);
        Sentry.addBreadcrumb({
            category: "Click",
            message: "Refresh ad account payment clicked",
        });
        this.getBusinessManagerOwnedAdAccounts(this.state.selectedBusinessManagerId);
        this.setState({
            showAdAccountSelection: true,
            selectedAdAccountId: "",
            showAdAccountPaymentWidget: false,
            showAcceptTos: false,
        });
    };

    onChangeAdsPixelId = (adsPixelId) => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SELECT_FB_ADS_PIXEL, { adsPixelId: adsPixelId });
        Sentry.addBreadcrumb({
            message: "User selected ads pixel id",
            data: { adsPixelId: adsPixelId },
        });

        this.setState({
            selectedAdsPixelId: adsPixelId,
        });
    };

    getAdAccountDetailed = (adAccountId) => {
        console.log("getAdAccountDetailed");
        const fields = "name,account_status,user_tasks,user_tos_accepted,balance,business_name,currency,owner,users";
        window.FB.api(adAccountId, { fields: fields }, (response) => {
            console.info(response);
            postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_AD_ACCOUNT_DETAILS_RETRIEVED, { response: response });
            Sentry.addBreadcrumb({
                message: "User ad account details",
                data: { response: response },
            });
        });
    };

    assignAdAccount = (adAccountId, systemUserId, businessManagerId) => {
        const data = { adAccountId: adAccountId, systemUserId: systemUserId, businessManagerId: businessManagerId };
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ASSIGN_FB_AD_ACCOUNT_TO_SYSTEM_USER, { data: data });
        Sentry.addBreadcrumb({
            message: "Assigning ad account",
            data: { data: data },
        });
        return new Promise((resolve, reject) => {
            window.FB.api(
                `${adAccountId}/assigned_users`,
                "POST",
                {
                    user: systemUserId,
                    tasks: "MANAGE",
                    business: businessManagerId,
                },
                (response) => {
                    console.log("assignAdAccount", response);
                    if (!response) {
                        this.updateError("Failed to assign ad account: No response");
                        reject();
                    } else if (response.error) {
                        if (response.error.code === 9) {
                            this.getAdAccountDetailed(adAccountId);
                        }
                        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ASSIGN_FB_AD_ACCOUNT_TO_SYSTEM_USER_FAILURE, {
                            error: response.error,
                        });
                        this.handleFacebookApiError(response.error);
                        reject(response.error);
                    } else if (response.success === true) {
                        resolve();
                    } else {
                        this.updateError("Something went wrong");
                        reject(Error("Something went wrong"));
                    }
                }
            );
        });
    };

    validate = async (selectedPageId, selectedAdAccountId, productCatalogId) => {
        Sentry.addBreadcrumb({
            message: "Validating",
        });

        const that = this;
        if (that.state.isError === true) {
            return;
        }

        const data = {
            ad_account_id: selectedAdAccountId,
            page_id: selectedPageId,
            product_catalog_id: productCatalogId,
            user_access_token: window.FB.getAccessToken(),
        };
        fetch("/api/v1/facebook/account/validate/", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("validate", data);
                if (data.result === "success") {
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.APPROVE_ALL_FB_SETTINGS_SUCCESS);
                    that.setState({
                        showAdsPixelSelection: false,
                        showPageSelection: false,
                        showBusinessManagerSelection: false,
                        showAdAccountSelection: false,
                        successMessage: "Account connected successfully!",
                        showCurrentConfiguration: true,
                        approveIsLoading: false,
                    });
                    that.props.updateShowGetStarted(true);
                    that.props.dispatch(fetchSettings());
                    this.setState({
                        showApprove: false,
                    });
                } else {
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.APPROVE_ALL_FB_SETTINGS_FAILURE, { data: data });
                    that.updateError("Error while validating facebook account");
                }
            })
            .catch((error) => {
                postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.APPROVE_ALL_FB_SETTINGS_FAILURE, { error: error });
                that.updateError(error);
            });
    };

    handleToggle = () => {
        this.setState((prevState) => ({
            showAdsPixelSelectionSection: !prevState.showAdsPixelSelectionSection,
        }));
    };

    handleFacebookApiError = (facebookError) => {
        Sentry.addBreadcrumb({
            message: "response.error",
            data: facebookError,
        });
        let errorMessage = "";
        if ("error_user_msg" in facebookError && facebookError.error_user_msg) {
            errorMessage = facebookError.error_user_msg;
        } else if ("message" in facebookError && facebookError.message) {
            errorMessage = facebookError.message;
        } else {
            errorMessage = "Unknown error";
        }

        this.updateError(errorMessage);
    };

    updateError = (errorMessage) => {
        this.handleToastDismiss();
        this.setState(() => ({
            isError: true,
            errorMessage: errorMessage,
        }));
    };

    scrollDown = () => {
        window.scrollBy(0, 300);
    };

    async linkAdsPixel(selectedAdsPixelId) {
        Sentry.addBreadcrumb({
            message: "Linking ads pixel",
        });
        const that = this;
        const data = {
            ads_pixel_id: selectedAdsPixelId,
        };

        try {
            const { result } = await fetch("/api/v1/facebook/account/adspixel/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            }).then((res) => res.json());
            console.log("linkAdsPixel", result);
            if (result === "success") {
                //    All good
            } else {
                that.updateError(data.error);
            }
        } catch (error) {
            this.updateError(error);
        }
    }

    async createSystemUser(businessManagerId) {
        Sentry.addBreadcrumb({
            message: "Creating system user",
        });
        const that = this;
        const data = { business_manager_id: businessManagerId };
        try {
            // eslint-disable-next-line camelcase
            const { result, system_user_id } = await fetch("/api/v1/facebook/account/link/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            }).then((response) => response.json());

            console.log("createSystemUser", result, system_user_id);
            if (result === "success") {
                // eslint-disable-next-line camelcase
                return system_user_id;
            }
            postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_CREATE_FB_SYSTEM_USER_FAILURE, { result: result });
            that.handleFacebookApiError(data.error);
        } catch (error) {
            this.updateError(error.message);
        }
        return "";
    }

    async assignPage(assetId, systemUserId, businessManagerId) {
        const data = { pageId: assetId, systemUserId: systemUserId, businessManagerId: businessManagerId };
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ASSIGN_FB_PAGE_TO_SYSTEM_USER, { data: data });
        Sentry.addBreadcrumb({
            message: "Assigning page",
            data: data,
        });

        console.log("assignPage suid", systemUserId);
        if (this.state.isError === true) {
            return undefined;
        }
        return new Promise((resolve, reject) => {
            window.FB.api(
                `${assetId}/assigned_users`,
                "POST",
                {
                    user: systemUserId,
                    tasks: "MANAGE",
                    business: businessManagerId,
                },
                (response) => {
                    console.log("assignPage", response);
                    if (!response) {
                        this.updateError("Failed to get assign page: No response");
                        reject(Error("error"));
                    } else if (response.error) {
                        this.handleFacebookApiError(response.error);
                        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_ASSIGN_FB_PAGE_TO_SYSTEM_USER_FAILURE, {
                            error: response.error,
                        });
                        reject(response.error);
                    } else if (response.success === true) {
                        this.setState({ linkedPageId: assetId, selectedPageId: assetId });
                        resolve();
                    } else {
                        this.updateError("Something went wrong");
                        reject(Error("Something went wrong"));
                    }
                }
            );
        });
    }

    handleToastDismiss() {
        this.setState(() => ({
            isError: false,
            errorMessage: "",
        }));
    }

    async createProductCatalog(selectedBusinessManagerId) {
        const { shopUrl } = this.props;

        const catalogName = `${shopUrl.replace(".myshopify.com", "")}_catalog_by_sixads`;

        return await this.createCatalog(selectedBusinessManagerId, catalogName);
    }

    async createCatalog(linkedBusinessManagerId, catalogName) {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CATALOG_CREATION_INITIATED);

        let productCatalogId = null;

        return new Promise((resolve, reject) => {
            window.FB.api(
                `${linkedBusinessManagerId}/owned_product_catalogs`,
                "POST",
                {
                    name: catalogName,
                    da_display_settings: {
                        carousel_ad: { transformation_type: "background_padding" },
                        single_ad: { transformation_type: "background_padding" },
                    },
                },
                (response) => {
                    if (response && !response.error) {
                        productCatalogId = response.id;
                        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CATALOG_CREATION_SUCCEED, response);
                        resolve(productCatalogId);
                    } else {
                        this.handleFacebookApiError(response.error);
                        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CATALOG_CREATION_FAILED, response.error);
                        Sentry.captureException(response.error);
                        reject(response.error);
                    }
                }
            );
        });
    }

    async assignCatalog(productCatalogId, selectedBusinessManagerId, systemUserId) {
        const requestPayload = {
            user: systemUserId,
            tasks: "MANAGE",
            business: selectedBusinessManagerId,
        };

        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_USER_CATALOG_ASSIGNMENT_INITIATED, requestPayload);

        return new Promise((resolve, reject) => {
            window.FB.api(`${productCatalogId}/assigned_users`, "POST", requestPayload, (response) => {
                if (response && !response.error) {
                    this.setState({
                        selectedProductCatalogId: productCatalogId,
                    });
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_USER_CATALOG_ASSIGNMENT_SUCCEED);
                    resolve("ok");
                } else {
                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SYSTEM_USER_CATALOG_ASSIGNMENT_FAILED, response.error);
                    Sentry.captureException(response.error);
                    reject(response.error);
                }
            });
        });
    }

    render() {
        const {
            successMessage,
            // Error
            isError,
            errorMessage,
            // Business manager
            showBusinessManagerSelection,
            businessManagerOptions,
            selectedBusinessManagerId,
            linkedBusinessManagerId,
            // Page
            showPageSelection,
            pageOptions,
            selectedPageId,
            linkedPageId,
            // Ad Account
            showAdAccountSelection,
            adAccountOptions,
            selectedAdAccountId,
            adAccountBlocked,
            linkedAdAccountId,
            paymentMethodAvailable,
            // Ads Pixel
            showAdsPixelSelection,
            adsPixelOptions,
            selectedAdsPixelId,
            linkedAdsPixelId,
            showCurrentConfiguration,

            // Errors
            showBusinessManagerSelectionError,
            showPageSelectionError,
            showAdAccountSelectionError,

            showTwoFacStatusWarningBanner,
            facebookBannerOpen,
            showApprove,
        } = this.state;

        const {
            // eslint-disable-next-line no-unused-vars
            sixadsBusinessManagerId,
            sixadsAppId,
            scope,
            hasAccount,
            showHowItWorks,
            disabled,
        } = this.props;
        // This is Web Custom Audience TOS https://www.facebook.com/customaudiences/app/tos/
        // Also available is Custom Audience TOS https://business.facebook.com/ads/manage/customaudiences/tos/
        const tosUrl = `https://www.facebook.com/customaudiences/app/tos/?act=${selectedAdAccountId.split("_")[1]}`;
        const setupUrl = "https://sixads.net/blog/facebook-business-manager-and-ad-account-setup/";
        const businessManagerSelectionError = showBusinessManagerSelectionError ? (
            <span>
                There are no Business Managers associated with this email.
                <br /> Sign in to Facebook with different email or{" "}
                <Link monochrome={true} onClick={() => this.onClickCreateBusinessManager("Link Click")}>
                    create an account
                </Link>
                .
            </span>
        ) : (
            ""
        );
        const pageSelectionError = showPageSelectionError ? (
            <span>
                No Facebook Page?{" "}
                <Link
                    monochrome={true}
                    url={`${setupUrl}#Setting_Up_Facebook_Page`}
                    href="/#"
                    external={true}
                    onClick={() => postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CREATE_FB_PAGE_BUTTON_CLICK)}
                >
                    Learn
                </Link>{" "}
                how to create one.
            </span>
        ) : (
            ""
        );

        const adAccountSelectionError = showAdAccountSelectionError ? (
            <span>
                There are no Ad Accounts associated with this email.{" "}
                <Link monochrome={true} onClick={() => this.onClickCreateAdAccount("Link Click")}>
                    Create an account.
                </Link>
            </span>
        ) : (
            ""
        );

        const showAccountConnectionSuccessBanner =
            (!showBusinessManagerSelection && hasAccount) || successMessage.length > 0;

        return (
            <Fragment>
                <Layout>
                    <Layout.Section>
                        <Card>
                            {facebookBannerOpen && (
                                <SignInFailedBanner
                                    sixadsAppId={sixadsAppId}
                                    scope={scope}
                                    responseFacebook={this.responseFacebook}
                                    handleFacebookError={this.handleFacebookError}
                                    API_VERSION={this.API_VERSION}
                                    onClickFacebookLogin={this.onClickFacebookLogin}
                                    onClose={() => {
                                        this.setState({
                                            facebookBannerOpen: false,
                                        });
                                    }}
                                />
                            )}
                            {showHowItWorks && <ShowHowItWorks />}

                            {showAccountConnectionSuccessBanner && (
                                <Card.Section>
                                    <Banner title="Account has been linked" status="success" />
                                </Card.Section>
                            )}

                            {isError === true && (
                                <Card.Section>
                                    <Banner
                                        title={errorMessage}
                                        status="warning"
                                        onDismiss={() => this.handleToastDismiss()}
                                    />
                                </Card.Section>
                            )}

                            <Card.Section>
                                {sixadsAppId != null && (
                                    <FacebookLogin
                                        appId={sixadsAppId}
                                        autoLoad={false}
                                        scope={scope}
                                        returnScopes={true}
                                        callback={this.responseFacebook}
                                        onFailure={this.handleFacebookError}
                                        version={this.API_VERSION}
                                        onClick={this.onClickFacebookLogin}
                                        disableMobileRedirect={true}
                                        render={(renderProps) => (
                                            <Button
                                                primary={true}
                                                size="large"
                                                loading={this.state.signInIsLoading}
                                                onClick={renderProps.onClick}
                                                xfbml={true}
                                                disabled={hasAccount || disabled}
                                            >
                                                {this.signInWithFacebookText}
                                            </Button>
                                        )}
                                    />
                                )}
                                <TextContainer>
                                    <div />
                                    <TooltipWithIcon
                                        label={<span>Why do we need this?</span>}
                                        text="To start selling connect to your Facebook Ads Manager."
                                        icon="question"
                                        styles={{ color: "grey" }}
                                    />
                                </TextContainer>
                            </Card.Section>
                            {showAccountConnectionSuccessBanner && (
                                <Card.Section>
                                    <TextContainer>
                                        <p>{`To edit your setup click on "${this.signInWithFacebookText}"`}</p>
                                    </TextContainer>
                                </Card.Section>
                            )}
                            {!!(showCurrentConfiguration === true && linkedAdsPixelId != null) && (
                                <Card.Section>
                                    <Card.Section title="Business Manager">
                                        <Select
                                            disabled={true}
                                            placeholder={linkedBusinessManagerId}
                                            helpText="Used to run ads."
                                        />
                                    </Card.Section>
                                    <Card.Section title="Page">
                                        <Select
                                            disabled={true}
                                            placeholder={linkedPageId}
                                            helpText="Used for publishing ads."
                                        />
                                    </Card.Section>
                                    <Card.Section title="Ad Account">
                                        <Select
                                            disabled={true}
                                            placeholder={linkedAdAccountId}
                                            helpText="Used to create & run ads."
                                        />
                                    </Card.Section>
                                    <Card.Section title="Ads Pixel">
                                        <Select
                                            disabled={true}
                                            placeholder={linkedAdsPixelId}
                                            helpText="Used to track the stats & optimize ad delivery."
                                        />
                                    </Card.Section>
                                </Card.Section>
                            )}

                            {showBusinessManagerSelection === true && (
                                <Card.Section title="Business Manager">
                                    <Stack distribution="fillEvenly">
                                        <Select
                                            options={businessManagerOptions}
                                            value={selectedBusinessManagerId}
                                            onChange={this.onChangeBusinessManager}
                                            placeholder="Select a Business Manager"
                                            helpText="Used to run ads."
                                            error={businessManagerSelectionError}
                                        />
                                        <Button
                                            onClick={() => {
                                                postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.CREATE_FB_BM_BUTTON_CLICK);
                                                this.onClickCreateBusinessManager("Button Click");
                                            }}
                                        >
                                            Create Business Manager
                                        </Button>
                                        {this.state.showCreateBusinessManagerModal && (
                                            <CreateBusinessManagerModal
                                                showCreateBusinessManagerModal={
                                                    this.state.showCreateBusinessManagerModal
                                                }
                                                hideCreateBusinessManager={() =>
                                                    this.setState({ showCreateBusinessManagerModal: false })
                                                }
                                                shopUrl={this.props.shopUrl}
                                                getBusinessManagers={this.getBusinessManagers}
                                                getBusinessUsers={this.getBusinessUsers}
                                                email={this.state.email}
                                                onCreationSuccess={() => {
                                                    this.setState({ showBusinessManagerSelectionError: false });
                                                }}
                                            />
                                        )}
                                    </Stack>

                                    {showTwoFacStatusWarningBanner && (
                                        <TwoFactorAuthenticationWarningBanner
                                            onSecondaryAction={this.refreshBusinessUsers}
                                        />
                                    )}
                                </Card.Section>
                            )}
                            {showPageSelection === true && (
                                <Card.Section title="Page">
                                    <Stack distribution="fillEvenly">
                                        <Select
                                            // label="Pixel"
                                            options={pageOptions}
                                            value={selectedPageId}
                                            onChange={this.onChangePageId}
                                            placeholder="Choose a Page"
                                            helpText="Used for publishing ads."
                                            error={pageSelectionError}
                                        />
                                        <Button
                                            url={`${setupUrl}#Setting_Up_Facebook_Page`}
                                            external={true}
                                            onClick={this.onClickCreatePage}
                                        >
                                            Create Facebook Page
                                        </Button>
                                    </Stack>
                                </Card.Section>
                            )}
                            {showAdAccountSelection === true && (
                                <Card.Section title="Ad Account">
                                    <Stack distribution="fillEvenly">
                                        <Select
                                            options={adAccountOptions}
                                            value={selectedAdAccountId}
                                            onChange={this.onChangeAdAccountId}
                                            placeholder="Select an Ad Account"
                                            helpText="Used to create & run ads."
                                            error={adAccountSelectionError}
                                        />
                                        {
                                            <Button onClick={() => this.onClickCreateAdAccount("Button Click")}>
                                                Create Ad Account
                                            </Button>
                                        }
                                        {this.state.showCreateAdAccountModal && (
                                            <CreateAdAccountModal
                                                businessManagerId={this.state.selectedBusinessManagerId}
                                                showCreateAdAccountModal={this.state.showCreateAdAccountModal}
                                                hideCreateAdAccountModal={() =>
                                                    this.setState({ showCreateAdAccountModal: false })
                                                }
                                                getBusinessManagerOwnedAdAccounts={
                                                    this.getBusinessManagerOwnedAdAccounts
                                                }
                                                onCreationSuccess={() => {
                                                    this.setState({ showAdAccountSelectionError: false });
                                                }}
                                                shopUrl={this.props.shopUrl}
                                            />
                                        )}
                                    </Stack>

                                    {adAccountBlocked && (
                                        <Card.Subsection>
                                            <Banner
                                                title="Chosen ad account is blocked. Please choose a different account."
                                                status="critical"
                                            />
                                        </Card.Subsection>
                                    )}
                                    {!adAccountBlocked && this.state.showAcceptTos && (
                                        <Banner
                                            title="Please accept Facebook Custom Audiences Terms in order to proceed."
                                            status="critical"
                                            action={{
                                                content: "Read the terms",
                                                url: tosUrl,
                                                external: true,
                                                onAction: () => {
                                                    postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ACCEPT_TOS_BUTTON_CLICK);
                                                },
                                            }}
                                            secondaryAction={{
                                                content: "Click here to refresh after accepting the terms",
                                                onAction: () => {
                                                    this.refreshAdAccounts();
                                                },
                                            }}
                                            primaryAction={{ content: "New" }}
                                        />
                                    )}
                                    {!adAccountBlocked && this.state.showAdAccountPaymentWidget && (
                                        <Card.Subsection>
                                            <Banner
                                                title="Add a payment method in order to proceed."
                                                status="critical"
                                                action={{
                                                    content: "Enter payment info on Facebook",
                                                    onAction: () => {
                                                        this.handleAddPayment();
                                                    },
                                                }}
                                                secondaryAction={{
                                                    content: "Click here to refresh after adding a payment method",
                                                    onAction: () => {
                                                        this.refreshAdAccounts();
                                                    },
                                                }}
                                            />
                                        </Card.Subsection>
                                    )}
                                </Card.Section>
                            )}
                            {showAdsPixelSelection === true && (
                                <Card.Section title="Ads Pixel">
                                    <Stack vertical={true}>
                                        <Select
                                            label="Ads Pixel"
                                            options={adsPixelOptions}
                                            value={selectedAdsPixelId}
                                            onChange={this.onChangeAdsPixelId}
                                            placeholder="Choose Ads Pixel"
                                            helpText="Used to track the stats & optimize ad delivery."
                                        />
                                    </Stack>
                                </Card.Section>
                            )}
                            {showApprove && paymentMethodAvailable && (
                                <Card.Section>
                                    <Button
                                        onClick={this.onClickApprove}
                                        loading={this.state.approveIsLoading}
                                        disabled={selectedAdsPixelId.length === 0}
                                    >
                                        Approve
                                    </Button>
                                </Card.Section>
                            )}

                            {showAccountConnectionSuccessBanner && (
                                <Card.Section>
                                    <Banner
                                        title="You must accept Facebook's non-discrimination policy before running ads"
                                        status="warning"
                                    >
                                        <p>
                                            You must review and accept Facebook's non-discrimination policy before
                                            running ads. It can be found by going to Business Settings, then System
                                            Users. For more details check the{" "}
                                            <Link
                                                url="https://www.facebook.com/business/help/338925176776440"
                                                external={true}
                                            >
                                                Help Center Link
                                            </Link>{" "}
                                            <span className="italic">(Ignore if already accepted.)</span>
                                        </p>
                                    </Banner>
                                </Card.Section>
                            )}
                        </Card>
                    </Layout.Section>
                </Layout>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, store } = state;

    const hasAccount = !!(
        settings.facebook.business_manager_id &&
        settings.facebook.page_id &&
        settings.facebook.ad_account_id &&
        settings.facebook.system_user_id
    );

    return {
        // Clients info
        linkedBusinessManagerId: settings.facebook.business_manager_id,
        linkedPageId: settings.facebook.page_id,
        linkedAdAccountId: settings.facebook.ad_account_id,
        linkedAdsPixelId: settings.facebook.ads_pixel_id,
        systemUserId: settings.facebook.system_user_id,
        // Sixads
        sixadsBusinessManagerId: settings.facebook.sixads_business_manager_id,
        sixadsAppId: settings.facebook.sixads_app_id,
        scope: settings.facebook.scope,
        hasAccount: hasAccount,
        store: store,
        shopUrl: store.shop_url,
    };
}

export default withRouter(connect(mapStateToProps)(FacebookUserSignInComponent));
