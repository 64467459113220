import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = (props) => {
    const defaultHtml = '<p><span style="color: rgb(204,204,204);font-size: 36px;"><strong>50% OFF</strong></span></p>';
    const contentBlock = htmlToDraft(defaultHtml);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [text, setText] = useState(EditorState.createWithContent(contentState));

    const onEditorStateChange = (editorState) => {
        const rawText = convertToRaw(editorState.getCurrentContent());
        const htmlText = draftToHtml(rawText);

        setText(editorState);
        props.onChange(htmlText);
    };

    useEffect(() => {
        props.onChange(defaultHtml);
    }, []);

    return (
        <Editor
            editorState={text}
            onEditorStateChange={onEditorStateChange}
            toolbarClassName="demo-toolbar-custom"
            wrapperClassName="border-1 border-yellow-400 divide-solid bg-white"
            editorClassName="demo-editor-custom"
            toolbar={{
                options: ["inline", "fontSize", "fontFamily", "colorPicker", "emoji"],
                inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic", "underline", "strikethrough", "superscript", "subscript"],
                },
            }}
        />
    );
};

export default TextEditor;
