import * as Sentry from "@sentry/browser";

const apiError = {
    error: true,
    status: null,
    response: null,
    sentryEvent: null,
};

/* eslint-disable prefer-promise-reject-errors */
export const api = (url, options = {}) => {
    /*
    Function for calling api requests
    usage:
     api("url", {data: {param: "value"}, method: "PUT"}).then(result => {
      console.log(result);
    }).catch((status, err) => {
      console.log(status, err);
    });
     */
    if (!options.headers) {
        options.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }
    if (options.data) {
        options.body = JSON.stringify(options.data);
        delete options.data;
        if (!options.method) options.method = "POST";
    }

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                return response
                    .json()
                    .then((responseBody) => {
                        if (response.ok) {
                            return resolve(responseBody);
                        }
                        return reject({
                            ...apiError,
                            status: response.status,
                            response: responseBody,
                        });
                    })
                    .catch(() => {
                        return reject({
                            ...apiError,
                            status: response.status,
                            response: response.statusText,
                        });
                    });
            })
            .catch((error) => {
                // errors from the client.
                // e.g TypeError: "Failed to fetch"
                const eventId = Sentry.captureException(error);
                return reject({
                    ...apiError,
                    response: error.message,
                    sentryEvent: eventId,
                });
            });
    });
};
