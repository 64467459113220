/* eslint-disable import/named */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import GoogleSettingPanelButton from "../../../common/components/GoogleSettingPanelButton";
import { loadGoogleAdsAccountBilling } from "../../../actions";

const BillingLayout = ({ body, footer }) => (
    <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
            <img
                className="p-1 m-2"
                src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/dd881a477807b6788dc697a5bbc62e40.svg"
                height={40}
                width={40}
                alt="Google Ads Account"
            />
            {body}
        </div>
        <div>{footer}</div>
    </div>
);

const BillingAlert = ({ onClose }) => (
    <Alert severity="warning" onClose={onClose}>
        <AlertTitle>Make sure your billing settings are always in sync!</AlertTitle>
        billing settings are important for setting up your smart shopping campaigns.
        <strong>Make sure they are always in sync with your google ads account!</strong>
    </Alert>
);

const BillingSetUp = () => (
    <BillingLayout
        body={
            <div className="flex flex-col">
                <p className="font-bold">GOOGLE ADS ACCOUNT BILLING SETTINGS</p>
                <div>
                    <span className="text-green-600">Everything looks good!</span> <br />
                </div>
            </div>
        }
    />
);

const BillingNeedsToBeSetUp = ({ setVersion }) => {
    const billingUrl = "https://ads.google.com/aw/billing/summary";

    return (
        <BillingLayout
            body={
                <div className="flex flex-col">
                    <p className="font-bold">GOOGLE ADS ACCOUNT BILLING SETTINGS</p>
                    <div className="flex flex-row">
                        <a className="inline text-blue-700" href={billingUrl} target="_blank" rel="noreferrer">
                            Please go to your google ads account
                        </a>{" "}
                        &nbsp; and add billing settings. Reload settings once you have added this.
                    </div>
                </div>
            }
            footer={
                <GoogleSettingPanelButton buttonType="REFRESH" onClick={setVersion}>
                    Reload settings
                </GoogleSettingPanelButton>
            }
        />
    );
};

const GoogleAdsAccountBillingSettings = () => {
    const dispatch = useDispatch();
    const adsAccountBilling = useSelector((state) => state.googleShopping.adsAccountBilling);
    const isAdsAccountLoading = useSelector((state) => state.googleShopping.isAdsAccountLoading);
    const isAdsAccountBillingLoading = useSelector((state) => state.googleShopping.isAdsAccountBillingLoading);

    useEffect(() => {
        dispatch(loadGoogleAdsAccountBilling());
    }, []);

    const [alertShown, setAlertShown] = useState(true);
    const isBillingSetUp = adsAccountBilling && adsAccountBilling.status === "APPROVED";
    const shouldShowTaxAlert = alertShown && isBillingSetUp;

    return (
        <SettingsLayout
            header="Your Google Ads Account Billing Settings"
            subtitle="Make sure your billing settings are in sync with google ads account."
            loading={isAdsAccountLoading || isAdsAccountBillingLoading}
        >
            {isBillingSetUp && <BillingSetUp />}
            {!isBillingSetUp && <BillingNeedsToBeSetUp setVersion={() => dispatch(loadGoogleAdsAccountBilling())} />}
            {shouldShowTaxAlert && <BillingAlert onClose={() => setAlertShown(false)} />}
        </SettingsLayout>
    );
};

export default GoogleAdsAccountBillingSettings;
