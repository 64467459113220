import { api } from "../utils";
import { toggleToastPopup } from "./action-toast";

export const ACTIONS = {
    TOGGLE_PRODUCT_SELECTION: "imageOverlayAds/TOGGLE_PRODUCT_SELECTION",

    GET_SHOPIFY_PRODUCT_REQUESTED: "imageOverlayAds/GET_SHOPIFY_PRODUCT_REQUESTED",
    GET_SHOPIFY_PRODUCT_SUCCEED: "imageOverlayAds/GET_SHOPIFY_PRODUCT_SUCCEED",
    GET_SHOPIFY_PRODUCT_FAILED: "imageOverlayAds/GET_SHOPIFY_PRODUCT_FAILED",

    CLEAR_SHOPIFY_PRODUCTS: "imageOverlayAds/CLEAR_SHOPIFY_PRODUCTS",

    SEARCH_SHOPIFY_PRODUCTS: "imageOverlayAds/SEARCH_SHOPIFY_PRODUCTS",
    FILTER_PRODUCTS_SELECTION: "imageOverlayAds/FILTER_PRODUCTS_SELECTION",

    GET_RENDERED_IMAGES_REQUESTED: "imageOverlayAds/GET_RENDERED_IMAGES_REQUESTED",
    GET_RENDERED_IMAGES_SUCCEED: "imageOverlayAds/GET_RENDERED_IMAGES_SUCCEED",
    GET_RENDERED_IMAGES_FAILED: "imageOverlayAds/GET_RENDERED_IMAGES_FAILED",

    RESET_STATE: "imageOverlayAds/RESET_STATE",
    SET_TEMPLATE_SELECTION: "imageOverlayAds/SET_TEMPLATE_SELECTION",
};

export const getRenderedTemplatesRequested = () => {
    return {
        type: ACTIONS.GET_RENDERED_IMAGES_REQUESTED,
        payload: {},
    };
};

export const getRenderedTemplatesSucceed = (images) => {
    return {
        type: ACTIONS.GET_RENDERED_IMAGES_SUCCEED,
        payload: {
            images: images,
        },
    };
};

export const getRenderedTemplatesFailed = () => {
    return {
        type: ACTIONS.GET_RENDERED_IMAGES_FAILED,
        payload: {},
    };
};

export const getRenderedTemplates = (selectedProduct) => (dispatch) => {
    dispatch(getRenderedTemplatesRequested());

    api(`/api/v1/image-overlay-ad/?product=${selectedProduct}`)
        .then((response) => {
            dispatch(getRenderedTemplatesSucceed(response));
        })
        .catch((error) => {
            try {
                dispatch(toggleToastPopup([error.response.detail], "error"));
            } catch {
                dispatch(toggleToastPopup(["Image loading failed."], "error"));
            }

            dispatch(getRenderedTemplatesFailed());
        });
};

export const setTemplateSelection = (
    templateLayout,
    top,
    right,
    bottom,
    left,
    originalImageWidth,
    currentImageWidth
) => {
    return {
        type: ACTIONS.SET_TEMPLATE_SELECTION,
        payload: {
            templateLayout,
            top,
            right,
            bottom,
            left,
            originalImageWidth,
            currentImageWidth,
        },
    };
};

export const resetState = () => {
    return {
        type: ACTIONS.RESET_STATE,
        payload: {},
    };
};
