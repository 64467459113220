import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { toPng } from "html-to-image";
import { Card, RangeSlider, Button, Modal } from "@shopify/polaris";

import { logAnalytics } from "../../../actions";
import SideSelector from "./SideSelector";
import ImageView from "./ImageView";
import ColorPicker from "./ColorPicker";
import TextEditor from "./TextEditor";
import Loader from "../../../common/components/sixads/loader/loader";

const CreateAd = (props) => {
    const { dispatch, selectedTemplate } = props;
    const history = useHistory();
    const [tagColor, setTagColor] = useState({
        r: "105",
        g: "105",
        b: "105",
        a: "1",
    });
    const [tagText, setTagText] = useState("");
    const [tagTextCopy, setTagTextCopy] = useState("");
    const [textMargin, setTextMargin] = useState(5);
    const [tagSize, setTagSize] = useState();
    const [tagSizeCopy, setTagSizeCopy] = useState();
    const [resizeCoefficient, setResizeCoefficient] = useState(1);
    const [modalActive, setModalActive] = useState(false);
    const ref = useRef(null);

    const resizeText = (htmlString, multiplier) => {
        let target = htmlString;
        let finalText = "";
        while (true) {
            const dividedText1 = target.split("px");
            if (dividedText1.length === 1) {
                setTagTextCopy(`${finalText}${dividedText1[0]}`);
                break;
            } else {
                const dividedText2 = dividedText1[0].split("font-size: ");
                const fontSize = dividedText2[1] * multiplier;
                finalText = `${finalText}${dividedText2[0]}font-size: ${Math.ceil(fontSize)}px`;
                target = target.replace(`${dividedText1[0]}px`, "");
            }
        }
    };

    useEffect(() => {
        setTagSizeCopy(tagSize * resizeCoefficient);
        resizeText(tagText, resizeCoefficient);
    }, [resizeCoefficient]);

    useEffect(() => {
        setTagSize(selectedTemplate.originalImageWidth / 3);
        setTagSizeCopy(selectedTemplate.currentImageWidth / 3);
    }, [selectedTemplate.originalImageWidth]);

    const handleColorChange = (color) => {
        setTagColor(color);
    };

    const handleTextChange = (text) => {
        setTagText(text);
        resizeText(text, resizeCoefficient);
    };

    const handleTextMarginChange = (value) => setTextMargin(value);

    const handleTagSizeChange = (value) => {
        setTagSize(value);
        setTagSizeCopy(value * resizeCoefficient);
    };

    const createImageName = () => {
        const template =
            selectedTemplate.templateLayout === 1
                ? "triangle-corner"
                : selectedTemplate.templateLayout === 2
                ? "circle-corner"
                : selectedTemplate.templateLayout === 3
                ? "rectangle-corner"
                : selectedTemplate.templateLayout === 4
                ? "square-border"
                : "";
        let direction = "";
        direction = selectedTemplate.top ? "top-" : selectedTemplate.bottom ? "bottom-" : "";
        direction = `${direction}${selectedTemplate.left ? "left" : selectedTemplate.right ? "right" : ""}`;
        const imageName = selectedTemplate.templateLayout !== 4 ? `${template}-${direction}` : template;
        return imageName;
    };

    const handleRoute = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Image ad creator flow",
                eventAction: `Button click ${createImageName()}`,
                eventLabel: "Download image",
            })
        );

        history.push("/ad-creative/image-ad-creator/thank-you/");
    };

    const onDownloadClick = () => {
        setModalActive(true);

        setTimeout(function () {
            if (ref.current === null) {
                console.log("no ref found");
                return;
            }
            toPng(ref.current, { cacheBust: true })
                .then((dataUrl) => {
                    const link = document.createElement("a");
                    link.download = "sixads_image_ad.png";
                    link.href = dataUrl;
                    link.click();
                    setModalActive(false);
                    handleRoute();
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 1000);
    };

    const ModalDownload = () => (
        <Modal open={modalActive} title="Downloading ..." large={true}>
            <Modal.Section>
                <div className="flex flex-shrink-0 items-center justify-center w-full">
                    <div ref={ref}>
                        <ImageView
                            template={selectedTemplate.templateLayout}
                            top={selectedTemplate.top}
                            right={selectedTemplate.right}
                            bottom={selectedTemplate.bottom}
                            left={selectedTemplate.left}
                            textMargin={textMargin}
                            tagText={tagText}
                            tagSize={tagSize}
                            tagColor={tagColor}
                            resizable={false}
                            setResizeCoefficient={setResizeCoefficient}
                        />
                    </div>
                </div>
            </Modal.Section>
        </Modal>
    );

    const ModalSpinner = () => (
        <Modal open={modalActive} title="Downloading ..." large={true}>
            <Modal.Section>
                <div className="flex flex-shrink-0 items-start justify-center w-full h-screen overflow-hidden pt-20">
                    <Loader />
                </div>
            </Modal.Section>
        </Modal>
    );

    return (
        <div className="pt-24 md:pt-0 flex h-screen items-start md:items-center justify-start md:justify-center">
            <div className="w-full lg:w-5/6 xl:w-2/3 edit-card-view">
                <Card>
                    <div className="grid md:grid-cols-2 image-edit-view">
                        <div className="flex items-center justify-center" style={{ backgroundColor: "#E0EBF6" }}>
                            <div className="lg:w-2/3 sm:1/2 px-6 py-10">
                                <ImageView
                                    template={selectedTemplate.templateLayout}
                                    top={selectedTemplate.top}
                                    right={selectedTemplate.right}
                                    bottom={selectedTemplate.bottom}
                                    left={selectedTemplate.left}
                                    textMargin={textMargin}
                                    tagText={tagTextCopy}
                                    tagSize={tagSizeCopy}
                                    tagColor={tagColor}
                                    resizable={true}
                                    setResizeCoefficient={setResizeCoefficient}
                                />
                            </div>
                        </div>
                        <div className="p-10 flex justify-center bg-white w-screen md:w-full overflow-auto">
                            <div>
                                <div className="flex-col mt-10 ml-5 mr-5">
                                    <div>
                                        <h1 className="page-title mb-5">Finish editing your ad</h1>
                                        <p className="welcome-description">
                                            Choose overlay color, size & position, add text & get ready to sell!
                                        </p>
                                    </div>
                                </div>

                                <div className="flex pt-20">
                                    <div className="text-16px font-semibold font-sf-display text-gray-700 leading-140p pl-5 pr-5">
                                        Select overlay color
                                    </div>
                                    <ColorPicker onChange={handleColorChange} />
                                </div>

                                {selectedTemplate.templateLayout !== 4 && (
                                    <div className="flex-col mt-10 ml-5 mr-5">
                                        <div className="text-16px font-semibold font-sf-display text-gray-700 leading-140p mb-5">
                                            Motivate shoppers to buy with a special offer
                                        </div>
                                        <TextEditor onChange={handleTextChange} />
                                    </div>
                                )}

                                {selectedTemplate.templateLayout === 1 && (
                                    <div className="flex-col mt-10 ml-5 mr-5">
                                        <div className="text-16px font-semibold font-sf-display text-gray-700 leading-140p mb-5">
                                            Text position
                                        </div>
                                        <Card sectioned={true}>
                                            <RangeSlider
                                                value={textMargin}
                                                onChange={handleTextMarginChange}
                                                min={0}
                                                max={20}
                                            />
                                        </Card>
                                    </div>
                                )}

                                <div className="flex-col mt-10 ml-5 mr-5">
                                    <div className="text-16px font-semibold font-sf-display text-gray-700 leading-140p mb-5">
                                        Overlay Size
                                    </div>
                                    <Card sectioned={true}>
                                        <RangeSlider
                                            value={tagSize}
                                            onChange={handleTagSizeChange}
                                            min={selectedTemplate.originalImageWidth / 5}
                                            max={selectedTemplate.originalImageWidth}
                                        />
                                    </Card>
                                </div>

                                {selectedTemplate.templateLayout !== 4 && <SideSelector />}

                                <div className="flex-col mt-16 ml-5 mr-5 pb-10">
                                    <Button class="text-16px" primary={true} onClick={onDownloadClick}>
                                        Download image
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <ModalDownload />
            <ModalSpinner />
        </div>
    );
};

const mapStateToProps = (state) => {
    const {
        imageOverlayAds: { selectedTemplate },
    } = state;

    return {
        selectedTemplate,
    };
};

CreateAd.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(CreateAd);
