import React from "react";
import PropTypes from "prop-types";

import { Select as PolarisSelect } from "@shopify/polaris";

const Select = (props) => {
    const { label, options, selected, onChange, error, placeholder, otherClasses, id, ...restProps } = props;

    return (
        <div className={otherClasses}>
            <PolarisSelect
                label={
                    <label htmlFor={id} className="text-gray-700 font-bold leading-10">{label}</label>
                }
                options={options}
                onChange={onChange}
                // TODO: Why we remap values?
                value={selected}
                error={error}
                placeholder={placeholder}
                id={id}
                {...restProps}
            />
        </div>
    );
};

Select.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    selected: PropTypes.string,
    otherClasses: PropTypes.string,
    id: PropTypes.string,
};

Select.defaultProps = {
    onChange: () => "",
    selected: "",
    otherClasses: '',
    id: "",
};

export default Select;
