import { FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_FAILURE, FETCH_CATEGORIES_SUCCESS } from "../actions";

const initial = {
    isLoading: true,
    isError: false,
    counter: 0,
    items: [],
};

export const categories = (state = initial, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_CATEGORIES_FAILURE: {
            const counter = state.counter + 1;

            return {
                ...state,
                isLoading: false,
                isError: true,
                counter: counter,
            };
        }

        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                counter: 0,
                items: Array.isArray(action.payload.items) ? action.payload.items : [],
            };

        default:
            return state;
    }
};
