import {
    INIT_UNINSTALL,
    EXIT_UNINSTALL,
    COMPLETE_UNINSTALL,
    POSTPONE_UNINSTALL,
    // REDIRECT_UNINSTALL,
    SET_UNINSTALL_MONEY,
    SET_UNINSTALL_ACTION,
    SET_UNINSTALL_OPTION,
} from "../actions";

const initial = {
    isActive: true,
    isRedirecting: false,
    step: 1,
    action: "",
    option: "",
    reason: "",
    comment: "",
};

export const uninstall = (state = initial, action) => {
    switch (action.type) {
        case INIT_UNINSTALL:
            return {
                ...state,
                step: 1,
                action: "",
                option: "",
                reason: "",
                comment: "",
                isActive: true,
                isRedirecting: false,
            };
        case EXIT_UNINSTALL:
            return {
                ...state,
                isActive: false,
            };
        case SET_UNINSTALL_ACTION:
            return {
                ...state,
                step: 2,
                action: action.action,
            };
        case SET_UNINSTALL_OPTION:
            return {
                ...state,
                step: 3,
                option: action.option,
            };
        case POSTPONE_UNINSTALL:
            return {
                ...state,
                step: 4,
                reason: action.reason,
            };

        case COMPLETE_UNINSTALL:
            return {
                ...state,
                step: 4,
                reason: action.reason,
                isRedirecting: true,
            };
        case SET_UNINSTALL_MONEY:
            return {
                ...state,
                step: 4,
                option: "0",
                // isRedirecting: true
            };
        // case REDIRECT_UNINSTALL:
        //     return {
        //         ...state,
        //         isRedirecting: true
        //     }
        default:
            return state;
    }
};
