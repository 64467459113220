import React, { Component } from "react";
import PropTypes from "prop-types";

import TooltipWithIcon from "./TooltipWithIcon";

class InputLabelWithTooltip extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        tooltipIcon: PropTypes.string.isRequired,
        tooltipText: PropTypes.string.isRequired,
        margins: PropTypes.object,
        darkBold: PropTypes.bool,
        position: PropTypes.string,
    };

    static defaultProps = {
        margins: {},
        darkBold: false,
        position: "mostSpace",
    };

    getStyles = () => {
        const { margins, darkBold } = this.props;

        const styles = {};

        if (margins) {
            if (margins.top) {
                styles.marginTop = margins.top;
            }
            if (margins.bottom) {
                styles.marginBottom = margins.bottom;
            }
            if (margins.left) {
                styles.marginLeft = margins.left;
            }
            if (margins.right) {
                styles.marginRight = margins.right;
            }
        }

        if (darkBold) {
            styles.color = "#637381";
            styles.fontWeight = "bold";
            styles.marginBottom = "0.5rem";
        }

        return styles;
    };

    render() {
        const { label, tooltipIcon, tooltipText, position } = this.props;

        return (
            <TooltipWithIcon
                label={label}
                styles={this.getStyles()}
                text={tooltipText}
                icon={tooltipIcon}
                position={position}
            />
        );
    }
}

export default InputLabelWithTooltip;
