import React from "react";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import classNames from "classnames";

const SubMenus = (props) => {
    const { menu, menuCollapse } = props;
    return (
        <Collapse in={!menuCollapse}>
            <ul className="submenu-holder flex flex-col px-20 text-gray-300 font-sf-text cursor-pointer">
                {menu.children.map((submenu) => {
                    const pathUrl = `${window.location.pathname}${window.location.search}`;
                    const submenuClasses = classNames("pt-3 nav-underlined", {
                        [["nav-selected"]]: submenu.url === pathUrl,
                    });
                    return (
                        <a className={submenuClasses} href={submenu.url} key={submenu.name}>
                            <li>{submenu.name}</li>
                        </a>
                    );
                })}
            </ul>
        </Collapse>
    );
};

SubMenus.propTypes = {
    // menu: PropTypes.array.isRequired,  // TODO: Enable proptypes validation. Disabling for now to reduce console errors.
    menuCollapse: PropTypes.bool.isRequired,
};

export default SubMenus;
