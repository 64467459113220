import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Select } from "@shopify/polaris";
import SettingsLayout from "./SettingsLayout";
import SixadsButton from "../../../common/components/button";
import { fetchCountryList, targetingSaved } from "../../../actions";
import { SaveTargetingRequest } from "../../../api/api-google-shopping";

const ProductFeed = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [targeting, setTargeting] = useState({
        country_code: null,
        language_code: null,
        country: null,
        language: null,
    });
    const [targetingCountryList, setTargetingCountryList] = useState([]);
    const [targetingLanguageList, setTargetingLanguageList] = useState([]);
    const [targetingModalShow, setTargetingModalShow] = useState(false);
    const coreAccountCountryCode = useSelector((state) => state.googleShopping.coreAccount.targeted_country_code);
    const coreAccountLanguageCode = useSelector((state) => state.googleShopping.coreAccount.targeted_language_code);


    const currency = useSelector((state) => state.store.currency);
    const countryList = useSelector((state) => state.googleShopping.countryList);

    const isCoreAccountLoading = useSelector((state) => state.googleShopping.isCoreAccountLoading);
    const isStoreRequirementsLoading = useSelector((state) => state.googleShopping.isStoreRequirementsLoading);
    const isCountryListLoading = useSelector((state) => state.googleShopping.isCountryListLoading);
    const merchantAccount = useSelector((state) => state.googleShopping.coreAccount.google_merchant_account_id);

    useEffect(() => {
        dispatch(fetchCountryList());
    }, []);

    useEffect(() => {
        if (countryList.length) {
            const countries = countryList.map((country) => ({
                label: country.name_display,
                value: country.iso,
            }));
            setTargetingCountryList(countries);
        }
    }, [countryList]);

    useEffect(() => {
        if (countryList.length) {
            const country = countryList.find((item) => item.iso === targeting.country_code);
            if (country) {
                const languages = country.languages.map((language) => ({
                    label: language.name,
                    value: language.iso,
                }));
                setTargetingLanguageList(languages);
            }
        }
    }, [targeting.country_code]);

    const closeModal = () => {
        setTargeting({
            country_code: "",
            language_code: "",
            country: "",
            language: "",
        });

        setTargetingModalShow(false);
    };

    const getCountryByCountryCode = (countryCode) => {
        if (!countryCode) return null;

        return countryList.find((country) => {
            return country.iso === countryCode;
        });
    };

    const getLanguagesByCountryCode = (countryCode) => {
        if (!countryCode) return [];

        const country = countryList.find((country) => {
            return country.iso === countryCode;
        });

        if (!country) return [];

        return country.languages;
    };

    const getLanguageByCountryAndLanguageCode = (countryCode, languageCode) => {
        const country = getCountryByCountryCode(countryCode);

        if (!country) return null;

        return country.languages.find((language) => {
            return language.iso === languageCode;
        });
    };

    const saveTargeting = async () => {
        setLoading(true);
        await SaveTargetingRequest(targeting.country_code, targeting.language_code);
        dispatch(targetingSaved(targeting));
        setLoading(false);
        setTargetingModalShow(false);
    };

    const CheckIcon = () => (
        <div className="px-4">
            <CheckCircleIcon fontSize="large" sx={{ color: "green" }} />
        </div>
    );

    const TargetingInfo = () => {
        const country = getCountryByCountryCode(coreAccountCountryCode);
        const language = getLanguageByCountryAndLanguageCode(coreAccountCountryCode, coreAccountLanguageCode);

        return (
            <div className="pt-2">
                <div className="welcome-description">{`Country : ${country.name_display || ""}`}</div>
                <div className="welcome-description">{`Language : ${language.name || ""}`}</div>
            </div>
        );
    };

    return (
        <SettingsLayout
            header="Product feed settings"
            subtitle="Target Market will be synced along with your products to your Google Merchant Center"
            disabled={isCoreAccountLoading || isStoreRequirementsLoading}
            loading={isCoreAccountLoading || isStoreRequirementsLoading || isCountryListLoading}
        >
            <Modal
                open={targetingModalShow}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex justify-center items-center h-full w-full overflow-auto">
                    <div className="w-4/5 md:w-1/3 shadow-2xl bg-white overflow-auto">
                        <div>
                            <div className="flex justify-between items-center px-10 py-5">
                                <div className="text-4xl font-semibold w-3/4">Manage target country and language</div>
                                <IconButton
                                    color="default"
                                    size="large"
                                    aria-label="close modal"
                                    component="span"
                                    onClick={closeModal}
                                >
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </div>
                            <hr />
                        </div>

                        <div>
                            <div className="welcome-description px-10 pt-8">
                                Target country and language options determine where your shopping ads will appear and
                                are based on your store currency: <b>{currency}</b>
                            </div>
                            <div className="px-10 pt-8">
                                <Select
                                    label="Target country"
                                    options={targetingCountryList}
                                    value={targeting.country_code}
                                    onChange={(countryCode) => {
                                        const country = getCountryByCountryCode(countryCode);
                                        const languages = getLanguagesByCountryCode(countryCode);

                                        setTargeting({
                                            country_code: country.iso,
                                            language_code: languages[0].iso,
                                            country: country.name_display,
                                            language: languages[0].name,
                                        });
                                    }}
                                />
                                <span className="text-gray-700 text-14px">
                                    Available options are based on your currency settings.
                                </span>
                            </div>
                            <div className="px-10 py-8">
                                <Select
                                    label="Target language"
                                    options={targetingLanguageList}
                                    value={targeting.language_code}
                                    onChange={(languageCode) => {
                                        const language = getLanguageByCountryAndLanguageCode(targeting.country_code, languageCode);

                                        setTargeting((prevState) => {
                                            return {
                                                country_code: prevState.country_code,
                                                language_code: languageCode,
                                                country: prevState.country,
                                                language: language.name,
                                            };
                                        });
                                    }}
                                />
                                <span className="text-gray-700 text-14px">
                                    Available options are based on your target country supported by Google.
                                </span>
                            </div>
                            <hr />
                            <div className="px-10 py-6 flex flex-row-reverse">
                                <div className="pl-2">
                                    <SixadsButton
                                        loading={loading}
                                        primary={true}
                                        disabled={
                                            coreAccountCountryCode === targeting.country_code &&
                                            coreAccountLanguageCode === targeting.language_code
                                        }
                                        onClick={saveTargeting}
                                    >
                                        Save
                                    </SixadsButton>
                                </div>
                                <div className="pr-2">
                                    <SixadsButton onClick={closeModal}>Cancel</SixadsButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                    <div className="font-semibold">Target country and language</div>
                    {coreAccountCountryCode && coreAccountLanguageCode ? <TargetingInfo /> : ""}
                </div>
                <div className="flex flex-row items-center">
                    {coreAccountCountryCode && coreAccountLanguageCode ? <CheckIcon /> : ""}
                    <Button
                        disabled={!!merchantAccount}
                        color="success"
                        size="large"
                        variant={targeting.country_code && targeting.language_code ? "outlined" : "contained"}
                        onClick={() => {
                            const isCountryAndLanguageExists = countryList.length && countryList[0].languages.length;
                            let country_code = isCountryAndLanguageExists ? countryList[0].iso : "";
                            let language_code = isCountryAndLanguageExists ? countryList[0].languages[0].iso : "";
                            let country = isCountryAndLanguageExists ? countryList[0].name_display : "";
                            let language = isCountryAndLanguageExists ? countryList[0].languages[0].name : "";

                            if (coreAccountCountryCode && coreAccountLanguageCode) {
                                country_code = coreAccountCountryCode;
                                language_code = coreAccountLanguageCode;
                                country = getCountryByCountryCode(coreAccountCountryCode).name_display;
                                language = getLanguageByCountryAndLanguageCode(coreAccountCountryCode, coreAccountLanguageCode).name;
                            }

                            setTargeting({
                                country_code: country_code,
                                language_code: language_code,
                                country: country,
                                language: language,
                            });
                            setTargetingModalShow(true);
                        }}
                    >
                        {coreAccountCountryCode && coreAccountLanguageCode ? "Manage" : "Select"}
                    </Button>
                </div>
            </div>
        </SettingsLayout>
    );
};

export default ProductFeed;
