/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template4 = ({
    name,
    image = `${window.location.origin}/static/img/video_promotions/template_4/heroImage.jpg?1624295322638`,
    price,
    adMessage,
    paused = false,
}) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"] = adMessage || "Special Discount";

        animationObject["ProductNameObj"]["ProductName"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"] = price;

        const images = AdobeAn.getComposition("94EE611ABD2EE84CAA7101A2FCD40DB8").getImages();
        images["heroImage"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_4"
                composition="94EE611ABD2EE84CAA7101A2FCD40DB8"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template4.design_name = "Elegant";

/* Get the width and height from template file
(lib.heroImage = function () {
    this.initialize(img.heroImage);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0, 0, 1080, 1083);
*/
Template4.image_width = 1080;
Template4.image_height = 1083;

export default Template4;
