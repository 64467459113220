/* eslint-disable camelcase */
import React from "react";
import { useSelector } from "react-redux";
import { tickIcon, errorIcon } from "../../../utils/icons-utils";

const ShowIcon = (props) => {
    const { campaign_type } = props;
    const list = useSelector((state) =>
        campaign_type === "reach"
            ? state.reachList.results
            : campaign_type === "remarketing"
            ? state.remarketingList.results
            : campaign_type === "upsell"
            ? state.upsellList.results
            : campaign_type === "google_shopping"
            ? state.googleShopping.list.campaigns
            : ""
    );

    const Icon = list.length ? tickIcon : errorIcon;

    return (
        <div className="flex content-center justify-center items-center">
            <Icon />
        </div>
    );
};

export default ShowIcon;
