import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import * as Sentry from "@sentry/browser";
import { AppProvider, Frame } from "@shopify/polaris";
import { PersistGate } from "redux-persist/integration/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

import { store, persistor } from "./store/create";
import { fetchSettings, initiate, loadCategories, loadCountries, loadGoogleCoreAccount } from "./actions";

import App from "./containers/App";

import Header from "./components/Header";
import Navigation from "./components/Navigation";

if (process.env.SENTRY_DNS) {
    Sentry.init({
        dsn: process.env.SENTRY_DNS,
        environment: process.env.NODE_ENV,
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.1,
    });
}

if (!Object.entries) {
    Object.entries = function (obj) {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i); // preallocate the Array
        // eslint-disable-next-line no-plusplus
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}

if (!document.querySelector("#container-login")) {
    store.dispatch(initiate());

    store.dispatch(fetchSettings());

    store.dispatch(loadCategories());

    store.dispatch(loadCountries());

    store.dispatch(loadGoogleCoreAccount());

    const container = document.querySelector("#container");

    const theme = {
        colors: {
            topBar: {
                background: "none",
                color: "#fff",
            },
        },
        logo: {
            width: 126,
            topBarSource: "/static/i/logo-header.svg",
            url: "/welcome/",
            accessibilityLabel: "",
        },
    };

    if (container) {
        render(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppProvider
                        theme={theme}
                        i18n={{
                            Polaris: {
                                Frame: {
                                    skipToContent: "Skip to content",
                                },
                                ContextualSaveBar: {
                                    save: "Start campaign",
                                    discard: "Cancel",
                                },
                            },
                        }}
                    >
                        <Frame>
                            <Router>
                                <App />
                            </Router>
                        </Frame>
                    </AppProvider>
                </PersistGate>
            </Provider>,
            container
        );
    } else {
        const header = document.querySelector("#l-header--wrapper");

        if (header) {
            render(
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <AppProvider theme={theme}>
                            <Router>
                                <Header />
                            </Router>
                        </AppProvider>
                    </PersistGate>
                </Provider>,
                header
            );
        }

        const navigation = document.querySelector("#l-navigation--wrapper");

        if (navigation) {
            render(
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router>
                            <Navigation />
                        </Router>
                    </PersistGate>
                </Provider>,
                navigation
            );
        }
    }
}

/* eslint-disable import/prefer-default-export */
export { store };
