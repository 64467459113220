import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

export const BADGE_STATUSES = {
    DEFAULT: "DEFAULT",
    INFO: "INFO",
    SUCCESS: "SUCCESS",
    DANGER: "DANGER",
};

export const Badge = ({ status = BADGE_STATUSES.DEFAULT, text = null, onClick, icon, id  }) => {
    const backGroundClasses = classnames("flex rounded-full py-3 px-6 items-center w-max h-10 text-12px", {
        [["bg-gray-200"]]: status === BADGE_STATUSES.DEFAULT,
        [["bg-blue-100"]]: status === BADGE_STATUSES.INFO,
        [["bg-green-100"]]: status === BADGE_STATUSES.SUCCESS,
        [["bg-red-100"]]: status === BADGE_STATUSES.DANGER,
        [["cursor-pointer"]]: status === BADGE_STATUSES.DANGER,
    });

    const iconClasses = classnames("flex rounded-full h-4 w-4 items-center justify-center mr-3.5", {
        [["bg-gray-700"]]: status === BADGE_STATUSES.DEFAULT,
        [["bg-blue-700"]]: status === BADGE_STATUSES.INFO,
        [["bg-green-700"]]: status === BADGE_STATUSES.SUCCESS,
        [["bg-red-700"]]: status === BADGE_STATUSES.DANGER,
    });

    const textClasess = classnames("inline font-sf-text", {
        [["text-gray-500"]]: status === BADGE_STATUSES.DEFAULT,
        [["text-blue-500"]]: status === BADGE_STATUSES.INFO,
        [["text-green-500"]]: status === BADGE_STATUSES.SUCCESS,
        [["text-red-500"]]: status === BADGE_STATUSES.DANGER,
    });

    return (
        <div 
            aria-hidden={true} 
            className={backGroundClasses}
            onClick={onClick} 
            id={id}
        >
            {icon && icon}
            {!icon && <div className={iconClasses} />} 
            <div className={textClasess}>{text}</div>
        </div>
    );
};

Badge.propTypes = {
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node
};

Badge.defaultProps = {
    status: BADGE_STATUSES.DEFAULT,
    text: "",
    onClick: null,
    icon: null
};
