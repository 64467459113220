/* eslint-disable react/jsx-boolean-value */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, TextField, Modal } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import LinkButton from "../../../common/components/LinkButton";
import GoogleSettingPanelButton from "../../../common/components/GoogleSettingPanelButton";
import {
    GetGoogleAdsAccountCheckInvitationStatusRequest,
    GoogleAdsAccountCreateRequest,
    GoogleAdsAccountConnectRequest,
} from "../../../api/api-google-shopping";
import {
    ADS_ACCOUNT_MODAL_MSG,
    ADS_ACCOUNT_PENDING_MODAL_MSG,
    ADS_ACCOUNT_VERIFIED_MODAL_MSG,
} from "../constants/strings";
import { googleAdsAccountConnected, loadAdsAccountList } from "../../../actions";

const AdsAccountCreateSuccessModal = ({ open, onGoogleAdsAccountConnectClick }) => {
    const [bodyMessage, setBodyMessage] = useState("");

    useEffect(() => {
        setBodyMessage(ADS_ACCOUNT_MODAL_MSG);
    }, []);

    const changeBodyMessage = async (status) => {
        switch (status) {
            case "PENDING":
                setBodyMessage(ADS_ACCOUNT_PENDING_MODAL_MSG);
                break;
            case "ACCEPTED":
                setBodyMessage(ADS_ACCOUNT_VERIFIED_MODAL_MSG);
                await onGoogleAdsAccountConnectClick();
                break;
            default:
        }
    };

    const checkInvitationStatus = async () => {
        const status = await GetGoogleAdsAccountCheckInvitationStatusRequest();
        changeBodyMessage(status.invitation_status);
    };

    return (
        <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div className="w-full h-full flex justify-center items-center">
                <div className="m-8 ml-24 mr-24 md:m-48 md:ml-70 md:mr-70 lg:ml-96 lg:mr-96 bg-gray-100 rounded-xl p-8 flex flex-col justify-center items-center card">
                    <h2 className="text-lg md:text-4xl text-green-400 text-center">Account create success!</h2>
                    <p className="lext-sm pt-4 md:text-2xl text-center">{bodyMessage}</p>
                    <div className="flex flex-col md:flex-row mt-12">
                        <Button className="bg-red text-white" color="primary" onClick={checkInvitationStatus}>
                            Accepted and verified!
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const ConnectAccountModule = ({
    selectedAccountId,
    accounts,
    onGoogleAdsAccountChange,
    onGoogleAdsAccountConnectClick,
    // version,
}) => {
    const dispatch = useDispatch();
    return (
        <Fragment>
            <div className="flex w-4/6">
                <img
                    className="p-1 m-2"
                    src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/dd881a477807b6788dc697a5bbc62e40.svg"
                    height={40}
                    width={40}
                    alt="Google Account"
                />
                <TextField
                    select
                    variant="outlined"
                    SelectProps={{ native: true }}
                    onChange={onGoogleAdsAccountChange}
                    fullWidth
                >
                    {accounts.map((account) => (
                        <option value={account.id} key={account.id}>
                            {`${account.id} ${account.descriptive_name}`}
                        </option>
                    ))}
                </TextField>
            </div>
            <div>
                {selectedAccountId && (
                    <GoogleSettingPanelButton buttonType="CONNECT" onClick={onGoogleAdsAccountConnectClick}>
                        Connect
                    </GoogleSettingPanelButton>
                )}
                <GoogleSettingPanelButton
                    buttonType="REFRESH"
                    onClick={() => {
                        dispatch(loadAdsAccountList());
                    }}
                >
                    Refresh
                </GoogleSettingPanelButton>
            </div>
        </Fragment>
    );
};

const CreateAccountModule = ({ onGoogleAdsAccountCreateClick }) => (
    <Fragment>
        <span className="mt-2">We did not find find any merchant account of yours. Let us create one for you</span>
        <LinkButton onClick={onGoogleAdsAccountCreateClick}>Create new</LinkButton>
    </Fragment>
);

const GoogleAdsAccountList = () => {
    const [loading, setLoading] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [createdAdsId, setCreatedAdsId] = useState("");

    const dispatch = useDispatch();
    const googleMerchantAccountId = useSelector((state) => state.googleShopping.coreAccount.google_merchant_account_id);
    const googleAdsAccountId = useSelector((state) => state.googleShopping.coreAccount.google_ads_account_id);
    const accounts = useSelector((state) => state.googleShopping.adAccounts);
    const coreAccountLoading = useSelector((state) => state.googleShopping.isCoreAccountLoading);
    const accountLoading = useSelector((state) => state.googleShopping.isAdsAccountLoading);

    const onGoogleAdsAccountChange = (ev) => {
        setSelectedAccountId(ev.target.value);
    };

    const onGoogleAdsAccountCreateClick = async () => {
        setLoading(true);
        const created = await GoogleAdsAccountCreateRequest().catch((error) => console.log(error));
        setLoading(false);
        if (created) {
            setModalOpen(true);
            setCreatedAdsId(created.id.split("/")[1]);
        }
    };

    const onGoogleAdsAccountConnectClick = async () => {
        const adsId = createdAdsId || selectedAccountId || "";
        setLoading(true);
        GoogleAdsAccountConnectRequest(adsId)
            .then((response) => {
                dispatch(googleAdsAccountConnected(response));
            })
            .catch(() => {
                // console.log({e})
            })
            .finally(() => {
                setLoading(false);
                if (createdAdsId) {
                    setModalOpen(false);
                }
            });
    };

    useEffect(() => {
        dispatch(loadAdsAccountList());
    }, []);

    useEffect(() => {
        if (!selectedAccountId && accounts && accounts.length > 0) {
            setSelectedAccountId(accounts[0].id);
        }
    }, [accounts]);

    return (
        <SettingsLayout
            header="Google Ads Account"
            subtitle="Campaigns created with the Google channel will appear in your Google Ads account. You'll be billed directly from Google."
            disabled={coreAccountLoading || loading || accountLoading}
            loading={coreAccountLoading || loading || accountLoading}
            active={googleMerchantAccountId && !googleAdsAccountId}
        >
            <div className="flex flex-row items-center justify-between">
                {accounts && (
                    <FormControl fullWidth>
                        <div className="flex flex-row items-center justify-between w-full">
                            <div className="text-2xl">Connect a Google Ads account</div>{" "}
                            {accounts.length > 0 && (
                                <LinkButton onClick={onGoogleAdsAccountCreateClick}>Create New</LinkButton>
                            )}
                        </div>
                        <br />

                        <div className="flex flex-row justify-between">
                            {accounts.length > 0 && (
                                <ConnectAccountModule
                                    selectedAccountId={selectedAccountId}
                                    accounts={accounts}
                                    onGoogleAdsAccountChange={onGoogleAdsAccountChange}
                                    onGoogleAdsAccountConnectClick={onGoogleAdsAccountConnectClick}
                                />
                            )}
                            {(accounts.length === 0 || !accounts) && (
                                <CreateAccountModule onGoogleAdsAccountCreateClick={onGoogleAdsAccountCreateClick} />
                            )}
                        </div>

                        <AdsAccountCreateSuccessModal
                            open={modalOpen}
                            onGoogleAdsAccountConnectClick={onGoogleAdsAccountConnectClick}
                        />
                    </FormControl>
                )}
            </div>
        </SettingsLayout>
    );
};

export default GoogleAdsAccountList;
