import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SettingsLayout from "./SettingsLayout";
import GoogleSettingPanelButton from "../../../common/components/GoogleSettingPanelButton";
import { GoogleMerchantAccountDisconnectRequest } from "../../../api/api-google-shopping";
import { googleMerchantAccountDisconnected } from "../../../actions";

const GoogleMerchantAccountDetails = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const merchantAccount = useSelector((state) => state.googleShopping.coreAccount.google_merchant_account_id);
    const isMerchantAccountLoading = useSelector((state) => state.googleShopping.isMerchantAccountLoading);

    const onGoogleMerchantAccountDisconnectClick = async () => {
        if (merchantAccount) {
            setLoading(true);
            await GoogleMerchantAccountDisconnectRequest(merchantAccount);
            setLoading(false);
            dispatch(googleMerchantAccountDisconnected());
        }
    };

    return (
        <SettingsLayout
            header="Google Merchant Center Account"
            subtitle="Product data will be synced to this Merchant Center account."
            loading={loading || isMerchantAccountLoading}
        >
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                    <img
                        className="p-1 m-2"
                        src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/1db70595ae0b827f62829419d3604e03.svg"
                        height={40}
                        width={40}
                        alt="Google Account"
                    />
                    <div className="flex flex-col">
                        <p className="text-2xl font-bold">GOOGLE MERCHANT CENTER ACCOUNT ID</p>
                        <p className="text-2xl">{merchantAccount}</p>
                    </div>
                </div>
                <div>
                    <GoogleSettingPanelButton buttonType="DISCONNECT" onClick={onGoogleMerchantAccountDisconnectClick}>
                        Disconnect
                    </GoogleSettingPanelButton>
                </div>
            </div>
        </SettingsLayout>
    );
};

export default GoogleMerchantAccountDetails;
