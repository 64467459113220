/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useState } from "react";
import Proptypes from "prop-types";

import Banner from "../../common/components/sixads/banner";

const BrowserNotSupportedBanner = ({ wrapperClasses }) => {
    const [open, setOpen] = useState(true);
    const isSafari = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;

    return (
        <Fragment>
            {isSafari && open && (
                <div className={wrapperClasses}>
                    <Banner
                        type="warning"
                        icon={
                            <span className="text-4xl" role="img" aria-label="pensive">
                                😔
                            </span>
                        }
                        onClose={() => setOpen(false)}
                    >
                        Unfortunately, Safari browser won’t allow you to download the video without taking additional
                        steps. You can either use another browser, or follow{" "}
                        <a
                            href="https://www.educative.io/edpresso/how-to-enable-the-mediarecorder-api-for-safari"
                            target="_blank"
                        >
                            these steps
                        </a>{" "}
                        to continue with Safari.
                    </Banner>
                </div>
            )}
        </Fragment>
    );
};

BrowserNotSupportedBanner.propTypes = {
    wrapperClasses: Proptypes.string,
};

BrowserNotSupportedBanner.defaultProps = {
    wrapperClasses: "",
};

export default BrowserNotSupportedBanner;
