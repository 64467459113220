import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { Icon } from "../../../utils/Icon";
import { locationChange, toggleNavigation } from "../../../actions";
import usePrevious from "./hooks/use-previous";
import MenuItem from "./MenuItem";

const excludingPages = [
    "welcome",
    "create-campaign",
    "video-ads",
    "dropshipping-course",
    "account-audits",
    "image-ad-creator",
    "account-audits",
    "image-ad-creator",
    "ad-text-creator",
    "shopify-store-checker",
    "custom-video-ads",
    "dynamic-ads/create-campaign",
    "google-shopping/create-campaign",
    "ad-creative",
    "ads-exchange",
];

const isOnboardingPage = (location) => {
    /* eslint-disable */
    for (const page of excludingPages) {
        const regex = new RegExp(`^/${page}/`);
        if (location.match(regex)) {
            return true;
        }
    }
    return false;
};

export const NAV_OPEN = "NAV_OPEN";
export const NAV_HIDDEN = "NAV_HIDDEN";
export const NAV_DOCKED = "NAV_DOCKED";

const onToggleMenu = (navStatus) => {
    const bodyCSS = document.body.classList;

    if (navStatus === NAV_OPEN) {
        bodyCSS.toggle("is-nav--open", true);
        bodyCSS.toggle("is-navigation--hidden", false);
        bodyCSS.toggle("is-navigation--docked", false);
    } else {
        bodyCSS.toggle("is-nav--open", false);
        if (navStatus === NAV_HIDDEN) {
            bodyCSS.toggle("is-navigation--hidden", true);
        } else {
            bodyCSS.toggle("is-navigation--docked", true);
        }
    }
};

const Navigation = ({ logo = "sixadsLogoWithBrand", menus, isOpen, dispatch = () => {} }) => {
    const [expanded, setExpanded] = useState(false);
    const [navStatus, setNavStatus] = useState(NAV_DOCKED);
    const location = useLocation();
    const [defaultPath, setPath] = useState(`${location.pathname}${location.search}`);
    const prevPath = usePrevious(defaultPath);
    const rootClassNames = classNames(
        `app-navigation flex flex-col justify-space-between items-stretch fixed h-full
         bg-gradient-to-r from-darkGreen-700 to-darkGreen-900
         transition-width duration-50 z-2000 overflow-x-hidden overflow-auto`,
        {
            [["w-0"]]: navStatus === NAV_HIDDEN,
            [["w-96"]]: navStatus === NAV_OPEN,
            [["w-36"]]: navStatus === NAV_DOCKED,
        }
    );

    const toggleMenu = () => {
        setExpanded(!expanded);
        dispatch(toggleNavigation());
    };

    const handleMenu = (isNavOpen, location) => {
        const isMobile = document.documentElement.clientWidth < 769;
        if (isNavOpen) {
            setNavStatus(NAV_OPEN);
        } else if (isMobile || isOnboardingPage(location.pathname)) {
            setNavStatus(NAV_HIDDEN);
        } else {
            setNavStatus(NAV_DOCKED);
        }
        setExpanded(isNavOpen);
        onToggleMenu(navStatus);
    };

    useEffect(() => {
        handleMenu(isOpen, location);
        window.addEventListener("resize", () => handleMenu(isOpen, location));
        const path = `${location.pathname}${location.search}`;
        if (prevPath !== undefined && path !== prevPath) {
            dispatch(locationChange(path));
            setPath(path);
        }
    }, [isOpen, navStatus, location]);

    const getClassNameSubMenu = () => {
        if (!menuCollapse && menu.children && expanded) {
            return {
                height: "0px",
            };
        }
    };

    return (
        <div className={rootClassNames}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                onClick={toggleMenu}
                className="flex flex-row items-center p-5 w-max justify-space-around py-14 mx-6 cursor-pointer"
            >
                <Icon name={expanded ? "navigationToggleCollapse" : "navigationToggleExpand"} />
                {expanded && (
                    <a href="/manage-campaigns">
                        <Icon name={logo} />{" "}
                    </a>
                )}
            </div>

            <div className="p-6">
                {menus.map((menu, index) => (
                    <MenuItem key={index} menu={menu} navStatus={navStatus} expanded={expanded} />
                ))}
            </div>

            <div>&nbsp;</div>

            <div>&nbsp;</div>

            <div>&nbsp;</div>

            <div>&nbsp;</div>

            <div>&nbsp;</div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { navigation } = state;
    const { isOpen = false } = navigation;

    return {
        isOpen: isOpen,
    };
};

export default connect(mapStateToProps)(Navigation);
