/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Checkbox } from "@mui/material";
import { withStyles } from "@mui/styles";

const SixadsCheckbox = withStyles({
    root: {
        color: "#499995",
        "&$checked": {
            color: "#499995",
        },
    },
    checked: {},
})((props) => <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} color="default" {...props} />);

export default SixadsCheckbox;
