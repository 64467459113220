import * as React from "react";
import PropTypes from "prop-types";
import Grid from "../../grid/grid";
import { smallScreen } from "../../../../../utils/window-size";
import { createWithClassesOrStyles, getComponents } from "../../../../../utils/override";

const StyledRoot = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: "stepper-header--default px- lg:px-120",
        props: props,
    });

const defaultComponents = {
    Root: StyledRoot,
};

export default function ProgressSteps(props) {
    const { children, current, overrides = {} } = props;
    const numChildren = React.Children.count(children);
    const {
        Root: { component: Root, props: rootProps },
    } = getComponents(defaultComponents, overrides);

    const modifiedChildren = React.Children.map(children, (child, index) => {
        if (!child) return;

        const childOverrides = child.props.overrides || {};

        const isActive = child.props.isActive ? child.props.isActive : index === current - 1;

        return (
            <Grid.Column width={2}>
                {React.cloneElement(child, {
                    isLast: index === numChildren - 1,
                    isCompleted: index < current - 1,
                    step: index + 1,
                    isActive: isActive,
                    overrides: {
                        ...childOverrides,
                    },
                })}
            </Grid.Column>
        );
    });

    return (
        <Root {...props} {...rootProps}>
            <Grid>
                <Grid.Row columns={numChildren} otherClasses={smallScreen() ? `flex--row ` : ``}>
                    {modifiedChildren}
                </Grid.Row>
            </Grid>
        </Root>
    );
}

ProgressSteps.propTypes = {
    children: PropTypes.node.isRequired,
    current: PropTypes.number,
};

ProgressSteps.defaultProps = {
    current: 1,
};
