import fetch from "cross-fetch";

const createURL = "/api/v1/features/";

export const create = (data) => {
    return fetch(createURL, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 201) {
                return res.json();
            }
            return {
                status: "error",
                response: res,
            };
        })
        .catch((error) => {
            return error;
        });
};
