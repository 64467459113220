import React, { Component } from "react";
import { Banner } from "@shopify/polaris";

class PasswordProtectedBanner extends Component {
    render() {
        return (
            <Banner title="Your ads cannot be displayed" status="warning">
                Unfortunately, we are unable to advertise your products as long as your store is password protected.
                Please remove the password and your ads will become automatically active.
            </Banner>
        );
    }
}

export default PasswordProtectedBanner;
