import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { ErrorSvg, WarringSvg, InfoSvg, SuccessSvg, ExitSvg } from "./icons";

const Banner = React.forwardRef((props, ref) => {
    const { children,  type, onClose, icon } = props;

    const types = {
        "error": {icon: <ErrorSvg />, exitButton:  <ExitSvg color="#BF1D08" />},
        "warning": {icon: <WarringSvg />, exitButton:  <ExitSvg color="#573B00" />},
        "info": {icon: <InfoSvg />, exitButton:  <ExitSvg color="#006FBB" />},
        "success": {icon: <SuccessSvg />, exitButton:  <ExitSvg color="#108043" />},
    }
    const banner = types[type];

    if (icon) {
        banner.icon = icon;
    }

    return (
        <div ref={ref} className={`Sixads-Banner ${type}`}>
            <div className="image">
                {banner.icon}
            </div>
            {children}
            <div className="exit" onClick={onClose} aria-hidden="true">
                {banner.exitButton}
            </div>
        </div>
    )
})

Banner.propTypes = {
    type: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
    children: PropTypes.string,
    onClose: PropTypes.func,
    icon: PropTypes.node,
};

Banner.defaultProps = {
    children: null,
    onClose: () => {},
    icon: null,
};

export  default Banner;
