import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { toggleNavigation } from "../../actions";
import HeaderWithoutUser from "../../components/HeaderWithoutUser";
import { ConnectAccounts, Welcome, ThankYou, GoogleShoppingPlans } from "../../components/google-shopping";

const GoogleShopping = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Google Shopping | SIXADS";
        dispatch(toggleNavigation("close"));
    });

    return (
        <Fragment>
            <HeaderWithoutUser teal={true} />
            <Switch>
                <Route path="/google-shopping/create-campaign/welcome/" component={Welcome} exact={true} />
                <Route
                    path="/google-shopping/create-campaign/connect-accounts/"
                    component={ConnectAccounts}
                    exact={true}
                />
                <Route path="/google-shopping/create-campaign/plans/" component={GoogleShoppingPlans} exact={true} />
                <Route path="/google-shopping/create-campaign/thank-you/" component={ThankYou} exact={true} />
            </Switch>
        </Fragment>
    );
};

export default GoogleShopping;
