import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./styles";

const Slider = ({ min, max, value, onChange, progressBarColor }) => {
    useEffect(() => {
        const slider = window.document.getElementById("slider");
        const percentage = (value * 100) / max;
        slider.style.backgroundImage = `linear-gradient(to right, ${progressBarColor} ${percentage}%, transparent ${percentage}%)`;
    });

    return (
        <Fragment>
            <input
                type="range"
                id="slider"
                className="slider"
                min={min}
                max={max}
                value={value}
                onChange={(ev) => onChange(ev.target.value)}
            />

            <style jsx={true}>{styles}</style>
        </Fragment>
    );
};

Slider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    progressBarColor: PropTypes.string,
};

Slider.defaultProps = {
    progressBarColor: "#499995",
};

export default Slider;
