import css from "styled-jsx/css";

const styles = css`
    @media only screen and (max-width: 700px) {
        .dates-column--inputs--dates {
            display: flex;
            flex-direction: row;
        }
    }
`;

export default styles;
