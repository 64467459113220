import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { DatePicker } from "@shopify/polaris";
import { Modal } from "@mui/material";
import moment from "moment";

import { getPrimaryText, updateDynamicAdCampaign } from "../../../api/api-facebook-ads";
import { updateReachCampaign, updateRemarketingCampaign, updateUpsellCampaign } from "../../../actions";
import TextField from "../../../common/components/textfield";
import TotalBudget from "../../../common/components/total-budget/TotalBudget";
import Button from "../../../common/components/button";
import Banner from "../../../common/components/sixads/banner";
import Loader from "../../../common/components/sixads/loader/loader";

const EditModal = (props) => {
    const { name, primaryKey, editModalShow, dailyBudget, startDate, endDate, setEditModalShow, campaignType } = props;
    const [dateEdit, setDateEdit] = useState({ start: new Date(startDate), end: new Date(endDate) });
    const [{ month, year }, setDate] = useState({
        month: dateEdit.end.getMonth(),
        year: dateEdit.end.getFullYear(),
    });
    const [dailyBudgetEdit, setDailyBudgetEdit] = useState(dailyBudget);
    const [primaryTextEdit, setPrimaryTextEdit] = useState("");
    const [primaryTextLoading, setPrimaryTextLoading] = useState(true);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [bannerState, setBannerState] = useState({
        type: "success",
        text: "",
        showBanner: false,
    });
    const usdEquivalentOfCurrency = useSelector((state) => state.settings.facebook.usd_equivalent_of_currency);
    const adAccountCurrency = useSelector((state) => state.settings.facebook.ad_account_currency);
    const dispatch = useDispatch();

    const minimumBudget = 2;
    const maximumBudget = 100;

    useEffect(() => {
        if (editModalShow) {
            setDateEdit({ start: new Date(startDate), end: new Date(endDate) });
            setDailyBudgetEdit(dailyBudget);
            getPrimaryText(primaryKey).then((response) => {
                setPrimaryTextEdit(response.primary_text);
                setPrimaryTextLoading(false);
            });
        }
    }, [editModalShow]);

    const handleDateChange = (date) => {
        const temp = {
            start: dateEdit.start,
            end: date.end,
        };
        setDateEdit(temp);
    };

    const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

    const handleTotalBudgetChange = (ev) => {
        let value = parseFloat(ev.target.value);
        if (Number.isNaN(value)) {
            value = 0;
        }

        setDailyBudgetEdit(value);
    };

    const handleUpdate = async () => {
        setBannerState((prev) => ({ ...prev, showBanner: false }));
        const error = isValidationError();
        if (!error) {
            setUpdateLoading(true);
            const endDate = moment(dateEdit.end).format("YYYY-MM-DD");
            const payload = {
                stop_time: endDate,
                daily_budget: dailyBudgetEdit,
                primary_text: primaryTextEdit,
            };

            let type, text;
            updateDynamicAdCampaign(primaryKey, payload)
                .then(
                    (response) => {
                        if (!response.error) {
                            dispatch(
                                campaignType === "reach"
                                    ? updateReachCampaign(primaryKey, endDate, dailyBudgetEdit)
                                    : campaignType === "remarketing"
                                    ? updateRemarketingCampaign(primaryKey, endDate, dailyBudgetEdit)
                                    : campaignType === "upsell"
                                    ? updateUpsellCampaign(primaryKey, endDate, dailyBudgetEdit)
                                    : ""
                            );
                            type = "success";
                            text = "Campaign update successful";
                        } else {
                            type = "error";
                            text = "Sorry, system can not update this campaign";
                        }
                    },
                    (reason) => {
                        type = "error";
                        text = "Sorry, system can not update this campaign";
                    }
                )
                .catch((error) => {
                    type = "error";
                    text = "Sorry, system can not update this campaign";
                })
                .finally(() => {
                    setUpdateLoading(false);
                    setBannerState({
                        type,
                        text,
                        showBanner: true,
                    });
                });
        } else {
            setUpdateLoading(false);
            setBannerState({
                type: "error",
                text: error,
                showBanner: true,
            });
        }
    };

    const isValidationError = () => {
        const a = moment([dateEdit.start.getFullYear(), dateEdit.start.getMonth(), dateEdit.start.getDate()]);
        const b = moment([dateEdit.end.getFullYear(), dateEdit.end.getMonth(), dateEdit.end.getDate()]);
        const dif = b.diff(a, "days");
        if (!primaryTextEdit) {
            return "Primary Text can not be empty";
        } else if (dailyBudgetEdit < Math.ceil(minimumBudget * usdEquivalentOfCurrency)) {
            return `Budget can not be less than ${Math.ceil(
                minimumBudget * usdEquivalentOfCurrency
            )} ${adAccountCurrency}`;
        } else if (dif < 3) {
            return "The interval between start and end date should be more than or equal 3 days";
        } else return false;
    };

    const ModalBanner = () => (
        <div className="px-6 py-2">
            <Banner
                type={bannerState.type}
                onClose={() => {
                    setBannerState((prev) => ({ ...prev, showBanner: false }));
                }}
            >
                {bannerState.text}
            </Banner>
        </div>
    );

    return (
        <Modal
            open={editModalShow}
            onClose={() => setEditModalShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                className={`absolute top-1/2 left-1/2 w-4/5 md:w-1/2 xl:w-1/3 ${
                    primaryTextLoading ? "" : "shadow-2xl bg-white"
                }`}
                style={{ transform: "translate(-50%, -50%)" }}
            >
                {primaryTextLoading && (
                    <div className="w-full h-full flex justify-center items-center">
                        <Loader />
                    </div>
                )}
                {!primaryTextLoading && (
                    <div>
                        <div className="p-8 w-full text-center text-3xl">Edit Campaign: {name}</div>
                        <hr />
                        {bannerState.showBanner && <ModalBanner />}
                        <div className="p-6">
                            <div>
                                <TextField
                                    label="Primary text"
                                    name="primaryText"
                                    value={primaryTextEdit}
                                    onChange={(event) => setPrimaryTextEdit(event.target.value)}
                                    maxLength={90}
                                />
                            </div>

                            <div className="my-10">
                                <TotalBudget
                                    min={Math.ceil(minimumBudget * usdEquivalentOfCurrency)}
                                    max={Math.ceil(maximumBudget * usdEquivalentOfCurrency)}
                                    dailyBudget={parseFloat(dailyBudgetEdit)}
                                    onChange={handleTotalBudgetChange}
                                    // errorMessage={totalError}
                                    currency={adAccountCurrency}
                                />
                            </div>

                            <div>
                                <div className="pb-2">
                                    <span className="text-gray-700 font-bold text-14px">End Date</span>
                                </div>
                                <DatePicker
                                    month={month}
                                    year={year}
                                    allowRange={true}
                                    disableDatesBefore={new Date()}
                                    onChange={(date) => handleDateChange(date)}
                                    onMonthChange={handleMonthChange}
                                    selected={dateEdit}
                                />
                            </div>
                        </div>
                        <div className="my-8 w-full flex justify-center">
                            <Button loading={updateLoading} primary={true} onClick={handleUpdate}>
                                Edit Campaign
                            </Button>
                            <Button basic={true} onClick={() => setEditModalShow(false)}>
                                Close
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default EditModal;
