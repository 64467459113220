import React from "react";
import PropTypes from "prop-types";

import AdViewImage from "./AdViewImage";
import Headline from "./Headline";
import Description from "./Description";

export const GoogleAdView = (props) => {
    const { description, businessName, headline, imageUrl } = props;

    return (
        <div className="googleAdView">
            <AdViewImage imageUrl={imageUrl} />
            <Headline headline={headline} />
            <Description description={description} businessName={businessName} />
        </div>
    );
};

GoogleAdView.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    businessName: PropTypes.string,
};

GoogleAdView.defaultProps = {
    businessName: "",
};

export default null;
