import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { Alert, AlertTitle } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import { loadTaxSettings } from "../../../actions";

const GoogleMerchantAccountTaxSettings = () => {
    const dispatch = useDispatch();
    const coreAccount = useSelector((state) => state.googleShopping.coreAccount);
    const accountLoading = useSelector((state) => state.googleShopping.isCoreAccountLoading);
    const taxSettings = useSelector((state) => state.googleShopping.taxSettings);
    const loading = useSelector((state) => state.googleShopping.isTaxSettingsLoading);

    const [alertShown, setAlertShown] = useState(true);
    const shouldShowTaxAlert = alertShown && taxSettings && taxSettings.rules;
    const taxUrl = `https://merchants.google.com/mc/tax/settings?a=${coreAccount.google_merchant_account_id}`;

    useEffect(() => {
        dispatch(loadTaxSettings());
    }, []);

    const SettingsAllRight = () => (
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
                <img
                    className="p-1 m-2"
                    src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/1db70595ae0b827f62829419d3604e03.svg"
                    height={40}
                    width={40}
                    alt="Google Account"
                />
                <div className="flex flex-col">
                    <p className="font-bold">GOOGLE MERCHANT CENTER TAX SETTINGS</p>

                    <div>
                        <span className="text-green-600">Everything looks good!</span> <br />
                    </div>
                </div>
            </div>
        </div>
    );

    const SettingsNotFound = () => (
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
                <img
                    className="p-1 m-2"
                    src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/1db70595ae0b827f62829419d3604e03.svg"
                    height={40}
                    width={40}
                    alt="Google Account"
                />
                <div className="flex flex-col">
                    <p className="font-bold">GOOGLE MERCHANT CENTER TAX SETTINGS</p>
                    <div className="flex flex-row">
                        <a className="inline text-blue-700" href={taxUrl} target="_blank" rel="noreferrer">
                            Please go to your merchant center
                        </a>{" "}
                        &nbsp; and add tax settings. Reload settings once you have added this.
                    </div>
                </div>
            </div>
            <div>
                <Button
                    color="secondary"
                    onClick={() => {
                        dispatch(loadTaxSettings());
                    }}
                >
                    Reload settings
                </Button>
            </div>
        </div>
    );

    return (
        <SettingsLayout
            header="Google Merchant Center Tax Settings"
            subtitle="Make sure your tax settings are in sync with google merchant account."
            loading={loading || accountLoading}
        >
            {taxSettings && taxSettings.rules ? <SettingsAllRight /> : ""}
            {!taxSettings || !taxSettings.rules ? <SettingsNotFound /> : ""}
            {shouldShowTaxAlert && (
                <Alert severity="warning" onClose={() => setAlertShown(false)}>
                    <AlertTitle>Make sure your tax settings are always in sync!</AlertTitle>
                    Tax settings are important for setting up your store.
                    <strong>Make sure they are always in sync with your google merchant account!</strong>
                </Alert>
            )}
        </SettingsLayout>
    );
};

export default GoogleMerchantAccountTaxSettings;
