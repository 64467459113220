/* eslint-disable */
import { ACTIONS } from "../actions/actions-image-overlay-ads";
import withSelectProducts from "./common/withSelectProducts";

const initialState = {
    welcome: {
        //
    },
    selectProducts: {
        isLoading: false,
        totalNumberOfaProducts: 0,
        nexPageUrl: null,
        products: [],
        selectedProducts: [],
        filters: {
            searchQuery: "",
            option: "",
        },
    },
    selectProducts: {
        isLoading: false,
        totalNumberOfaProducts: 0,
        nexPageUrl: null,
        products: [],
        selectedProducts: [],
        filters: {
            searchQuery: "",
            option: "",
        },
    },
    selectedTemplate: {
        templateLayout: "",
        top: false,
        right: false,
        bottom: false,
        left: false,
        originalImageWidth: -1,
        currentImageWidth: -1,
    },
    createAd: {
        isLoading: false,
        templates: [],
    },
    thankYou: {
        //
    },
};

const imageOverlayAds = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_RENDERED_IMAGES_REQUESTED:
            return {
                ...state,
                createAd: {
                    ...state.createAd,
                    isLoading: true,
                },
            };
        case ACTIONS.GET_RENDERED_IMAGES_SUCCEED:
            return {
                ...state,
                createAd: {
                    ...state.createAd,
                    isLoading: false,
                    templates: action.payload.images,
                },
            };
        case ACTIONS.GET_RENDERED_IMAGES_FAILED:
            return {
                ...state,
                createAd: {
                    ...state.createAd,
                    isLoading: false,
                    templates: [],
                },
            };

        case ACTIONS.RESET_STATE:
            return {
                ...state,
                welcome: initialState.welcome,
                selectProducts: initialState.selectProducts,
                createAd: initialState.createAd,
                thankYou: initialState.thankYou,
            };

        case ACTIONS.SET_TEMPLATE_SELECTION:
            return {
                ...state,
                selectedTemplate: {
                    templateLayout: action.payload.templateLayout,
                    top: action.payload.top,
                    right: action.payload.right,
                    bottom: action.payload.bottom,
                    left: action.payload.left,
                    originalImageWidth: action.payload.originalImageWidth,
                    currentImageWidth: action.payload.currentImageWidth,
                },
            };

        default:
            return state;
    }
};

export default withSelectProducts(imageOverlayAds, ACTIONS);
