/* eslint-disable import/prefer-default-export */
// Incomplete. For now just laying out the structure.
import { api } from "../utils";
import { toggleToastPopup } from "./action-toast";

export const ACTIONS = {
    FETCH_CAMPAIGN_REQUESTED: "dynamicAds/FETCH_CAMPAIGN_REQUESTED",
    FETCH_CAMPAIGN_SUCCEED: "dynamicAds/FETCH_CAMPAIGN_SUCCEED",
    FETCH_CAMPAIGN_FAILED: "dynamicAds/FETCH_CAMPAIGN_FAILED",

    CREATE_CAMPAIGN_REQUESTED: "dynamicAds/CREATE_CAMPAIGN_REQUESTED",
    CREATE_CAMPAIGN_SUCCEED: "dynamicAds/CREATE_CAMPAIGN_SUCCEED",
    CREATE_CAMPAIGN_FAILED: "dynamicAds/CREATE_CAMPAIGN_FAILED",

    RESET_CAMPAIGN_CREATE_STATE: "dynamicAds/RESET_CAMPAIGN_CREATE_STATE",
    RESET_TYPE: "dynamicAds/RESET_TYPE",

    SET_TYPE: "dynamicAds/SET_TYPE",
    SET_CREATIVE: "dynamicAds/SET_CREATIVE",
    SET_BUDGET_AND_SCHEDULE: "dynamicAds/SET_BUDGET_AND_SCHEDULE",

    SET_ERROR_BANNER: "dynamicAds/SET_ERROR_BANNER",
    SET_SUCCESS_BANNER: "dynamicAds/SET_SUCCESS_BANNER",
    CLOSE_ALL_BANNER: "dynamicAds/CLOSE_ALL_BANNER",
    SET_BANNER_MESSAGE: "dynamicAds/SET_BANNER_MESSAGE",
    RESET_BANNER_MESSAGE: "dynamicAds/RESET_BANNER_MESSAGE",
};

export const setType = (type) => {
    return {
        type: ACTIONS.SET_TYPE,
        payload: type,
    };
};

export const setCreative = (primaryText, countries, audiences, ageMin, ageMax, gender, lookalikeAudiences) => {
    return {
        type: ACTIONS.SET_CREATIVE,
        payload: {
            primaryText,
            countries,
            lookalikeAudiences,
            audiences,
            age: {
                min: ageMin,
                max: ageMax,
            },
            gender,
        },
    };
};

export const setBudgetAndSchedule = (startDate, endDate, dailyBudget) => {
    return {
        type: ACTIONS.SET_BUDGET_AND_SCHEDULE,
        payload: {
            startDate,
            endDate,
            dailyBudget,
        },
    };
};

export const setErrorBanner = (flag) => {
    return {
        type: ACTIONS.SET_ERROR_BANNER,
        payload: flag,
    };
};

export const setSuccessBanner = (flag) => {
    return {
        type: ACTIONS.SET_SUCCESS_BANNER,
        payload: flag,
    };
};

export const closeAllBanner = () => {
    return {
        type: ACTIONS.CLOSE_ALL_BANNER,
    };
};

export const setBannerMessage = (message) => {
    return {
        type: ACTIONS.SET_BANNER_MESSAGE,
        payload: message,
    };
};

export const resetDynamicAdReducer = () => {
    return {
        type: ACTIONS.RESET_CAMPAIGN_CREATE_STATE,
    };
};

export const resetType = () => {
    return {
        type: ACTIONS.RESET_TYPE,
    };
};

export const resetBannerMessage = () => {
    return {
        type: ACTIONS.RESET_BANNER_MESSAGE,
    };
};
