import { get } from "../api/api-categories";

export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";

export const fetchCategoriesRequest = (payload) => ({
    type: FETCH_CATEGORIES_REQUEST,
    payload: payload,
});

export const fetchCategoriesFailure = (payload) => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: payload,
});

export const fetchCategoriesSuccess = (payload) => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: payload,
});

const fetchCategories = () => (dispatch) => {
    dispatch(fetchCategoriesRequest());

    return get()
        .then((payload) => {
            if (payload.status === "error") {
                dispatch(fetchCategoriesFailure(payload));
            } else {
                dispatch(fetchCategoriesSuccess(payload));
            }
        })
        .catch((error) => {
            throw error;
        });
};

const shouldfetchCategories = (state) => {
    const { categories } = state;

    if (!categories.items || !categories.items.length) {
        return true;
    }

    if (categories.isLoading) {
        return false;
    }

    return categories.isError;
};

export const loadCategories = () => (dispatch, getState) => {
    if (shouldfetchCategories(getState())) {
        return dispatch(fetchCategories());
    }
};
