export const INIT_ONBOARDING = "INIT_ONBOARDING";
export const EXIT_ONBOARDING = "EXIT_ONBOARDING";
export const SKIP_ONBOARDING = "SKIP_ONBOARDING";
export const NAVIGATE_ONBOARDING = "NAVIGATE_ONBOARDING";
export const SET_ONBOARDING_OPTION = "SET_ONBOARDING_OPTION";

export const initOnboarding = (payload) => ({
    type: INIT_ONBOARDING,
    payload: payload,
});

export const exitOnboarding = (payload) => ({
    type: EXIT_ONBOARDING,
    payload: payload,
});

export const skipOnboarding = (payload) => ({
    type: SKIP_ONBOARDING,
    payload: payload,
});

export const navigateOnboarding = (step) => ({
    type: NAVIGATE_ONBOARDING,
    step: step,
});

export const setOnboardingOption = (option, step) => ({
    type: SET_ONBOARDING_OPTION,
    option: option,
    step: step,
});
