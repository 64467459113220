export const PUT_VIDEO_ADS_VALUE = "PUT_VIDEO_ADS_VALUE";
export const PUT_VIDEO_ADS_ERROR = "PUT_VIDEO_ADS_ERROR";
export const INIT_VIDEO_ADS = "INIT_VIDEO_ADS";
export const EXIT_VIDEO_ADS = "EXIT_VIDEO_ADS";
export const SKIP_VIDEO_ADS = "SKIP_VIDEO_ADS";
export const NAVIGATE_VIDEO_ADS = "NAVIGATE_VIDEO_ADS";
export const SET_VIDEO_ADS_OPTION = "SET_VIDEO_ADS_OPTION";

export const initVideoAds = (payload) => ({
    type: INIT_VIDEO_ADS,
    payload: payload,
});

export const exitVideoAds = (payload) => ({
    type: EXIT_VIDEO_ADS,
    payload: payload,
});

export const skipVideoAds = (payload) => ({
    type: SKIP_VIDEO_ADS,
    payload: payload,
});

export const navigateVideoAds = (step) => ({
    type: NAVIGATE_VIDEO_ADS,
    step: step,
});

export const setVideoAdsOption = (option, step) => ({
    type: SET_VIDEO_ADS_OPTION,
    option: option,
    step: step,
});
