/* eslint-disable no-undef */
import React, { useState, useEffect, Fragment } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import TextField from "../../../common/components/textfield";
import {
    productConditionOptions,
    ageGroupOptions,
    genderOptions,
    sizeSystemOptions,
    sizeTypeOptions,
} from "../constants/options";
import Button from "../../../common/components/button";
import { UpdateGoogleProduct, UpdateMultiGoogleProducts } from "../../../api/api-google-shopping";
import Banner from "../../../common/components/sixads/banner";
import { deepEqual } from "../../../utils";

const ProductEditModal = ({ productItems, editModalShow, setEditModalShow, successCallback }) => {
    const [editedProductItem, setEditedProductItem] = useState(productItems);
    const [productItemOld, setProductItemOld] = useState(productItems);
    const [multiProductObject, setMultiProductObject] = useState({});
    const [updateLoading, setUpdateLoading] = useState(false);
    const [formated, setFormated] = useState(false);
    const [bannerState, setBannerState] = useState({
        type: "success",
        text: "",
        showBanner: false,
    });

    useEffect(() => {
        if (editModalShow && Array.isArray(productItems)) {
            setEditedProductItem({});
        }
    }, [editModalShow]);

    useEffect(() => {
        setEditedProductItem(productItems);
    }, [productItems]);

    const closeModal = () => {
        setBannerState({
            type: "success",
            text: "",
            showBanner: false,
        });
        setFormated(false);
        setEditModalShow(false);
    };

    const formatObject = () => {
        setUpdateLoading(true);
        const item = Array.isArray(editedProductItem) ? multiProductObject : editedProductItem;
        if (item.sizes) {
            const sizesArray = Array.isArray(item.sizes) ? item.sizes.toString() : item.sizes;
            const sizes = sizesArray.replaceAll(" ", "");
            const sizesList = sizes.split(",");
            if (Array.isArray(editedProductItem)) {
                setMultiProductObject((prev) => ({
                    ...prev,
                    sizes: sizesList,
                }));
            } else {
                setEditedProductItem((prev) => ({
                    ...prev,
                    sizes: sizesList,
                }));
            }
        }
        setFormated(true);
    };

    const handleChange = (newValue) => {
        if (Array.isArray(editedProductItem)) {
            setMultiProductObject((prev) => ({
                ...prev,
                ...newValue,
            }));
        } else {
            setEditedProductItem((prev) => ({
                ...prev,
                ...newValue,
            }));
        }
    };

    const handleTextEdit = (e) => {
        const { name, value } = e.target;
        handleChange({ [name]: value });
    };

    const handleSingleUpdate = () => {
        let type;
        let text;
        UpdateGoogleProduct(editedProductItem)
            .then((response) => {
                if (!response.error) {
                    type = "success";
                    text = "Product update successful";
                    successCallback(editedProductItem);
                    setProductItemOld(editedProductItem);
                } else {
                    type = "error";
                    text = "Sorry, system can not update this product now";
                }
            })
            .catch(() => {
                type = "error";
                text = "Sorry, system can not update this product now";
            })
            .finally(() => {
                setUpdateLoading(false);
                setFormated(false);
                setBannerState({
                    type,
                    text,
                    showBanner: true,
                });
            });
    };

    const handleMultipleUpdate = () => {
        let type;
        let text;
        const payload = editedProductItem.map((item) => ({
            ...item,
            ...multiProductObject,
        }));
        UpdateMultiGoogleProducts(payload)
            .then((response) => {
                if (!response.error) {
                    type = "success";
                    text = "Product update successful";
                    successCallback(payload);
                    setProductItemOld(payload);
                    setMultiProductObject({});
                } else {
                    type = "error";
                    text = "Sorry, system can not update this product now";
                }
            })
            .catch(() => {
                type = "error";
                text = "Sorry, system can not update this product now";
            })
            .finally(() => {
                setUpdateLoading(false);
                setFormated(false);
                setBannerState({
                    type,
                    text,
                    showBanner: true,
                });
            });
    };

    const handleUpdate = async () => {
        setBannerState({
            type: "",
            text: "",
            showBanner: false,
        });

        if (Array.isArray(editedProductItem)) {
            handleMultipleUpdate();
        } else {
            handleSingleUpdate();
        }
    };

    useEffect(() => {
        if (formated) {
            handleUpdate();
        }
    }, [formated]);

    const ifUpdateDisabled = () => {
        if (Array.isArray(editedProductItem)) {
            return !Object.values(multiProductObject).filter((item) => item).length;
        }
        return deepEqual(productItems, editedProductItem) || deepEqual(productItemOld, editedProductItem);
    };

    const ModalBanner = () => (
        <div className="pb-4">
            <Banner
                type={bannerState.type}
                onClose={() => {
                    setBannerState((prev) => ({ ...prev, showBanner: false }));
                }}
            >
                {bannerState.text}
            </Banner>
        </div>
    );

    const ModalTitle = () => {
        if (Array.isArray(productItems)) {
            return <Fragment>Edit Selected Items</Fragment>;
        }
        return <Fragment>{editedProductItem.title}</Fragment>;
    };

    const WarningText = ({ keyName }) => {
        if (Array.isArray(productItems)) {
            const isThereValue = productItems.find((item) => !!item[keyName]);
            if (isThereValue) {
                return (
                    <span className="text-8px font-medium" style={{ color: "#FF5733" }}>
                        One of the item selected already has value in this field. If you update this field now, previous
                        value will be lost.
                    </span>
                );
            }
        }
        return null;
    };

    return (
        <Modal
            open={editModalShow}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="flex justify-center items-center h-full w-full overflow-auto">
                <div className="w-4/5 md:w-1/2 shadow-2xl bg-white overflow-auto">
                    <div>
                        <div className="flex justify-between items-center p-10">
                            <div className="text-4xl font-bold truncate w-1/2">
                                <ModalTitle />
                            </div>
                            <IconButton
                                color="default"
                                size="large"
                                aria-label="close modal"
                                component="span"
                                onClick={closeModal}
                            >
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </div>
                        <hr />
                    </div>

                    <div className="px-10 py-10">
                        {bannerState.showBanner && <ModalBanner />}
                        <div className="grid grid-cols-1 md:grid-cols-3">
                            <div className="pr-0 md:pr-4 pb-3">
                                <span className="text-gray-700 font-bold text-14px">Age</span>
                                <Select
                                    options={ageGroupOptions}
                                    value={ageGroupOptions.filter((option) => {
                                        const x = Array.isArray(editedProductItem)
                                            ? multiProductObject.age_group
                                            : editedProductItem.age_group;
                                        return option.value === x;
                                    })}
                                    name="age_group"
                                    onChange={(e) => handleChange({ age_group: e.value })}
                                />
                                <WarningText keyName="age_group" />
                            </div>

                            <div className="px-0 md:px-8 pt-3 md:pt-0 pb-3">
                                <span className="text-gray-700 font-bold text-14px">Gender</span>
                                <Select
                                    options={genderOptions}
                                    value={genderOptions.filter((option) => {
                                        const x = Array.isArray(editedProductItem)
                                            ? multiProductObject.gender
                                            : editedProductItem.gender;
                                        return option.value === x;
                                    })}
                                    name="gender"
                                    onChange={(e) => handleChange({ gender: e.value })}
                                />
                                <WarningText keyName="gender" />
                            </div>

                            <div className="pl-0 md:pl-4 pt-3 md:pt-0 pb-3">
                                <span className="text-gray-700 font-bold text-14px">Condition</span>
                                <Select
                                    options={productConditionOptions}
                                    value={productConditionOptions.filter((option) => {
                                        const x = Array.isArray(editedProductItem)
                                            ? multiProductObject.condition
                                            : editedProductItem.condition;
                                        return option.value === x;
                                    })}
                                    name="condition"
                                    onChange={(e) => handleChange({ condition: e.value })}
                                />
                                <WarningText keyName="condition" />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="pr-0 md:pr-4 py-3">
                                <span className="text-gray-700 font-bold text-14px">Size system</span>
                                <Select
                                    options={sizeSystemOptions}
                                    value={sizeSystemOptions.filter((option) => {
                                        const x = Array.isArray(editedProductItem)
                                            ? multiProductObject.size_system
                                            : editedProductItem.size_system;
                                        return option.value === x;
                                    })}
                                    name="size_system"
                                    onChange={(e) => handleChange({ size_system: e.value })}
                                />
                                <WarningText keyName="size_system" />
                            </div>

                            <div className="pl-0 md:pl-4 py-3">
                                <span className="text-gray-700 font-bold text-14px">Size type</span>
                                <Select
                                    options={sizeTypeOptions}
                                    value={sizeTypeOptions.filter((option) => {
                                        const x = Array.isArray(editedProductItem)
                                            ? multiProductObject.size_type
                                            : editedProductItem.size_type;
                                        return option.value === x;
                                    })}
                                    name="size_type"
                                    onChange={(e) => handleChange({ size_type: e.value })}
                                />
                                <WarningText keyName="size_type" />
                            </div>
                        </div>

                        <div className="pt-5 pb-3">
                            <TextField
                                label="Manufacturer Part Number (MPN)"
                                name="mpn"
                                value={
                                    Array.isArray(editedProductItem) ? multiProductObject.mpn : editedProductItem.mpn
                                }
                                onChange={handleTextEdit}
                                maxLength={90}
                            />
                            <WarningText keyName="mpn" />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="pr-0 md:pr-4 py-3">
                                <TextField
                                    label="Google product category"
                                    name="google_product_category"
                                    value={
                                        Array.isArray(editedProductItem)
                                            ? multiProductObject.google_product_category
                                            : editedProductItem.google_product_category
                                    }
                                    onChange={handleTextEdit}
                                    maxLength={90}
                                />
                                <WarningText keyName="google_product_category" />
                            </div>

                            <div className="pl-0 md:pl-4 py-3">
                                <TextField
                                    label="Sizes"
                                    name="sizes"
                                    value={
                                        Array.isArray(editedProductItem)
                                            ? multiProductObject.sizes
                                            : editedProductItem.sizes
                                    }
                                    onChange={handleTextEdit}
                                    maxLength={90}
                                />
                                <WarningText keyName="sizes" />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="pr-0 md:pr-4 py-3">
                                <TextField
                                    label="Material"
                                    name="material"
                                    value={
                                        Array.isArray(editedProductItem)
                                            ? multiProductObject.material
                                            : editedProductItem.material
                                    }
                                    onChange={handleTextEdit}
                                    maxLength={90}
                                />
                                <WarningText keyName="material" />
                            </div>

                            <div className="pl-0 md:pl-4 py-3">
                                <TextField
                                    label="Color"
                                    name="color"
                                    value={
                                        Array.isArray(editedProductItem)
                                            ? multiProductObject.color
                                            : editedProductItem.color
                                    }
                                    onChange={handleTextEdit}
                                    maxLength={90}
                                />
                                <WarningText keyName="color" />
                            </div>
                        </div>

                        <div className="mt-8 w-full flex justify-end">
                            <Button
                                disabled={ifUpdateDisabled()}
                                loading={updateLoading}
                                primary={true}
                                onClick={formatObject}
                            >
                                Edit Product
                            </Button>
                            <Button basic={true} onClick={closeModal}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ProductEditModal;
