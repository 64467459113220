import { unionByKey } from "../../utils";

const withSelectProducts = (reducer, actions) => (state, action) => {
    switch (action.type) {
        case actions.GET_SHOPIFY_PRODUCT_REQUESTED:
            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    isLoading: true,
                },
            };
        case actions.GET_SHOPIFY_PRODUCT_SUCCEED:
            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    isLoading: false,
                    totalNumberOfaProducts: action.payload.totalNumberOfaProducts,
                    nextPageUrl: action.payload.nextPageUrl,
                    products: unionByKey(action.payload.products, state.selectProducts.products, "api_id"),
                },
            };
        case actions.GET_SHOPIFY_PRODUCT_FAILED:
            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    isLoading: false,
                },
            };

        case actions.TOGGLE_PRODUCT_SELECTION:
            // eslint-disable-next-line no-case-declarations
            let selectedProducts = JSON.parse(JSON.stringify(state.selectProducts.selectedProducts));
            if (selectedProducts.includes(action.payload.apiId)) {
                selectedProducts = selectedProducts.filter(
                    (selectedProduct) => selectedProduct !== action.payload.apiId
                );
            } else {
                selectedProducts.push(action.payload.apiId);
            }

            if (action.payload.maxSelectedProduct) {
                while (selectedProducts.length > action.payload.maxSelectedProduct) {
                    selectedProducts.shift();
                }
            }

            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    selectedProducts: selectedProducts,
                },
            };

        case actions.SEARCH_SHOPIFY_PRODUCTS:
            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    filters: {
                        ...state.selectProducts.filters,
                        searchQuery: action.payload.searchQuery,
                    },
                },
            };
        case actions.FILTER_PRODUCTS_SELECTION:
            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    filters: {
                        ...state.selectProducts.filters,
                        option: action.payload.option,
                    },
                },
            };

        case actions.CLEAR_SHOPIFY_PRODUCTS:
            return {
                ...state,
                selectProducts: {
                    ...state.selectProducts,
                    isLoading: false,
                    totalNumberOfaProducts: 0,
                    nexPageUrl: null,
                    products: [],
                },
            };

        default:
            return reducer(state, action);
    }
};

export default withSelectProducts;
