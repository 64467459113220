import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

export default function Column({ children, width, otherClasses = [], style }) {
    const classes = classnames("grid__column", {
        [`grid__column--width-${width}`]: width,
    });

    return (
        <div className={`${classes} ${otherClasses}`} style={style}>
            {children}
        </div>
    );
}

Column.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.number.isRequired,
    otherClasses: PropTypes.string,
    style: PropTypes.object,
};

Column.defaultProps = {
    otherClasses: "",
    style: {},
};
