// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3lhUcpeNJ3ielnLRuDyMTY {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  width: 14px;\n  height: 14px;\n  border-width: 8px 0px 8px 16px;\n  border-color: transparent transparent transparent #ffffff;\n}\n", "",{"version":3,"sources":["webpack://./js/components/custom-video-ads/Welcome.module.css"],"names":[],"mappings":"AAAA;EACI,8BAAsB;UAAtB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,yDAAyD;AAC7D","sourcesContent":[".buttonPlay {\n    box-sizing: border-box;\n    width: 14px;\n    height: 14px;\n    border-width: 8px 0px 8px 16px;\n    border-color: transparent transparent transparent #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonPlay": "_3lhUcpeNJ3ielnLRuDyMTY"
};
export default ___CSS_LOADER_EXPORT___;
