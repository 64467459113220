/* eslint-disable no-case-declarations */
import {
    FETCH_LIST_REQUEST,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_ERROR,
    DISPLAY_MORE_BEGIN,
    DISPLAY_MORE_END,
    TOGGLE_COLLAPSE_LIST_ITEM,
    UPDATE_LIST_ITEM_REQUEST,
    UPDATE_LIST_ITEM_FAILURE,
    UPDATE_LIST_ITEM_SUCCESS,
    ADD_FILTER,
    ADD_FILTERS,
    REMOVE_FILTER,
    RETRY_AD_LAUNCH_REQUEST,
    RETRY_AD_LAUNCH_FAILURE,
    SHOW_LOADER,
    REMOVE_LOADER,
} from "../actions";
import { ACTIONS } from "../actions/action-ads";
import dateUtils from "../utils/date";

const getDates = () => {
    const today = new Date();
    const startDateRaw = new Date(today.getFullYear(), today.getMonth(), 1);
    const startDateFormatted = dateUtils.formatDate(startDateRaw);
    const endDateFormatted = dateUtils.formatDate(today);
    return [startDateFormatted, endDateFormatted];
};
const [startDate, endDate] = getDates();

const initial = {
    isLoading: true,
    isScrolling: false,
    count: 0,
    results: [],
    error: null,
    filters: {
        start_date: startDate,
        end_date: endDate,
    },
    next: null,
    type: "",
};

const list = (state = initial, action) => {
    const { payload: { response: { results = [] } = {} } = {} } = action;
    const newResults = results.length > 0 ? results.map((result) => ({ ...result, isExpanded: true })) : [];

    const findAndUpdateListItem = (updatedListItem, key = null, itemModifier = null) => {
        const listResults = JSON.parse(JSON.stringify(state.results));
        const itemKey = key || "id";
        const indexListItem = listResults.findIndex((listItem) => listItem.id === updatedListItem[itemKey]);

        if (indexListItem !== -1) {
            if (itemModifier) {
                listResults[indexListItem] = itemModifier(listResults[indexListItem]);
            } else {
                listResults[indexListItem] = updatedListItem;
            }
        }

        return {
            ...state,
            results: listResults,
            isLoading: false,
        };
    };

    switch (action.type) {
        case FETCH_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                type: action.listType ? action.listType : state.type,
                results: [],
            };
        case FETCH_LIST_SUCCESS:
            return {
                ...state,
                count: action.payload.response.count,
                results: action.payload.type === state.type ? newResults : state.results,
                isLoading: false,
                next: action.payload.response.next,
            };
        case FETCH_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        case DISPLAY_MORE_BEGIN:
            return {
                ...state,
                isScrolling: true,
                isLoading: false,
            };
        case DISPLAY_MORE_END:
            return {
                ...state,
                isLoading: false,
                isScrolling: false,
                results: state.results.length <= state.count ? [...state.results, ...newResults] : state.results,
                next: action.payload.response.next,
            };

        case TOGGLE_COLLAPSE_LIST_ITEM:
            return {
                ...state,
                results: state.results.map((result, ind) =>
                    ind === action.payload.index ? { ...result, isExpanded: !result.isExpanded } : result
                ),
            };

        case UPDATE_LIST_ITEM_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_LIST_ITEM_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case UPDATE_LIST_ITEM_SUCCESS:
            return findAndUpdateListItem(action.payload.data, action.payload.key, action.payload.itemModifier);

        case ACTIONS.UPDATE_REMARKETING_AD_SUCCESS:
            // TODO: Refactor campaign update logic like this, which is more straight forward and easy to understand.
            const updatedResults = JSON.parse(JSON.stringify(state.results));
            const index = updatedResults.findIndex((ad) => {
                if (ad.traffic_channel_remarketing_ad) {
                    return ad.traffic_channel_remarketing_ad.id === action.payload.response.id;
                }
                return ad.id === action.payload.response.id;
            });

            if (updatedResults[index].traffic_channel_remarketing_ad) {
                updatedResults[index].traffic_channel_remarketing_ad = action.payload.response;
            } else {
                updatedResults[index] = action.payload.response;
            }

            return {
                ...state,
                results: updatedResults,
                isLoading: false,
            };

        case ADD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload.filter,
                },
            };

        case ADD_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case REMOVE_FILTER:
            const filters = { ...state.filters };
            action.payload.keys.forEach((key) => {
                if (filters[key]) {
                    delete filters[key];
                }
            });
            return {
                ...state,
                filters: filters,
            };
        case RETRY_AD_LAUNCH_REQUEST: {
            const newAds = [...state.results];

            if (action.payload.adType === "reach") {
                const index = state.results.findIndex((ad) => ad.id === action.payload.adId);
                newAds[index].isLoading = true;
            } else {
                const index = state.results.findIndex(
                    (ad) =>
                        ad.traffic_channel_remarketing_ad &&
                        ad.traffic_channel_remarketing_ad.id === action.payload.adId
                );
                newAds[index].traffic_channel_remarketing_ad.isLoading = true;
            }

            return {
                ...state,
                results: newAds,
            };
        }
        case RETRY_AD_LAUNCH_FAILURE: {
            const newAds = [...state.results];

            if (action.payload.adType === "reach") {
                const index = state.results.findIndex((ad) => ad.id === action.payload.adId);
                newAds[index].isLoading = false;
            } else {
                const index = state.results.findIndex(
                    (ad) =>
                        ad.traffic_channel_remarketing_ad &&
                        ad.traffic_channel_remarketing_ad.id === action.payload.adId
                );
                newAds[index].traffic_channel_remarketing_ad.isLoading = false;
            }

            return {
                ...state,
                results: newAds,
            };
        }
        case ACTIONS.RETRY_REMARKETING_AD_LAUNCH_SUCCESS:
            const newAds = [...state.results];
            const adIndex = newAds.findIndex((ad) => {
                if (ad.traffic_channel_remarketing_ad) {
                    return ad.traffic_channel_remarketing_ad.id === action.payload.response.id;
                }
            });

            newAds[adIndex].traffic_channel_remarketing_ad = action.payload.response;

            return {
                ...state,
                results: newAds,
                isLoading: false,
            };

        case SHOW_LOADER:
            return {
                ...state,
                isLoading: true,
            };
        case REMOVE_LOADER:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default list;
