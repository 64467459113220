import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Card, ButtonGroup, Button } from "@shopify/polaris";

import { setUninstallAction } from "../../actions";

class FirstPage extends Component {
    setAction = (action) => {
        this.props.dispatch(setUninstallAction(action));
    };

    render() {
        return (
            <Fragment>
                <Card title="Experiencing problems?" sectioned={true}>
                    <p>
                        We’re sorry to see you go. Is there anything we can do to change your mind? Please do get in
                        touch with us at <a href="mailto:support@sixads.net">support@sixads.net</a>, we’re sure we can
                        help you solve your issue.
                    </p>
                </Card>
                <Card title="Want to uninstall Sixads App?" sectioned={true}>
                    <p>
                        If you have your mind made up, then you can uninstall Sixads App by going to your Shopify Apps
                        page.
                    </p>
                    <br />
                    <ButtonGroup>
                        <Button plain={true} onClick={() => this.setAction("uninstall")}>
                            Uninstall
                        </Button>
                    </ButtonGroup>
                </Card>
            </Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(FirstPage);
