import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { Modal, Tooltip } from "@shopify/polaris";

import { Badge, BADGE_STATUSES } from "../../common/components/sixads/badge/badge";
import { capitalizedText } from "../../utils";

const mapInternalStatus = (status) => {
    switch (status) {
        case "in_draft":
            return "IN_DRAFT";
        case "ready":
            return "IN_REVIEW";
        case "active":
            return "ACTIVE";
        case "paused":
            return "PAUSED";
        case "error":
            return "SYSTEM_ERROR";
        case "archived":
            return "ARCHIVED";
        default:
            return "";
    }
};

const getBadgeStatusText = (status, statusInternal) => {
    let statusText = null;

    switch (statusInternal) {
        case "in_draft":
        case "ready":
        case "error":
        case "archived":
            statusText = mapInternalStatus(statusInternal);
            break;
        case "active":
        case "paused":
            statusText = status;
            break;
        default:
            break;
    }

    return statusText || "UNKNOWN_ISSUE";
};

const getBadgeStatus = (status, statusInternal) => {
    const statusText = getBadgeStatusText(status, statusInternal);

    switch (statusText) {
        case "IN_REVIEW":
        case "IN_PROCESS":
        case "PENDING":
        case "REVIEW_IN_PROGRESS":
        case "PENDING_REVIEW":
        case "UNDER_APPEAL":
        case "PENDING_BILLING_INFO":
        case "LIMITED":
        case "DESCRIPTIVE":
            return BADGE_STATUSES.INFO;

        case "ACTIVE":
        case "SERVING":
        case "ENABLED":
        case "APPROVED":
        case "APPROVED_LIMITED":
        case "AREA_OF_INTEREST_ONLY":
        case "REVIEWED":
        case "PREAPPROVED":
        case "ELIGIBLE_MAY_SERVE":
            return BADGE_STATUSES.SUCCESS;

        case "WITH_ISSUE":
        case "DISAPPROVED":
        case "UNKNOWN_ISSUE":
        case "SUSPENDED":
        case "PROHIBITED":
        case "FULLY_LIMITED":
        case "SYSTEM_ERROR":
            return BADGE_STATUSES.DANGER;

        case "PAUSED":
        case "CAMPAIGN_PAUSED":
        case "ADSET_PAUSED":
        case "ARCHIVED":
        case "DELETED":
        case "ENDED":
        case "NONE":
        case "REMOVED":
        default:
            return BADGE_STATUSES.DEFAULT;
    }
};

const getFormattedBadgeText = (status, statusInternal) => {
    const statusText = getBadgeStatusText(status, statusInternal);

    // Replace underscore with space and capitalize words
    return (statusText.charAt(0).toUpperCase() + statusText.slice(1).toLowerCase()).split("_").join(" ");
};

function BadgeCell(props) {
    const { effectiveStatus, internalStatus, statusFeedback, resource, resourceRef } = props;
    const badgeStatus = getBadgeStatus(effectiveStatus, internalStatus);

    const [isFeedbackModalActive, setIsFeedbackModalActive] = useState(false);

    const getTooltipText = () => {
        const resourceName = capitalizedText(resource);

        if (internalStatus === "error") {
            return `${resourceName} creation fails. Click to see more details`;
        }

        if (statusFeedback.length) {
            `${resourceName} has ${statusFeedback.length} issue(s) Click to see mode details.`;
        }

        return `${resourceName} has no issues.`;
    };

    return (
        <Fragment>
            <div className="cursor-pointer" onClick={() => setIsFeedbackModalActive(true)}>
                <Tooltip active={false} content={getTooltipText()}>
                    <Badge
                        status={badgeStatus}
                        text={getFormattedBadgeText(effectiveStatus, internalStatus)}
                        id={resourceRef}
                    />
                </Tooltip>
            </div>

            {/* TODO: For some reason using modal inside another modal isn't working properly for our custom made Modal component. So using Polaris modal component here. */}
            <Modal
                small={true}
                title={
                    <div className="text-gray-700 text-3xl font-semibold">
                        This {capitalizedText(resource)} has {statusFeedback.length} issue(s).
                    </div>
                }
                open={isFeedbackModalActive}
                onClose={() => {
                    setIsFeedbackModalActive(false);
                }}
                secondaryActions={[
                    {
                        content: "Close",
                        onAction: () => {
                            setIsFeedbackModalActive(false);
                        },
                    },
                ]}
            >
                <Modal.Section>
                    <div className="pl-6 text-gray-700 font-normal text-14px">
                        {statusFeedback.length > 0 && (
                            <ol className="list-decimal">
                                {statusFeedback.map((feedback, index) => {
                                    return <li key={index} dangerouslySetInnerHTML={{ __html: feedback }} />;
                                })}
                            </ol>
                        )}

                        {statusFeedback.length === 0 && <span>{capitalizedText(resource)} has no issues.</span>}
                    </div>
                </Modal.Section>
            </Modal>
        </Fragment>
    );
}

BadgeCell.propTypes = {
    effectiveStatus: PropTypes.string,
    internalStatus: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    resourceRef: PropTypes.string.isRequired,
};

export default BadgeCell;
