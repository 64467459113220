import { applyMiddleware, createStore, compose } from "redux";

import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import reducer from "../reducers";
import migrations from "./migrations";

import analyticsMiddleware from "../middlewares/analytics/analytics";
import navigationMiddleware from "../middlewares/middleware-navigation";
import timerNavigation from "../middlewares/middleware-timer";

import categoriesMiddleware from "../middlewares/middleware-categories";
import countriesMiddleware from "../middlewares/middleware-countries";
import featuresMiddleware from "../middlewares/middleware-features";
import onboardingMiddleware from "../middlewares/middleware-onboarding";
import storeMiddleware from "../middlewares/middleware-store";
import conversionMiddleware from "../middlewares/middleware-conversion";

const middleware = [
    thunk,
    conversionMiddleware,
    analyticsMiddleware,
    navigationMiddleware,
    timerNavigation,
    categoriesMiddleware,
    countriesMiddleware,
    featuresMiddleware,
    onboardingMiddleware,
    storeMiddleware,
];

const MIGRATION_DEBUG = false;

const persistConfig = {
    key: "sixads_store",
    version: 1,
    migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
    storage: storage,
    whitelist: ["imageOverlayAds", "adTextCreator", "dynamicAds"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
const persistor = persistStore(store);

export { store, persistor };
