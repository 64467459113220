import fetch from "cross-fetch";
import {api} from "../utils"


const targetingSearch = "/api/v1/facebook/targeting-search/";
const dynamicAds = "/api/v1/dynamic-ads-campaigns/";


export const getGeolocation = (query) => {
    return fetch(`${targetingSearch}ad_geolocation?location_types=country&q=${query}`)
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            }
            return {
                status: "error",
                response: res,
            };
        })
        .catch((error) => {
            return error;
        });
};

export const getInterest = (query) => {
    return fetch(`${targetingSearch}ad_interest?q=${query}`)
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            }
            return {
                status: "error",
                response: res,
            };
        })
        .catch((error) => {
            return error;
        });
};

export const getInterestSuggestion = (query) => {
    return fetch(`${targetingSearch}promotion_interest_suggestion/?interest_list=${query}`)
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            }
            return {
                status: "error",
                response: res,
            };
        })
        .catch((error) => {
            return error;
        });
};

export const createDynamicAd = (payload) => {
    return api(`${dynamicAds}create_entire/`, {
            data: payload
        })
        .then((response) => response)
        .catch((error) => {
            return error;
        });
};

export const getAllDynamicAds = async () => {
    try {
        const response = await api(dynamicAds, { method: "GET" });
        return response;
    } catch (error) {
        return error;
    }
};

export const retryDynamicAdCampaign = async (campaignPrimaryKey) => {
    const url = `${dynamicAds+campaignPrimaryKey}/retry-campaign/`;

    try {
        return await api(url, {method: "GET"});
    } catch (error) {
        return error;
    }
};

export const changeDynamicAdsCampaignStatus = async (primaryKey, status) => {
    const url = `${dynamicAds+primaryKey}/change-status/`;
    try {
        return await api(url, {data: {"status": status}});
    } catch (error) {
        return error;
    }
};

export const getPrimaryText = async (primaryKey) => {
    const url = `${dynamicAds+primaryKey}/ad-creatives-primary-text/`;
    try {
        const response = await api(url, { method: "GET" });
        return response;
    } catch (error) {
        return error;
    }
};

export const updateDynamicAdCampaign = async (primaryKey, payload) => {
    return api(`${dynamicAds+primaryKey}/update/`, {
            data: payload
        })
        .then((response) => response)
        .catch((error) => {
            return error;
        });
};
