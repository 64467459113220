import { FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_SUCCESS } from "../actions";

const initial = {
    isLoading: true,
    isError: false,
    counter: 0,
    items: [],
};

export const countries = (state = initial, action) => {
    switch (action.type) {
        case FETCH_COUNTRIES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_COUNTRIES_FAILURE: {
            const counter = state.counter + 1;

            return {
                ...state,
                isLoading: false,
                isError: true,
                counter: counter,
            };
        }

        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                counter: 0,
                items: Array.isArray(action.payload) ? action.payload : [],
            };

        default:
            return state;
    }
};
