/* eslint-disable no-plusplus */
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CardContent, Skeleton, Pagination, FormControlLabel } from "@mui/material";
import { Card, Tabs } from "@shopify/polaris";
import { Icon } from "../../../utils/Icon";
import { GetProductList } from "../../../api/api-google-shopping";
import ProductEditModal from "./ProductEditModal";
import Button from "../../../common/components/button";
import SixadsCheckbox from "../../../common/components/SixadsCheckbox";

const ProductInfo = () => {
    const [products, setProducts] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [currentPage, setCurrentPage] = useState();
    const [totalPage, setTotalPage] = useState(0);
    const [editModalShow, setEditModalShow] = useState(false);
    const [productsForEdit, setProductsForEdit] = useState([]);
    const [allProductSelected, setAllProductSelected] = useState(false);
    const [productSelected, setProductSelected] = useState([
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
        {
            selected: false,
        },
    ]);

    const history = useHistory();
    const limit = 10;

    const tabs = [
        {
            id: "",
            content: "All",
            panelID: "panel-all",
        },
        {
            id: "not_approved",
            content: "Not approved",
            panelID: "panel-not-approved",
        },
        {
            id: "pending",
            content: "Pending",
            panelID: "panel-pending",
        },
        {
            id: "approved",
            content: "Approved",
            panelID: "panel-approved",
        },
    ];

    const checkIfMultipleSelected = () => {
        const selected = productSelected.filter((item) => item.selected === true);
        if (selected.length > 1) {
            return false;
        }
        return true;
    };

    const setValuesToCheckBoxes = (value) => {
        const newProductSelected = productSelected.map(() => ({
            selected: value,
        }));
        setProductSelected(newProductSelected);
        setAllProductSelected(value);
    };

    const handleAllProductSelected = () => {
        if (allProductSelected) {
            setValuesToCheckBoxes(false);
        } else {
            setValuesToCheckBoxes(true);
        }
    };

    const handleTabChange = (selectedTabIndex) => setSelectedTab(selectedTabIndex);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const fetchProductList = async () => {
        GetProductList(tabs[selectedTab].id, limit, currentPage)
            .then((response) => {
                if (!response.error) {
                    setProducts(response.items);
                    setTotalPage(response.pages);
                    setLoading(false);
                } else {
                    setFetchError(true);
                    setLoading(false);
                }
            })
            .catch((e) => {
                if (e.status === 500) {
                    setFetchError(true);
                    setLoading(false);
                }
            });
    };

    const successCallback = (productItem) => {
        let newProductList;
        if (Array.isArray(productItem)) {
            newProductList = [...products];
            for (let i = 0; i < products.length; i++) {
                for (let j = 0; j < productItem.length; j++) {
                    if (products[i].primary_key === productItem[j].primary_key) {
                        newProductList[i] = productItem[j];
                    }
                }
            }
        } else {
            newProductList = products.map((product) => {
                if (product.primary_key === productItem.primary_key) {
                    return productItem;
                }
                return product;
            });
        }
        setProducts(newProductList);
    };

    useEffect(() => {
        const flag = productSelected.find((item) => item.selected === false);
        if (flag) {
            setAllProductSelected(false);
        } else {
            setAllProductSelected(true);
        }
    }, [productSelected]);

    useEffect(() => {
        setCurrentPage(1);
    }, []);

    useEffect(() => {
        if (currentPage) {
            fetchProductList();
            setValuesToCheckBoxes(false);
        }
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        if (currentPage === 1) {
            fetchProductList();
            setValuesToCheckBoxes(false);
        }
    }, [selectedTab]);

    const openModalForMultipleProducts = () => {
        const items = [];
        for (let i = 0; i < productSelected.length; i++) {
            if (productSelected[i].selected) {
                items.push(products[i]);
            }
        }
        setProductsForEdit(items);
        setEditModalShow(true);
    };

    const handleOpenEditModal = (productItem) => {
        setProductsForEdit(productItem);
        setEditModalShow(true);
    };

    const StatusBadge = ({ status }) => {
        const bgColor =
            status === "not_approved"
                ? "bg-red-300"
                : status === "pending"
                ? "bg-gray-300"
                : status === "approved"
                ? "bg-green-300"
                : "";
        const statusText =
            status === "not_approved"
                ? "Not approved"
                : status === "pending"
                ? "Pending"
                : status === "approved"
                ? "Approved"
                : "";
        return <div className={`px-4 py-1 rounded-3xl ${bgColor}`}>{statusText}</div>;
    };

    const ProductItem = ({ productItem, index }) => (
        <div className="px-8 w-full grid grid-cols-12 border border-gray-200">
            <div className="col-span-1 flex items-center py-4">
                <SixadsCheckbox
                    checked={productSelected[index].selected}
                    onChange={(e) => {
                        const selected = productSelected;
                        selected[index].selected = e.target.checked;
                        setProductSelected([...selected]);
                    }}
                />
            </div>
            <div className="col-span-7 flex items-center py-4">
                <div className="border border-gray-400 mr-4">
                    <img className="w-16 h-16 mx-auto" src={productItem.image_link} alt="Product" />
                </div>
                <div className="flex flex-col">
                    <div className="text-blue-500 pb-1 pl-2">{productItem.title}</div>
                    <div className="flex flex-col">
                        {productItem.status.item_level_issues
                            ? productItem.status.item_level_issues.map((issue) => (
                                  <div className="flex flex-row p-1 items-center" key={issue.detail}>
                                      <div className="pr-2">
                                          <Icon name="errorCircleFilled" />
                                      </div>
                                      <div className="welcome-description ">{issue.detail}</div>
                                  </div>
                              ))
                            : ""}
                    </div>
                </div>
            </div>
            <div className="col-span-4 px-5 py-3 flex justify-end">
                <div className="grid grid-cols-2 items-center">
                    <div className="pr-2">
                        <div className="flex justify-end">
                            <StatusBadge status={productItem.status.shopping_destination_status} />
                        </div>
                    </div>
                    <div
                        className="pl-2 text-blue-600"
                        style={{ textDecorationLine: "underline", cursor: "pointer" }}
                        onClick={() => handleOpenEditModal(productItem)}
                    >
                        Edit Google fields
                    </div>
                </div>
            </div>
        </div>
    );

    const ProductList = () => (
        <Fragment>
            <div className="pl-12 pr-11 w-full border border-gray-200 flex justify-between">
                <FormControlLabel
                    control={<SixadsCheckbox checked={allProductSelected} onChange={handleAllProductSelected} />}
                    label={<div className="welcome-description">Select all</div>}
                />
                <Button primary={true} disabled={checkIfMultipleSelected()} onClick={openModalForMultipleProducts}>
                    Edit All items
                </Button>
            </div>
            {products.map((filteredProduct, index) => (
                <ProductItem productItem={filteredProduct} key={filteredProduct.primary_key} index={index} />
            ))}
            <div className="py-5 w-full flex justify-center">
                <Pagination
                    shape="rounded"
                    size="large"
                    count={totalPage}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            </div>
        </Fragment>
    );

    const LoadingComponent = () => (
        <CardContent>
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />

            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
        </CardContent>
    );

    const TabsView = () => (
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
            {loading && <LoadingComponent />}
            {!loading && <ProductList />}
        </Tabs>
    );

    const NotConnectedView = () => (
        <div className="p-10">
            <h1 className="page-title pl-1">Connect Merchant Center Account</h1>
            <p className="welcome-description pt-10 pl-1">
                You have not connected us with your Google Merchant Center account yet. Please go to the Google Shopping
                Settings page and connect your Merchant Center Account if you already have one. You can also create new
                one if you do not have any Merchant Center Account.
            </p>
            <div className="w-full flex justify-end mt-24">
                <Button
                    class="text-16px"
                    primary={true}
                    onClick={() => {
                        history.push("/google-shopping/create-campaign/connect-accounts/");
                    }}
                >
                    Goto settings page
                </Button>
            </div>
        </div>
    );

    return (
        <div className="pt-28 pb-14">
            <div className="px-4 md:px-28">
                <div className="font-sf-text text-26px text-gray-900 font-bold leading-10">
                    {fetchError ? "" : "Products"}
                </div>
                <div className="mt-10">
                    <Card>{fetchError ? <NotConnectedView /> : <TabsView />}</Card>
                </div>
            </div>

            <ProductEditModal
                productItems={productsForEdit}
                editModalShow={editModalShow}
                setEditModalShow={setEditModalShow}
                successCallback={successCallback}
            />
        </div>
    );
};

export default ProductInfo;
