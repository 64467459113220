/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../common/components/button";
import Plans from "../../../pages/onboarding-v2/plans/Plans";
import { logAnalytics } from "../../../actions";

const DynamicAdsPlans = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { subscription } = props;
    const budgetPageUrl = "/dynamic-ads/create-campaign/budget-and-schedule/";

    useEffect(() => {
        if (subscription.id) {
            history.push(budgetPageUrl);
        }
    }, [subscription]);

    const handleNextClick = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Sixads plan subscription",
                eventAction: "Button click",
                eventLabel: "Next",
            })
        );

        history.push(budgetPageUrl);
    };

    return (
        <Fragment>
            <div>
                <div>
                    <Plans returnUrl={budgetPageUrl} freeUrl="/manage-campaigns/" />
                </div>
                <div className="bg-white fixed bottom-0 w-full py-6">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 flex justify-end">
                        <Button primary={true} onClick={handleNextClick} disabled={!subscription.id}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        subscription: state.store.subscription,
    };
};

export default connect(mapStateToProps)(DynamicAdsPlans);
