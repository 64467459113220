import React, { Fragment } from "react";
import Proptypes from "prop-types";
import { FormLayout } from "@shopify/polaris";

import styles from "./styles";
import DateSelector from "./DateSelector";

const Dates = (props) => {
    const { dates, onUpdateDates } = props;

    return (
        <Fragment>
            <FormLayout>
                <FormLayout.Group condensed={false}>
                    <div className="date-picker">
                        <DateSelector dates={dates} onUpdateDates={onUpdateDates} />
                    </div>
                </FormLayout.Group>
            </FormLayout>

            <style jsx={true}>{styles}</style>
        </Fragment>
    );
};

Dates.propTypes = {
    dates: Proptypes.shape({
        start: Proptypes.instanceOf(Date),
        end: Proptypes.instanceOf(Date),
    }).isRequired,
    onUpdateDates: Proptypes.func.isRequired,
};

export default Dates;
