import React, { Component, Fragment } from "react";

import { Link } from "react-router-dom";

import { Banner } from "@shopify/polaris";

class Feedback extends Component {
    getHeading = (option) => {
        switch (option) {
            case "0":
                return "Thank you for showing interest in making money by showing ads on your website.";
            case "1":
                return "Your account was successfully paused.";
            case "2":
                return "From now on ads on your webstore are not showing.";
            case "3":
                return "Your request to cancel subscription was successfully sent.";
            case "4":
                return "Your issue was successfully submitted.";
            case "5":
                return (
                    <Fragment>
                        Use your code <i>halfprice</i>
                    </Fragment>
                );
            default:
                return "";
        }
    };

    render() {
        const { option } = this.props;

        return (
            <Banner title={this.getHeading(option)} status="success">
                {option === "0" && (
                    <p>We will get back to you with more details in no longer than the next 48 hours.</p>
                )}
                {option === "4" && <p>You will get a response in no longer than the next 48 hours.</p>}
                {option === "5" && (
                    <p>
                        Use your code{" "}
                        <b>
                            <i>halfprice</i>
                        </b>{" "}
                        when choosing a Boosted Ads plan here{" "}
                        <Link to="/boosted-ads/">https://app.sixads.net/boosted-ads/</Link>.
                    </p>
                )}
            </Banner>
        );
    }
}

export default Feedback;
