import React, { Component, Fragment } from "react";

class TableHead extends Component {
    render() {
        const { children } = this.props;

        return (
            <Fragment>
                <thead className="rounded-t-md bg-white shadow-lg font-semibold text-gray-700">{children}</thead>
            </Fragment>
        );
    }
}

export default TableHead;
