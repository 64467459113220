import { get } from "../api/api-countries";

export const FETCH_COUNTRIES_REQUEST = "FETCH_COUNTRIES_REQUEST";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";

export const fetchCountriesRequest = (payload) => ({
    type: FETCH_COUNTRIES_REQUEST,
    payload: payload,
});

export const fetchCountriesFailure = (payload) => ({
    type: FETCH_COUNTRIES_FAILURE,
    payload: payload,
});

export const fetchCountriesSuccess = (payload) => ({
    type: FETCH_COUNTRIES_SUCCESS,
    payload: payload,
});

const fetchCountries = () => (dispatch) => {
    dispatch(fetchCountriesRequest());

    return get()
        .then((payload) => {
            if (payload.status === "error") {
                dispatch(fetchCountriesFailure(payload));
            } else {
                dispatch(fetchCountriesSuccess(payload));
            }
        })
        .catch((error) => {
            throw error;
        });
};

const shouldfetchCountries = (state) => {
    const { countries } = state;

    if (!countries.items || !countries.items.length) {
        return true;
    }

    if (countries.isLoading) {
        return false;
    }

    return countries.isError;
};

export const loadCountries = () => (dispatch, getState) => {
    if (shouldfetchCountries(getState())) {
        return dispatch(fetchCountries());
    }
};
