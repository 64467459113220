import {api} from "../utils";

export const get = (apiUrl, type) => {
    const url = `/api/v1/${apiUrl}?format=json`;
    return api(url, {
        method: 'GET',
    })
        .then((response) => {
            return {
                response: response,
                type: type,
            }
        })
        .catch((error) => {
            return error;
        });
}


export const loadMore = (nextUrl) => {
    if (!nextUrl)
        return;
    return api(nextUrl, {
        method: 'GET',
    })
        .then((response) => {
            return { response: response };
        })
        .catch((error) => {
            return error;
        });
}
