export const stripLen = (str = "", limit = 10) => {
    return str && str.length > limit ? `${str.slice(0, limit - 1)}...` : str;
};

export const capitalizedText = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const getFormattedChannelName = (channelName) => {
    if (channelName) {
        return channelName.charAt(0).toUpperCase() + channelName.slice(1);
    }

    return "";
};
