import { useEffect, useState } from "react";
import { getInterest } from "../../../api/api-facebook-ads";

export default function useInterestSearch(query, list) {
    const [loading, setLoading] = useState(true);
    const [interests, setInterests] = useState([]);

    useEffect(() => {
        setInterests([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        if (query.length) {
            getInterest(query).then((res) => {
                if (res.status !== "error") {
                    const audiences = res.map((data) => {
                        const temp = list.filter((item) => item.value === data.id);
                        if (!temp.length) {
                            return { label: data.name, value: data.id };
                        }
                    });
                    setInterests(audiences.filter((item) => item));
                    setLoading(false);
                } else {
                    setInterests([]);
                    setLoading(true);
                }
            });
        }
    }, [query]);

    return [interests, loading];
}
