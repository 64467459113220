import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

const AdImage = ({ imageUrl }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const ref = useRef(null);

    const handleResize = () => {
        setContainerWidth(ref.current.offsetWidth);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [ref.current]);

    return (
        <div ref={ref} className="ad-image" style={{ height: `${containerWidth * 1.2}px` }}>
            <img className="DesktopNewsFeed__product__logo__img" src={imageUrl} alt="Ad template icon" />
        </div>
    );
};

AdImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
};

const AdDescription = ({ primaryText, description }) => {
    return (
        <div className="grid grid-cols-3 p-6">
            <div className="col-span-2">
                <p className="primary-text">{primaryText}</p>
                <p className="description">{description}</p>
            </div>
            {/* eslint-disable-next-line react/button-has-type */}
            <div className="flex justify-center items-center">
                <button className="description-button">Learn more</button>
            </div>
        </div>
    );
};

AdDescription.propTypes = {
    primaryText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

const AdView = (props) => {
    const { imageUrl, primaryText, description } = props;

    return (
        <div className="ad-view-wrapper">
            <div className="ad-view">
                <AdImage imageUrl={imageUrl} />
                <AdDescription primaryText={primaryText} description={description} />
            </div>
        </div>
    );
};

AdView.propTypes = {
    primaryText: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default AdView;
