import React, { Component, Fragment } from "react";

class Table extends Component {
    render() {
        const { children, classes } = this.props;

        return (
            <Fragment>
                <table className={`table-auto w-full font-sf-text ${classes}`}>{children}</table>
            </Fragment>
        );
    }
}

export default Table;
