import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { toggleNavigation } from "../../actions";
import Button from "../../common/components/button";
import SelectTemplate from "./Select-Template";
import VideoEditor from "./Video-Editor";
import Success from "../../components/video-ads/Success";

const SelectTemplateRoute = "/video-ads-v2/select-template/";
const CustomizeRoute = "/video-ads-v2/customize/";
const SuccessRoute = "/video-ads-v2/success/";

const VideoAdsV2Router = ({ history, imageUrl }) => {
    const dispatch = useDispatch();
    const ImageQueryParam = "?image=" + imageUrl;
    return (
        <Switch>
            <Route path={SelectTemplateRoute} exact={true}>
                <SelectTemplate />
            </Route>
            <Route path={CustomizeRoute} exact={true}>
                <VideoEditor />
            </Route>
            <Route path={SuccessRoute} exact={true}>
                <Success linkBack={SelectTemplateRoute + ImageQueryParam} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = (state) => {
    const {
        videoAds: {
            pages: {
                template: {
                    values: { selectedDefaultProduct },
                },
            },
        },
    } = state;

    if (selectedDefaultProduct) {
        return {
            imageUrl: selectedDefaultProduct.image,
        };
    }

    return {
        imageUrl: "",
    };
};

export default withRouter(connect(mapStateToProps)(VideoAdsV2Router));
