/* eslint-disable no-unused-vars */

export const SAVE_EDITED_PRODUCT_INFO = "SAVE_EDITED_PRODUCT_INFO";

export const saveEditedProductInfo = (payload) => {
    return {
        type: SAVE_EDITED_PRODUCT_INFO,
        payload,
    };
};
