import {
    EXIT_VIDEO_ADS,
    INIT_VIDEO_ADS,
    NAVIGATE_VIDEO_ADS,
    PUT_VIDEO_ADS_ERROR,
    PUT_VIDEO_ADS_VALUE,
    SET_VIDEO_ADS_OPTION,
    SKIP_VIDEO_ADS,
} from "../actions";

const initial = {
    step: 1,
    progres: 10,
    pages: {
        welcome: {
            values: {
                text: "",
            },
            errors: [],
        },
        product: {
            values: {
                selectedProducts: null,
            },
            errors: [],
        },
        template: {
            values: {
                selectedTemplate: "",
            },
            errors: [],
        },
        edit: {
            values: {},
            errors: [],
        },
        success: {
            values: {},
            errors: [],
        },
    },
    current: {
        hasErrors: false,
    },
};

// eslint-disable-next-line import/prefer-default-export
export const videoAds = (state = initial, action) => {
    switch (action.type) {
        case INIT_VIDEO_ADS:
            return {
                ...state,
                isActive: true,
                step: action.payload.step || 0,
                option: action.payload.step ? "manually" : "",
            };
        case EXIT_VIDEO_ADS:
            return {
                ...state,
                isActive: false,
            };
        case SKIP_VIDEO_ADS:
            return {
                ...state,
                isActive: false,
                isSkipped: true,
            };
        case NAVIGATE_VIDEO_ADS:
            return {
                ...state,
                step: action.step,
            };
        case SET_VIDEO_ADS_OPTION:
            return {
                ...state,
                step: action.step,
                option: action.option,
            };
        case PUT_VIDEO_ADS_VALUE:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page]: {
                        values: {
                            ...action.values,
                        },
                        errors: [...state.pages[action.page].errors],
                    },
                },
            };
        case PUT_VIDEO_ADS_ERROR:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page]: {
                        values: {
                            ...state.pages[action.page].values,
                        },
                        errors: [...action.errors],
                    },
                },
            };
        default:
            return state;
    }
};
