import React from "react";

import { Card } from "@shopify/polaris";

import ListRoot from "../list";
import ListItemGrid from "../list-item-grid";
import { createWithClassesOrStyles } from "../../../../../utils/override";
import FilterRoot from "./filter";

export const Root = (props) => <Card {...props} />;

export const Filter = (props) => <FilterRoot {...props} />;

export const List = (props) => <ListRoot {...props} />;

export const ListItem = (props) => <ListItemGrid {...props} />;

export const EmptyList = (props) =>
    createWithClassesOrStyles({
        type: "div",
        styles: { padding: "20px" },
        props: props,
    });
