export const API_URL = process.env.NEXT_PUBLIC_BASE_API_URL || "/api/v1/";
export const GOOGLE_SHOPPING_CORE = `${API_URL}google-shopping`;
export const ACCOUNT_URL = `${GOOGLE_SHOPPING_CORE}/accounts`;
export const GUIDELINE_REQUIREMENTS_LIST = `${ACCOUNT_URL}/guideline-requirements`;
export const STORE_REQUIREMENTS_LIST = `${ACCOUNT_URL}/store-requirements`;
export const UPDATE_STORE_REQUIREMENTS_LIST = `${ACCOUNT_URL}/accept-store-requirement`;
export const PRODUCT_URL = `${ACCOUNT_URL}/merchant-account/products`;
export const MULTI_PRODUCT_UPDATE_URL = `${ACCOUNT_URL}/merchant-account/products/update`;
export const COUNTRY_LIST = `${GOOGLE_SHOPPING_CORE}/countries/list`;
export const TARGETING_URL = `${GOOGLE_SHOPPING_CORE}/targeting`;
export const CAMPAIGN_URL = `${GOOGLE_SHOPPING_CORE}/campaigns`;

export const VERSION_STATES = {
    CORE_ACCOUNTS: "core-accounts",
    STORE_REQUIREMENTS: "store-requirements",
    GOOGLE_ADS_ACCOUNT_BILLING: "google-ads-account-billing",
    GOOGLE_ADS_ACCOUNT_DETAILS: "google-ads-account-details",
    GOOGLE_ADS_ACCOUNT_LIST: "google-ads-account-list",
    GOOGLE_MERCHANT_ACCOUNT_LIST: "google-merchant-account-list",
    GOOGLE_MERCHANT_ACCOUNT_DETAILS: "google-merchant-account-details",
    GOOGLE_MERCHANT_ACCOUNT_SHIPPING: "google-merchant-account-shipping",
    GOOGLE_MERCHANT_ACCOUNT_TAX: "google-merchant-account-tax",
    GOOGLE_CREDENTIALS_VALIDITY: "google-credentials-validity",
};
