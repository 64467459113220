import { create } from "../api/api-features";

export const CREATE_FEATURES_REQUEST = "CREATE_FEATURES_REQUEST";

export const FETCH_FEATURES_REQUEST = "FETCH_FEATURES_REQUEST";
export const FETCH_FEATURES_FAILURE = "FETCH_FEATURES_FAILURE";
export const FETCH_FEATURES_SUCCESS = "FETCH_FEATURES_SUCCESS";

export const createFeaturesRequest = (request) => ({
    type: CREATE_FEATURES_REQUEST,
    request: request,
});

export const fetchFeaturesRequest = (payload) => ({
    type: FETCH_FEATURES_REQUEST,
    payload: payload,
});

export const fetchFeaturesFailure = () => ({
    type: FETCH_FEATURES_FAILURE,
});

export const fetchFeaturesSuccess = (payload) => ({
    type: FETCH_FEATURES_SUCCESS,
    payload: payload,
});

export const sendFeaturesRequest = () => (dispatch, getState) => {
    const { features } = getState();

    const data = features.requests && features.requests[0] ? features.requests[0] : null;

    if (!data) {
        return;
    }

    return create(data)
        .then((payload) => {
            if (payload.status === "error") {
                dispatch(fetchFeaturesFailure());
            } else {
                dispatch(fetchFeaturesSuccess({ item: payload }));
            }
        })
        .catch((error) => {
            throw error;
        });
};

export const createFeatureRequest = (data) => (dispatch) => {
    dispatch(createFeaturesRequest(data));

    return dispatch(sendFeaturesRequest());
};
