import React, { useCallback, Fragment } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import Button from "../../common/components/button";

const Thanks = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleCloseButton = () => {
        history.push("/video-ads/select-template/");
    };

    return (
        <Fragment>
            <div className="flex flex-col md:flex-row h-screen">
                <div className="flex-1 bg-white flex items-center justify-center">
                    <div className="w-2/5 md:w-2/4 md:h-1/3 flex flex-col justify-center items-center">
                        <div className="pb-6">
                            <h1 className="page-title pb-6 md:pb-10 text-center">
                                Thanks! <br />
                            </h1>
                            <p className="leading-140p text-18px pb-6">
                                Our team of designers are now working on other stores, you’re next in line & we'll be in
                                touch soon.
                            </p>
                        </div>

                        <Button primary={true} onClick={handleCloseButton}>
                            CLOSE
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(Thanks);
