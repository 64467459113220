/* eslint-disable default-case */
/* eslint-disable camelcase */
import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Tooltip } from "@shopify/polaris";

import { Table, TableBody, TableData, TableHeader, TableRow } from "../../../common/components/table";
import DynamicAdCampaignHeader from "./DynamicAdCampaignHeader";
import DynamicAdCampaignRow from "./DynamicAdCampaignRow";
import Loader from "../../../common/components/sixads/loader/loader";
import useListHook from "../hooks/use-list-hook";
import { getFormattedPrice, roundNumber, stripLen } from "../../../utils";
import RetryResourceLaunch from "./RetryResourceLaunch";
import {
    retryLaunchingReachAd,
    retryLaunchingReachAdSet,
    retryLaunchingRemarketingAd,
    retryLaunchingRemarketingAdSet,
    retryLaunchingUpsellAd,
    retryLaunchingUpsellAdSet,
} from "../../../actions";
import BadgeCell from "../../dynamic-ads/BadgeCell";

const AdSetsAndAds = ({ campaign, campaignType }) => {
    const dispatch = useDispatch();

    const adSetAdTableBorderColor =
        campaignType === "reach" ? "blue" : campaignType === "remarketing" ? "yellow" : "red";

    const handleRetryLaunchingAdSet = (adSet) => {
        switch (campaignType) {
            case "reach":
                dispatch(retryLaunchingReachAdSet(adSet.primary_key));
                break;
            case "remarketing":
                dispatch(retryLaunchingRemarketingAdSet(adSet.primary_key));
                break;
            case "upsell":
                dispatch(retryLaunchingUpsellAdSet(adSet.primary_key));
                break;
        }
    };

    const handleRetryLaunchingAd = (ad) => {
        switch (campaignType) {
            case "reach":
                dispatch(retryLaunchingReachAd(ad.primary_key));
                break;
            case "remarketing":
                dispatch(retryLaunchingRemarketingAd(ad.primary_key));
                break;
            case "upsell":
                dispatch(retryLaunchingUpsellAd(ad.primary_key));
                break;
        }
    };

    return (
        <TableRow>
            <td colSpan={13} className="py-8 px-4">
                <div className="">
                    <h3 className="text-gray-700 font-semibold pb-4">{campaign.name}</h3>
                    <Table classes={`border-2 border-${adSetAdTableBorderColor}-300`}>
                        <TableBody>
                            <TableRow classes={`border-b border-${adSetAdTableBorderColor}-200`}>
                                <TableHeader>
                                    <span className="font-bold">Resource type</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Delivery</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Name</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Impressions</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Clicks</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">CTR</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">CPC</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Cost</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Add to carts</span>
                                </TableHeader>
                                <TableHeader>
                                    <span className="font-bold">Sales</span>
                                </TableHeader>
                            </TableRow>
                            {campaign.ad_sets.map((adSet) => {
                                return (
                                    <Fragment key={adSet.primary_key}>
                                        <TableRow classes={`border-b border-t border-${adSetAdTableBorderColor}-200`}>
                                            <TableData tdClasses={`border-r border-${adSetAdTableBorderColor}-200`}>
                                                <span className="font-bold">Ad set</span>
                                            </TableData>
                                            <TableData>
                                                <BadgeCell
                                                    effectiveStatus={adSet.effective_status}
                                                    internalStatus={adSet.internal_status}
                                                    statusFeedback={adSet.status_feedback}
                                                    resource="adset"
                                                    resourceRef={`adset-${adSet.primary_key}`}
                                                />
                                                {adSet.internal_status === "error" && (
                                                    <RetryResourceLaunch
                                                        resource="Adset"
                                                        isRetryLoading={adSet.isLoading}
                                                        onRetryLaunch={() => handleRetryLaunchingAdSet(adSet)}
                                                    />
                                                )}
                                            </TableData>
                                            <TableData>
                                                <Tooltip active={false} content={adSet.name}>
                                                    <span className="underline">{stripLen(adSet.name, 25)}</span>
                                                </Tooltip>
                                            </TableData>
                                            <TableData>{adSet.insights.impressions}</TableData>
                                            <TableData>{adSet.insights.clicks}</TableData>
                                            <TableData>{roundNumber(adSet.insights.ctr)}%</TableData>
                                            <TableData>
                                                {adSet.currency
                                                    ? getFormattedPrice(adSet.insights.cpc, adSet.currency)
                                                    : adSet.insights.cpc}
                                            </TableData>
                                            <TableData>
                                                {adSet.currency
                                                    ? getFormattedPrice(adSet.insights.cost, adSet.currency)
                                                    : adSet.insights.cost}
                                            </TableData>
                                            <TableData>{adSet.insights.add_to_cart}</TableData>
                                            <TableData>{adSet.insights.sales}</TableData>
                                        </TableRow>
                                        {adSet.ads.map((ad, index) => {
                                            return (
                                                <TableRow key={ad.primary_key}>
                                                    {index === 0 && (
                                                        <TableData
                                                            tdClasses="border-r border-blue-200"
                                                            rowSpan={adSet.ads.length}
                                                        >
                                                            <span className="font-bold">Ad</span>
                                                        </TableData>
                                                    )}
                                                    <TableData>
                                                        <BadgeCell
                                                            effectiveStatus={ad.effective_status}
                                                            internalStatus={ad.internal_status}
                                                            statusFeedback={ad.status_feedback}
                                                            resource="ad"
                                                            resourceRef={`ad-${ad.primary_key}`}
                                                        />
                                                        {ad.internal_status === "error" && (
                                                            <RetryResourceLaunch
                                                                resource="Ad"
                                                                isRetryLoading={ad.isLoading}
                                                                onRetryLaunch={() => handleRetryLaunchingAd(ad)}
                                                            />
                                                        )}
                                                    </TableData>
                                                    <TableData>
                                                        <Tooltip active={false} content={ad.name}>
                                                            <span className="underline">{stripLen(ad.name, 25)}</span>
                                                        </Tooltip>
                                                    </TableData>
                                                    <TableData>{ad.insights.impressions}</TableData>
                                                    <TableData>{ad.insights.clicks}</TableData>
                                                    <TableData>{roundNumber(ad.insights.ctr)}%</TableData>
                                                    <TableData>
                                                        {ad.currency
                                                            ? getFormattedPrice(ad.insights.cpc, ad.currency)
                                                            : ad.insights.cpc}
                                                    </TableData>
                                                    <TableData>
                                                        {ad.currency
                                                            ? getFormattedPrice(ad.insights.cost, ad.currency)
                                                            : ad.insights.cost}
                                                    </TableData>
                                                    <TableData>{ad.insights.add_to_cart}</TableData>
                                                    <TableData>{ad.insights.sales}</TableData>
                                                </TableRow>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </td>
        </TableRow>
    );
};

const CampaignTable = (props) => {
    const { campaign_type } = props;
    const { result: campaigns, isLoading, loadList } = useListHook(campaign_type);

    useEffect(() => {
        loadList();
    }, [loadList]);

    return (
        <div
            className="bg-transparent mt-8 flex flex-col overflow-auto"
            style={{ maxHeight: "50vh", minHeight: "70px" }}
        >
            <div className="flex-grow overflow-auto">
                <Table>
                    <DynamicAdCampaignHeader campaign_type={campaign_type} />
                    <TableBody>
                        {!isLoading && (
                            <Fragment>
                                {campaigns.map((campaign) => (
                                    <Fragment key={campaign.primary_key}>
                                        <DynamicAdCampaignRow
                                            id={campaign.id}
                                            key={campaign.primary_key}
                                            primaryKey={campaign.primary_key}
                                            name={campaign.name}
                                            namePrefix={
                                                <div>
                                                    {campaign.image_url && (
                                                        <img
                                                            height={80}
                                                            width={80}
                                                            className="h-ful l pr-4"
                                                            src={campaign.image_url}
                                                            alt={campaign.name}
                                                        />
                                                    )}
                                                </div>
                                            }
                                            campaign_type={campaign_type}
                                            dailyBudget={campaign.daily_budget}
                                            startDate={campaign.start_time}
                                            endDate={campaign.stop_time}
                                            effectiveStatus={campaign.effective_status}
                                            internalStatus={campaign.internal_status}
                                            statusFeedback={campaign.status_feedback}
                                            insights={campaign.insights}
                                            currency={campaign.currency}
                                            backgroundClass=""
                                            inactive={false}
                                            isExpanded={campaign.isExpanded}
                                        />

                                        {campaign.isExpanded && (
                                            <AdSetsAndAds campaign={campaign} campaignType={campaign_type} />
                                        )}
                                    </Fragment>
                                ))}
                                {!campaigns.length ? (
                                    <DynamicAdCampaignRow
                                        campaign_type={campaign_type}
                                        backgroundClass=""
                                        inactive={true}
                                    />
                                ) : (
                                    <Fragment />
                                )}
                            </Fragment>
                        )}
                    </TableBody>
                </Table>
                {isLoading && <Loader />}
            </div>
        </div>
    );
};

CampaignTable.propTypes = {
    campaign_type: PropTypes.string.isRequired,
};

export default CampaignTable;
