/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template0 = ({
    name,
    image = `${window.location.origin}/static/img/video_promotions/template_0/HeroImg.jpg?1624295624490`,
    price,
    adMessage,
    paused = false,
}) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"] = adMessage || "Special Discount";

        animationObject["ProductNameObj"]["text"]["text"] = name;
        animationObject["ProductNameObj2"]["text"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow"]["text"] = price;

        const images = AdobeAn.getComposition("903F77C0D948D94FB75E1A5C3896175E").getImages();
        images["HeroImg"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_0"
                composition="903F77C0D948D94FB75E1A5C3896175E"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template0.design_name = "Sports";

export default Template0;
