import React from "react";
import { Grid, Card, CardContent, Skeleton } from "@mui/material";

const SettingsLayout = ({
    header = "",
    loading = false,
    disabled = false,
    subtitle = "",
    children,
    active = false,
}) => (
    <Grid container={true} spacing={3} className={disabled ? "opacity-40 pt-5" : "pt-5"}>
        <Grid item={true} xs={12} sm={12} lg={3} className={subtitle ? "" : "flex items-center"}>
            <span className="google-settings-header">{header}</span>
            {subtitle && <div className="welcome-description">{subtitle}</div>}
        </Grid>
        <Grid item={true} xs={12} sm={12} lg={9}>
            <Card className={active ? "border-green-600 border-2" : ""}>
                {!loading && (
                    <CardContent>
                        <div className="text-2xl">{children}</div>
                    </CardContent>
                )}
                {loading && (
                    <CardContent>
                        <Skeleton variant="text" animation="wave" />
                        <Skeleton variant="text" animation="wave" />
                        <Skeleton variant="text" animation="wave" />
                    </CardContent>
                )}
            </Card>
        </Grid>
    </Grid>
);

export default SettingsLayout;
