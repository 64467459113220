import css from "styled-jsx/css";

export default css`
    .box {
        background: white;
        display: flex;
        flex-direction: column;
    }

    .box-header {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-image: linear-gradient(to bottom, #d2dde8, #f9fbfe);
        padding-top: 2rem;
        text-align: center;
    }

    .box-body {
        flex: 1;
        min-height: 210px;
        margin: 0.5rem 0;
        padding: 0 2rem;
        margin-top: 2rem;
    }

    .box-footer {
        flex: 0.2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0 40px 0;
    }

    @media screen and (max-width: 700px) {

        div.notification:empty {
            flex: 0;
        }
    }

    .banner {
        display: flex;
        text-align: center;
        font-size: 12px;
        justify-items: center;
        margin: 0 auto;
        margin-top: -14px;
        color: #676302;
        background: #FFE792;
        padding: 4px;
        border-radius: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;
