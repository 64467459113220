import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { roundNumber } from "../../../../utils";

import { Icon } from "../../../../utils/Icon";

const InsightsOverviewWidget = () => {
    const reachCampaigns = useSelector((state) => state.reachList.results);
    const remarketingCampaigns = useSelector((state) => state.remarketingList.results);
    const upsellCampaigns = useSelector((state) => state.upsellList.results);
    const campaigns = [...reachCampaigns, ...remarketingCampaigns, ...upsellCampaigns];
    const insights = {
        impression: 0,
        ctr: 0,
        addToCart: 0,
    };
    campaigns.forEach((campaign) => {
        if (campaign.insights) {
            insights["impression"] += campaign.insights.impressions;
            insights["ctr"] += campaign.insights.ctr;
            insights["addToCart"] += campaign.insights.add_to_cart;
        }
    });
    insights.ctr *= 100;

    return (
        <Fragment>
            <div className="grid grid-cols-1 gap-8">
                <div className="border border-gray-275 rounded-2xl shadow-md bg-white">
                    <div className="py-10 grid grid-cols-5">
                        <div className="col-span-2 justify-self-center">
                            <Icon name="impressions" />
                        </div>
                        <div className="col-span-3 text-gray-900">
                            <p>Impressions</p>
                            <h1 className="text-5xl font-bold w-36 truncate">{insights.impression}</h1>
                        </div>
                    </div>
                </div>
                <div className="border border-gray-275 rounded-2xl shadow-md bg-white">
                    <div className="py-10 grid grid-cols-5">
                        <div className="col-span-2 justify-self-center">
                            <Icon name="ctr" />
                        </div>
                        <div className="col-span-3 text-gray-900">
                            <p>Click-Through Rate</p>
                            <h1 className="text-5xl font-bold w-36 truncate">{roundNumber(insights.ctr)}</h1>
                        </div>
                    </div>
                </div>
                <div className="border border-gray-275 rounded-2xl shadow-md bg-white">
                    <div className="py-10 grid grid-cols-5">
                        <div className="col-span-2 justify-self-center">
                            <Icon name="addToCart" />
                        </div>
                        <div className="col-span-3 text-gray-900">
                            <p>Add to Cart</p>
                            <h1 className="text-5xl font-bold w-36 truncate">{insights.addToCart}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default InsightsOverviewWidget;
