export const TOGGLE_POPUP = "TOGGLE_POPUP";
export const REVIEW_POPUP = "REVIEW_POPUP";

export const showReviewPopup = () => {
    return {
        type: TOGGLE_POPUP,
        payload: {
            type: REVIEW_POPUP,
            show: true,
        },
    };
};
