import { TOGGLE_POPUP } from "../actions/action-popup";

const initial = {
    type: null,
    show: false,
};

export const popUp = (state = initial, action) => {
    switch (action.type) {
        case TOGGLE_POPUP:
            return {
                ...state,
                type: action.payload.type,
                show: action.payload.show,
            };

        default:
            return state;
    }
};
