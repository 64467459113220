/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { api } from "../../utils";
import Button from "../../common/components/button";
import BrowserNotSupportedBanner from "../../pages/video-ads/BrowserNotSupportedBanner";
import { logAnalytics, navigateVideoAds, toggleToastPopup } from "../../actions";
import VideoEditorPriceV2 from "./VideoEditorPriceV2";
import {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    Template6,
    Template7,
    Template8,
    Template9,
    Template10,
    Template11,
    Template12,
    Template13,
    Template14,
    Template15,
} from "../../components/video-ads/templates";

const VideoEditor = ({ selectedTemplate, selectedProduct, videoAdsVersion = "v1", history }) => {
    const dispatch = useDispatch();

    const templates = {
        template_1: Template1,
        template_2: Template2,
        template_3: Template3,
        template_4: Template4,
        template_5: Template5,
        template_6: Template6,
        template_7: Template7,
        template_8: Template8,
        template_9: Template9,
        template_10: Template10,
        template_11: Template11,
        template_12: Template12,
        template_13: Template13,
        template_14: Template14,
        template_15: Template15,
    };
    const Template = templates[selectedTemplate];
    let apiLink;
    let videoAdsLink;
    let linkBack;

    if (videoAdsVersion == "v1") {
        apiLink = `/api/v1/video-promotions/`;
        videoAdsLink = `/video-ads/`;
        linkBack = `/video-ads/select-template/`;
    } else {
        apiLink = `/api/v2/video-promotions/`;
        videoAdsLink = `/video-ads-v2/`;
        linkBack = `/video-ads-v2/select-template/?image=` + selectedProduct.image;
    }

    useEffect(() => {
        window.document.title = "Video Editor | SIXADS";
    });

    useEffect(() => {
        api(apiLink, {
            method: "POST",
            data: {
                image: selectedProduct.image,
                width: Template.image_width,
                height: Template.image_height,
            },
        })
            .then((response) => {
                setImage(response.image);
            })
            .catch((error) => {
                dispatch(toggleToastPopup(["Something went wrong."], "error"));
            });
    }, [selectedProduct.image]);

    const [adMessage, setAdMessage] = useState(selectedProduct.adMessage);
    const [productName, setProductName] = useState(selectedProduct.name);
    const [discount, setDiscount] = useState("-50%");
    const [image, setImage] = useState(null);
    const [downloadComplete, setDownloadComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(selectedProduct.price);
    const [currency, setCurrency] = useState(selectedProduct.currency);

    const handleVideoDownload = () => {
        setLoading(true);

        let recordedChunks = [];

        const canvas = document.getElementsByTagName("canvas")[0];
        if (canvas) {
            let stream = canvas.captureStream(25);

            let options = { mimeType: "video/webm; codecs=vp8" };
            const mediaRecorder = new MediaRecorder(stream, options);

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunks.push(event.data);

                    let blob = new Blob(recordedChunks, {
                        type: "video/mp4",
                    });
                    let url = URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "sixads_powerful_video_ad.mp4";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    setLoading(false);
                    setDownloadComplete(true);
                } else {
                    setLoading(false);
                }
            };
            mediaRecorder.start();

            setTimeout((event) => {
                mediaRecorder.stop();
            }, 7000);

            dispatch(
                logAnalytics({
                    hitType: "event",
                    eventCategory: "Download Your Video",
                    eventAction: "Button click",
                    eventLabel: "Video flow",
                })
            );
        }
    };

    const handleVideoShareFeature = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Share video",
                eventAction: "Button click",
                eventLabel: "Video flow",
            })
        );

        dispatch(toggleToastPopup(["This is an experimental feature."]));
    };

    if (downloadComplete) {
        const history = useHistory();
        dispatch(navigateVideoAds(5));
        history.push(videoAdsLink + "success/");
    }

    const extraFields = Template.extra_fields || [];

    return (
        <div className="p-14 bg-white">
            {!image && (
                <div className="p-24">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDQgNDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjU0MiAxLjQ4N0EyMS41MDcgMjEuNTA3IDAgMDAuNSAyMmMwIDExLjg3NCA5LjYyNiAyMS41IDIxLjUgMjEuNSA5Ljg0NyAwIDE4LjM2NC02LjY3NSAyMC44MDktMTYuMDcyYTEuNSAxLjUgMCAwMC0yLjkwNC0uNzU2QzM3LjgwMyAzNC43NTUgMzAuNDczIDQwLjUgMjIgNDAuNSAxMS43ODMgNDAuNSAzLjUgMzIuMjE3IDMuNSAyMmMwLTguMTM3IDUuMy0xNS4yNDcgMTIuOTQyLTE3LjY1YTEuNSAxLjUgMCAxMC0uOS0yLjg2M3oiIGZpbGw9IiM5MTlFQUIiLz48L3N2Zz4K"
                        alt=""
                        className="Polaris-Spinner Polaris-Spinner--colorTeal Polaris-Spinner--sizeLarge mx-auto"
                        draggable="false"
                        role="status"
                    />
                </div>
            )}
            {image && (
                <div className="flex flex-col md:flex-row justify-around">
                    <div className="flex-1 mb-8">
                        <p className="text-3xl text-gray-600 cursor-pointer" onClick={() => history.push(linkBack)}>
                            &#60; Template gallery
                        </p>
                        <div className="mt-16 md:mt-56">
                            <div className="grid grid-cols-1 gap-8">
                                <label className="block text-3xl">
                                    <span className="text-gray-900 leading-10 text-2xl font-bold">
                                        Motivate shoppers to buy with a strong message
                                    </span>
                                    <input
                                        type="text"
                                        name="adMessage"
                                        autoFocus={true}
                                        className="mt-7 block w-10/12 rounded-md  border-0 border-b-3 border-gray-200 focus:ring-0 focus:border-gray-900 text-gray-900"
                                        placeholder="Special Discount"
                                        onChange={(ev) => setAdMessage(ev.target.value)}
                                        value={adMessage}
                                    />
                                </label>
                                <label className="block text-3xl">
                                    <span className="text-gray-900 leading-10 text-2xl font-bold">Product name</span>
                                    <input
                                        type="text"
                                        name="productName"
                                        className="mt-7 block w-10/12 rounded-md  border-0 border-b-3 border-gray-200 focus:ring-0 focus:border-gray-900 text-gray-900"
                                        onChange={(ev) => setProductName(ev.target.value)}
                                        value={productName}
                                    />
                                </label>
                                {videoAdsVersion == "v2" && (
                                    <VideoEditorPriceV2
                                        currency={currency}
                                        price={price}
                                        setCurrency={setCurrency}
                                        setPrice={setPrice}
                                    />
                                )}
                                {extraFields.includes("discount") && (
                                    <label className="block text-3xl">
                                        <span className="text-gray-900 leading-10 text-2xl font-bold">Discount</span>
                                        <input
                                            type="text"
                                            name="discount"
                                            className="mt-7 block w-10/12 rounded-md  border-0 border-b-3 border-gray-200 focus:ring-0 focus:border-gray-900 text-gray-900"
                                            onChange={(ev) => setDiscount(ev.target.value)}
                                            value={discount}
                                        />
                                    </label>
                                )}

                                <div>
                                    <Button primary={true} onClick={handleVideoDownload} loading={loading}>
                                        Download
                                    </Button>
                                    <Button onClick={handleVideoShareFeature}>Share video on Facebook</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-1 max-w-xl template-holder"
                        style={{
                            boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
                        }}
                    >
                        {image && (
                            <Template
                                name={productName}
                                description={selectedProduct.description}
                                image={image}
                                price={`${price} ${currency}`}
                                adMessage={adMessage}
                                discount={discount}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps)(VideoEditor));
