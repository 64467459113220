import React from "react";
import Proptypes from "prop-types";

import { roundNumber } from "../../../utils";
import BudgetInput from "./BudgetInput";
import InputLabelWithTooltip from "../InputLabelWithTooltip";

const TotalBudget = (props) => {
    const { dailyBudget, onChange, errorMessage, currency, max, min, perDay, disabled } = props;
    return (
        <span className="component--total-budget">
            <InputLabelWithTooltip
                label="Total campaign budget"
                tooltipIcon="info"
                tooltipText="Invoices for campaigns will be available directly on Facebook & Google."
                darkBold={true}
                position="above"
            />
            <div className={`total-budget--inputs ${errorMessage ? "error" : ""}`}>
                <div className="total-budget--input-border">
                    <BudgetInput
                        id="total-budget"
                        className="input-value"
                        value={dailyBudget}
                        onChange={onChange}
                        onValueFormat={roundNumber}
                        max={max}
                        min={min}
                        disabled={disabled}
                    />
                    <input type="text" className="input-currency-icon" value={currency ? "" : "$"} disabled={true} />
                </div>
                <InputLabelWithTooltip
                    label={`${currency || ""}${perDay ? " / per day" : ""}`}
                    darkBold={true}
                    tooltipIcon=""
                    tooltipText=""
                />
            </div>
            {errorMessage && <div className="total-budget--inputs--error">{errorMessage}</div>}
        </span>
    );
};

TotalBudget.propTypes = {
    dailyBudget: Proptypes.number.isRequired,
    errorMessage: Proptypes.string,
    onChange: Proptypes.func,
    currency: Proptypes.string.isRequired,
    max: Proptypes.number.isRequired,
    min: Proptypes.number.isRequired,
    perDay: Proptypes.bool,
    disabled: Proptypes.bool,
};

TotalBudget.defaultProps = {
    errorMessage: null,
    onChange: () => {},
    perDay: true,
    disabled: false,
};

export default TotalBudget;
