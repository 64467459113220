import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FacebookFeedAdView } from "../components/FacebookFeedAdView";
import { getStoreInformation } from "../api/api-store";
import { Card } from "@shopify/polaris";

const ProductsCarousel = ({ primaryText }) => {
    const [storeProducts, setStoreProducts] = useState([]);
    const [carousel, setCarousel] = useState([]);
    const [containerWidth, setContainerWidth] = useState(0);
    const [currentFocus, setCurrentFocus] = useState(0);
    const containerRef = useRef(null);
    const shopUrl = useSelector((state) => state.store.shop_url);
    const refs = carousel.reduce((acc, value, index) => {
        acc[index] = React.createRef();
        return acc;
    }, {});

    const handleScroll = (id) => {
        if (carousel[id]) {
            refs[id].current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
            });
        }
    };

    const handleResize = () => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    };

    const handleAutoScroll = () => {
        if (carousel.length) {
            if (carousel.length > 2) {
                if (currentFocus === carousel.length - 2) {
                    setCarousel((prev) => [...prev, ...storeProducts]);
                }
            } else {
                if (currentFocus === carousel.length - 1) {
                    setCarousel((prev) => [...prev, ...storeProducts]);
                }
            }
            setCurrentFocus((prev) => prev + 1);
        }
    };

    useEffect(() => {
        (async () => {
            const result = await getStoreInformation();
            setStoreProducts(result.results.filter((item) => item.images.length !== 0));
            setCarousel(result.results.filter((item) => item.images.length !== 0 && item.inventory_quantity > 0));
        })();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            handleAutoScroll();
        }, 4000);
        return () => clearInterval(interval);
    }, [storeProducts, currentFocus, carousel]);

    useEffect(() => {
        handleScroll(currentFocus);
    }, [currentFocus, containerWidth]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [carousel]);

    return (
        <div>
            {carousel.length > 0 && (
                <Card>
                    <div className="w-full p-8">
                        <div className="w-full flex flex-row justify-between">
                            <div className="flex flex-row">
                                <div className="h-14 pt-1">
                                    <img
                                        src="/static/img/shop-icon.png"
                                        alt="Shop Icon"
                                        style={{ height: "inherit" }}
                                    />
                                </div>
                                <div className="px-5">
                                    <div className="font-semibold text-2xl">{shopUrl.split(".")[0]}</div>
                                    <div className="flex flex-row items-center">
                                        <div className="font-semibold text-xl text-gray-400">Sponsored .</div>
                                        <img
                                            className="pl-1"
                                            src="/static/img/globe-52.png"
                                            alt="Shop Icon"
                                            style={{ height: "14px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <MoreHorizIcon className="text-gray-400" fontSize="large" />
                        </div>
                        <div className="mt-4 primary-text">
                            {primaryText !== undefined
                                ? primaryText
                                : carousel[currentFocus]
                                ? carousel[currentFocus].title
                                : ""}
                        </div>
                        <div ref={containerRef} className="relative flex items-center justify-center">
                            <div className="absolute left-5 z-50">
                                <div className="flex items-center pl-6">
                                    <div
                                        className="p-4 rounded-full bg-white hover:bg-gray-200"
                                        style={{ boxShadow: "2px 2px rgb(214 211 209)" }}
                                        onClick={() => {
                                            if (currentFocus > 0) {
                                                setCurrentFocus((prev) => prev - 1);
                                            }
                                        }}
                                    >
                                        <ArrowBackIosNewIcon className="text-gray-800" style={{ fontSize: "20px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="absolute right-5 z-50">
                                <div className="flex items-center pr-6">
                                    <div
                                        className="p-4 rounded-full bg-white hover:bg-gray-200"
                                        style={{ boxShadow: "2px 2px rgb(214 211 209)" }}
                                        onClick={() => {
                                            if (currentFocus < carousel.length - 1) {
                                                setCurrentFocus((prev) => prev + 1);
                                            }
                                        }}
                                    >
                                        <ArrowForwardIosIcon className="text-gray-800" style={{ fontSize: "20px" }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: containerWidth }}>
                                <div className="h-full overflow-hidden">
                                    <div className="h-full flex overflow-x-auto hide-scrollbar">
                                        {carousel.map((product, index) => {
                                            return (
                                                <div
                                                    ref={refs[index]}
                                                    className="h-full"
                                                    style={{ width: `${containerWidth * 0.5}px`, margin: "0px" }}
                                                    key={`${product.api_id}-${index}`}
                                                >
                                                    <div
                                                        className="h-full"
                                                        style={{ width: `${containerWidth * 0.5}px` }}
                                                    >
                                                        <div className="w-full flex justify-center">
                                                            <FacebookFeedAdView
                                                                primaryText={product.title}
                                                                imageUrl={product.images[0].src}
                                                                headline={product.description}
                                                                description={`${product.currency} ${product.price}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default ProductsCarousel;
