/* eslint-disable import/no-named-as-default-member */
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import GoogleAdsAccountList from "./GoogleAdsAccountList";
import GoogleAdsAccountDetails from "./GoogleAdsAccountDetails";

const GoogleAdsAccountConnection = () => {
    const googleAdsAccountId = useSelector((state) => state.googleShopping.coreAccount.google_ads_account_id);

    return <Fragment>{googleAdsAccountId ? <GoogleAdsAccountDetails /> : <GoogleAdsAccountList />}</Fragment>;
};

export default GoogleAdsAccountConnection;
