/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import classnames from "classnames";

import Loader from "../loader";
import {
    getShopifyProducts,
    filterProductSelection,
    searchShopifyProducts,
    toggleProductSelection,
} from "../../../actions/common/actions-select-products";
import InfiniteScrollPagination from "../infinite-scroll-pagination";

class SelectProducts extends Component {
    static propTypes = {
        dispatch: Proptypes.func.isRequired,
        selectProductsState: Proptypes.shape({
            isLoading: Proptypes.bool.isRequired,
            products: Proptypes.array.isRequired,
            selectedProducts: Proptypes.array.isRequired,
            filters: Proptypes.shape({
                option: Proptypes.oneOf(["", "selected", "deselected"]).isRequired,
                searchQuery: Proptypes.string.isRequired,
            }).isRequired,
            nextPageUrl: Proptypes.string,
            totalNumberOfaProducts: Proptypes.number.isRequired,
        }).isRequired,
        actions: Proptypes.shape({
            TOGGLE_PRODUCT_SELECTION: Proptypes.string.isRequired,
            GET_SHOPIFY_PRODUCT_REQUESTED: Proptypes.string.isRequired,
            GET_SHOPIFY_PRODUCT_SUCCEED: Proptypes.string.isRequired,
            GET_SHOPIFY_PRODUCT_FAILED: Proptypes.string.isRequired,
            CLEAR_SHOPIFY_PRODUCTS: Proptypes.string.isRequired,
            SEARCH_SHOPIFY_PRODUCTS: Proptypes.string.isRequired,
            FILTER_PRODUCTS_SELECTION: Proptypes.string.isRequired,
        }).isRequired,
        maxSelectedProduct: Proptypes.number,
    };

    static defaultProps = {
        maxSelectedProduct: null,
    };

    constructor(props) {
        super(props);
        this.abortController = React.createRef();
    }

    componentDidMount() {
        const { dispatch, actions } = this.props;

        dispatch(
            getShopifyProducts({
                onLoadClearProducts: true,
                actionGetProductsRequested: actions.GET_SHOPIFY_PRODUCT_REQUESTED,
                actionGetProductsSucceed: actions.GET_SHOPIFY_PRODUCT_SUCCEED,
                actionGetProductsFailed: actions.GET_SHOPIFY_PRODUCT_FAILED,
                actionClearProducts: actions.CLEAR_SHOPIFY_PRODUCTS,
            })
        );
    }

    getFilteredProducts = () => {
        const {
            selectProductsState: { products, selectedProducts, filters },
        } = this.props;

        let filteredProducts = [];
        switch (filters.option) {
            case "selected":
                filteredProducts = products.filter((product) => selectedProducts.includes(product.api_id));
                break;
            case "deselected":
                filteredProducts = products.filter((product) => !selectedProducts.includes(product.api_id));
                break;
            default:
                filteredProducts = products;
                break;
        }

        return filteredProducts;
    };

    getProductName = (fullName) => {
        return fullName.length < 20 ? fullName : `${fullName.substring(0, 20)}...`;
    };

    handleToggleProductSelection = (product) => {
        const { dispatch, actions, maxSelectedProduct } = this.props;

        dispatch(
            toggleProductSelection({
                apiId: product.api_id,
                actionToggleProductSelection: actions.TOGGLE_PRODUCT_SELECTION,
                maxSelectedProduct: maxSelectedProduct,
            })
        );
    };

    handlePaginationNext = () => {
        const {
            dispatch,
            actions,
            selectProductsState: { nextPageUrl },
        } = this.props;

        const queryParams = new URL(nextPageUrl).searchParams;
        const searchQuery = queryParams.get("title");
        const page = queryParams.get("page");

        dispatch(
            getShopifyProducts({
                searchQuery: searchQuery,
                page: page,
                actionGetProductsRequested: actions.GET_SHOPIFY_PRODUCT_REQUESTED,
                actionGetProductsSucceed: actions.GET_SHOPIFY_PRODUCT_SUCCEED,
                actionGetProductsFailed: actions.GET_SHOPIFY_PRODUCT_FAILED,
                actionClearProducts: actions.CLEAR_SHOPIFY_PRODUCTS,
            })
        );
    };

    handleFilterProductSelection = (option) => {
        const { dispatch, actions } = this.props;

        dispatch(
            filterProductSelection({
                option: option,
                actionFilterProductsSelection: actions.FILTER_PRODUCTS_SELECTION,
            })
        );
    };

    handleSearchProducts = (searchQuery) => {
        const { dispatch, actions } = this.props;

        if (this.abortController.current) {
            this.abortController.current.abort();
        }
        const controller = new AbortController();
        const { signal } = controller;
        this.abortController.current = controller;

        dispatch(
            searchShopifyProducts({
                searchQuery: searchQuery,
                signal: signal,
                actionSearchShopifyProducts: actions.SEARCH_SHOPIFY_PRODUCTS,
                actionGetProductsRequested: actions.GET_SHOPIFY_PRODUCT_REQUESTED,
                actionGetProductsSucceed: actions.GET_SHOPIFY_PRODUCT_SUCCEED,
                actionGetProductsFailed: actions.GET_SHOPIFY_PRODUCT_FAILED,
                actionClearProducts: actions.CLEAR_SHOPIFY_PRODUCTS,
            })
        );
    };

    render() {
        const {
            selectProductsState: { isLoading, nextPageUrl, selectedProducts, filters, totalNumberOfaProducts },
        } = this.props;

        const products = this.getFilteredProducts();

        return (
            <Fragment>
                <div className="border-b border-gray-200 p-8 flex flex-col md:flex-row items-center">
                    <select
                        className="block w-full mt-1 rounded-l-md border-gray-300 shadow-sm focus:border-indigo-300 flex-1/2"
                        onChange={(event) => this.handleFilterProductSelection(event.target.value)}
                    >
                        <option value="">All Products</option>
                        <option value="selected" selected={filters.option === "selected"}>
                            Selected
                        </option>
                        <option value="deselected" selected={filters.option === "deselected"}>
                            Disabled
                        </option>
                    </select>

                    <input
                        type="text"
                        className="mt-1 block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-300 flex-1"
                        placeholder="Search for products"
                        value={filters.searchQuery}
                        onChange={(event) => this.handleSearchProducts(event.target.value)}
                    />

                    <span className="flex-1/2 text-center">
                        {selectedProducts.length}/{totalNumberOfaProducts} items selected
                    </span>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-8">
                    <InfiniteScrollPagination
                        isLoading={isLoading}
                        nextPageUrl={nextPageUrl}
                        onNext={this.handlePaginationNext}
                        loader={
                            <div className="col-span-4 my-0 mx-auto">
                                <Loader />
                            </div>
                        }
                    >
                        {products.map((product) => {
                            const selected = selectedProducts.includes(product.api_id);

                            const classes = classnames("flex flex-col justify-center items-center cursor-pointer", {
                                "bg-green-200 opacity-90 border-2 border-green-400 relative": selected,
                            });

                            return (
                                <div
                                    key={product.api_id}
                                    className={classes}
                                    style={{ boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)", minHeight: "20rem" }}
                                    onClick={() => this.handleToggleProductSelection(product)}
                                >
                                    <img
                                        src={product.images[0].src}
                                        alt={this.getProductName(product.title)}
                                        className="w-48"
                                    />
                                    <p className="text-gray-500 text-center">{this.getProductName(product.title)}</p>
                                    <h3 className="font-semibold text-gray-900 text-xl leading-20px">{`${product.price} ${product.currency}`}</h3>
                                    {selected && (
                                        <Fragment>
                                            <input
                                                type="checkbox"
                                                checked={true}
                                                className="w-8 h-8 absolute top-6 left-10"
                                            />
                                            <style jsx={true}>{`
                                                [type="checkbox"] {
                                                    background-color: #1eca77 !important;
                                                }
                                            `}</style>
                                        </Fragment>
                                    )}
                                </div>
                            );
                        })}
                    </InfiniteScrollPagination>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {
        //
    };
};

export default withRouter(connect(mapStateToProps)(SelectProducts));
