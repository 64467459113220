/* eslint-disable eqeqeq */
import { CREATE_ADS_REQUEST, CREATE_ADS_SUCCESS, REMOVE_ADS_REQUEST, REMOVE_ADS_SUCCESS } from "../actions";

const initial = {
    isLoading: true,
    isError: false,
    counter: 0,
    items: [],
    pages: 1,
    nextPageInfo: "",
    isExtending: false,
};

export const products = (state = initial, action) => {
    switch (action.type) {
        case CREATE_ADS_REQUEST:
            return {
                ...state,
                items: state.items.map((item) =>
                    action.payload.some((o) => o.api_id === item.api_id) ? { ...item, isLoading: true } : item
                ),
            };

        case REMOVE_ADS_REQUEST:
            return {
                ...state,
                items: state.items.map((item) =>
                    action.payload.indexOf(item.id) !== -1 ? { ...item, isLoading: true } : item
                ),
            };
        case CREATE_ADS_SUCCESS:
            return {
                ...state,
                items: state.items.map((item) => {
                    if (action.payload.items.some((o) => o.api_id === item.api_id)) {
                        const ID = action.payload.items.reduce((res, o) => {
                            return o.api_id === item.api_id ? o.id : res;
                        }, null);

                        return { ...item, id: ID, isLoading: false };
                    }
                    return item;
                }),
            };

        case REMOVE_ADS_SUCCESS:
            return {
                ...state,
                items: state.items.map((item) =>
                    action.payload.items.some((o) => o.id === item.id) ? { ...item, isLoading: false } : item
                ),
            };

        default:
            return state;
    }
};
