/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";

const GetCourse = () => {
    const dispatch = useDispatch();

    const handleSeeCourseClick = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Get courses flow",
                eventAction: "Button click",
                eventLabel: "Check out course on verum",
            })
        );

        window.open("https://projectverum.org/?ref=kristinaziauke", "_blank").focus();
    };

    return (
        <Fragment>
            <div className="pt-24 md:pt-0 flex flex-col md:flex-row h-screen">
                <div className="flex-1 bg-white flex items-center justify-center">
                    <div className="w-5/6 md:w-3/4 md:h-2/4 flex flex-col justify-between items-start">
                        <div>
                            <h1 className="font-sf-display font-semibold text-4xl md:text-42px text-gray-900 leading-120p md:pb-10">
                                The last e-commerce course by Verum Ecom that you’ll ever need{" "}
                                <span role="img" aria-label="Clipboard">
                                    📋
                                </span>
                            </h1>
                            <div className="welcome-description">
                                <p>
                                    The course is made by PROS with over 3 years of experience in e-commerce and
                                    handling $10m+ of Facebook Ad spend budgets. It is based on methods tested across
                                    50+ stores.
                                </p>
                                <div>
                                    <h3 className="py-3 md:py-6">You’ll learn:</h3>
                                    <p className="leading-140p">
                                        ✔️ How to select products & find suppliers <br />
                                        ✔️ How to set up your online store <br />
                                        ✔️ How to market it on Facebook & Instagram
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Button primary={true} onClick={handleSeeCourseClick}>
                            <span>See the course on Verum</span>
                        </Button>
                    </div>
                </div>
                <div className="flex-1 bg-teal-300 flex items-center justify-center">
                    <iframe
                        title="vimeo-player"
                        src="https://player.vimeo.com/video/416570966?h=114525e023"
                        width="640"
                        height="360"
                        frameBorder="0"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = () => {
    return {
        //
    };
};

export default withRouter(connect(mapStateToProps)(GetCourse));
