/* eslint-disable import/prefer-default-export */
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";
import { smallScreen } from "../../../../../../utils/window-size";
import { getComponents } from "../../../../../../utils/override";
import * as defaultComponents from "./default-components";

export const Step = (props) => {
    const { step = "", children, isCompleted = false, isActive = false, overrides = {} } = props;

    const {
        Root: { component: Root, props: rootProps },
        IconContainer: { component: IconContainer, props: iconContainerProps },
        ContentContainer: { component: ContentContainer, props: contentContainerProps },
    } = getComponents(defaultComponents, overrides);

    const sharedProps = {
        isCompleted: isCompleted,
        isActive: isActive,
    };

    return (
        <Root {...rootProps}>
            <IconContainer {...sharedProps} {...iconContainerProps}>
                <span>
                    {!isCompleted && <span>{step}</span>}
                    {isCompleted && <Icon source={TickSmallMinor} color="white" />}
                </span>
            </IconContainer>
            {smallScreen() && <br />}
            <ContentContainer {...contentContainerProps}>{children}</ContentContainer>
        </Root>
    );
};

Step.propTypes = {
    step: PropTypes.number,
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool,
    isLast: PropTypes.bool,
    isCompleted: PropTypes.bool,
    icon: PropTypes.shape({}),
    overrides: PropTypes.shape({}),
};

Step.defaultProps = {
    step: 1,
    isActive: false,
    isLast: false,
    isCompleted: false,
    icon: null,
    overrides: {},
};
