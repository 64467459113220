import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";
import { resetState } from "../../actions/actions-ad-text-creator";

class Welcome extends Component {
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(resetState());
    }

    handleCreateAdClick = () => {
        const { dispatch, history } = this.props;

        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Ad text creation flow",
                eventAction: "Button click",
                eventLabel: "Get started",
            })
        );

        history.push("/ad-creative/ad-text-creator/about-business/");
    };

    render() {
        return (
            <Fragment>
                <div className="flex flex-col md:flex-row h-screen">
                    <div className="pt-12 md:pt-24 flex-1 bg-white flex justify-center items-center">
                        <div className="h-4/5 md:h-2/5 flex flex-col justify-between items-start w-4/5">
                            <h1 className="welcome-title">
                                Create effective Facebook ad texts in seconds️{" "}
                                <span role="img" aria-label="Black Nib">
                                    ✒
                                </span>
                            </h1>
                            <p className="welcome-description flex-3/5">
                                Struggling to create texts for Facebook ads? Unlock your creativity with the help of
                                artificial intelligence. Enter your company&apos;s name, audience, a few keywords &
                                choose from generated ad texts or get inspired to create your own.
                            </p>
                            <Button class="text-16px" primary={true} onClick={this.handleCreateAdClick}>
                                Create ad texts
                            </Button>
                        </div>
                    </div>
                    <div className="pt-0 md:pt-24 flex-1/2 md:flex-1 bg-teal-500 flex justify-center items-center">
                        <img src="/static/img/ad-text-creator.jpg" alt="Ad text" className="w-2/5 md:w-3/4" />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {
        //
    };
};

export default withRouter(connect(mapStateToProps)(Welcome));
