/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
import {
    GOOGLE_ADS_ACCOUNT_CONNECT_URL,
    GOOGLE_ADS_ACCOUNT_CREATE_URL,
    GOOGLE_ADS_ACCOUNT_DISCONNECT_URL,
    GOOGLE_ADS_ACCOUNT_LIST_URL,
    GOOGLE_ADS_ACCOUNT_STATUS_URL,
    GOOGLE_DISCONNECT_URL,
    GOOGLE_MERCHANT_ACCOUNT_CONNECT_URL,
    GOOGLE_MERCHANT_ACCOUNT_DISCONNECT_URL,
    GOOGLE_MERCHANT_ACCOUNT_LIST_URL,
    GOOGLE_MERCHANT_ACCOUNT_SHIPPING_SETTINGS_URL,
    GOOGLE_PRODUCT_STATUS_URL,
    GOOGLE_MERCHANT_ACCOUNT_TAX_SETTINGS_URL,
    MERCHANT_ACCOUNT_CREATE_URL,
    GOOGLE_ADS_ACCOUNT_BILLING_SETTINGS_URL,
    GOOGLE_ADS_ACCOUNT_INVITATION_STATUS_URL,
    GOOGLE_CREDENTIALS_API_URL,
    GOOGLE_CREDENTIALS_VALIDATE_API_URL,
    GOOGLE_SHOPPING_CAMPAIGN_CREATE_URL,
    GOOGLE_SHOPPING_TARGETING_CREATE,
} from "../components/google-shopping/constants/google";
import { ACCOUNT_URL, STORE_REQUIREMENTS_LIST, UPDATE_STORE_REQUIREMENTS_LIST, PRODUCT_URL, COUNTRY_LIST, CAMPAIGN_URL, MULTI_PRODUCT_UPDATE_URL } from "../components/google-shopping/constants/common";
import {api} from "../utils"

export const getCoreAccountRequest = () => {
    return api(ACCOUNT_URL);
};

export const GoogleCredentialsDisconnectRequest = async () => {
    const options = { method: "POST" };
    api(GOOGLE_DISCONNECT_URL, options)
        .then(async () => {
            window.location.reload();
        })
        .catch(() => {
            window.location.reload();
        });
};

export const googleMerchantAccountListRequest = async () => {
    return api(GOOGLE_MERCHANT_ACCOUNT_LIST_URL);
};

export const GoogleMerchantAccountConnectRequest = async (selectedAccountId) => {
    if (!selectedAccountId) {
        return false;
    }

    const options = { data: { merchant_id: selectedAccountId } };

    try {
        const result = await api(GOOGLE_MERCHANT_ACCOUNT_CONNECT_URL, options);
        return result;
    } catch (e) {
        // GoogleCredentialsDisconnectRequest();
        return false;
    }
};

export const GoogleMerchantAccountDisconnectRequest = async (selectedAccountId) => {
    const options = { data: { merchant_id: selectedAccountId } };
    try {
        await api(GOOGLE_MERCHANT_ACCOUNT_DISCONNECT_URL, options);
    } catch (error) {
        return false;
    }
    return true;
};

export const GoogleMerchantAccountCreateRequest = async () => {
    const options = { data: {} };
    try {
        const result = await api(MERCHANT_ACCOUNT_CREATE_URL, options);
        return result;
    } catch (e) {
        return false;
    }
};

export const GoogleAdsAccountListRequest = async () => {
    return await api(GOOGLE_ADS_ACCOUNT_LIST_URL);
};

export const GoogleAdsAccountConnectRequest = async (selectedAccountId) => {
    const options = { data: { account_id: selectedAccountId } };
    return await api(GOOGLE_ADS_ACCOUNT_CONNECT_URL, options);;
};

export const GoogleAdsAccountDisconnectRequest = async () => {
    const options = { method: "POST" };
    try {
        await api(GOOGLE_ADS_ACCOUNT_DISCONNECT_URL, options);
    } catch (e) {
        return false;
    }
    return true;
};

export const GoogleAdsAccountCreateRequest = async () => {
    const options = { data: {} };
    return await api(GOOGLE_ADS_ACCOUNT_CREATE_URL, options);
};

export const GetGoogleAdsAccountCheckInvitationStatusRequest = async () => {
    return await api(GOOGLE_ADS_ACCOUNT_INVITATION_STATUS_URL);
};

export const GetGoogleMerchantAccountShippingSettings = async () => {
    return await api(GOOGLE_MERCHANT_ACCOUNT_SHIPPING_SETTINGS_URL);
};

export const GetGoogleMerchantAccountTaxSettings = async () => {
    return await api(GOOGLE_MERCHANT_ACCOUNT_TAX_SETTINGS_URL);
};

export const GetGoogleAdsAccountStatus = async () => {
    return await api(GOOGLE_ADS_ACCOUNT_STATUS_URL);
};

export const GetGoogleAdsAccountBillingSettings = async () => {
    return api(GOOGLE_ADS_ACCOUNT_BILLING_SETTINGS_URL);
};

export const ConnectGoogleCredentials = async (
    onFailure,
    authCode
) => {
    const options = { data: { auth_code: authCode } };
    return await api(GOOGLE_CREDENTIALS_API_URL, options)
        .then((response) => response)
        .catch((error) => onFailure(error));
};

export const ValidateGoogleCredentials = async () => {
    return await api(GOOGLE_CREDENTIALS_VALIDATE_API_URL);
};

export const GetStoreRequirementRequest = async () => {
    return await api(STORE_REQUIREMENTS_LIST);
};

export const UpdateStoreRequirementRequest = async (storeRequirementItem) => {
    const options = {
        data: { guideline_id: storeRequirementItem.guideline_id, accepted: storeRequirementItem.accepted },
    };
    return await api(UPDATE_STORE_REQUIREMENTS_LIST, options);
};

export const GetProductList = async (status, limit, page) => {
    const filter = `?status=${status}`;
    const paginationLimit = limit?`&limit=${limit}`:"";
    const paginationPage = page?`&page=${page}`:"";
    return await api(`${PRODUCT_URL}${filter}${paginationLimit}${paginationPage}`);
};

export const UpdateGoogleProduct = async (productItem) => {
    return await api(`${PRODUCT_URL}/${productItem.primary_key}`, { data: productItem, method: "PUT" })
};

export const UpdateMultiGoogleProducts = async (productItems) => {
    return await api(`${MULTI_PRODUCT_UPDATE_URL}`, { data: productItems, method: "PUT" })
};

export const CreateGoogleShoppingCampaign = async (dailyBudget) => {
    const options = { data: { daily_budget: dailyBudget } };
    return await api(GOOGLE_SHOPPING_CAMPAIGN_CREATE_URL, options);
};

export const GetCountryListRequest = async () => {
    return await api(COUNTRY_LIST);
};

export const SaveTargetingRequest = async (country_code, language_code) => {
    const options = { data: {
        country_code,
        language_code,
    }};
    try {
        const result = await api(GOOGLE_SHOPPING_TARGETING_CREATE, options);
        return result;
    } catch (e) {
        return false;
    }
};

export const GetGoogleShoppingCampaignRequest = async () => {
    return await api(CAMPAIGN_URL);
};

export const UpdateGoogleShoppingCampaign = async (campaign_id, payload) => {
    return await api(`${CAMPAIGN_URL}/${campaign_id}`, { data: payload, method: "PATCH" })
};

export const ConfirmWebsiteClaimedByUser = async () => {
    try {
        return await api(
            `${ACCOUNT_URL}/website-claimed-manually`, {method: "POST"}
        )
    } catch (e) {
        return false;
    }
}
