export const productConditionOptions = [
    {
        label: "",
        value: null,
    },
    {
        label: "New",
        value: "new",
    },
    {
        label: "Refurbished",
        value: "refurbished",
    },
    {
        label: "Used",
        value: "refurbished",
    },
];

export const ageGroupOptions = [
    {
        label: "",
        value: null,
    },
    {
        label: "Newborn",
        value: "newborn",
    },
    {
        label: "Infant",
        value: "infant",
    },
    {
        label: "Toddler",
        value: "toddler",
    },
    {
        label: "Kids",
        value: "kids",
    },
    {
        label: "Adult",
        value: "adult",
    },
];

export const genderOptions = [
    {
        label: "",
        value: null,
    },
    {
        label: "Unisex",
        value: "unisex",
    },
    {
        label: "Male",
        value: "male",
    },
    {
        label: "Female",
        value: "female",
    },
];

export const sizeSystemOptions = [
    {
        label: "AU",
        value: "AU",
    },
    {
        label: "BR",
        value: "BR",
    },
    {
        label: "CN",
        value: "CN",
    },
    {
        label: "DE",
        value: "DE",
    },
    {
        label: "EU",
        value: "EU",
    },
    {
        label: "FR",
        value: "FR",
    },
    {
        label: "IT",
        value: "IT",
    },
    {
        label: "JP",
        value: "JP",
    },
    {
        label: "MEX",
        value: "MEX",
    },
    {
        label: "UK",
        value: "UK",
    },
    {
        label: "US",
        value: "US",
    },
];

export const sizeTypeOptions = [
    {
        label: "None",
        value: "",
    },
    {
        label: "Regular",
        value: "regular",
    },
    {
        label: "Petite",
        value: "petite",
    },
    {
        label: "Plus",
        value: "plus",
    },
    {
        label: "Big and tall",
        value: "big_and_tall",
    },
    {
        label: "Maternity",
        value: "maternity",
    },
];
