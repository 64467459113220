import React, { Component } from "react";
import PropTypes from "prop-types";
import { createWithClassesOrStyles, getComponents } from "../../../../utils/override";
import Grid from "../grid/grid";

const StyleRoot = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: "list-root",
        props: props,
    });

const StyleListItem = (props) =>
    createWithClassesOrStyles({
        type: "div",
        classes: "list-item--default",
        props: props,
    });

const defaultComponents = {
    Root: StyleRoot,
    ListItem: StyleListItem,
};

export default class List extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        overrides: PropTypes.shape({}),
        view: PropTypes.oneOf(["grid", "list"]).isRequired,
        options: PropTypes.shape({
            gridWidth: PropTypes.number
        }),
        onChange: PropTypes.func,
        preventResize: PropTypes.bool,
        single: PropTypes.bool,
    };

    static defaultProps = {
        overrides: {},
        options: {},
        onChange: () => {},
        preventResize: false,
        single: false,
    };

    state = {
        gridWidth: 4,
    };

    componentDidMount() {
        this.handleGridWidth();
        window.addEventListener("resize", this.handleGridWidth);
    }

    setItems = (item) => {
        const { onChange = () => {}, items=  [], single = false } = this.props;
        if (single) {
            const { selected = false } = item;
            if (selected) {
                const newItems = items.map(it => ({
                    ...it,
                    selected: item.api_id === it.api_id,
                }));
                onChange({ selectedItems: newItems.filter(it => it.selected) });  
            } else {
                onChange({ selectedItems: [] });
            }
        } else {
            onChange({ selectedItems: items.filter(it => it.selected)});
        }
    }

    handleGridWidth = () => {
        const { options: { gridWidth = 4 }, preventResize = false } = this.props;

        if (window.innerWidth <= 700 && !preventResize) {
            this.setState({
                gridWidth: 2,
            });
        } else {
            this.setState({
                gridWidth: gridWidth,
            });
        }
    }

    render() {
        const { items = [], view, overrides = {} } = this.props;
        const { gridWidth } = this.state;

        const {
            Root: { component: Root, props: rootProps },
            ListItem: { component: ListItem, props: listItemProps },
        } = getComponents(defaultComponents, overrides);

        const listItems = items.map((item) => (
            <Grid.Column width={1}>
                <ListItem
                    {...listItemProps}
                    item={item}
                    selected={item.selected || false}
                    onSelect={this.setItems}
                    onDeSelect={this.setItems}
                >
                    {item}
                </ListItem>
            </Grid.Column>
        ));

        if (view === "grid") {
            return (
                <Root {...rootProps}>
                    <Grid>
                        <Grid.Row columns={gridWidth}>{listItems}</Grid.Row>
                    </Grid>
                </Root>
            );
        }

        return <Root {...rootProps}>{listItems}</Root>;
    }
}
