import { get, loadMore } from "../api/api-list";
import { toggleToastPopup } from "./action-toast";

export const FETCH_LIST_REQUEST = "FETCH_LIST_REQUEST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";
export const DISPLAY_MORE = "DISPLAY_MORE";
export const DISPLAY_MORE_BEGIN = "DISPLAY_MORE_BEGIN";
export const DISPLAY_MORE_END = "DISPLAY_MORE_END";
export const TOGGLE_COLLAPSE_LIST_ITEM = "TOGGLE_COLLAPSE_LIST_ITEM";
export const UPDATE_LIST_ITEM_REQUEST = "UPDATE_LIST_ITEM_REQUEST";
export const UPDATE_LIST_ITEM_SUCCESS = "UPDATE_LIST_ITEM_SUCCESS";
export const UPDATE_LIST_ITEM_FAILURE = "UPDATE_LIST_ITEM_FAILURE";
export const ADD_FILTER = "ADD_FILTER";
export const ADD_FILTERS = "ADD_FILTERS";
export const REMOVE_FILTER = "REMOVE_FILTER";

export const SHOW_LOADER = "SHOW_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";

export const loadListRequest = (listType) => {
    return {
        type: FETCH_LIST_REQUEST,
        listType: listType,
    };
};

export const loadListSucceeded = (payload) => {
    return {
        type: FETCH_LIST_SUCCESS,
        payload: payload,
    };
};

export const loadListFailed = (payload) => {
    return {
        type: FETCH_LIST_ERROR,
        payload: payload,
    };
};

export const loadMoreBegin = (payload) => {
    return {
        type: DISPLAY_MORE_BEGIN,
        payload: payload,
    };
};

export const loadMoreEnded = (payload) => {
    return {
        type: DISPLAY_MORE_END,
        payload: payload,
    };
};

export const toggleCollapseListItem = (index) => {
    return {
        type: TOGGLE_COLLAPSE_LIST_ITEM,
        payload: {
            index: index,
        },
    };
};

export const updateListItemRequest = () => {
    return {
        type: UPDATE_LIST_ITEM_REQUEST,
    };
};

export const updateListItemSuccess = (data, key = null, itemModifier = null) => {
    return {
        type: UPDATE_LIST_ITEM_SUCCESS,
        payload: {
            data: data,
            key: key,
            itemModifier: itemModifier,
        },
    };
};

export const updateListItemFailure = (payload) => {
    return {
        type: UPDATE_LIST_ITEM_FAILURE,
        payload: payload,
    };
};

export const loadList = (apiUrl, filters = "", type = null, limit = 10) => (dispatch) => {
    if (!type) {
        return;
    }
    dispatch(loadListRequest(type));
    get(apiUrl, type, filters, limit)
        .then((res) => {
            // TODO: Ideally the error should be handled in `catch` block. Reject promise from
            //  front-end/js/api/api-list.js file's `get` method's `catch` block.
            if (res.error) {
                dispatch(toggleToastPopup([res.response.detail], "error"));
                dispatch(loadListFailed(res.response.detail));
            } else {
                dispatch(loadListSucceeded(res));
            }
        })
        .catch((err) => {
            dispatch(loadListFailed(err));
        });
};

export const loadMoreItems = (next) => (dispatch) => {
    dispatch(loadMoreBegin());
    loadMore(next)
        .then((res) => {
            dispatch(loadMoreEnded(res));
        })
        .catch((err) => {
            dispatch(loadMoreEnded(err));
        });
};

export const addListFilter = (payload) => {
    return {
        type: ADD_FILTER,
        payload: payload,
    };
};

export const addListFilters = (payload) => {
    return {
        type: ADD_FILTERS,
        payload: payload,
    };
};

export const removeListFilter = (payload) => {
    return {
        type: REMOVE_FILTER,
        payload: payload,
    };
};

export const addFilter = (filter) => (dispatch) => {
    dispatch(
        addListFilter({
            filter: filter,
        })
    );
};

export const addFilters = (filters) => (dispatch) => {
    dispatch(addListFilters(filters));
};

export const removeFilter = (keys) => (dispatch) => {
    dispatch(
        removeListFilter({
            keys: keys,
        })
    );
};

export const showLoader = () => {
    return {
        type: SHOW_LOADER,
        payload: {},
    };
};

export const removeLoader = () => {
    return {
        type: REMOVE_LOADER,
        payload: {},
    };
};
