import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Banner, Button, Modal, Select } from "@shopify/polaris";
import * as Sentry from "@sentry/browser";
import { assignAdUser, createAdAccount, getBusinessUser } from "./utils";
import { currencyOptions } from "./constants";
import TextField from "../../../common/components/sixads/inputs/TextField";
import { postBusinessEvent } from "../../../api/api-business-event";
import FACEBOOK_BUSINESS_EVENTS from "../../../common/business_events_constants";

function FacebookCreateAdAccountError(message) {
    this.name = "FacebookCreateAdAccountError";
    this.message = message;
    this.stack = new Error().stack;
}

FacebookCreateAdAccountError.prototype = new Error();

const CurrencySelect = ({ currency = "", setCurrency, disabled }) => {
    const handleChange = useCallback((newValue) => setCurrency(newValue), []);
    return (
        <Select
            options={currencyOptions}
            label="Ad account currency"
            onChange={handleChange}
            value={currency}
            disabled={disabled}
        />
    );
};

CurrencySelect.propTypes = {
    currency: PropTypes.string.isRequired,
    setCurrency: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const YourAdAccountNameTextField = ({ adAccountName = "", setAdAccountName, disabled }) => {
    const handleChange = useCallback((newValue) => setAdAccountName(newValue), []);
    return (
        <TextField
            id="new-ad-account-name"
            label="Ad account name"
            value={adAccountName}
            onChange={handleChange}
            disabled={disabled}
        />
    );
};

YourAdAccountNameTextField.propTypes = {
    adAccountName: PropTypes.string.isRequired,
    setAdAccountName: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const CreateAdAccountModal = ({
    businessManagerId = "",
    showCreateAdAccountModal = false,
    hideCreateAdAccountModal,
    getBusinessManagerOwnedAdAccounts,
    onCreationSuccess,
    shopUrl,
}) => {
    const title = "Create new ad account";
    const primaryActionContent = "Submit";
    const [active, setActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = useCallback(() => setActive(!active), [active]);
    const activator = <Button onClick={handleChange}>Open</Button>;

    const [adAccountName, setAdAccountName] = useState(shopUrl);
    const [currency, setCurrency] = useState("USD");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const closeModal = () => {
        getBusinessManagerOwnedAdAccounts(businessManagerId);
        hideCreateAdAccountModal();
        onCreationSuccess();
    };

    const handleError = (errorMessage) => {
        Sentry.captureException(new FacebookCreateAdAccountError(errorMessage));
        setError(errorMessage);
        setIsLoading(false);
    };

    const handleFacebookError = (facebookError) => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SUBMIT_FB_AD_ACCOUNT_FAILURE, { error: facebookError });
        if ("error_user_msg" in facebookError && facebookError.error_user_msg) {
            handleError(facebookError.error_user_msg);
        } else if ("message" in facebookError && facebookError.message) {
            handleError(facebookError.message);
        } else {
            handleError("Unknown error");
        }
    };

    const onAssignAdUserSuccess = () => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SUBMIT_FB_AD_ACCOUNT_SUCCESS);
        setSuccess(true);
        setIsLoading(false);
    };

    const onGetBusinessUserSuccess = (businessUserId, adAccountId) => {
        assignAdUser(adAccountId, businessUserId, onAssignAdUserSuccess, handleFacebookError);
    };

    const handleAdAccountCreationSuccess = (adAccountId) => {
        console.log("handleAdAccountCreationSuccess", "adAccount", adAccountId);
        getBusinessUser(businessManagerId, onGetBusinessUserSuccess, handleFacebookError, adAccountId);
    };

    const handleSubmit = useCallback(() => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SUBMIT_FB_AD_ACCOUNT_BUTTON_CLICK_MODAL);
        createAdAccount(
            businessManagerId,
            adAccountName,
            currency,
            handleFacebookError,
            handleAdAccountCreationSuccess,
            setIsLoading,
            setError
        );
    }, [businessManagerId, adAccountName, currency, handleFacebookError, handleAdAccountCreationSuccess, setIsLoading]);

    const errorModalSection = (
        <Modal.Section>
            <Banner status="critical" title={error} />
        </Modal.Section>
    );

    const warningTitle = "Ad account created, click continue to proceed.";
    const successModalSection = (
        <Modal.Section>
            <Banner status="warning" title={warningTitle} />
        </Modal.Section>
    );

    const primaryAction = success
        ? { content: "Continue", onAction: closeModal, loading: isLoading }
        : { content: primaryActionContent, onAction: handleSubmit, loading: isLoading };

    return (
        <div>
            <Modal
                activator={activator}
                open={showCreateAdAccountModal}
                onClose={closeModal}
                title={title}
                primaryAction={primaryAction}
            >
                <Modal.Section>
                    <YourAdAccountNameTextField
                        adAccountName={adAccountName}
                        setAdAccountName={setAdAccountName}
                        disabled={success}
                    />
                    <CurrencySelect currency={currency} setCurrency={setCurrency} disabled={success} />
                </Modal.Section>
                {error && errorModalSection}
                {success && successModalSection}
            </Modal>
        </div>
    );
};

CreateAdAccountModal.propTypes = {
    businessManagerId: PropTypes.string.isRequired,
    showCreateAdAccountModal: PropTypes.bool.isRequired,
    hideCreateAdAccountModal: PropTypes.func.isRequired,
    getBusinessManagerOwnedAdAccounts: PropTypes.func.isRequired,
    onCreationSuccess: PropTypes.func.isRequired,
    shopUrl: PropTypes.string.isRequired,
};

export default CreateAdAccountModal;
