import {
    FETCH_REACH_LIST_REQUEST,
    FETCH_REACH_LIST_SUCCESS,
    FETCH_REACH_LIST_ERROR,
    UPDATE_REACH_CAMPAIGN,
    TOGGLE_REACH_CAMPAIGN_EXPANSION,
    TOGGLE_REACH_TABLE_EXPANSION,
} from "../actions";
import { ACTIONS } from "../actions/action-dynamic-reach-list";
import withRetryLaunching from "./common/withRetryLaunching";

const initial = {
    isLoading: true,
    count: 0,
    isExpanded: true,
    results: [],
    type: "",
};

const reachList = (state = initial, action) => {
    const { payload: { response = [] } = {} } = action;
    const newResults =
        response.length > 0
            ? response.map((campaign) => {
                  return {
                      ...campaign,
                      isExpanded: true,
                      ad_sets: campaign.ad_sets.map((adSet) => {
                          return {
                              ...adSet,
                              isLoading: false,
                              ads: adSet.ads.map((ad) => {
                                  return {
                                      ...ad,
                                      isLoading: false,
                                  };
                              }),
                          };
                      }),
                  };
              })
            : [];

    switch (action.type) {
        case FETCH_REACH_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                type: action.listType ? action.listType : state.type,
                results: [],
            };
        case FETCH_REACH_LIST_SUCCESS:
            return {
                ...state,
                count: action.payload.response.length,
                results: action.payload.type === state.type ? newResults : state.results,
                isLoading: false,
            };
        case FETCH_REACH_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        case UPDATE_REACH_CAMPAIGN:
            const changedItem = {
                ...state.results.find((item) => item.primary_key === action.payload.primary_key),
                ...action.payload,
            };
            const updatedResults = state.results.map((item) => {
                if (item.primary_key === action.payload.primary_key) {
                    return changedItem;
                } else {
                    return item;
                }
            });
            return {
                ...state,
                results: updatedResults,
                isLoading: false,
            };

        case TOGGLE_REACH_CAMPAIGN_EXPANSION:
            return {
                ...state,
                results: state.results.map((campaign) => {
                    if (campaign.primary_key === action.payload.primaryKey) {
                        return {
                            ...campaign,
                            isExpanded: !campaign.isExpanded,
                        };
                    }
                    return campaign;
                }),
            };

        case TOGGLE_REACH_TABLE_EXPANSION:
            return {
                ...state,
                isExpanded: !state.isExpanded,
            };

        default:
            return state;
    }
};

export default withRetryLaunching(reachList, ACTIONS);
