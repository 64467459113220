export * from "./collections";
export * from "./ads-utils";
export * from "./number-utils";
export * from "./storage-utils";
export * from "./dataLayer-utils";
export * from "./api-utils";
export * from "./intercom";
export * from "./sentry";
export * from "./string-utils";
export * from "./facebook-countries";

export const triggerConnectPlus = () => {
    const nodes = document.querySelectorAll(".cpio-wrap");

    if (!nodes.length) {
        return;
    }

    nodes.forEach((node) => {
        node.innerHTML = "";
    });

    const scripts = document.querySelectorAll("script[src*=connectplus]");

    scripts.forEach((s) => {
        s.remove();
    });

    const script = document.createElement("script");
    script.src = `https://connectplus.io/scripts/website/65d3c93a8a9e223c4c263c672b589e23/cplus.js?d=${new Date().getTime()}`;
    script.setAttribute("data-position", "main");
    document.head.appendChild(script);
};

export const getNodeEnv = () => {
    if (window.location.hostname.includes("ngrok.io")) {
        return "development";
    }

    return "production";
};

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

// Return true only for a plain String, for JSON string return false
export const isString = (str) => {
    if (isJson(str)) {
        return false;
    }

    return typeof str === "string";
};

// this function checks and returns true if all fields are equal between two objects
export function deepEqual(x, y) {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;
    return x && y && tx === "object" && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y;
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
