import { get } from "../api/api-settings";

export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const FETCH_SETTINGS_SUCCESS = "LOAD_SETTINGS_SUCCESS";

const fetchSettingsRequest = (response) => ({
    type: FETCH_SETTINGS_REQUEST,
    response: response,
});

const fetchSettingsSuccess = (payload) => ({
    type: FETCH_SETTINGS_SUCCESS,
    payload: payload,
});

const fetchSettingsFailure = (payload) => ({
    type: FETCH_SETTINGS_FAILURE,
    payload: payload,
});

export const fetchSettings = () => (dispatch) => {
    dispatch(fetchSettingsRequest());

    get()
        .then((response) => {
            dispatch(fetchSettingsSuccess(response));
        })
        .catch((error) => {
            dispatch(fetchSettingsFailure(error));
        });
};
