import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Proptypes from "prop-types";

import { Banner, Card, Page } from "@shopify/polaris";
import FilterableList from "../../../common/components/sixads/list/filterable-list/filterable-list";
import preload from "./preload";
import { renderError } from "../../onboarding-v2/utils/utils";
import productValidation from "./validation";
import Loader from "../../../common/components/sixads/loader/loader";

import useListHook from "./hooks/use-list-hook";
import BrowserNotSupportedBanner from "../BrowserNotSupportedBanner";
import { mergeWithSelectedItems, parseShopifyItems } from "./utils/shopify-products-mapping";
import withVideoAdsData from "../with-video-ads";

const ShopifyProductList = ({ categories, onChange, selectedProducts }) => {
    const { results: shopifyProducts, resultsCount, isLoading, isScrolling, loadList, handleScroll } = useListHook({
        listType: "shopify-products",
    });
    const [searchQuery, setSearchQuery] = useState("");
    const products = parseShopifyItems(shopifyProducts);
    const items = mergeWithSelectedItems(products, selectedProducts);
    let timeout = null;

    useEffect(() => {
        const filters = searchQuery !== "" ? `&title=${searchQuery}` : "";
        loadList(filters);
        document.addEventListener("scroll", handleScroll);
        return () => {
            // unsubscribe event
            document.removeEventListener("scroll", handleScroll);
        };
    }, [loadList, searchQuery]);

    const onSearchChange = (query = "") => {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            timeout = null;
            setSearchQuery(query);
        }, 400);
    };

    return (
        <div>
            <FilterableList
                items={items || []}
                itemsCount={resultsCount}
                categories={categories}
                onChange={onChange}
                onSearchChange={onSearchChange}
                loading={isScrolling || isLoading}
                single={true}
            />
            {(isScrolling || isLoading) && <Loader />}
        </div>
    );
};

ShopifyProductList.propTypes = {
    categories: Proptypes.array.isRequired,
    onChange: Proptypes.func.isRequired,
    selectedProducts: Proptypes.array.isRequired,
};

class SelectProduct extends Component {
    prefix = "importProducts";

    static propTypes = {
        onRef: Proptypes.func,
        setStep: Proptypes.func,
        setProgress: Proptypes.func,
        history: Proptypes.shape({ push: Proptypes.func.isRequired }),
        campaign: Proptypes.shape({ id: Proptypes.number.isRequired }).isRequired,
        values: Proptypes.arrayOf(Proptypes.any),
        errors: Proptypes.arrayOf(Proptypes.any),
        onChange: Proptypes.func,
    };

    static defaultProps = {
        onRef: () => {},
        setStep: () => {},
        setProgress: () => {},
        history: [],
        values: [],
        errors: [],
        onChange: () => {},
    };

    state = {
        selectedProducts: [],
        filterCategories: [
            {
                label: "All Products",
                value: "1",
                filter: (items) => items,
            },
            {
                label: "Selected",
                value: "2",
                filter: (items) => items.filter((item) => item.selected),
            },
            {
                label: "Disabled",
                value: "3",
                filter: (items) => items.filter((item) => !item.selected),
            },
        ],
    };

    componentDidMount() {
        window.document.title = "Select Product | SIXADS";

        const { onRef, setProgress, setStep } = this.props;
        onRef(this);

        const {
            values: { selectedProducts },
        } = this.props;
        this.setState({
            selectedProducts: selectedProducts,
        });
        setProgress(26);
        setStep(2);
    }

    componentWillUnmount() {
        const { onRef } = this.props;
        onRef(undefined);
    }

    // eslint-disable-next-line class-methods-use-this
    parseProducts(products) {
        return products.map((p) => ({
            api_id: p.api_id,
            title: p.title.substring(0, 60),
            thumbnail_link: p.thumbnail_link,
            url: p.url,
            price: p.price,
            description: p.description || "",
            id: p.id,
        }));
    }

    debouncer() {
        const { onChange } = this.props;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.timeout = null;
            onChange(this.state);
        }, 250);
    }

    // eslint-disable-next-line class-methods-use-this
    renderListItem() {
        const { errors = [] } = this.props;
        const { filterCategories, selectedProducts } = this.state;
        return (
            <Page
                title={
                    <span>
                        Select a product for your show-stopping video{" "}
                        <span role="img" aria-label="Check Mark Button">
                            &#9989;
                        </span>
                    </span>
                }
                subtitle="Transform your Shopify products to powerful video ads that sell."
            >
                <Card>
                    {errors.length > 0 && (
                        <Card.Section>
                            <Banner status="critical">{renderError(errors, "selectedProducts")}</Banner>
                        </Card.Section>
                    )}
                    <ShopifyProductList
                        selectedProducts={selectedProducts}
                        categories={filterCategories}
                        onChange={(selectedItems) => {
                            this.setState(
                                {
                                    selectedProducts: selectedItems,
                                },
                                this.debouncer
                            );
                        }}
                    />
                </Card>
            </Page>
        );
    }

    render() {
        return (
            <Fragment>
                <BrowserNotSupportedBanner wrapperClasses="mx-auto md:pt-32 md:max-w-1/2 my-0" />

                {this.renderListItem()}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { videoAds } = state;

    const { pages: { product: { values = {}, errors = [] } } = {} } = videoAds;

    return {
        values: values,
        errors: errors,
    };
};

export default withRouter(
    withVideoAdsData({
        prefix: "product",
        preload: preload,
        validate: productValidation,
        WrappedComponent: connect(mapStateToProps)(SelectProduct),
    })
);
