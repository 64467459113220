import React, { useEffect, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function ListItemGrid(props) {
    const { item, onSelect, onDeSelect } = props;
    const toggleSelected = () => {
        if (item.selected) {
            item.selected = false;
            onDeSelect(item);
        } else {
            item.selected = true;
            onSelect(item);
        }
    };

    const { name = "", image = null, price = null, currency = null, selected = false, hidden = false } = item;

    useEffect(() => {
        if (selected) {
            item.selected = true;
            onSelect(item);
        }
    }, []);

    const classes = classNames("list-item-grid--container", {
        "list-item-grid--selected list-item-grid--less-opacity": selected,
    });

    const imageClasses = classNames("list-item-grid--image", {
        "list-item-grid--less-opacity": selected,
    });

    /* eslint-disable */
    return (
        <Fragment>
            {!hidden &&
            <div className={classes} onClick={toggleSelected} role="div">
                {selected && (
                    <label className="list-item-grid--checkbox-container">
                        <input type="checkbox" className="list-item-grid--checkbox" defaultChecked={true}
                               hidden={true}/>
                        <span className="list-item-grid--checkbox-checkmark"/>
                    </label>
                )}
                {image && <img alt={name} src={image} className={imageClasses}/>}
                <p className="list-item-grid--name">
                    <span>{name}</span>
                </p>
                {price && <p className="list-item-grid--price">{price} {currency}</p>}
            </div>
            }
        </Fragment>
    );
    /* eslint-enable */
}

ListItemGrid.propTypes = {
    item: PropTypes.shape({
        selected: PropTypes.bool,
        name: PropTypes.string,
        image: PropTypes.string,
        price: PropTypes.string,
        currency: PropTypes.string,
        hidden: PropTypes.bool,
    }).isRequired,
    onSelect: PropTypes.func,
    onDeSelect: PropTypes.func,
};

ListItemGrid.defaultProps = {
    onSelect: () => {},
    onDeSelect: () => {},
};
