/* eslint-disable no-nested-ternary */
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { logAnalytics } from "../../../actions";
import Button from "../../../common/components/button";
import { setTemplateSelection } from "../../../actions/actions-image-overlay-ads";

function withTemplateSelectionTile(WrappedComponent, template, top, bottom, left, right) {
    const Component = (props) => {
        const tagColor = {
            r: "105",
            g: "105",
            b: "105",
            a: "1",
        };

        const { dispatch } = props;
        let { selectedTemplate } = props;
        const history = useHistory();

        if (!selectedTemplate.templateLayout) {
            selectedTemplate = {
                templateLayout: 1,
                top: true,
                right: true,
                bottom: false,
                left: false,
            };
        }

        const createTemplateName = () => {
            const templateName =
                template === 1
                    ? "triangle-corner"
                    : template === 2
                    ? "circle-corner"
                    : template === 3
                    ? "rectangle-corner"
                    : template === 4
                    ? "square-border"
                    : "";

            return templateName;
        };

        const onClickChooseDesign = () => {
            dispatch(
                logAnalytics({
                    hitType: "event",
                    eventCategory: "Image ad creator flow",
                    eventAction: `Button click ${createTemplateName()}`,
                    eventLabel: "Choose design",
                })
            );

            dispatch(setTemplateSelection(template, top, right, bottom, left));

            history.push("/ad-creative/image-ad-creator/create-ad/");
        };

        return (
            <div className="shadow-xl cursor-pointer container">
                <div className="template">
                    <WrappedComponent
                        top={top}
                        right={right}
                        bottom={bottom}
                        left={left}
                        tagColor={tagColor}
                        tagText="hello"
                        thumbnail={true}
                        resizable={true}
                    />
                </div>
                <div className="button">
                    <Button primary={true} onClick={() => onClickChooseDesign(template.name)}>
                        Choose design
                    </Button>
                </div>

                <style jsx={true}>{`
                    .container {
                        position: relative;
                    }

                    .template {
                        opacity: 1;
                        display: block;
                        width: 100%;
                        height: auto;
                        transition: 0.5s ease;
                        backface-visibility: hidden;
                    }

                    .button {
                        transition: 0.5s ease;
                        opacity: 0;
                        position: absolute;
                        top: 50%;
                        left: 48%;
                        transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                    }

                    .container:hover .template {
                        opacity: 0.3;
                    }

                    .container:hover .button {
                        opacity: 1;
                    }
                `}</style>
            </div>
        );
    };

    Component.propTypes = {
        dispatch: PropTypes.func.isRequired,
        selectedTemplate: PropTypes.object.isRequired,
    };

    const mapStateToProps = (state) => {
        const {
            imageOverlayAds: { selectedTemplate },
        } = state;

        return {
            selectedTemplate,
        };
    };

    return connect(mapStateToProps)(Component);
}

withTemplateSelectionTile.propTypes = {
    WrappedComponent: PropTypes.element.isRequired,
    template: PropTypes.number.isRequired,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
};

withTemplateSelectionTile.defaultProps = {
    top: false,
    bottom: false,
    left: false,
    right: false,
};

export default withTemplateSelectionTile;
