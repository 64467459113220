import React from "react";

import { Tooltip } from "@shopify/polaris";

import Icon from "./Icon";

const TooltipWithIcon = ({ icon, text, label, styles = null, position = "mostSpace" }) => {
    let classNames = `TooltipWithIcon`;
    if (!styles) {
        classNames = `${classNames} text-gray-700 font-medium`;
    }
    return (
        <div className={classNames} style={styles}>
            {label}&nbsp;
            {Boolean(icon) && (
                <span className="TooltipWithIcon--icon">
                    <Tooltip active={false} content={text} preferredPosition={position}>
                        <span className="Polaris-Icon">
                            <Icon width={16} height={16} icon={icon} style={{ verticalAlign: "middle" }} />
                        </span>
                    </Tooltip>
                </span>
            )}
        </div>
    );
};

export default TooltipWithIcon;
