import {
    CANCEL_SUBSCRIPTION_ERROR,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CREATE_SUBSCRIPTION_ERROR,
    CREATE_SUBSCRIPTION_REQUEST,
    EXCLUDE_CATEGORIES_SUCCESS,
    INITIATE_STORE_FAILURE,
    INITIATE_STORE_REQUEST,
    INITIATE_STORE_SUCCESS,
    UPDATE_STORE_COUNTRIES,
    UPDATE_STORE_REQUEST,
    UPDATE_STORE_SUCCESS,
    UPDATE_SUBSCRIPTION_CHANNELS_ERROR,
    UPDATE_SUBSCRIPTION_CHANNELS_REQUEST,
    UPDATE_SUBSCRIPTION_CHANNELS_SUCCESS,
} from "../actions";

const initial = {
    isLoading: true,
    isUpdating: false,
    isError: false,
    counter: 0,

    token: "",
    id: 0,
    shop_url: "",
    boosts: 0,
    email: "",
    is_email_confirmed: false,
    shopify_plan: "",
    currency: "",
    homepage_visible: false,
    collection_visible: false,
    products_page_visible: false,
    thank_you_page_visible: false,
    single_image_ad_visible: false,
    hover_ads_visible: false,
    is_paused: false,
    ads_disabled: false,
    excluded_categories: [],
    exclude_adult: false,
    password_protected: false,
    shop_type: "",
    shop_country_code: "",
    gender: null,
    countries: [],
    headline: "Partner Products",
    header_color: "#000000",
    header_size: 35,
    has_border: false,
    border_color: "#000000",
    border_size: 1,
    background_color: "#ffffff",
    font_size: 12,
    font_color: "#111111",
    single_image_ad_background_color: null,
    single_image_ad_font_size: null,
    single_image_ad_text_color: null,
    plan_name: "",
    plan_name_user: "",
    has_facebook_ad: false,
    has_impressions: false,
    subscription: {},
    facebook_business_manager_id: "",

    // cancel subscription
    isCancelSubscriptionLoading: false,
    isCancelSubscriptionSuccess: false,

    // Update subscription
    isUpdateSubscriptionLoading: false,

    // create subscription
    isCreateSubscriptionLoading: false,
};

export const store = (state = initial, action) => {
    switch (action.type) {
        case INITIATE_STORE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case INITIATE_STORE_FAILURE: {
            const counter = state.counter + 1;

            return {
                ...state,
                isLoading: false,
                isError: true,
                counter: counter,
            };
        }

        case INITIATE_STORE_SUCCESS:
            return { ...state, ...action.payload, isLoading: false, isError: false, counter: 0 };

        case UPDATE_STORE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };

        case UPDATE_STORE_SUCCESS:
            return { ...state, ...action.payload, isUpdating: false };

        case EXCLUDE_CATEGORIES_SUCCESS:
            return { ...state, excluded_categories: action.payload.items };

        case UPDATE_STORE_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            };

        case CANCEL_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                isCancelSubscriptionLoading: true,
                isCancelSubscriptionSuccess: false,
            };

        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscription: {},
                isCancelSubscriptionLoading: false,
                isCancelSubscriptionSuccess: true,
            };

        case CANCEL_SUBSCRIPTION_ERROR:
            return {
                ...state,
                isCancelSubscriptionLoading: false,
                isCancelSubscriptionSuccess: false,
            };

        case CREATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                isCreateSubscriptionLoading: true,
            };

        case CREATE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                isCreateSubscriptionLoading: false,
            };

        case UPDATE_SUBSCRIPTION_CHANNELS_REQUEST:
            return {
                ...state,
                isUpdateSubscriptionLoading: true,
            };

        case UPDATE_SUBSCRIPTION_CHANNELS_ERROR:
            return {
                ...state,
                isUpdateSubscriptionLoading: false,
            };

        case UPDATE_SUBSCRIPTION_CHANNELS_SUCCESS:
            return {
                ...state,
                subscription: action.payload,
                isUpdateSubscriptionLoading: false,
            };

        default:
            return state;
    }
};
