import React from "react";
import { Button, TextField } from "@shopify/polaris";
import { CalendarMajorMonotone } from "@shopify/polaris-icons";
import moment from "moment";
import DateRangePicker from "../../../../../common/components/sixads/date-range-picker/date-range-picker";

const DateSelector = ({ dates, onUpdateDates }) => {
    const SuffixButton = ({ toggleDateModal }) => <Button icon={CalendarMajorMonotone} onClick={toggleDateModal} />;
    return (
        <DateRangePicker
            startDate={dates.start}
            endDate={dates.end}
            updateDates={onUpdateDates}
            fullWidthButton={true}
            render={(toggleDateModal) => (
                <div className="flex flex-column space-x-4 dates-column--inputs--dates">
                    <div>
                        <TextField
                            label="Campaign start date"
                            value={moment(dates.start).format("Y-MM-DD")}
                            suffix={<SuffixButton toggleDateModal={toggleDateModal} />}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Campaign end date"
                            value={moment(dates.end).format("Y-MM-DD")}
                            suffix={<SuffixButton toggleDateModal={toggleDateModal} />}
                            disabled={true}
                        />
                    </div>
                </div>
            )}
        />
    );
};

export default DateSelector;
