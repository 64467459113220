import { SET_UNINSTALL_MONEY } from "../../actions";

const events = {
    [SET_UNINSTALL_MONEY]: {
        hitType: "event",
        eventCategory: "Button",
        eventAction: "Click",
        eventLabel: "2st: Earn by Showing Ads",
    },
};

export default events;
