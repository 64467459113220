import React from "react";
import * as defaultComponents from "./default-components";
import { getComponents } from "../../../../utils/override";

export default function Stepper({ overrides = {} }) {
    const {
        Root: { component: Root, props: rootProps },
        Header: { component: Header, props: headerProps },
        Body: { component: Body, props: bodyProps },
        Footer: { component: Footer, props: footerProps },
    } = getComponents(defaultComponents, overrides);

    return (
        <Root {...rootProps}>
            <Header {...headerProps} />
            <Body {...bodyProps} />
            <Footer {...footerProps} />
        </Root>
    );
}
