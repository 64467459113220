/* eslint-disable react/jsx-boolean-value */
import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Stack, Tag, Tooltip } from "@shopify/polaris";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { setCreative } from "../../../actions/actions-dynamic-ads";
import { getGeolocation, getInterestSuggestion } from "../../../api/api-facebook-ads";
import ProductsCarousel from "../../ProductsCarousel";
import TextField from "../../../common/components/textfield";
import InputNumber from "../../../common/components/inputNumber";
import Button from "../../../common/components/button";
import Banner from "../../../common/components/sixads/banner";
import Icon from "../../../common/components/Icon";
import { logAnalytics } from "../../../actions";
import useInterestSearch from "../hooks/useInterestSearch";

const CreativeAndPreview = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const errorRef = useRef(null);

    const adType = useSelector((state) => state.dynamicAds.type);
    const facebookLookAlikeAudiences = useSelector((state) => state.settings.facebook.lookalike_audiences);
    const lookalikeAudiencesOptions = useSelector((state) => {
        const audiences = [
            {
                label: "Sixads generated lookalike audiences",
                options: [],
            },
            {
                label: "Other lookalike audiences",
                options: [],
            },
        ];

        state.settings.facebook.lookalike_audiences.forEach((audience) => {
            const audienceItem = {
                value: audience.primary_key,
                label: audience.name,
            };

            if (audience.is_created_by_sixads) {
                audiences[0]["options"].push(audienceItem);
            } else {
                audiences[1]["options"].push(audienceItem);
            }
        });

        return audiences;
    });

    const [primaryText, setPrimaryText] = useState("");
    const [ageStart, setAgeStart] = useState(18);
    const [ageEnd, setAgeEnd] = useState(65);
    const [geoTargeting, setGeoTargeting] = useState([{}]);
    const [interestTargeting, setInterestTargeting] = useState([]);
    const [genderTargeting, setGenderTargeting] = useState("");
    const [lookalikeAudiences, setLookalikeAudiences] = useState([]);
    const [interestQuery, setInterestQuery] = useState("");
    const [interestList, interestLoading] = useInterestSearch(interestQuery, interestTargeting);
    const [interestSuggestionList, setInterestSuggestionList] = useState([]);
    const [interestSuggestionListLoading, setInterestSuggestionListLoading] = useState(false);
    const genderOptions = [
        {
            label: "Male",
            value: 1,
        },
        {
            label: "Female",
            value: 2,
        },
        {
            label: "All",
            value: "",
        },
    ];

    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorText, setErrorText] = useState("");

    const handleSubmit = () => {
        dispatch(
            setCreative(
                primaryText,
                geoTargeting,
                interestTargeting,
                ageStart,
                ageEnd,
                genderTargeting,
                lookalikeAudiences
            )
        );

        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Targeting settings",
                eventAction: "Button click",
                eventLabel: "Next",
            })
        );

        history.push("/dynamic-ads/create-campaign/plans/");
    };

    useEffect(() => {
        if (showErrorBanner) {
            errorRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
            });
            if (window.innerWidth >= 768 && window.innerHeight <= 970) {
                errorRef.current.style.marginTop = "60px";
            }
        }
    }, [showErrorBanner]);

    const ErrorBanner = () => {
        return (
            <Banner ref={errorRef} type="error" onClose={() => setShowErrorBanner(false)}>
                {errorText}
            </Banner>
        );
    };

    const handleValidation = () => {
        let hasInterestTargeting = true;
        let hasGeoTargeting = true;

        const isInterestTargetingSet = interestTargeting.length === 0;
        const isInterestTargetingWithValue =
            interestTargeting.length === 1 && Object.keys(interestTargeting[0]).length === 0;
        if (isInterestTargetingSet || isInterestTargetingWithValue) {
            hasInterestTargeting = false;
        }

        const isGeoTargetingSet = geoTargeting.length === 0;
        const isGeoTargetingWithValue = geoTargeting.length === 1 && Object.keys(geoTargeting[0]).length === 0;

        if (isGeoTargetingSet || isGeoTargetingWithValue) {
            hasGeoTargeting = false;
        }

        if (
            !primaryText ||
            !ageStart ||
            !ageEnd ||
            (!geoTargeting && ["REACH", "UPSELL"].includes(adType)) ||
            (!hasGeoTargeting && ["REACH", "UPSELL"].includes(adType)) ||
            (!interestTargeting && ["REACH", "UPSELL"].includes(adType)) ||
            (!hasInterestTargeting && ["REACH", "UPSELL"].includes(adType))
        ) {
            const errorMessage = [];
            if (!primaryText) {
                errorMessage.push("Primary text is missing");
            }
            if (!ageStart) {
                errorMessage.push("Age start is missing");
            }
            if (!ageEnd) {
                errorMessage.push("Age end is missing");
            }
            if (["REACH", "UPSELL"].includes(adType)) {
                if (!geoTargeting || !hasGeoTargeting) {
                    errorMessage.push("Geo targeting are missing");
                }
                if (!interestTargeting || !hasInterestTargeting) {
                    errorMessage.push("Audiences are missing");
                }
            }

            setShowErrorBanner(true);
            setErrorText(errorMessage.join(", "));
        } else if (ageStart && ageEnd && (ageStart < 18 || ageEnd < 18)) {
            const errorMessage = "Age can't be less than 18";
            setShowErrorBanner(true);
            setErrorText(errorMessage);
        } else if (ageStart && ageEnd && (ageStart > 65 || ageEnd > 65)) {
            const errorMessage = "Age can't be greater than 65";
            setShowErrorBanner(true);
            setErrorText(errorMessage);
        } else if (ageStart && ageEnd && ageEnd < ageStart) {
            const errorMessage = "Age range is incorrect";
            setShowErrorBanner(true);
            setErrorText(errorMessage);
        } else {
            setShowErrorBanner(false);
            handleSubmit();
        }
    };

    const getLocations = async (inputValue) => {
        if (inputValue.length) {
            const apiResponse = await getGeolocation(inputValue);
            const locations = apiResponse.map((data) => ({ label: data.name, value: data.key }));
            return locations;
        }
    };

    const getSuggestions = async () => {
        setInterestSuggestionListLoading(true);
        let inputValue = "";
        interestTargeting.map((interest, index) => {
            if (index + 1 === interestTargeting.length) {
                inputValue = inputValue + interest.label;
            } else {
                inputValue = inputValue + interest.label + ",";
            }
        });
        const apiResponse = await getInterestSuggestion(inputValue);
        if (apiResponse.length) {
            const suggestions = apiResponse.map((data) => ({ label: data.name, value: data.id }));
            setInterestSuggestionList(suggestions);
            setInterestSuggestionListLoading(false);
        } else {
            setInterestSuggestionList([]);
            setInterestSuggestionListLoading(false);
        }
    };

    useEffect(() => {
        if (interestTargeting.length) {
            getSuggestions();
        }
    }, [interestTargeting]);

    const AudiencesTag = () => {
        const removeTag = useCallback(
            (value) => () => {
                setInterestTargeting((previousTags) =>
                    previousTags.filter((previousTag) => previousTag.value !== value)
                );
            },
            []
        );

        const tagMarkup = interestTargeting.map((option) => (
            <Tag key={option.value} onRemove={removeTag(option.value)}>
                {option.label}
            </Tag>
        ));

        return <Stack spacing="tight">{tagMarkup}</Stack>;
    };

    const AllInterest = ({ list }) => (
        <div className="absolute z-50 w-1/2 max-h-96 bg-white my-5 mr-14 border border-gray-100 rounded-md overflow-auto">
            {!interestLoading &&
                list.map((interestItem) => {
                    const temp = interestTargeting.filter((item) => item.value === interestItem.value);
                    if (!temp.length) {
                        return (
                            <div
                                className="pl-6 py-4 border border-gray-100 hover:bg-blue-200"
                                key={interestItem.value}
                                onClick={() => {
                                    setInterestTargeting((prev) => [...prev, interestItem]);
                                }}
                            >
                                {interestItem.label}
                            </div>
                        );
                    }
                })}
            {interestLoading && <div className="py-4 border border-gray-100 text-center">loading ...</div>}
        </div>
    );

    const AllSuggestions = ({ list }) => (
        <div className="w-full h-full bg-white overflow-auto">
            {!interestSuggestionListLoading &&
                list.map((interestItem) => {
                    const temp = interestTargeting.filter((item) => item.value === interestItem.value);
                    if (!temp.length) {
                        return (
                            <div
                                className="m-2 p-4 border border-gray-400 rounded-3xl bg-gray-100 text-gray-700 hover:bg-blue-200 flex flex-row"
                                key={interestItem.value}
                                onClick={() => {
                                    setInterestTargeting((prev) => [...prev, interestItem]);
                                }}
                            >
                                <div className="pl-2 pr-4 font-semibold border-r-2 flex items-center">+</div>
                                <div className="pl-4">{interestItem.label}</div>
                            </div>
                        );
                    }
                })}
            {interestSuggestionListLoading && (
                <div className="h-full flex justify-center items-center text-gray-700">loading ...</div>
            )}
            {!interestSuggestionListLoading && !interestSuggestionList.length && (
                <div className="h-full flex justify-center items-center text-gray-700">No suggestions</div>
            )}
        </div>
    );

    const InterestAutoCompleteList = () => <div>{interestQuery && <AllInterest list={interestList} />}</div>;

    const NoPixelWarning = () => {
        if (facebookLookAlikeAudiences.length === 0)
            return (
                <div className="w-full mt-4 pl-5 py-3 bg-yellow-100 text-yellow-700">
                    You don't have enough people associated with your Pixel just yet
                </div>
            );
        else return "";
    };

    return (
        <Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                <div className="pt-36 lg:pt-40 px-10 flex bg-white justify-center items-center">
                    <div className="h-full flex flex-col items-start w-full md:w-5/6">
                        <div className="w-full">
                            {showErrorBanner && <ErrorBanner />}
                            <div className="flex-col justify-between items-start mt-8">
                                <h1 className="page-title">Create & Preview</h1>
                            </div>
                        </div>
                        <div className="w-full items-start py-10">
                            <div className="py-3">
                                <TextField
                                    label="Primary text *"
                                    name="primaryText"
                                    value={primaryText}
                                    onChange={(event) => setPrimaryText(event.target.value)}
                                    maxLength={90}
                                />
                            </div>

                            <div className="relative border rounded p-10 pb-5 mt-6">
                                <div
                                    className="absolute px-5 text-gray-700 font-bold text-14px bg-white"
                                    style={{ top: "-10px", left: "12px" }}
                                >
                                    Targeting
                                </div>

                                {adType === "REACH" && (
                                    <div className="py-3">
                                        <div className="flex flex-row ga">
                                            <span className="text-gray-700 font-bold text-14px">
                                                Lookalike audience
                                            </span>
                                            &nbsp;&nbsp;
                                            <Tooltip
                                                active={false}
                                                content="A lookalike audience is a way your ads can reach new people who are likely to be interested in your business because they share similar characteristics to your existing customers."
                                                preferredPosition="mostSpace"
                                            >
                                                <Icon width={16} height={16} ico="info" />
                                            </Tooltip>
                                        </div>
                                        <Select
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={lookalikeAudiencesOptions}
                                            isDisabled={facebookLookAlikeAudiences.length === 0}
                                            onChange={(event) => {
                                                setLookalikeAudiences(event);
                                            }}
                                        />
                                        <NoPixelWarning />
                                    </div>
                                )}
                                <div className="py-3">
                                    <span className="text-gray-700 font-bold text-14px">Gender *</span>
                                    <Select
                                        options={genderOptions}
                                        value={genderOptions.filter((option) => option.value === genderTargeting)}
                                        onChange={(e) => {
                                            setGenderTargeting(e.value);
                                        }}
                                    />
                                </div>

                                {adType === "REMARKETING" && (
                                    <div className="py-3 flex flex-col">
                                        <span className="text-gray-700 font-bold text-14px">Age *</span>
                                        <div className="grid grid-cols-3">
                                            <InputNumber
                                                name="ageStart"
                                                value={ageStart}
                                                onChange={(event) => setAgeStart(event.target.value)}
                                                placeholder="From"
                                                minValue="18"
                                                maxValue="65"
                                            />
                                            <div className="flex justify-center items-center text-gray-700 font-bold text-18px"></div>
                                            <InputNumber
                                                name="ageEnd"
                                                value={ageEnd}
                                                onChange={(event) => setAgeEnd(event.target.value)}
                                                placeholder="To"
                                                minValue="18"
                                                maxValue="65"
                                            />
                                        </div>
                                    </div>
                                )}

                                {["REACH", "UPSELL"].includes(adType) && (
                                    <div>
                                        <div className="py-3">
                                            <span className="text-gray-700 font-bold text-14px">Geo targeting *</span>
                                            <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                loadOptions={getLocations}
                                                defaultOptions
                                                onChange={(e) => {
                                                    setGeoTargeting(e);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3">
                                            <span className="text-gray-700 font-bold text-14px">Audiences *</span>
                                            <div className="py-1">
                                                <AudiencesTag />
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="mr-4">
                                                    <div>
                                                        <TextField
                                                            inputClasses="h-20"
                                                            placeholder="Search interest"
                                                            name="interestQuery"
                                                            value={interestQuery}
                                                            onChange={(event) => setInterestQuery(event.target.value)}
                                                            onBlur={() => {
                                                                setTimeout(() => {
                                                                    setInterestQuery("");
                                                                }, 200);
                                                            }}
                                                            autocomplete="off"
                                                        />
                                                        <InterestAutoCompleteList />
                                                    </div>

                                                    <div className="py-3 flex flex-col">
                                                        <span className="text-gray-700 font-bold text-14px">Age *</span>
                                                        <div className="grid grid-cols-3">
                                                            <InputNumber
                                                                name="ageStart"
                                                                value={ageStart}
                                                                onChange={(event) => setAgeStart(event.target.value)}
                                                                placeholder="From"
                                                                minValue="18"
                                                                maxValue="65"
                                                            />
                                                            <div className="flex justify-center items-center text-gray-700 font-bold text-18px">
                                                                -
                                                            </div>
                                                            <InputNumber
                                                                name="ageEnd"
                                                                value={ageEnd}
                                                                onChange={(event) => setAgeEnd(event.target.value)}
                                                                placeholder="To"
                                                                minValue="18"
                                                                maxValue="65"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border rounded h-96 w-full my-1 ml-2 mr-5">
                                                    <AllSuggestions list={interestSuggestionList} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="w-full mx-auto my-10 flex justify-end">
                                <Button primary={true} onClick={() => handleValidation()}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="pt-0 md:pt-24 px-10 xl:px-20 flex justify-center items-center"
                    style={{ backgroundColor: "#E0EBF6" }}
                >
                    <div className="w-full">
                        <ProductsCarousel primaryText={primaryText} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CreativeAndPreview;
