import css from "styled-jsx/css";

export default css`
    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        background-color: #c4cdd5;
        outline: none;
        border-radius: 999999999px;
        cursor: pointer;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        border: 1.9px solid #dfe3e8;
        background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
        box-shadow: 0 4px 24px rgba(199, 199, 199, 0.25);
        border-radius: 50%;
        cursor: w-resize;
    }
`;
