/* eslint-disable dot-notation */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template10 = ({ name, image, price, adMessage, paused = false, discount }) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"]["text"] = adMessage || "SPECIAL DISCOUNT";

        animationObject["ProductNameObj"]["ProductName"]["text"]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName2"]["text"]["text"] = name;
        animationObject["ProductNameObj3"]["ProductName3"]["text"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow2"]["text"]["text"] = price;
        animationObject["PriceTagObj3"]["PriceNow3"]["text"]["text"] = price;

        animationObject["DiscountTagObj"]["DiscountTag"]["text"]["text"] = discount;
        animationObject["DiscountTagObj2"]["DiscountTag2"]["text"]["text"] = discount;
        animationObject["DiscountTagObj3"]["DiscountTag3"]["text"]["text"] = discount;

        const images = window.AdobeAn.getComposition("CCB70635D42A4CE596CEAB17894AFB50").getImages();
        images["HeroImg1"]["src"] = image;
        images["productimage1543990445_1080x1"]["src"] = image;
        images["productimage15928289301"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_10"
                composition="CCB70635D42A4CE596CEAB17894AFB50"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template10.design_name = "concrete_red";
Template10.image_width = 1000;
Template10.image_height = 1000;
Template10.extra_fields = ["discount"];

export default Template10;
