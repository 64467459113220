/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import "./Header.css";

import { Icon } from "../utils/Icon";
import { persistor } from "../store/create";
import { toggleNavigation } from "../actions";

class Header extends Component {
    state = {
        popoverActive: false,
        isMobile: false,
    };

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
        initials: PropTypes.string.isRequired,
    };

    componentDidMount() {
        document.addEventListener("click", this.toggleProfileMenu);
        this.handleDisplay();
    }

    handleDisplay = () => {
        const isMobile = window.screen.width <= 769;
        this.setState({
            isMobile: isMobile,
        });
    };

    onToggle = () => {
        const { dispatch } = this.props;

        dispatch(toggleNavigation());
    };

    toggleProfileMenu = (e) => {
        if (!["profile-menu", "profile-menu-expander"].includes(e.target.id)) {
            this.setState({ popoverActive: false });
        }
    };

    render() {
        const { email, initials, teal = false, isOpen } = this.props;
        const { popoverActive = false, isMobile = false } = this.state;

        const userMenu = (
            <div
                className="flex flex-row items-center gap-3 cursor-pointer"
                style={{
                    backgroundColor: "#f4f6f8",
                    width: "100%",
                    borderRadius: "20px",
                }}
                id="user-menu"
                onClick={() => this.setState({ popoverActive: true })}
            >
                <div id="profile-menu-expander" className="user-menu">
                    <div className="user-initials">{initials}</div>
                </div>
                {!isMobile && (
                    <Fragment>
                        <span id="profile-menu-expander">{email}</span>
                        <Icon name="caretDown" id="profile-menu-expander" />
                    </Fragment>
                )}
            </div>
        );

        const popOver = (
            <div>
                <div
                    id="profile-menu"
                    className="fixed top-0 right-0 rounded-sm bg-white shadow text-black z-10000"
                    onClick={this.toggleProfileMenu}
                >
                    <div className="flex flex-col w-max text-gray-700">
                        <div className="border-b w-full pr-28 p-4">
                            <div className="flex flex-row items-center gap-3 p-4 w-full">
                                <div id="profile-menu-expander" className="user-menu">
                                    <div className="user-initials">{initials}</div>
                                </div>
                                <span>{email}</span>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-8">
                            <a href="https://sixads.net/faq/" className="py-2" target="_blank" rel="noreferrer">
                                <span className="p-1">FAQ</span>
                            </a>
                            <a href="/logout" className="py-2" onClick={persistor.purge}>
                                <span className="p-1">Logout</span>
                            </a>
                            <a href="/app/uninstall/" className="py-2">
                                <span className="p-1">Uninstall</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );

        const rootClasses = classnames("fixed w-full right-0 flex justify-between items-center p-5 z-1000", {
            [["bg-teal-700 text-white"]]: teal,
            [["bg-gray-90"]]: !teal && isMobile,
        });

        return (
            <div className={rootClasses}>
                {!isMobile && teal && (
                    <div
                        onClick={this.onToggle}
                        className="flex flex-row items-center w-max justify-space-around cursor-pointer space-x-4"
                    >
                        <Icon name={isOpen ? "navigationToggleCollapse" : "navigationToggleExpand"} />
                        {!isOpen && <Icon name="sixadsLogoWithBrand" />}
                    </div>
                )}
                {isMobile && (
                    <div className="text-black" onClick={this.onToggle}>
                        <Icon
                            color={teal ? "white" : "black"}
                            name={isOpen ? "navigationToggleCollapse" : "navigationToggleExpand"}
                        />
                    </div>
                )}
                {!teal && <div>&nbsp;</div>}
                <div>
                    {popoverActive && popOver}
                    {!popoverActive && userMenu}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { email } = state.store;
    const { navigation } = state;
    const { isOpen = false } = navigation;

    return {
        email: email,
        initials: email ? email.charAt(0).toUpperCase() : "",
        isOpen: isOpen,
    };
};

export default connect(mapStateToProps)(Header);
