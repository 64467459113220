import { GOOGLE_SHOPPING_CORE, ACCOUNT_URL, TARGETING_URL } from "./common";

export const OAUTH_SCOPES = [
    "openid",
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/adwords",
    "https://www.googleapis.com/auth/content",
    "https://www.googleapis.com/auth/siteverification.verify_only",
];

export const GOOGLE_CREDENTIALS_API_URL = `${ACCOUNT_URL}/google-credentials`;
export const GOOGLE_DISCONNECT_URL = `${ACCOUNT_URL}/disconnect-google-credentials`;
export const GOOGLE_MERCHANT_ACCOUNT_CONNECT_URL = `${ACCOUNT_URL}/connect-google-merchant-account`;
export const GOOGLE_MERCHANT_ACCOUNT_DISCONNECT_URL = `${ACCOUNT_URL}/disconnect-google-merchant-account`;
export const GOOGLE_MERCHANT_ACCOUNT_LIST_URL = `${ACCOUNT_URL}/google-merchant-accounts`;
export const MERCHANT_ACCOUNT_CREATE_URL = `${ACCOUNT_URL}/create-merchant-account`;
export const GOOGLE_ADS_ACCOUNT_CONNECT_URL = `${ACCOUNT_URL}/connect-google-promotions-account`;
export const GOOGLE_ADS_ACCOUNT_DISCONNECT_URL = `${ACCOUNT_URL}/disconnect-google-promotions-account`;
export const GOOGLE_ADS_ACCOUNT_LIST_URL = `${ACCOUNT_URL}/google-promotions-accounts`;
export const GOOGLE_ADS_ACCOUNT_CREATE_URL = `${ACCOUNT_URL}/create-google-promotions-account`;
export const GOOGLE_ADS_ACCOUNT_INVITATION_STATUS_URL = `${ACCOUNT_URL}/google-promotions-invitation-status`;
export const GOOGLE_MERCHANT_ACCOUNT_SHIPPING_SETTINGS_URL = `${ACCOUNT_URL}/get-google-merchant-account-shipping-settings`;
export const GOOGLE_MERCHANT_ACCOUNT_TAX_SETTINGS_URL = `${ACCOUNT_URL}/get-google-merchant-account-tax-settings`;
export const GOOGLE_ADS_ACCOUNT_STATUS_URL = `${ACCOUNT_URL}/google-promotions-account-status`;
export const GOOGLE_ADS_ACCOUNT_BILLING_SETTINGS_URL = `${ACCOUNT_URL}/get-google-promotions-account-billing-settings`;
export const GOOGLE_PRODUCT_STATUS_URL = `${ACCOUNT_URL}/google-products-status`;
export const GOOGLE_CREDENTIALS_VALIDATE_API_URL = `${ACCOUNT_URL}/validate-google-credentials`;
export const GOOGLE_SHOPPING_CAMPAIGN_CREATE_URL = `${GOOGLE_SHOPPING_CORE}/campaigns`;
export const GOOGLE_SHOPPING_TARGETING_CREATE = `${TARGETING_URL}/countries/create`;
