import { Banner, Link } from "@shopify/polaris";
import React from "react";
import Proptypes from "prop-types";

import { postBusinessEvent } from "../../api/api-business-event";
import FACEBOOK_BUSINESS_EVENTS from "../../common/business_events_constants";

function TwoFactorAuthenticationWarningBanner({ onSecondaryAction }) {
    const twoFactorAuthenticationSettingsUrl = "https://www.facebook.com/security/2fac/settings";
    const onClick = () => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.ENABLE_TWO_FACTOR_AUTH_CLICK);
    };

    const link = (
        <Link monochrome={true} url={twoFactorAuthenticationSettingsUrl} external={true} onClick={onClick}>
            here
        </Link>
    );

    const title = <p>Oops, access denied. Enable two-factor authentication {link} or pick another account.</p>;

    return (
        <Banner
            title={title}
            status="critical"
            action={{
                content: "Enable two-factor authentication",
                url: twoFactorAuthenticationSettingsUrl,
                external: true,
                onAction: onClick,
            }}
            secondaryAction={{
                content: "Click here to refresh after enabling two-factor authentication",
                onAction: onSecondaryAction,
            }}
        />
    );
}

TwoFactorAuthenticationWarningBanner.propTypes = {
    onSecondaryAction: Proptypes.func.isRequired,
};
export default TwoFactorAuthenticationWarningBanner;
