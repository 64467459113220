import { api } from "../utils";
import { toggleToastPopup } from "./action-toast";
import { create, patch, remove, update } from "../api/api-ads";
import { updateListItemFailure, updateListItemRequest, updateListItemSuccess } from "./action-lists";

export const ACTIONS = {
    UPDATE_REMARKETING_AD_SUCCESS: "ads/UPDATE_REMARKETING_AD_SUCCESS",
    RETRY_REMARKETING_AD_LAUNCH_SUCCESS: "ads/RETRY_REMARKETING_AD_LAUNCH_SUCCESS",
};

export const updateRemarketingAdSuccess = (response) => {
    return {
        type: ACTIONS.UPDATE_REMARKETING_AD_SUCCESS,
        payload: {
            response: response,
        },
    };
};

export const retryRemarketingAdLaunchSuccess = (response) => {
    return {
        type: ACTIONS.RETRY_REMARKETING_AD_LAUNCH_SUCCESS,
        payload: {
            response: response,
        },
    };
};

export const CREATE_ADS_REQUEST = "CREATE_ADS_REQUEST";
export const CREATE_ADS_FAILURE = "CREATE_ADS_FAILURE";
export const CREATE_ADS_SUCCESS = "CREATE_ADS_SUCCESS";

export const createAdsRequest = (payload) => ({
    type: CREATE_ADS_REQUEST,
    payload: payload,
});

export const createAdsFailure = (payload) => ({
    type: CREATE_ADS_FAILURE,
    payload: payload,
});

export const createAdsSuccess = (payload, ads) => ({
    type: CREATE_ADS_SUCCESS,
    payload: payload,
    ads: ads,
});

export const createAds = (data) => (dispatch, getState) => {
    dispatch(createAdsRequest(data));

    return create({ ads: data })
        .then((payload) => {
            if (payload.items) {
                const ads = getState().products.items.filter((item) => payload.items.some((o) => o.id === item.id));

                dispatch(createAdsSuccess(payload, ads));
            }
        })
        .catch((error) => {
            throw error;
        });
};

export const REMOVE_ADS_REQUEST = "REMOVE_ADS_REQUEST";
export const REMOVE_ADS_FAILURE = "REMOVE_ADS_FAILURE";
export const REMOVE_ADS_SUCCESS = "REMOVE_ADS_SUCCESS";

export const removeAdsRequest = (payload) => ({
    type: REMOVE_ADS_REQUEST,
    payload: payload,
});

export const removeAdsFailure = (payload) => ({
    type: REMOVE_ADS_FAILURE,
    payload: payload,
});

export const removeAdsSuccess = (payload) => ({
    type: REMOVE_ADS_SUCCESS,
    payload: payload,
});

export const removeAds = (data) => (dispatch) => {
    dispatch(removeAdsRequest(data));

    return remove({ ids: data })
        .then((payload) => {
            dispatch(removeAdsSuccess(payload));
        })
        .catch((error) => {
            throw error;
        });
};

export const UPDATE_ADS_REQUEST = "UPDATE_ADS_REQUEST";
export const UPDATE_ADS_FAILURE = "UPDATE_ADS_FAILURE";
export const UPDATE_ADS_SUCCESS = "UPDATE_ADS_SUCCESS";

export const updateAdsRequest = (payload) => ({
    type: UPDATE_ADS_REQUEST,
    payload: payload,
});

export const updateAdsFailure = (payload) => ({
    type: UPDATE_ADS_FAILURE,
    payload: payload,
});

export const updateAdsSuccess = (payload, data) => ({
    type: UPDATE_ADS_SUCCESS,
    payload: payload,
    data: data,
});

export const updateAds = (data) => (dispatch) => {
    dispatch(updateAdsRequest(data));

    const ads = data.map((ad) => {
        const adObject = ad;
        delete adObject.isLoading;
        delete adObject.isEnabling;
        return adObject;
    });

    return update({ ads: ads })
        .then((payload) => {
            dispatch(updateAdsSuccess(payload, data));
        })
        .catch((error) => {
            throw error;
        });
};

export const PATCH_ADS_REQUEST = "PATCH_ADS_REQUEST";
export const PATCH_ADS_FAILURE = "PATCH_ADS_FAILURE";
export const PATCH_ADS_SUCCESS = "PATCH_ADS_SUCCESS";

export const patchAdsRequest = (payload) => ({
    type: PATCH_ADS_REQUEST,
    payload: payload,
});

export const patchAdsFailure = (payload) => ({
    type: PATCH_ADS_FAILURE,
    payload: payload,
});

export const patchAdsSuccess = (ad) => ({
    type: PATCH_ADS_SUCCESS,
    payload: {
        ad: ad,
    },
});

export const patchAd = (adId, data, adType) => (dispatch) => {
    dispatch(updateListItemRequest());

    patch(adId, data, adType)
        .then((res) => {
            if (res.status_code) {
                if (res.status_code === 400) {
                    const keys = Object.keys(res);
                    dispatch(updateListItemFailure());
                    // eslint-disable-next-line no-restricted-syntax
                    for (const key of keys) {
                        if (key !== "status_code") {
                            dispatch(toggleToastPopup([res[key][0]], "error"));
                            break;
                        }
                    }
                    return;
                }

                dispatch(updateListItemFailure());
                dispatch(toggleToastPopup(["Ad failed to update."], "error"));
                return;
            }

            if (adType === "remarketing") {
                dispatch(updateRemarketingAdSuccess(res));
            } else {
                dispatch(updateListItemSuccess(res));
            }

            dispatch(toggleToastPopup(["Ad updated."], "success"));
        })
        .catch(() => {
            dispatch(updateListItemFailure());
            dispatch(toggleToastPopup(["Ad failed to update."], "error"));
        });
};

export const RETRY_AD_LAUNCH_REQUEST = "RETRY_AD_LAUNCH_REQUEST";
export const RETRY_AD_LAUNCH_FAILURE = "RETRY_AD_LAUNCH_FAILURE";

export const retryAdLaunchRequest = (adId, adType) => {
    return {
        type: RETRY_AD_LAUNCH_REQUEST,
        payload: {
            adId: adId,
            adType: adType,
        },
    };
};

export const retryAdLaunchFailure = (adId, adType) => {
    return {
        type: RETRY_AD_LAUNCH_FAILURE,
        payload: {
            adId: adId,
            adType: adType,
        },
    };
};

export const retryAdLaunch = (adId, adType, beforeApiCall = null, afterApiCall = null) => (dispatch) => {
    const url =
        adType === "reach" ? `/api/v1/promotions/${adId}/retry/` : `/api/v1/remarketing-promotions/${adId}/retry/`;

    dispatch(retryAdLaunchRequest(adId, adType));
    if (beforeApiCall) {
        beforeApiCall();
    }

    api(url, { method: "GET" })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
            if (adType === "remarketing") {
                dispatch(retryRemarketingAdLaunchSuccess(response));
            } else {
                dispatch(updateListItemSuccess(response));
            }
        })
        .catch((error) => {
            let errors = ["Ad failed to update."];
            if (error.response) {
                errors = [error.response];
            }

            dispatch(retryAdLaunchFailure(adId, adType));
            dispatch(toggleToastPopup(errors, "error"));
        })
        .finally(() => {
            if (afterApiCall) {
                afterApiCall();
            }
        });
};
