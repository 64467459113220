import React, { Fragment, useState } from "react";
import Proptypes from "prop-types";

const BudgetInput = (props) => {
    const { id, className, step, value, disabled, onChange, onValueFormat, max, min } = props;
    const [read, setRead] = useState(false);

    return (
        <Fragment>
            {read ? (
                <input
                    type="number"
                    step={step}
                    id={id}
                    className={className}
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                        setRead(false);
                    }}
                    disabled={disabled}
                    min={min}
                    max={max}
                />
            ) : (
                <input
                    type="text"
                    id={id}
                    className={className}
                    value={onValueFormat(value)}
                    onFocus={() => {
                        setRead(true);
                    }}
                    disabled={disabled}
                    readOnly={true}
                />
            )}
        </Fragment>
    );
};

BudgetInput.propTypes = {
    value: Proptypes.number.isRequired,
    id: Proptypes.string,
    className: Proptypes.string,
    step: Proptypes.string,
    disabled: Proptypes.bool,
    onChange: Proptypes.func,
    onValueFormat: Proptypes.func,
    max: Proptypes.number,
    min: Proptypes.number,
};

BudgetInput.defaultProps = {
    id: "",
    className: "",
    step: ".01",
    disabled: false,
    onChange: () => {},
    onValueFormat: () => {},
};

export default BudgetInput;
