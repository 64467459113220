/* eslint-disable react/no-unused-prop-types */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { setTemplateSelection } from "../../../actions/actions-image-overlay-ads";
import { RectangleCornerTag } from "../../styled/Elements";
import CornerTagImageContainer from "../../styled/Container";

const LayoutThree = (props) => {
    const ref1 = useRef(null);
    const [imgDim, setImgDim] = useState({});
    const [imageLoaded, setImageLoaded] = useState(false);
    const {
        dispatch,
        selectProducts,
        selectedProduct,
        selectedTemplate,
        thumbnail,
        tagText,
        tagSize,
        tagColor,
        resizable,
        setResizeCoefficient,
    } = props;
    const { top, right, bottom, left } = thumbnail ? props : selectedTemplate;
    const selectedProductImage = selectProducts.products.find((product) => product.api_id === selectedProduct).images[0]
        .src;

    let width = tagSize || imgDim.width / 2.8;

    const handleSize = () => {
        if (!imageLoaded && resizable) {
            const { clientWidth, clientHeight, naturalWidth } = ref1.current;
            setImgDim({ height: clientHeight, width: clientWidth });
            setResizeCoefficient(clientWidth / naturalWidth);
            width = tagSize || imgDim.width / 2.8;

            dispatch(
                setTemplateSelection(
                    selectedTemplate.templateLayout,
                    top,
                    right,
                    bottom,
                    left,
                    naturalWidth,
                    clientWidth
                )
            );
            setImageLoaded(true);
        }
    };

    const handleResize = () => {
        const { clientWidth, clientHeight, naturalWidth } = ref1.current;
        setImgDim({ height: clientHeight, width: clientWidth });
        setResizeCoefficient(clientWidth / naturalWidth);
        width = tagSize || imgDim.width / 2.8;

        // dispatch(
        //     setTemplateSelection(selectedTemplate.templateLayout, top, right, bottom, left, naturalWidth, clientWidth)
        // );
    };

    useEffect(() => {
        if (resizable) {
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    return (
        <CornerTagImageContainer resizable={resizable} maxWidth={selectedTemplate.originalImageWidth}>
            <img src={selectedProductImage} alt="frame one" ref={ref1} onLoad={() => handleSize()} />
            <RectangleCornerTag
                top={top}
                left={left}
                bottom={bottom}
                right={right}
                width={width}
                tagMargin={`${width / 15}px`}
                tagColor={tagColor}
            >
                {!thumbnail && <div className="p-10 text-center">{parse(tagText)}</div>}
            </RectangleCornerTag>
        </CornerTagImageContainer>
    );
};

LayoutThree.propTypes = {
    dispatch: PropTypes.func.isRequired,
    tagSize: PropTypes.number,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    tagColor: PropTypes.object.isRequired,
    thumbnail: PropTypes.bool,
    tagText: PropTypes.string,
    selectedProduct: PropTypes.number.isRequired,
    selectProducts: PropTypes.object.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
    resizable: PropTypes.bool.isRequired,
    setResizeCoefficient: PropTypes.func,
};

LayoutThree.defaultProps = {
    tagSize: false,
    top: false,
    bottom: false,
    left: false,
    right: false,
    thumbnail: false,
    tagText: "",
    setResizeCoefficient: () => {},
};

const mapStateToProps = (state) => {
    const {
        imageOverlayAds: { selectProducts, selectedTemplate },
    } = state;

    return {
        selectedProduct: selectProducts.selectedProducts.length ? selectProducts.selectedProducts[0] : null,
        selectProducts,
        selectedTemplate,
    };
};

export default connect(mapStateToProps)(LayoutThree);
