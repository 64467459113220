import PropTypes from "prop-types";
import React from "react";

const Headline = ({ headline }) => {
    return <h1 className="headline">{headline}</h1>;
};

Headline.propTypes = {
    headline: PropTypes.string.isRequired,
};

export default Headline;
