const TZ_AMERICA_LOS_ANGELES_TIMEZONE = 1;

export function createAdAccount(
    businessManagerId = "",
    adAccountName = "",
    currency = "",
    handleFacebookError,
    handleAdAccountCreationSuccess,
    setIsLoading,
    setError
) {
    setError("");
    setIsLoading(true);
    const NONE = "NONE";

    window.FB.api(
        `${businessManagerId}/adaccount`,
        "POST",
        {
            name: adAccountName,
            timezone_id: TZ_AMERICA_LOS_ANGELES_TIMEZONE,
            currency: currency,
            end_advertiser: NONE,
            media_agency: NONE,
            partner: NONE,
        },
        (response) => {
            if (!response) {
                handleFacebookError({});
            } else if (response.error) {
                handleFacebookError(response.error);
            } else {
                const adAccountId = response.id;
                handleAdAccountCreationSuccess(adAccountId);
            }
        }
    );
}

export function getBusinessUser(businessManagerId = "", onGetBusinessUserSuccess, handleFacebookError, adAccountId) {
    window.FB.api("me/business_users", "GET", (response) => {
        if (!response) {
            handleFacebookError({});
        } else if (response.error) {
            handleFacebookError(response.error);
        } else {
            const businessUser = response.data.find((v) => v.business.id === businessManagerId);
            const businessUserId = businessUser.id;
            onGetBusinessUserSuccess(businessUserId, adAccountId);
        }
    });
}

export function assignAdUser(adAccountId = "", businessUserId = "", onAssignAdUserSuccess, handleFacebookError) {
    window.FB.api(
        `${adAccountId}/assigned_users`,
        "POST",
        {
            user: businessUserId,
            tasks: "MANAGE",
        },
        (response) => {
            if (!response) {
                handleFacebookError({});
            } else if (response.error) {
                handleFacebookError(response.error);
            } else {
                onAssignAdUserSuccess();
            }
        }
    );
}
