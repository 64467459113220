import React from "react";
import PropTypes from "prop-types";
// import Header from "./Header";
// import Headline from "./Headline";
// import FooterButtons from "./FooterButtons";
import AdView from "./AdView";

export const FacebookFeedAdView = (props) => {
    const { primaryText, imageUrl, headline, description } = props;

    return (
        <div className="facebookAdView">
            {/* <Header />
            <Headline headline={primaryText} /> */}
            <AdView imageUrl={imageUrl} primaryText={primaryText} description={description} />
            {/* <FooterButtons /> */}
        </div>
    );
};

FacebookFeedAdView.propTypes = {
    primaryText: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default null;
