import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Banner from "../common/components/sixads/banner";

const PlanPurchaseSuccessBanner = ({ subscription = null }) => {
    const [shouldShow, setShouldShow] = useState(false);
    useEffect(() => {
        const hasChargeId = window.location.search.includes("charge_id");
        if (hasChargeId) {
            setShouldShow(true);
            window.history.pushState({}, "");
        }
    }, []);

    return (
        <div>
            {shouldShow && subscription && (
                <div>
                    <Banner type="success" onClose={() => setShouldShow(false)}>
                        Congratulations your {subscription.type && subscription.type.toLowerCase()} {subscription.name}{" "}
                        plan is active! Now you can run unlimited ads on Facebook, Instagram & Google.
                    </Banner>{" "}
                    <br />
                </div>
            )}
            {(!shouldShow || !subscription) && <span />}
        </div>
    );
};

const mapStateToProps = (state) => ({
    subscription: state.store.subscription,
});

export default connect(mapStateToProps)(PlanPurchaseSuccessBanner);
