/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, Page } from "@shopify/polaris";

import withVideoAdsData from "../with-video-ads";
import { logAnalytics, navigateVideoAds } from "../../../actions";
import TemplatesList from "../../../components/video-ads/TemplatesList";

class SelectTemplate extends Component {
    state = {
        selectedTemplate: "",
        pauseAnimations: false,
    };

    componentDidMount() {
        window.document.title = "Select Template | SIXADS";

        /*setTimeout(() => {
            this.setState({
                pauseAnimations: true,
            });
        }, 2000);*/
    }

    debouncer() {
        const { onChange } = this.props;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.timeout = null;
            onChange(this.state);
        }, 250);
    }

    onTemplateSelect = async (templateName, designName) => {
        const { history, setLoading, dispatch } = this.props;

        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: `Choose design ${designName}`,
                eventAction: "Button click",
                eventLabel: "Video flow",
            })
        );

        this.setState(
            {
                selectedTemplate: templateName,
            },
            this.debouncer
        );
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            dispatch(navigateVideoAds(4));
            history.push("/video-ads/customize/");
        }, 500);
    };

    onListChange = (selectedItems) => {
        this.setState(
            {
                selectedProducts: selectedItems,
            },
            this.debouncer
        );
    };

    onFirstUpgradeSelect = () => {
        const { history, dispatch } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Video flow upgrade",
                eventAction: "Button click",
                eventLabel: "Choose to upgrade 1st",
            })
        );

        history.push("/video-ads/upgrade/");
    };

    onSecondUpgradeSelect = () => {
        const { history, dispatch } = this.props;
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Video flow upgrade",
                eventAction: "Button click",
                eventLabel: "Choose to upgrade 2st",
            })
        );

        history.push("/video-ads/upgrade/");
    };

    render() {
        return (
            <Fragment>
                <div className="template-select">
                    <Page
                        title={<span>Show-stopping templates 🤩</span>}
                        subtitle="Make video ads you are proud of. Explore engaging templates, customize them to fit your business & you're done!"
                    >
                        <Card>
                            <TemplatesList
                                onChangeList={this.onListChange}
                                onTemplateSelect={this.onTemplateSelect}
                                onFirstUpgradeSelect={this.onFirstUpgradeSelect}
                                onSecondUpgradeSelect={this.onSecondUpgradeSelect}
                                withUpgrade={true}
                            />
                        </Card>
                    </Page>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { videoAds } = state;

    const { pages: { product: { values = {}, errors = [] } } = {} } = videoAds;

    return {
        values: values,
        errors: errors,
    };
};

export default withRouter(
    withVideoAdsData({
        prefix: "template",
        // preload: preload,
        validate: () => {},
        WrappedComponent: connect(mapStateToProps)(SelectTemplate),
    })
);
