import React, { useCallback, useRef, Fragment } from "react";

const InfiniteScrollPagination = ({ isLoading, nextPageUrl, onNext, loader, children }) => {
    const observer = useRef();

    const lastItemElementRef = useCallback(
        (node) => {
            if (isLoading) return;
            if (!nextPageUrl) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    onNext();
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [nextPageUrl]
    );

    const childrenWithObserver = React.Children.map(children, (child, index) => {
        if (children.length === index + 1) {
            return React.cloneElement(child, { ref: lastItemElementRef });
        }

        return React.cloneElement(child);
    });

    return (
        <Fragment>
            {childrenWithObserver}
            {isLoading && loader}
        </Fragment>
    );
};

export default InfiniteScrollPagination;
