/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { logAnalytics, navigateVideoAds } from "../../actions";
import Button from "../../common/components/button";
import ReviewPopup from "../../components/ReviewPopup";

const Success = ({ linkBack, history }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.document.title = "Success | SIXADS";
    }, []);
    return (
        <div className="p-12 md:max-w-1/2 md:mx-auto my-0">
            <ReviewPopup />
            <div className="flex-1 flex flex-col">
                <div className="flex flex-col justify-around space-y-11 pt-32">
                    <p className="text-gray-800 text-5xl md:text-7xl">
                        Thanks & congrats on creating a thumb-stopping video!
                    </p>
                    <p className="text-gray-700 text-3xl">
                        Now that it's successfully downloaded, go ahead and create another one - it's free!
                    </p>
                </div>
                <div className="mt-48">
                    <Button
                        primary={true}
                        onClick={() => {
                            dispatch(
                                logAnalytics({
                                    hitType: "event",
                                    eventCategory: "Create another video on thank you",
                                    eventAction: "Button click",
                                    eventLabel: "Video flow",
                                })
                            );

                            dispatch(navigateVideoAds(1));
                            history.push(linkBack);
                        }}
                    >
                        Create another video
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Success);
