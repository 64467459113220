import React from "react";

export const ConnectPlus = () => {
    const isDisabled = true;
    return isDisabled ? null : (
        <div className="cpio-wrap cpio-pos-main" data-quantity="2">
            <a className="cpio-item" href="https://billo.app?ref=sixads" rel="noreferrer" target="_blank">
                <img className="desktop" src="/static/img/billo_desktop.png" alt="Billo" />
                <img className="mobile" src="/static/img/billo_mobile.png" alt="Billo" />
            </a>
        </div>
    );
};

export default ConnectPlus;
