import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import styles from "./styles";

class Switch extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        selected: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { selected } = this.props;

        const form = window.document.getElementById("switchForm");
        const inputs = form.querySelectorAll("input");

        inputs.forEach((input) => {
            input.addEventListener("click", () => {
                form.querySelector("div").style.transform = `translateX(${input.dataset.location})`;

                form.querySelectorAll("label").forEach((label) => {
                    label.classList.remove("selected");
                });

                input.parentElement.classList.add("selected");
            });
        });

        // Highlight initial selected option
        const selectedInput = window.document.querySelector(`input[value="${selected}"]`);
        form.querySelector("div").style.transform = `translateX(${selectedInput.dataset.location})`;
    }

    getDataAttribute = (index) => {
        switch (index) {
            case 0:
                return "0";
            case 1:
                return "calc(100% - 8px)";
            case 2:
                return "calc(200% - 12px)";
            default:
                return "0";
        }
    };

    getColoredDivStyles = () => {
        const { options } = this.props;

        return {
            width: `calc(${100 / options.length}% + 8px)`,
            height: `60px`,
        };
    };

    getLabelStyles = () => {
        const { options } = this.props;

        return {
            width: `calc(${100 / options.length}% - 1px)`,
        };
    };

    render() {
        const { options, selected } = this.props;

        return (
            <Fragment>
                <form id="switchForm">
                    <div style={this.getColoredDivStyles()} />

                    {options.map((option, index) => {
                        return (
                            <Fragment key={option}>
                                <label className={option === selected ? "selected" : ""} style={this.getLabelStyles()}>
                                    <input
                                        type="radio"
                                        name="option"
                                        value={option}
                                        data-location={this.getDataAttribute(index)}
                                        onClick={() => this.props.onChange(option)}
                                    />
                                    <div>{`${option.charAt(0).toUpperCase()}${option.slice(1)}`}</div>
                                </label>
                            </Fragment>
                        );
                    })}
                </form>

                <style jsx={true}>{styles}</style>
            </Fragment>
        );
    }
}

export default Switch;
