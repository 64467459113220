/* eslint-disable dot-notation */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template12 = ({ name, image, price, adMessage, paused = false }) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"]["text"] = adMessage || "SPECIAL DISCOUNT";

        animationObject["ProductNameObj"]["ProductName"]["text"]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName2"]["text"]["text"] = name;
        animationObject["ProductNameObj3"]["ProductName3"]["text"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow2"]["text"]["text"] = price;
        animationObject["PriceTagObj3"]["PriceNow3"]["text"]["text"] = price;

        const images = window.AdobeAn.getComposition("CCB71136D42A4CE536ZEAB17094ZFB50").getImages();
        images["Bitmap6111"]["src"] = image;
        images["Screenshot20210729at2246371"]["src"] = image;
        images["Screenshot20210729at2247091"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_12"
                composition="CCB71136D42A4CE536ZEAB17094ZFB50"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template12.design_name = "bottom_moving_mountains";
Template12.image_width = 1000;
Template12.image_height = 1000;

export default Template12;
