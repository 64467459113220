import React from "react";
import PropTypes from "prop-types";
import { FormLayout, Select } from "@shopify/polaris";
import dateUtils from "../../../../utils/date";
import DateField from "./date-field";

export default function DateRangeSelector({
    selected: { start = new Date(), end = new Date() } = {},
    dayDifference = "0",
    dayDifferenceLabel = "Today",
    onDateRangeSelect = () => {},
}) {
    const notCustomMode = dayDifferenceLabel !== "Custom";
    return (
        <FormLayout>
            <FormLayout>
                <Select
                    label="Date Range"
                    options={dateUtils.dateRangeOptions}
                    onChange={(difference) => {
                        const selectedDates = {
                            start: dateUtils.nDaysAgo(Number(difference)),
                            end: new Date(),
                        };
                        onDateRangeSelect({
                            selected: selectedDates,
                            dayDifference: difference,
                            dayDifferenceLabel: dateUtils.dateRangeOptions.find((o) => o.value === difference).label,
                        });
                    }}
                    value={dayDifference}
                />
            </FormLayout>
            <FormLayout>
                <FormLayout.Group>
                    <DateField
                        fieldLabel="Starting"
                        fieldValue={dateUtils.formatDate(start)}
                        disabled={notCustomMode}
                    />
                    <DateField fieldLabel="Ending" fieldValue={dateUtils.formatDate(end)} disabled={notCustomMode} />
                </FormLayout.Group>
            </FormLayout>
        </FormLayout>
    );
}

DateRangeSelector.prototypes = {
    selected: PropTypes.shape({
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
    }).isRequired,
    dayDifference: PropTypes.string,
    dayDifferenceLabel: PropTypes.string,
    onDateRangeSelect: PropTypes.func,
};

DateRangeSelector.defaultProps = {
    dayDifference: "0",
    dayDifferenceLabel: "Today",
    onDateRangeSelect: () => {},
};
