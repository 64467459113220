// Can be generated by going to the spreadsheet
// https://docs.google.com/spreadsheets/d/1OmFe8YiZAsrk9xJMzMg9Q7ap0Ci_tzQ6odMyK46ZzQk/edit#gid=0
// copying the event names and running the code below.
// s = """all the lines""".split('\n')
// rows = [(x.upper().replace(" ", "_").replace(":",''), x) for x in s.split("\n")]
// for row in rows:
//     print(row[0], ':', f'"{row[1]}",')

export default {
    // Facebook sign in
    SIGN_IN_WITH_FB_BUTTON_CLICK: "Sign in with Fb button click",
    FB_SIGN_IN_SUCCESS: "Fb sign in success",
    FB_SIGN_IN_FAILURE: "Fb sign in failure",

    SYSTEM_ALL_BUSINESS_USERS_RETRIEVED: "Business users retrieved",

    ENABLE_TWO_FACTOR_AUTH_SHOWN: "Enable two factor auth shown",
    ENABLE_TWO_FACTOR_AUTH_CLICK: "Enable two factor auth button click",
    ENABLE_TWO_FACTOR_AUTH_REFRESH_LINK_CLICK: "Enable two factor auth refresh link click",

    // Business Manager: Select
    SYSTEM_ALL_FB_BMS_RETRIEVED: "System: all Fb BM accounts retrieved",
    SELECT_FB_BM: "Select Fb BM",
    CREATE_FB_BM_BUTTON_CLICK: "Create Fb BM button click",
    SUBMIT_FB_BM_BUTTON_CLICK_MODAL: "Submit Fb BM button click modal",
    SUBMIT_FB_BM_SUCCESS: "Submit Fb BM success",
    SUBMIT_FB_BM_FAILURE: "Submit Fb BM failure",

    // Ad accounts
    SYSTEM_ALL_FB_AD_ACCOUNTS_RETRIEVED: "System: all Fb ad accounts retrieved",
    SYSTEM_AD_ACCOUNT_DETAILS_RETRIEVED: "System: get ad account details",
    SELECT_FB_AD_ACCOUNT: "Select Fb ad account",
    CREATE_FB_AD_ACCOUNT_BUTTON_CLICK: "Create Fb ad account button click",
    SUBMIT_FB_AD_ACCOUNT_BUTTON_CLICK_MODAL: "Submit Fb ad account button click (modal)",
    SUBMIT_FB_AD_ACCOUNT_SUCCESS: "Submit Fb ad account success",
    SUBMIT_FB_AD_ACCOUNT_FAILURE: "Submit  Fb ad account failure",

    // Payment method
    ADD_PAYMENT_METHOD_WARNING_SHOWN: "Add payment method warning shown",
    ADD_PAYMENT_METHOD_BUTTON_CLICK: "Add payment method button click",

    // Tos
    ACCEPT_TOS_WARNING_SHOWN: "Accept TOS warning shown",
    ACCEPT_TOS_BUTTON_CLICK: "Accept TOS button click",

    ADD_PAYMENT_OR_TOS_METHOD_REFRESH_LINK_CLICK: "Add payment method or Accept TOS refresh link click",

    SELECT_FB_ADS_PIXEL: "Select Fb ads pixel",
    SELECT_FB_PAGE: "Select Fb page",

    CREATE_FB_PAGE_BUTTON_CLICK: "Create Fb page button click",

    // Actions that take places on Approve click
    APPROVE_ALL_FB_SETTINGS_BUTTON_CLICK: "Approve all Fb settings button click",

    SYSTEM_CONNECT_FB_BMS: "System: Connect Fb BMs",

    SYSTEM_CREATE_FB_SYSTEM_USER: "System: Create Fb system user",
    SYSTEM_CREATE_FB_SYSTEM_USER_FAILURE: "System: Create Fb system user failure",
    SYSTEM_ASSIGN_FB_AD_ACCOUNT_TO_SYSTEM_USER: "System: Assign Fb ad account to system user",
    SYSTEM_ASSIGN_FB_AD_ACCOUNT_TO_SYSTEM_USER_FAILURE: "System: Assign Fb ad account to system user failure",
    SYSTEM_ASSIGN_FB_PAGE_TO_SYSTEM_USER: "System: Assign Fb page to system user",
    SYSTEM_ASSIGN_FB_PAGE_TO_SYSTEM_USER_FAILURE: "System: Assign Fb page to system user failure",

    FB_ACCOUNT_CONNECTION_FLOW_EXITED: "Fb account connection flow exited",
    APPROVE_ALL_FB_SETTINGS_SUCCESS: "Approve all Fb settings success",
    APPROVE_ALL_FB_SETTINGS_FAILURE: "Approve all Fb settings failure",

    EXISTING_CATALOG_CHECK_INITIATED: "FB: EXISTING_CATALOG_CHECK_INITIATED",
    EXISTING_CATALOG_FOUND: "FB: EXISTING_CATALOG_FOUND",
    EXISTING_CATALOG_NOT_FOUND: "FB: EXISTING_CATALOG_NOT_FOUND",
    EXISTING_CATALOG_CHECK_FAILED: "FB: EXISTING_CATALOG_CHECK_FAILED",

    CATALOG_CREATION_INITIATED: "FB: CATALOG_CREATION_INITIATED",
    CATALOG_CREATION_SUCCEED: "FB: CATALOG_CREATION_SUCCEED",
    CATALOG_CREATION_FAILED: "FB: CATALOG_CREATION_FAILED",

    SYSTEM_USER_CATALOG_ASSIGNMENT_INITIATED: "FB: SYSTEM_USER_CATALOG_ASSIGNMENT_INITIATED",
    SYSTEM_USER_CATALOG_ASSIGNMENT_SUCCEED: "FB: SYSTEM_USER_CATALOG_ASSIGNMENT_SUCCEED",
    SYSTEM_USER_CATALOG_ASSIGNMENT_FAILED: "FB: SYSTEM_USER_CATALOG_ASSIGNMENT_FAILED",
};
