/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { api } from "../../utils";
import { logAnalytics, navigateVideoAds, toggleToastPopup } from "../../actions";
import VideoEditor from "../../components/video-ads/VideoEditor";

const VideoEditorPage = ({ selectedTemplate, selectedProduct, history }) => {
    const dispatch = useDispatch();

    if (!selectedProduct || !selectedTemplate) {
        window.location.href = "/video-ads-v2/select-template/";
    }

    return <VideoEditor selectedProduct={selectedProduct} selectedTemplate={selectedTemplate} videoAdsVersion={"v2"} />;
};

const mapStateToProps = (state) => {
    const {
        videoAds: {
            pages: {
                product: {
                    values: { selectedProducts },
                },
                template: {
                    values: { selectedTemplate, selectedDefaultProduct },
                },
            },
        },
    } = state;

    let selectedProduct;

    if (selectedDefaultProduct) {
        selectedProduct = selectedDefaultProduct;
    } else {
        selectedProduct = selectedProducts && selectedProducts.length ? selectedProducts[0] : null;
    }
    return {
        selectedProduct: selectedProduct,
        selectedTemplate: selectedTemplate,
    };
};

export default withRouter(connect(mapStateToProps)(VideoEditorPage));
