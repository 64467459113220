const concatenateString = (str) => (str.length < 44 ? str : `${str.substring(0, 44)}...`);

export const mergeWithSelectedItems = (items, selectedItems) => {
    const current = items.map((p) => ({
        ...p,
        selected: !!selectedItems.find((item) => item.api_id === p.api_id),
    }));

    const selectedHidden = selectedItems
        .filter((item) => {
            return (
                current.filter((product) => {
                    return product.api_id === item.api_id;
                }).length === 0
            );
        })
        .map((item) => ({
            ...item,
            hidden: true,
        }));

    return current.concat(selectedHidden);
};

export const parseShopifyItems = (shopifyItems) => {
    return shopifyItems
        .filter((p) => p.images.length > 0)
        .map((p) => {
            const res = {
                api_id: p.api_id,
                title: concatenateString(p.title),
                name: concatenateString(p.title),
                thumbnail_link: p.images[0].src,
                image: p.images[0].src,
                url: p.url,
                currency: p.currency,
                price: p.price,
                description: p.description,
                create_product_ad: true,
                delete_old: true,
            };

            if (p.id) {
                res.id = p.id;
            }

            return res;
        });
};
