/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, TextField } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import LinkButton from "../../../common/components/LinkButton";
import GoogleSettingPanelButton from "../../../common/components/GoogleSettingPanelButton";
import {
    GoogleMerchantAccountCreateRequest,
    GoogleMerchantAccountConnectRequest,
} from "../../../api/api-google-shopping";
import { googleMerchantAccountConnected, loadMerchantAccountList } from "../../../actions";

const ConnectAccountModule = ({ accounts, onGoogleMerchantAccountChange, onGoogleMerchantAccountConnectClick }) => (
    <React.Fragment>
        <div className="flex w-4/6">
            <img
                className="p-1 m-2"
                src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/1db70595ae0b827f62829419d3604e03.svg"
                height={40}
                width={40}
                alt="Google Account"
            />
            <TextField
                fullWidth={true}
                select={true}
                variant="outlined"
                SelectProps={{ native: true }}
                onChange={onGoogleMerchantAccountChange}
            >
                {accounts.map(({ merchant_id, name }) => (
                    <option className="text-2xl" value={merchant_id} key={merchant_id}>
                        {name}
                    </option>
                ))}
            </TextField>
        </div>
        <GoogleSettingPanelButton buttonType="CONNECT" onClick={onGoogleMerchantAccountConnectClick}>
            Connect
        </GoogleSettingPanelButton>
    </React.Fragment>
);

const CreateAccountModule = ({ onCreateGoogleMerchantAccountConnectClick, disabled }) => (
    <React.Fragment>
        <span className="mt-2 text-2xl">
            We did not find find any merchant account of yours. Let us create one for you
        </span>
        <LinkButton disabled={disabled} onClick={onCreateGoogleMerchantAccountConnectClick}>
            Create new
        </LinkButton>
    </React.Fragment>
);

export default function GoogleMerchantList() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(null);

    const googleCredentialsId = useSelector((state) => state.googleShopping.coreAccount.google_credentials_id);
    const googleMerchantAccounts = useSelector((state) => state.googleShopping.merchantAccounts);
    const accountLoading = useSelector((state) => state.googleShopping.isMerchantAccountLoading);

    const onGoogleMerchantAccountChange = (ev) => {
        setSelectedAccountId(ev.target.value);
    };

    const onCreateGoogleMerchantAccountConnectClick = async () => {
        setLoading(true);
        const response = await GoogleMerchantAccountCreateRequest();
        dispatch(googleMerchantAccountConnected(response));
        setLoading(false);
    };

    const onGoogleMerchantAccountConnectClick = async () => {
        setLoading(true);
        const response = await GoogleMerchantAccountConnectRequest(selectedAccountId);
        dispatch(googleMerchantAccountConnected(response));
        setLoading(false);
    };

    useEffect(() => {
        dispatch(loadMerchantAccountList());
    }, []);

    useEffect(() => {
        if (googleMerchantAccounts.length > 0) {
            setSelectedAccountId(googleMerchantAccounts[0].merchant_id);
        }
    }, [googleMerchantAccounts]);

    return (
        <SettingsLayout
            header="Google Merchant Account"
            subtitle="Shopify products that are synced to your Google Merchant Center product feed will be used by Google to create ads"
            disabled={loading || accountLoading || !googleCredentialsId}
            // active={!loading}
            loading={loading || accountLoading}
        >
            <FormControl fullWidth={true}>
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="text-2xl">Connect a Google Merchant account</div>{" "}
                    {googleMerchantAccounts.length > 0 && (
                        <LinkButton onClick={onCreateGoogleMerchantAccountConnectClick}>Create new</LinkButton>
                    )}
                </div>
                <br />
                <div className="flex flex-row justify-between items-center">
                    {googleMerchantAccounts.length > 0 && (
                        <ConnectAccountModule
                            onGoogleMerchantAccountChange={onGoogleMerchantAccountChange}
                            onGoogleMerchantAccountConnectClick={onGoogleMerchantAccountConnectClick}
                            accounts={googleMerchantAccounts}
                        />
                    )}
                    {googleMerchantAccounts.length === 0 && (
                        <CreateAccountModule
                            disabled={loading}
                            onCreateGoogleMerchantAccountConnectClick={onCreateGoogleMerchantAccountConnectClick}
                        />
                    )}
                </div>
            </FormControl>
        </SettingsLayout>
    );
}
