import { api } from "../../utils";

export const getShopifyProducts = ({
    searchQuery = "",
    page = 1,
    limit = 36,
    signal = null,
    onLoadClearProducts = false,
    actionGetProductsRequested,
    actionGetProductsSucceed,
    actionGetProductsFailed,
    actionClearProducts,
}) => (dispatch) => {
    if (onLoadClearProducts) {
        dispatch({ type: actionClearProducts, payload: {} });
    }

    dispatch({ type: actionGetProductsRequested, payload: {} });

    const url = `/api/v1/shopify-products/?limit=${limit}&page=${page}&title=${searchQuery}`;

    api(url, {
        signal: signal,
    })
        .then((response) => {
            dispatch({
                type: actionGetProductsSucceed,
                payload: {
                    totalNumberOfaProducts: response.count,
                    products: response.results,
                    nextPageUrl: response.next,
                },
            });
        })
        .catch(() => {
            dispatch({ type: actionGetProductsFailed, payload: {} });
        });
};

export const filterProductSelection = ({ option, actionFilterProductsSelection }) => (dispatch) => {
    dispatch({
        type: actionFilterProductsSelection,
        payload: {
            option: option,
        },
    });
};

export const searchShopifyProducts = ({
    searchQuery,
    signal,
    actionSearchShopifyProducts,
    actionGetProductsRequested,
    actionGetProductsSucceed,
    actionGetProductsFailed,
    actionClearProducts,
}) => (dispatch) => {
    dispatch({ type: actionSearchShopifyProducts, payload: { searchQuery: searchQuery } });
    dispatch({ type: actionClearProducts, payload: {} });

    dispatch(
        getShopifyProducts({
            searchQuery: searchQuery,
            signal: signal,
            actionGetProductsRequested: actionGetProductsRequested,
            actionGetProductsSucceed: actionGetProductsSucceed,
            actionGetProductsFailed: actionGetProductsFailed,
        })
    );
};

export const toggleProductSelection = ({ apiId, actionToggleProductSelection, maxSelectedProduct = null }) => (
    dispatch
) => {
    dispatch({
        type: actionToggleProductSelection,
        payload: {
            apiId: apiId,
            maxSelectedProduct: maxSelectedProduct,
        },
    });
};
