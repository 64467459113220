import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

export default function Row({ children, columns = 5, otherClasses = "", style }) {
    const classes = classnames("grid__row", {
        [`grid__row--columns-${columns}`]: columns,
    });

    return (
        <div className={`${classes} ${otherClasses}`} style={style}>
            {children}
        </div>
    );
}

Row.propTypes = {
    children: PropTypes.node.isRequired,
    columns: PropTypes.number,
    otherClasses: PropTypes.string,
    style: PropTypes.object,
};

Row.defaultProps = {
    columns: 5,
    otherClasses: "",
    style: {},
};
