export const TOGGLE_SECTION = "TOGGLE_SECTION";
export const NAVIGATION_INITIATED = "NAVIGATION_INITIATED";

export const toggleSection = (index) => ({
    type: TOGGLE_SECTION,
    index: index,
});

export const initNavigation = (path) => ({
    type: NAVIGATION_INITIATED,
    path: path,
});
