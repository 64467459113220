import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import { ConfirmWebsiteClaimedByUser } from "../../../api/api-google-shopping";
import { confirmClaimWebsiteByUserSuccess } from "../../../actions";

const ClaimWebsiteSettings = () => {
    const dispatch = useDispatch();
    const coreAccount = useSelector((state) => state.googleShopping.coreAccount);
    const accountLoading = useSelector((state) => state.googleShopping.isCoreAccountLoading);

    const claimWebsiteUrl = `https://merchants.google.com/mc/settings/website?a=${coreAccount.google_merchant_account_id}`;

    const onWebsiteIsClaimedClick = async () => {
        await ConfirmWebsiteClaimedByUser();
        dispatch(confirmClaimWebsiteByUserSuccess());
    };

    const ClaimingWebsiteWarning = () => (
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
                <img
                    className="p-1 m-2"
                    src="https://cdn.shopify.com/shopifycloud/google_shopping/bundles/1db70595ae0b827f62829419d3604e03.svg"
                    height={40}
                    width={40}
                    alt="Google Account"
                />
                <div className="flex flex-col">
                    <p className="font-bold">ERROR IN CLAIMING WEBSITE</p>
                </div>
            </div>
        </div>
    );

    const ClaimingWarningAlert = () => (
        <Alert severity="warning" className="p-1">
            <a className="inline text-blue-700" href={claimWebsiteUrl} target="_blank" rel="noreferrer">
                Please go to your merchant center
            </a>{" "}
            and make sure that your shopify shop store url is set, verified and claimed.{" "}
            <a className="inline text-blue-700" href="#" onClick={onWebsiteIsClaimedClick}>
                My website is claimed
            </a>
            <br />
            Error message: <strong>{coreAccount.claim_website_warning}</strong>
        </Alert>
    );

    return (
        <SettingsLayout
            header="Google Merchant Website Settings"
            subtitle="Make sure your shopify website url is verified and claimed in Google merchant center."
            loading={accountLoading}
        >
            <ClaimingWebsiteWarning />
            <ClaimingWarningAlert />
        </SettingsLayout>
    );
};

export default ClaimWebsiteSettings;
