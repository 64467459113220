/**
 * Validation logics goes here. The param `data` is specific store data
 * (in this specific example, aboutBusiness store from onboarding), which should be validated
 * before storing in reducer.
 *
 * You mast return a array of object for fields which failed validation with
 * `message` and `requiredFor` key. The `requiredFor` key is the id of the field and should match.
 *
 * @param data
 * @returns array
 */
const productValidation = (data) => {
    const { selectedProducts = [] } = data;

    const validations = [];
    if (!selectedProducts.find((p) => p.selected)) {
        validations.push({
            message: "Please choose one product to proceed.",
            requiredFor: "selectedProducts",
        });
    }
    return validations;
};

export default productValidation;
