import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "../routes";
import { getNodeEnv } from "../utils";
import MENU from "../common/components/navigation/menu";
import Navigation from "../common/components/navigation/navigation";

class App extends Component {
    componentDidMount() {
        this.handleIntercomIconPosition();
    }

    handleIntercomIconPosition = () => {
        const environment = getNodeEnv();

        if (environment === "production") {
            const intercomIconChecker = setInterval(() => {
                let icon = window.document.getElementsByClassName("intercom-launcher");
                if (icon.length) {
                    // eslint-disable-next-line prefer-destructuring
                    icon = icon[0];
                    if (window.innerWidth < 768) {
                        icon.style.bottom = "60px";
                    }
                    clearInterval(intercomIconChecker);
                }
            }, 500);
        }
    };

    render() {
        return (
            <Router>
                <Fragment>
                    <Navigation menus={MENU} />
                    <Routes />
                </Fragment>
            </Router>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(App);
