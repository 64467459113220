import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { TableHead, TableRow, TableHeader } from "../../../common/components/table";
import TooltipWithIcon from "../../../common/components/TooltipWithIcon";

const DynamicAdCampaignHeader = (props) => {
    const { campaign_type } = props;

    const list = useSelector((state) =>
        campaign_type === "reach"
            ? state.reachList.results
            : campaign_type === "remarketing"
            ? state.remarketingList.results
            : campaign_type === "upsell"
            ? state.upsellList.results
            : ""
    );

    const backgroundColor = !list.length
        ? "#EDEDED"
        : campaign_type === "reach"
        ? "#DCEFFF"
        : campaign_type === "remarketing"
        ? "#FFFAEB"
        : campaign_type === "upsell"
        ? "#ffedeb"
        : "";

    const style = {
        backgroundColor,
    };

    return (
        <TableHead>
            <TableRow>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    ON/OFF
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon label="Delivery" text="Current status of your campaigns." icon="info" />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    Campaign name
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Duration"
                        text="Current duration of your campaigns, ad sets or ads."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Budget"
                        text="The max. amount of money you're willing to spend on your ads."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Impressions"
                        text="The number of times your ads were shown on screen."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Clicks"
                        text="The number of clicks on an ad, based on campaign performance & daily reach. The actual number may differ from estimated."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="CTR"
                        text="Click-through-rate. The percentage of people who clicked on your ad."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="CPC"
                        text="Cost-per-click. An average amount of money you pay for one ad click."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon label="Cost" text="The total amount of money spent on your ads." icon="info" />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Add to carts"
                        text="The number of times buyers added items to the cart."
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Sales"
                        text='The number of purchases made on your website from your ads. For Google Ads only - these can also include clicks, sign-ups or any other actions set as "conversion events" in your Google Ads account.'
                        icon="info"
                    />
                </TableHeader>
                <TableHeader rootElementClasses="sticky top-0 z-10" classes="text-12px" style={style}>
                    <TooltipWithIcon
                        label="Edit"
                        text="Edit Primary text, End date and Budget of your Campaign."
                        icon="info"
                    />
                </TableHeader>
            </TableRow>
        </TableHead>
    );
};

DynamicAdCampaignHeader.propTypes = {
    campaign_type: PropTypes.string.isRequired,
};

export default DynamicAdCampaignHeader;
