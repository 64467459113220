import fetch from "cross-fetch";
import { api } from "../utils"

const listURL = "/api/v1/shops/";
const updateURL = "/api/v1/shops/settings/";
const shopifyStoreURL = "/api/v1/products/list/shopify/";

const unsubscribeURL = `${listURL}subscriptions/cancel/`;

export const get = async (URL = listURL) => {
    try {
        const res = await fetch(URL);
        if (res.status === 200) {
            return res.json();
        }
        return {
            status: "error",
            response: res,
        };
    } catch (error) {
        return error;
    }
};

export const update = async (data) => {
    try {
        const res = await fetch(updateURL, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (res.status === 200) {
            return res.json();
        }
        return {
            status: "error",
            response: res,
        };
    } catch (error) {
        return error;
    }
};

export const exclude = async (data, action) => {
    const excludeURL =
        action === "exclude"
            ? "/api/v1/shops/excluded-categories/list/create/"
            : "/api/v1/shops/excluded-categories/list/delete/";

    try {
        const r = await fetch(excludeURL, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return await r.json();
    } catch (error) {
        return error;
    }
};

export const unsubscribe = async (reason) => {
    const response = await fetch(unsubscribeURL, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ reason: reason }),
    });
    if (response.status === 200) {
        return response.json();
    }
    return {
        status: "error",
        response: response,
    };
};

export const subscribe = async (subscriptionId, returnUrl, discountCode) => {
    const subscribeURL = `${listURL}subscriptions/`;
    const data = { plan_id: subscriptionId, return_url: returnUrl || "", discount_code:discountCode}

    try {
        const response = await api(subscribeURL, { data: data });
        return response;
    } catch (error) {
        return error;
    }
};

export const countries = async (data, action) => {
    const countriesURL =
        action === "exclude" ? "/api/v1/shops/countries/list/delete/" : "/api/v1/shops/countries/list/create/";

    try {
        const res = await fetch(countriesURL, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (res.status === 200) {
            return res.json();
        }
        return {
            status: "error",
            response: res,
        };
    } catch (error) {
        return error;
    }
};

export const updateSubscription = async (data) => {
    const updateSubscriptionURL = `${listURL}subscriptions/update/`;

    try {
        const response = await api(updateSubscriptionURL, { data: data });
        return response;
    } catch (error) {
        return error;
    }
};

export const getStoreInformation = async () => {
    try {
        const response = await api(shopifyStoreURL, { method: "GET" });
        return response;
    } catch (error) {
        return error;
    }
};
