import React, { Fragment } from "react";
import { withRouter, useHistory } from "react-router-dom";

import Button from "../../common/components/button";

const Thanks = () => {
    const history = useHistory();

    const handleCloseButton = () => {
        history.push("/manage-campaigns/");
    };

    return (
        <Fragment>
            <div className="pt-24 bg-white h-screen">
                <div className="w-4/5 md:w-3/5 mx-auto my-0 mt-32">
                    <h1 className="welcome-title">
                        Thanks! We have received your ideas.{" "}
                        <span role="img" aria-label="nerd">
                            🤓
                        </span>
                    </h1>
                    <p className="welcome-description 0 mt-12">You’re on your way to running a successful business!</p>
                    <div className="pb-6 mt-4">
                        <Button primary={true} onClick={handleCloseButton}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(Thanks);
