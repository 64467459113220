/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate  */
// React
import React, { Component } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";

// Libs
import moment from "moment";

// Polaris
import en from "@shopify/polaris/locales/en.json";
import { Page } from "@shopify/polaris";

// Sixads components
import { logAnalytics, updateStore } from "../../../actions";
import Slider from "../../../common/components/sixads/slider";

// Budget and Schedule
import styles from "./budget/styles";

// Budget components
import Dates from "./budget/dates/Dates";
import Estimations from "./estimations/Estimations";
import TotalBudget from "../../../common/components/total-budget/TotalBudget";

// Utils
import { renderError } from "../../../pages/onboarding-v2/utils/utils";
import { createDynamicAd } from "../../../api/api-facebook-ads";

import Button from "../../../common/components/button";
import Banner from "../../../common/components/sixads/banner";

class BudgetAndSchedule extends Component {
    static propTypes = {
        campaign: Proptypes.shape({ id: Proptypes.number }),
        history: Proptypes.shape({
            push: Proptypes.func,
        }),
        dispatch: Proptypes.func,
        onChange: Proptypes.func,
        values: Proptypes.object,
        errors: Proptypes.array,
        dynamicAds: Proptypes.object.isRequired,
        currency: Proptypes.string.isRequired,
        usd_equivalent: Proptypes.number.isRequired,
    };

    static defaultProps = {
        campaign: {},
        history: {},
        dispatch: () => {},
        onChange: () => {},
        values: {},
        errors: [],
    };

    state = {
        dates: {
            start: new Date(),
            end: new Date(new Date().setDate(new Date().getDate() + 90)),
        },
        dailyBudget: 0,
        dynamicAds: {},
        approvalActive: false,
        approvalLoading: false,
        creationSuccess: false,
        creationError: false,
        errorMessage: "",
        ad_account_currency: "USD",
        usd_equivalent_of_currency: 1,
        outOfRangeError: false,
        validationErrorMessage: "",
    };

    minimumBudget = 2;

    maximumBudget = 100;

    impressionsPerUsd = {
        min: 110,
        max: 530,
    };

    clicksPerUsd = {
        min: 6,
        max: 11,
    };

    componentDidMount() {
        window.document.title = "Budget | SIXADS";

        const { values, dynamicAds, subscription, currency, usd_equivalent } = this.props;

        this.setState({
            dynamicAds: dynamicAds,
        });
        if (currency !== undefined && usd_equivalent !== undefined) {
            this.setState({
                ad_account_currency: currency,
                usd_equivalent_of_currency: usd_equivalent,
                dailyBudget: Math.ceil(values.dailyBudget * usd_equivalent),
            });
        }
        if (subscription.id) {
            this.setState({
                approvalActive: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { values, subscription, isSubscriptionLoading, history, currency, usd_equivalent } = this.props;

        if (
            prevProps.currency !== currency ||
            prevProps.usd_equivalent !== usd_equivalent ||
            prevProps.values !== values
        ) {
            this.setState({
                ad_account_currency: currency,
                usd_equivalent_of_currency: usd_equivalent,
                dailyBudget: Math.ceil(values.dailyBudget * usd_equivalent),
            });
        }

        // If user do not have subscription
        // Do not let approve the campaign and redirect to Plans page
        if (prevProps.isSubscriptionLoading !== isSubscriptionLoading) {
            if (!subscription.id && !isSubscriptionLoading) {
                history.push("/dynamic-ads/create-campaign/plans/");
            } else if (subscription.id && !isSubscriptionLoading) {
                // eslint-disable-next-line
                this.setState({
                    approvalActive: true,
                });
            }
        }
    }

    getEstimations = () => {
        const result = {
            reach: { min: 0, max: 0 },
            click: { min: 0, max: 0 },
        };

        const { dailyBudget, usd_equivalent_of_currency } = this.state;
        const campaignDurationInDays = this.getCampaignDurationInDays();
        const campaign_budget = dailyBudget * campaignDurationInDays;
        const campaign_budget_usd = campaign_budget / usd_equivalent_of_currency;

        result.reach.min += Math.round(this.impressionsPerUsd.min * campaign_budget_usd);
        result.reach.max += Math.round(this.impressionsPerUsd.max * campaign_budget_usd);

        result.click.min += Math.round(this.clicksPerUsd.min * campaign_budget_usd);
        result.click.max += Math.round(this.clicksPerUsd.max * campaign_budget_usd);

        return result;
    };

    handleDatesUpdate = (dateRangeObject) => {
        this.setState({
            dates: dateRangeObject,
        });
    };

    getCampaignDurationInDays = () => {
        const {
            dates: { start: startDate, end: endDate },
        } = this.state;

        return moment(endDate).diff(moment(startDate), "days") + 1;
    };

    handleSliderChange = (value) => {
        this.setState({ dailyBudget: value, outOfRangeError: false });
    };

    /**
     * Handle the "Total daily budget" filed change. The value will be evenly distribute among all channels.
     * @param ev (native onchange event)
     */
    handleTotalBudgetChange = (ev) => {
        let value = parseFloat(ev.target.value);
        if (Number.isNaN(value)) {
            value = 0;
        }

        this.setState({ dailyBudget: value, outOfRangeError: false });
    };

    generatePayload = () => {
        const { dynamicAds } = this.props;
        const campaign = {
            daily_budget: this.state.dailyBudget,
            start_time: this.state.dates.start.toISOString().split("T")[0],
            stop_time: this.state.dates.end.toISOString().split("T")[0],
        };
        const targeting = {
            device_platforms: "all",
            genders: dynamicAds.create.creative.gender,
            age_min: dynamicAds.create.creative.age.min,
            age_max: dynamicAds.create.creative.age.max,
            countries: dynamicAds.create.creative.countries.map((country) => country.value),
            interests: dynamicAds.create.creative.audiences.map((item) => ({ id: item.value, name: item.label })),
        };
        if (dynamicAds.type === "REACH") {
            targeting["lookalike_audiences"] = dynamicAds.create.creative.lookalikeAudiences.map(
                (audience) => audience.value
            );
        }

        const adcreative = {
            primary_text: dynamicAds.create.creative.primaryText,
        };
        const payload = {
            data: {
                campaign,
                targeting,
                adcreative,
                adset: {},
                ad: {},
            },
            campaign_type: dynamicAds.type.toLowerCase(),
        };

        return payload;
    };

    submitDynamicAdsCampaign = async (payload) => {
        const { dispatch } = this.props;
        const apiResponse = await createDynamicAd(payload);
        if ([500, 400].includes(apiResponse.status)) {
            this.setState({
                approvalLoading: false,
                creationSuccess: false,
                creationError: true,
                errorMessage: this.getApiErrorMessage(apiResponse.response),
            });
        } else {
            dispatch(updateStore({ published: true }));
            this.setState({
                approvalActive: false,
                approvalLoading: false,
                creationSuccess: true,
                creationError: false,
            });
            this.props.history.push("/dynamic-ads/create-campaign/thank-you/");
        }

        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Budget settings",
                eventAction: "Button click",
                eventLabel: "Approve",
            })
        );
    };

    getApiErrorMessage(response) {
        if (response.detail) {
            return response.detail;
        }

        if (response.non_field_errors) {
            return response.non_field_errors.join(", ");
        }

        if (Array.isArray(response)) {
            return response.join(", ");
        }

        if (typeof response === "string") {
            return response;
        }

        return "Campaign creation failed with an error.";
    }

    getDaysGap = (day1, day2) => {
        const diffInSecond = Math.floor(day2.getTime() - day1.getTime());
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const daysGap = Math.floor(diffInSecond / millisecondsPerDay);
        return daysGap + 1;
    };

    isStartDateInThePast = (startDate) => {
        const today = new Date();
        if (startDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
            return true;
        }

        return false;
    };

    isStartEndDateSame = (dates) => {
        if (dates.start.toDateString() == dates.end.toDateString()) {
            return true;
        }
        return false;
    };

    isStartEndDateGapLessThanThree = (dates) => {
        if (this.getDaysGap(dates.start, dates.end) < 3) {
            return true;
        }
        return false;
    };

    dateValidation = (dates) => {
        if (this.isStartDateInThePast(dates.start)) {
            this.setState({ validationErrorMessage: "Start date shouldn't be past date" });
            return false;
        }
        if (this.isStartEndDateSame(dates)) {
            this.setState({ validationErrorMessage: "Start date & End date shouldn't be same" });
            return false;
        }

        if (this.isStartEndDateGapLessThanThree(dates)) {
            this.setState({
                validationErrorMessage:
                    "The interval between start date and end date should be more than or equal 3 days",
            });
            return false;
        }

        return true;
    };

    handleApprove = () => {
        const { dailyBudget, usd_equivalent_of_currency } = this.state;
        if (!this.dateValidation(this.state.dates)) {
            return;
        }
        if (
            parseFloat(dailyBudget) >= Math.ceil(this.minimumBudget * usd_equivalent_of_currency) &&
            parseFloat(dailyBudget) <= Math.ceil(this.maximumBudget * usd_equivalent_of_currency)
        ) {
            this.setState({ approvalLoading: true });
            const payload = this.generatePayload();
            this.submitDynamicAdsCampaign(payload);
        } else {
            this.setState({
                outOfRangeError: true,
            });
        }
    };

    OutOfRangeBudgetBanner = () => {
        const { dailyBudget, ad_account_currency, usd_equivalent_of_currency } = this.state;
        return (
            <Banner type="error" onClose={() => this.setState({ outOfRangeError: false })}>
                <div className="flex justify-start">
                    <p>
                        {parseFloat(dailyBudget) < Math.ceil(this.minimumBudget * usd_equivalent_of_currency)
                            ? `Campaign Budget can't be lower than ${Math.ceil(
                                  this.minimumBudget * usd_equivalent_of_currency
                              )} ${ad_account_currency}`
                            : ""}
                    </p>
                    <p>
                        {parseFloat(dailyBudget) > Math.ceil(this.maximumBudget * usd_equivalent_of_currency)
                            ? `Campaign Budget can't be greater than ${Math.ceil(
                                  this.maximumBudget * usd_equivalent_of_currency
                              )} ${ad_account_currency}`
                            : ""}
                    </p>
                </div>
            </Banner>
        );
    };

    ErrorBanner = () => {
        return (
            <Banner type="error" onClose={() => this.setState({ creationError: false })}>
                <div className="flex justify-between">
                    <p>{this.state.errorMessage}</p>
                    <a
                        className="px-20 py-4 rounded border border-gray-400 no-underline"
                        onClick={() => this.props.history.push("/dynamic-ads/campaigns/")}
                    >
                        Campaign Dashboard
                    </a>
                </div>
            </Banner>
        );
    };

    ValidationErrorBanner = () => {
        return (
            <Banner type="error" onClose={() => this.setState({ validationErrorMessage: "" })}>
                <div className="flex justify-between">
                    <p>{this.state.validationErrorMessage}</p>
                </div>
            </Banner>
        );
    };

    // SuccessBanner = () => {
    //     return (
    //         <Banner type="success" onClose={() => this.setState({ creationSuccess: false })}>
    //             <div className="flex justify-between">
    //                 <p>Successfully created dynamic ads campaign</p>
    //                 <a
    //                     className="px-20 py-4 rounded border border-gray-400 no-underline"
    //                     onClick={() => this.props.history.push("/dynamic-ads/campaigns/")}
    //                 >
    //                     Campaign Dashboard
    //                 </a>
    //             </div>
    //         </Banner>
    //     );
    // };

    render() {
        const {
            dates,
            dailyBudget,
            approvalActive,
            approvalLoading,
            creationSuccess,
            creationError,
            ad_account_currency,
            usd_equivalent_of_currency,
            outOfRangeError,
        } = this.state;
        const { errors } = this.props;
        // const { channels } = dailyBudget;
        const totalError = renderError(errors, "total");

        const estimations = this.getEstimations();

        return (
            <div className="pt-24">
                <div i18n={en} className="w-11/12 md:w-4/5 lg:w-2/4 mx-auto my-0 mt-12 font-sf-text pb-40">
                    {this.state.validationErrorMessage && <this.ValidationErrorBanner />}
                    {creationError && <this.ErrorBanner />}
                    {/* {creationSuccess && <this.SuccessBanner />} */}
                    {outOfRangeError && <this.OutOfRangeBudgetBanner />}
                    <Page
                        title={
                            <span>
                                Budget and schedule{" "}
                                <span role="img" aria-label="Spiral Calendar ">
                                    &#128467;&#65039;
                                </span>
                            </span>
                        }
                        subtitle="You can edit this later"
                    >
                        <div className="budget">
                            <div className="dates-column">
                                <div className="channels-and-dates">
                                    <div className="dates-column--inputs">
                                        <Dates dates={dates} onUpdateDates={this.handleDatesUpdate} />
                                    </div>
                                </div>
                            </div>

                            <div className="budget-column">
                                <div className="budget-column--total-budget">
                                    <TotalBudget
                                        min={Math.ceil(this.minimumBudget * usd_equivalent_of_currency)}
                                        max={Math.ceil(this.maximumBudget * usd_equivalent_of_currency)}
                                        dailyBudget={parseFloat(dailyBudget)}
                                        onChange={this.handleTotalBudgetChange}
                                        errorMessage={totalError}
                                        currency={ad_account_currency}
                                    />
                                </div>

                                <div className="budget-column--slider">
                                    <Slider
                                        min={Math.ceil(this.minimumBudget * usd_equivalent_of_currency)}
                                        max={Math.ceil(this.maximumBudget * usd_equivalent_of_currency)}
                                        value={parseFloat(dailyBudget)}
                                        onChange={this.handleSliderChange}
                                        progressBarColor={totalError ? "#DE3618" : "#499995"}
                                    />
                                    <span className="budget-column--slider--range-mark">
                                        <span />
                                        <p>Typical competitor budget</p>
                                    </span>
                                </div>

                                <div className="budget-column--estimations mt-2">
                                    <Estimations estimations={estimations} />
                                </div>
                            </div>
                        </div>
                    </Page>
                    <style jsx={true}>{styles}</style>
                </div>
                <div className="bg-white fixed bottom-0 w-full py-6">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 flex justify-end">
                        <Button
                            primary={true}
                            onClick={() => this.handleApprove()}
                            loading={approvalLoading}
                            disabled={!approvalActive}
                        >
                            Approve
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        dynamicAds,
        campaign,
        store: { subscription, isLoading },
        settings: { facebook },
    } = state;
    const {
        create: { budgetAndSchedule = {} },
    } = dynamicAds;

    return {
        values: budgetAndSchedule,
        campaign: campaign,
        subscription: subscription,
        isSubscriptionLoading: isLoading,
        dynamicAds,
        currency: facebook.ad_account_currency,
        usd_equivalent: facebook.usd_equivalent_of_currency,
    };
};

export default connect(mapStateToProps)(BudgetAndSchedule);
