/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template2 = ({
    name,
    image = `${window.location.origin}/static/img/video_promotions/template_2/HeroImg.jpg?1624295259971`,
    price,
    adMessage,
    paused = false,
}) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"] = adMessage || "Special Discount";

        animationObject["ProductNameObj"]["ProductName"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"] = price;

        const images = AdobeAn.getComposition("81126ADAB42E784DABAE30208C4E25B7").getImages();
        images["HeroImg"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_2"
                composition="81126ADAB42E784DABAE30208C4E25B7"
                getAnimationObject={getAnimationObject}
            />
        </div>
    );
};

Template2.design_name = "3cut_blue";

/* Get the width and height from template file
(lib.HeroImg = function () {
    this.initialize(img.HeroImg);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0, 0, 1080, 1083);
*/
Template2.image_width = 1080;
Template2.image_height = 1083;

export default Template2;
