/* eslint-disable */
import React, { useState } from "react";

import AnimateCC from "react-adobe-animate";

const Template3 = ({
    name,
    image = `${window.location.origin}/static/img/video_promotions/template_3/HeroImg.jpg?1624295189769`,
    price,
    adMessage,
    paused = false,
}) => {
    const [animationObject, setAnimationObject] = useState(null);
    const getAnimationObject = (obj) => setAnimationObject(obj);

    if (animationObject) {
        animationObject["HeaderTextObj"]["HeaderText"]["text"] = adMessage || "Special Discount";
        animationObject["HeaderTextObj2"]["HeaderText"]["text"] = adMessage || "Special Discount";

        animationObject["ProductNameObj"]["ProductName"]["text"] = name;
        animationObject["ProductNameObj2"]["ProductName"]["text"] = name;

        animationObject["PriceTagObj"]["PriceNow"]["text"] = price;
        animationObject["PriceTagObj2"]["PriceNow"]["text"] = price;

        const images = AdobeAn.getComposition("803F77C0D948D94FB75E1A5C3896175F").getImages();
        images["HeroImg"]["src"] = image;
    }

    return (
        <div>
            <AnimateCC
                animationName="template_3"
                composition="803F77C0D948D94FB75E1A5C3896175F"
                getAnimationObject={getAnimationObject}
                paused={paused}
            />
        </div>
    );
};

Template3.design_name = "Mens_Fashion";

/* Get the width and height from template file
(lib.HeroImg = function () {
    this.initialize(img.HeroImg);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0, 0, 974, 1218);
*/
Template3.image_width = 974;
Template3.image_height = 1218;

export default Template3;
