import React, { useCallback, useState } from "react";
import { Banner, Modal } from "@shopify/polaris";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import createBusinessManager from "./utils";
import { postBusinessEvent } from "../../../api/api-business-event";
import TextField from "../../../common/components/sixads/inputs/TextField";
import FACEBOOK_BUSINESS_EVENTS from "../../../common/business_events_constants";

function FacebookCreateBusinessManagerError(message) {
    this.name = "FacebookCreateBusinessManagerError";
    this.message = message;
    this.stack = new Error().stack;
}

FacebookCreateBusinessManagerError.prototype = new Error();

const YourBusinessAndAccountNameTextField = ({ businessManagerName, setBusinessManagerName, disabled }) => {
    const handleChange = useCallback((newValue) => setBusinessManagerName(newValue), []);
    const helpText = (
        <p>This should match the public name of your business since it will be visible across Facebook.</p>
    );
    return (
        <TextField
            id="new-business-manager-name"
            label="Business manager name"
            value={businessManagerName}
            onChange={handleChange}
            helpText={helpText}
            disabled={disabled}
        />
    );
};

YourBusinessAndAccountNameTextField.propTypes = {
    businessManagerName: PropTypes.string.isRequired,
    setBusinessManagerName: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const CreateBusinessManagerModal = ({
    showCreateBusinessManagerModal,
    hideCreateBusinessManager,
    shopUrl,
    getBusinessManagers,
    getBusinessUsers,
    email,
    onCreationSuccess,
}) => {
    const [businessManagerName, setBusinessManagerName] = useState(shopUrl);
    const [businessManagerEmail, setBusinessManagerEmail] = useState(email);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleError = (errorMessage) => {
        Sentry.captureException(new FacebookCreateBusinessManagerError(errorMessage));
        setError(errorMessage);
        setIsLoading(false);
    };

    const handleSubmit = useCallback(() => {
        postBusinessEvent(FACEBOOK_BUSINESS_EVENTS.SUBMIT_FB_BM_BUTTON_CLICK_MODAL);
        createBusinessManager(
            businessManagerName,
            businessManagerEmail,
            setError,
            handleError,
            setSuccess,
            setIsLoading
        );
    }, [businessManagerName, setError, handleError, setSuccess, setIsLoading]);

    const closeModal = () => {
        onCreationSuccess();
        getBusinessManagers();
        getBusinessUsers();
        hideCreateBusinessManager();
    };

    const errorModalSection = (
        <Modal.Section>
            <Banner status="critical" title={error} />
        </Modal.Section>
    );

    const successMessage = `Success! Your Business Manager is created. Check your email ${email} to confirm your email address.`;
    const successModalSection = (
        <Modal.Section>
            <Banner status="warning" title={successMessage} />
        </Modal.Section>
    );
    const primaryAction = success
        ? { content: "Continue", onAction: closeModal, loading: isLoading }
        : { content: "Submit", onAction: handleSubmit, loading: isLoading };

    return (
        <div>
            <Modal
                open={showCreateBusinessManagerModal}
                onClose={closeModal}
                title="Create a Business Manager"
                primaryAction={primaryAction}
            >
                <Modal.Section>
                    <YourBusinessAndAccountNameTextField
                        shopUrl={shopUrl}
                        businessManagerName={businessManagerName}
                        setBusinessManagerName={setBusinessManagerName}
                        disabled={success}
                    />
                    <TextField
                        label="Email"
                        value={businessManagerEmail}
                        onChange={(v) => setBusinessManagerEmail(v)}
                    />
                </Modal.Section>
                {error && errorModalSection}
                {success && successModalSection}
            </Modal>
        </div>
    );
};

CreateBusinessManagerModal.propTypes = {
    showCreateBusinessManagerModal: PropTypes.bool.isRequired,
    hideCreateBusinessManager: PropTypes.func.isRequired,
    shopUrl: PropTypes.string.isRequired,
    getBusinessManagers: PropTypes.func.isRequired,
    email: PropTypes.string,
    onCreationSuccess: PropTypes.func.isRequired,
};

CreateBusinessManagerModal.defaultProps = {
    email: "",
};

export default CreateBusinessManagerModal;
