import css from "styled-jsx/css";

const styles = css`
    .estimations {
        display: flex;
        flex-direction: row;
        background: white;
        justify-content: space-around;
        height: 10rem;
    }
    .estimations > div {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .estimations--icon,
    .estimations--estimate {
        flex: 1;
    }

    .estimations--icon {
        text-align: right;
        margin-right: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .estimations--icon img {
        height: 45px;
        width: 45px;
    }

    .estimations--estimate :global(div) {
        color: #637381;
        font-weight: bold;
    }

    .estimations > div:first-child {
        border-right: 1px solid #dff3e8;
    }

    .responsive-estimations {
        padding-right: 5px;
    }
`;

export default styles;
