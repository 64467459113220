import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import GoogleMerchantList from "./GoogleMerchantList";
import GoogleMerchantAccountDetails from "./GoogleMerchantAccountDetails";

const GoogleMerchantAccountConnection = () => {
    const googleMerchantAccountId = useSelector((state) => state.googleShopping.coreAccount.google_merchant_account_id);
    const accountLoading = useSelector((state) => state.googleShopping.isMerchantAccountLoading);

    return (
        <Fragment>
            {googleMerchantAccountId && !accountLoading ? <GoogleMerchantAccountDetails /> : <GoogleMerchantList />}
        </Fragment>
    );
};

export default GoogleMerchantAccountConnection;
