/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { Collapsible, Tooltip } from "@shopify/polaris";
import { resetDynamicAdReducer, setType, resetType } from "../../../actions/actions-dynamic-ads";
import { logAnalytics } from "../../../actions";
import CampaignTable from "./CampaignTable";
import ShowIcon from "./ShowIcon";

const CampaignTableContainer = (props) => {
    const { campaign_type, header_text, isTableExpanded, onTableExpansionToggle } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const isFacebookConnected = useSelector((state) => state.settings.facebook.is_connected);

    const nextRoute =
        campaign_type === "reach"
            ? "/dynamic-ads/create-campaign/welcome/"
            : isFacebookConnected
            ? "/dynamic-ads/create-campaign/creative-and-preview/"
            : "/dynamic-ads/create-campaign/account-connection/";

    useEffect(() => {
        dispatch(resetType());
        dispatch(resetDynamicAdReducer());
    }, []);

    const handleReachCreate = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Reach Campaign",
            })
        );

        dispatch(setType("REACH"));
        history.push(nextRoute);
    };

    const handleRemarketingCreate = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Retargeting Campaign",
            })
        );

        dispatch(setType("REMARKETING"));
        history.push(nextRoute);
    };

    const handleUpsellCreate = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Dynamic Campaign Creation Flow",
                eventAction: "Button click",
                eventLabel: "Create Upsell Campaign",
            })
        );

        dispatch(setType("UPSELL"));
        history.push(nextRoute);
    };

    const list = useSelector((state) =>
        campaign_type === "reach"
            ? state.reachList.results
            : campaign_type === "remarketing"
            ? state.remarketingList.results
            : campaign_type === "upsell"
            ? state.upsellList.results
            : ""
    );

    const primaryColorText = !list.length
        ? "#CCCCCC"
        : campaign_type === "reach"
        ? "#006FBB"
        : campaign_type === "remarketing"
        ? "#8A6116"
        : campaign_type === "upsell"
        ? "#ad1a00"
        : "";

    const buttonText =
        campaign_type === "reach"
            ? "Create Reach campaign"
            : campaign_type === "remarketing"
            ? "Create Remarketing campaign"
            : campaign_type === "upsell"
            ? "Create Upsell campaign"
            : "";

    const buttonTextColor =
        campaign_type === "reach"
            ? "#FFFFFF"
            : campaign_type === "remarketing"
            ? "#212B36"
            : campaign_type === "upsell"
            ? "#FFFFFF"
            : "";

    const buttonBackgroundColor =
        campaign_type === "reach"
            ? "create-reach-button"
            : campaign_type === "remarketing"
            ? "create-remarketing-button"
            : campaign_type === "upsell"
            ? "create-upsell-button"
            : "";

    const CampaignCreateButton = () => (
        <div className="w-full flex content-center justify-center m-6 mb-2">
            <style jsx={true}>{`
                .create-reach-button {
                    background-color: #006fbb;
                }

                .create-remarketing-button {
                    background-color: #ffe46d;
                }

                .create-upsell-button {
                    background-color: #de3618;
                }

                .create-reach-button:hover {
                    background-color: #319ee8;
                }

                .create-remarketing-button:hover {
                    background-color: #ffed9c;
                }

                .create-upsell-button:hover {
                    background-color: #f7674d;
                }
            `}</style>
            <button
                className={`border rounded-3xl px-6 py-2 flex justify-center items-center text-xl font-medium ${buttonBackgroundColor}`}
                style={{ color: buttonTextColor }}
                onClick={() =>
                    campaign_type === "reach"
                        ? handleReachCreate()
                        : campaign_type === "remarketing"
                        ? handleRemarketingCreate()
                        : campaign_type === "upsell"
                        ? handleUpsellCreate()
                        : ""
                }
            >
                <div>+</div>
                <div className="px-3">{buttonText}</div>
            </button>
        </div>
    );

    return (
        <div className="border rounded-2xl" style={{ boxShadow: "0px 3px 6px #00000029", borderColor: "#CCCCCC" }}>
            <div className="px-8 pt-10 pb-5">
                <div
                    className="font-sf-display font-semibold text-3xl tracking-wide flex flex-row items-center"
                    style={{ color: primaryColorText }}
                >
                    {isTableExpanded && (
                        <Tooltip active={false} content="Collapse section">
                            <IconButton size="small" onClick={() => onTableExpansionToggle()}>
                                <RemoveCircleIcon style={{ fontSize: 20, color: primaryColorText }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!isTableExpanded && (
                        <Tooltip active={false} content="Expand section">
                            <IconButton size="small" onClick={() => onTableExpansionToggle()}>
                                <AddCircleIcon style={{ fontSize: 20, color: primaryColorText }} />
                            </IconButton>
                        </Tooltip>
                    )}

                    <span>{header_text}</span>
                    <span className="ml-5 flex justify-center items-center">
                        <ShowIcon campaign_type={campaign_type} />
                    </span>
                </div>

                <Collapsible open={isTableExpanded} transition={{ duration: "500ms", timingFunction: "ease-in-out" }}>
                    <CampaignTable campaign_type={campaign_type} />
                </Collapsible>
                <CampaignCreateButton />
            </div>
        </div>
    );
};

CampaignTableContainer.propTypes = {
    campaign_type: PropTypes.string.isRequired,
};

export default CampaignTableContainer;
