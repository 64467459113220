import React, { Fragment } from "react";

const PageHeader = () => {
    return (
        <Fragment>
            <div>
                <h1 className="page-title">
                    Your awe-inspiring Facebook ad images are ready!{" "}
                    <span role="img" aria-label="Money-Mouth">
                        🤑
                    </span>
                </h1>
                <p className="page-subtitle">
                    Pick a design you love the most, edit and download it to start running stunning image ads on
                    Facebook.
                </p>
            </div>
        </Fragment>
    );
};

export default PageHeader;
