import { ACTIONS } from "../actions/actions-ad-text-creator";

const initialState = {
    welcome: {
        //
    },
    aboutBusiness: {
        companyName: "",
        companyKeywords: "",
        companyAudience: "",
        specialOffer: "",
    },
    facebookHeadlines: {
        isLoading: false,
        headlines: [],
    },
};

const adTextCreator = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_ABOUT_BUSINESS_DATA:
            return {
                ...state,
                aboutBusiness: {
                    ...state.aboutBusiness,
                    companyName: action.payload.companyName,
                    companyKeywords: action.payload.companyKeywords,
                    companyAudience: action.payload.companyAudience,
                    specialOffer: action.payload.specialOffer,
                },
            };

        case ACTIONS.GET_FACEBOOK_HEADLINES_REQUESTED:
            return {
                ...state,
                facebookHeadlines: {
                    ...state.facebookHeadlines,
                    isLoading: true,
                    headlines: [],
                },
            };
        case ACTIONS.GET_FACEBOOK_HEADLINES_SUCCEED:
            return {
                ...state,
                facebookHeadlines: {
                    ...state.facebookHeadlines,
                    isLoading: false,
                    headlines: action.payload.headlines,
                },
            };
        case ACTIONS.GET_FACEBOOK_HEADLINES_FAILED:
            return {
                ...state,
                facebookHeadlines: {
                    ...state.facebookHeadlines,
                    isLoading: false,
                },
            };

        case ACTIONS.RESET_STATE:
            return {
                ...state,
                welcome: initialState.welcome,
                aboutBusiness: initialState.aboutBusiness,
                facebookHeadlines: initialState.facebookHeadlines,
            };

        default:
            return state;
    }
};

export default adTextCreator;
