/* eslint-disable */
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ACTIONS } from "../../actions/actions-image-overlay-ads";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";
import Banner from "../../common/components/sixads/banner";
import ProductsSelection from "../../common/components/select-products/SelectProducts";

const SelectProducts = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {selectProducts} = useSelector((state) => state.imageOverlayAds)
    const {selectedProducts} = selectProducts;

    const handleNavigateNext = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Ads exchange flow",
                eventAction: "Button click",
                eventLabel: "Go to selected product list",
            })
        );

        history.push("/ads-exchange/selected-product-list");
    };

    return (
        <Fragment>
            <div className="pt-24">
                <div className="w-11/12 md:w-4/5 lg:w-1/2 mx-auto my-0 mt-12 font-sf-text pb-40">
                    {selectedProducts.length === 0 && (
                        <div className="mb-10">
                            <Banner
                                type="error"
                                icon={
                                    <span className="text-4xl" role="img" aria-label="pensive">
                                        💔
                                    </span>
                                }
                            >
                                Please choose at least one product to proceed.
                            </Banner>
                        </div>
                    )}

                    <div className="">
                        <h1 className="page-title">
                            Choose products to advertise{" "}
                            <span role="img" aria-label="Magnifying Glass">
                                ✅
                            </span>
                        </h1>
                        <p className="page-subtitle">
                            We have pre-selected items that are most likely to sell. You can edit them later.
                        </p>
                    </div>
                    <div className="bg-white mt-5 rounded-md shadow">
                        <ProductsSelection
                            actions={ACTIONS}
                            selectProductsState={selectProducts}
                            // maxSelectedProduct={10}
                        />
                    </div>
                </div>
                <div className="bg-white fixed bottom-0 w-full py-6">
                    <div className="w-11/12 md:w-2/4 mx-auto my-0 flex justify-end">
                        <Button primary={true} disabled={selectedProducts.length === 0} onClick={handleNavigateNext}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SelectProducts;