import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";
import SettingsLayout from "./SettingsLayout";
import TotalBudget from "../../../common/components/total-budget/TotalBudget";
import { setCampaignBudget } from "../../../actions";

const CampaignBudget = () => {
    const dispatch = useDispatch();
    const [alertShown, setAlertShown] = useState(true);
    const googleAdsAccountCurrency = useSelector(
        (state) => state.googleShopping.coreAccount.google_ads_account_currency
    );
    const campaigns = useSelector((state) => state.googleShopping.list.campaigns);

    let usdEquivalent = 0;
    const [budget, setBudget] = useState(2 * usdEquivalent);

    useEffect(() => {
        if(googleAdsAccountCurrency) {
            if (googleAdsAccountCurrency.usd_equivalent) {
                usdEquivalent = googleAdsAccountCurrency.usd_equivalent;
                setBudget(2 * usdEquivalent);
            }
        }
    }, [googleAdsAccountCurrency])

    useEffect(() => {
        if (campaigns.length) {
            setBudget(campaigns[0].daily_budget.value);
        }
    }, [campaigns]);

    useEffect(() => {
        dispatch(setCampaignBudget(budget));
    }, [budget]);

    const handleBudgetChange = (ev) => {
        let value = parseFloat(ev.target.value);
        if (Number.isNaN(value)) {
            value = 0;
        }
        setBudget(value);
    };

    return (
        <SettingsLayout
            header="Google Shopping Campaign Budget"
            subtitle="Set the budget that you want to spend on your Google Shopping Campaign"
        >
            <div className="flex flex-row items-center justify-between py-4">
                <TotalBudget
                    min={Math.ceil(2 * usdEquivalent)}
                    max={Math.ceil(100 * usdEquivalent)}
                    dailyBudget={parseFloat(budget)}
                    onChange={handleBudgetChange}
                    perDay={false}
                    currency={googleAdsAccountCurrency.code}
                    disabled={campaigns.length}
                />
            </div>
            {alertShown && (
                <Alert severity="warning" onClose={() => setAlertShown(false)}>
                    <AlertTitle>Budget Limit</AlertTitle>
                    {`Budget limit is from ${2 * usdEquivalent} ${
                        googleAdsAccountCurrency.code
                    } to ${100 * usdEquivalent} ${googleAdsAccountCurrency.code}`}
                </Alert>
            )}
        </SettingsLayout>
    );
};

export default CampaignBudget;
