import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

import { logAnalytics } from "../../actions";
import Button from "../../common/components/button";
import { resetState } from "../../actions/actions-image-overlay-ads";

const ThankYou = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetState());
    }, []);

    const handleButtonClick = () => {
        dispatch(
            logAnalytics({
                hitType: "event",
                eventCategory: "Image ad creator flow",
                eventAction: "Button click",
                eventLabel: "Create another design",
            })
        );

        history.replace("/ad-creative/image-ad-creator/welcome/");
    };

    return (
        <Fragment>
            <div className="">
                <div className="w-11/12 md:w-2/3 mx-auto font-sf-text flex flex-col items-center justify-center h-screen">
                    <div className="text-left">
                        <h1 className="page-title pl-1">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            Congrats! You're on your way to running stunning Facebook image ads.{" "}
                            <span role="img" aria-label="Partying Face">
                                🥳
                            </span>
                        </h1>
                        <p className="page-subtitle  pl-1 mt-10">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            Your ad image has been downloaded. Want another one? Go for it - it's FREE.
                        </p>
                        <div className="mt-10">
                            <Button primary={true} onClick={handleButtonClick}>
                                Create another design
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(ThankYou);
