import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Button from "../../common/components/button";

const TemplateItem = (props) => {
    const { item, onSelect } = props;

    const { selected = false } = item;

    useEffect(() => {
        if (selected) {
            item.selected = true;
            onSelect();
        }
    }, []);

    return (
        <div>
            <div className="list-item-grid--container m-10">
                <div className="container">
                    {item.isUpgrade ? (
                        <>
                            <div className="upgradeCanvas">{item.RenderedItem}</div>
                            <div className="upgradeButton">
                                <button
                                    type="button"
                                    className="border rounded-md inline-flex items-center m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline text-white px-12 py-4 px-10 text-white-900 hover:text-white-900 hover:border-white-800"
                                    onClick={() => item.onSelect()}
                                >
                                    <span className="flex items-center gap-x-3 font-bold">Request custom design</span>
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="canvas">{item.RenderedItem}</div>
                            <div className="button">
                                <Button
                                    class="px-10"
                                    primary={true}
                                    onClick={() => item.onSelect(item.name, item.design_name)}
                                >
                                    Choose design
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <style jsx={true}>{`
                .container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .canvas,
                upgradeCanvas {
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: 0.5s ease;
                    backface-visibility: hidden;
                }

                .button {
                    transition: 0.5s ease;
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    left: 48%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }
                .upgradeButton {
                    transition: 0.5s ease;
                    opacity: 1;
                    position: absolute;
                    top: 75%;
                }

                .upgradeButton:hover {
                    opacity: 0.5;
                }
                .container:hover .canvas {
                    opacity: 0.3;
                }

                .container:hover .button {
                    opacity: 1;
                }
                @media screen and (max-width: 768px) {
                    .upgradeButton button {
                        padding: 5px 10px;
                        font-size: 8px;
                    }
                }
                @media screen and (max-width: 700px) {
                    .upgradeButton button {
                        padding: 8px 24px;
                        font-size: 10px;
                    }
                }
                @media screen and (max-width: 430px) {
                    .upgradeButton button {
                        padding: 5px 10px;
                        font-size: 8px;
                    }
                }
            `}</style>
        </div>
    );
};

TemplateItem.propTypes = {
    item: PropTypes.shape({
        selected: PropTypes.bool,
        name: PropTypes.string,
        image: PropTypes.string,
        isUpgrade: PropTypes.bool,
    }).isRequired,
    onSelect: PropTypes.func,
};

TemplateItem.defaultProps = {
    onSelect: () => {},
};

export default TemplateItem;
