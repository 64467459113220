export const TOGGLE_TOAST_POPUP = "TOGGLE_TOAST_POPUP";

export const toggleToastPopup = (messages = null, type = null) => {
    return {
        type: TOGGLE_TOAST_POPUP,
        payload: {
            type: type,
            messages: messages,
        },
    };
};
